import React from 'react';

type WordIconProps = {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
};

export const WordIcon: React.FC<WordIconProps> = ({ width, height, style }) => (
  <svg
    width={width ? width : '18'}
    height={height ? height : '18'}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_801_1559)">
      <path
        d="M17.4375 15.1876H9.5625C9.252 15.1876 9 14.9367 9 14.6251C9 14.3135 9 3.68562 9 3.37512C9 3.06462 9.252 2.81262 9.5625 2.81262H17.4375C17.748 2.81262 18 3.06462 18 3.37512V14.6251C18 14.9367 17.748 15.1876 17.4375 15.1876Z"
        fill="#ECEFF1"
      />
      <path
        d="M15.1875 6.18762H9.5625C9.252 6.18762 9 5.93675 9 5.62512C9 5.3135 9.252 5.06262 9.5625 5.06262H15.1875C15.498 5.06262 15.75 5.31462 15.75 5.62512C15.75 5.93562 15.498 6.18762 15.1875 6.18762Z"
        fill="#1976D2"
      />
      <path
        d="M15.1875 8.43762H9.5625C9.252 8.43762 9 8.18675 9 7.87512C9 7.5635 9.252 7.31262 9.5625 7.31262H15.1875C15.498 7.31262 15.75 7.56462 15.75 7.87512C15.75 8.18562 15.498 8.43762 15.1875 8.43762Z"
        fill="#1976D2"
      />
      <path
        d="M15.1875 10.6876H9.5625C9.252 10.6876 9 10.4367 9 10.1251C9 9.8135 9.252 9.56262 9.5625 9.56262H15.1875C15.498 9.56262 15.75 9.81462 15.75 10.1251C15.75 10.4356 15.498 10.6876 15.1875 10.6876Z"
        fill="#1976D2"
      />
      <path
        d="M15.1875 12.9376H9.5625C9.252 12.9376 9 12.6867 9 12.3751C9 12.0635 9.252 11.8126 9.5625 11.8126H15.1875C15.498 11.8126 15.75 12.0646 15.75 12.3751C15.75 12.6856 15.498 12.9376 15.1875 12.9376Z"
        fill="#1976D2"
      />
      <path
        d="M9.92138 0.693116C9.79313 0.585116 9.621 0.540116 9.459 0.572741L0.459 2.26024C0.1935 2.30974 0 2.54262 0 2.81262V15.1876C0 15.4576 0.192375 15.6905 0.459 15.74L9.459 17.4275C9.49387 17.4342 9.52763 17.4376 9.5625 17.4376C9.693 17.4376 9.82013 17.3926 9.92138 17.3082C10.0508 17.2014 10.125 17.0427 10.125 16.8751V1.12512C10.125 0.957491 10.0508 0.799991 9.92138 0.693116Z"
        fill="#1565C0"
      />
      <path
        d="M7.30921 11.8756C7.27996 12.1333 7.07746 12.3391 6.81983 12.3706C6.79621 12.374 6.77371 12.3751 6.75008 12.3751C6.51833 12.3751 6.30683 12.2311 6.22358 12.0106L5.06258 8.91464L3.90158 12.0106C3.81158 12.2514 3.57533 12.3988 3.31433 12.3718C3.05896 12.3448 2.85421 12.1468 2.81821 11.8914L2.25571 7.95389C2.21183 7.64789 2.42558 7.36214 2.73271 7.31827C3.04096 7.27327 3.32558 7.48814 3.36946 7.79527L3.62033 9.55477L4.53496 7.11464C4.69921 6.67589 5.42371 6.67589 5.58908 7.11464L6.45084 9.41189L6.75346 6.68714C6.78833 6.37889 7.07408 6.15727 7.37446 6.18989C7.68383 6.22589 7.90546 6.50377 7.87171 6.81202L7.30921 11.8756Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <clipPath id="clip0_801_1559">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
