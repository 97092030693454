import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Modal, Form, Select, message } from 'antd';
import { createDocumentChecklist } from '@app/api/documentChecklist.api';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DocumentChecklist } from '@app/types/documentCheckTypes';
import { useErrorHandling } from '@app/hooks/useErrorHandling';

type ModalCreateDocumentChecklistBasicProps = {
  showDocumentChecklistModal: boolean;
  onCloseModal: () => void;
  onDocumentChecklistCreated: (id: string) => void;
};

export const ModalCreateDocumentChecklistBasic: React.FC<ModalCreateDocumentChecklistBasicProps> = ({
  showDocumentChecklistModal,
  onCloseModal,
  onDocumentChecklistCreated,
}) => {
  const { t } = useTranslation();
  const companyState = useAppSelector((state) => state.company);
  const user = useAppSelector((state) => state.user.user);
  const [form] = Form.useForm();
  const { handleApiError } = useErrorHandling();

  const handleSubmit = async () => {
    form.validateFields().then(async (values) => {
      try {
        const companyId = companyState._id ?? '';

        const documentChecklistData: DocumentChecklist = {
          name: values.name.trim(),
          createdBy: user?._id ?? '',
          companyId,
          elements: [],
          category: values.category.trim(),
          schemaVersion: 2,
        };

        const id = await createDocumentChecklist(documentChecklistData);
        message.success(t('documentChecker.checklistCreated'));
        onDocumentChecklistCreated(id);
        onCloseModal();
      } catch (error) {
        console.log('Error during creation document checklist', error);
        handleApiError(error);
      }
    });
  };

  const handleCancel = () => {
    onCloseModal();
    form.resetFields();
  };

  return (
    <Modal
      title={t('documentChecker.createNewChecklist')}
      open={showDocumentChecklistModal}
      onOk={handleSubmit}
      onCancel={handleCancel}
      bodyStyle={{ maxHeight: '50vh', overflowY: 'scroll' }}
      okText={t('save')}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          rules={[{ required: true, message: t('enterTitleMessage') }]}
          style={{ marginBottom: '0.5rem' }}
          label={t('documentChecker.nameOfTheChecklist')}
        >
          <BaseInput maxLength={50} showCount placeholder={t('documentChecker.checklistNameInputPlaceholder')} />
        </Form.Item>
        <Form.Item
          key={'category'}
          labelCol={{ span: 24 }}
          label={t('Kategorie')}
          name={'category'}
          rules={[{ required: true, message: t('requiredField') }]}
        >
          <Select placeholder={t('documentChecker.checklistCategoryPlaceholder')}>
            <Select.Option value="LEGAL_DOCUMENT">Legal document</Select.Option>
            <Select.Option value="TECHNICAL_DOCUMENT">Technical document</Select.Option>
            <Select.Option value="OTHER_DOCUMENT">Other document</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
