import React from 'react';
import { Modal, Form, Button, message } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector } from '@app/hooks/reduxHooks';
import moment from 'moment';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ISourceUpdateMonitoring } from '@app/domain/SourceUpdateMonitoringModel';
import { createSourceUpdateMonitoring } from '@app/api/sourceUpdateMonitoring.api';

type ModalCreateNewUpdateMonitoringProps = {
  showCreateModal: boolean;
  numberExistentMonitorings: number;
  onCloseModal: (createdItem?: ISourceUpdateMonitoring) => void;
};

const MaxUpdateMonitorings = Number.parseInt(process.env.REACT_APP_MAX_UPDATE_MONITORINGS ?? '') || 10;

export const ModalCreateNewUpdateMonitoring: React.FC<ModalCreateNewUpdateMonitoringProps> = ({
  showCreateModal,
  numberExistentMonitorings,
  onCloseModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const companyState = useAppSelector((state) => state.company);
  const user = useAppSelector((state) => state.user.user);

  const maxLimitExceed = numberExistentMonitorings > MaxUpdateMonitorings;

  const handleOkCreateNew = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          const monitoringItem: ISourceUpdateMonitoring = {
            title: values.title,
            description: values.description,
            contextDescription: '',
            ignoreTopicsDescription: '',
            createdByUserId: user?._id ?? '',
            companyId: companyState._id ?? '',
            timestampLastUpdatedAt: moment().unix(),
            intervalCheckForUpdatesInHours: 24,
            sources: [],
            sendNotificationsToEmails: [],
            sendNotificationsIfNoChangesFound: false,
            isActive: true,
          };
          const id = await createSourceUpdateMonitoring(monitoringItem);
          monitoringItem._id = id;
          form.resetFields();
          onCloseModal(monitoringItem);
          message.success(t('knowledgeAreaCreatedSuccessfully', { title: values.title }));
        } catch (error) {
          console.log('****** handleOkCreateNew error: ', error);
          if (error instanceof Error) {
            notificationController.error({ message: error.message });
          } else if (typeof error === 'string') {
            notificationController.error({ message: error });
          } else {
            notificationController.error({
              message: t('knowledgeAreaCreationError'),
            });
          }
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    onCloseModal();
    form.resetFields();
  };

  if (maxLimitExceed) {
    return (
      <Modal
        title={t('sourceUpdateMonitoring.createNewDialogTitle')}
        open={showCreateModal}
        onCancel={handleCancel}
        onOk={handleCancel}
      >
        <NormalText size="m" style={{ marginBottom: '1rem' }}>
          {t('sourceUpdateMonitoring.maxMonitoringItemsReached', { count: MaxUpdateMonitorings })}
        </NormalText>
      </Modal>
    );
  }

  if (!companyState._id) {
    return (
      <Modal
        title={t('sourceUpdateMonitoring.createNewDialogTitle')}
        open={showCreateModal}
        onCancel={handleCancel}
        onOk={handleCancel}
      >
        <NormalText size="m" style={{ marginBottom: '1rem' }}>
          {t('pleaseCreateCompanyFirst')}
        </NormalText>
        <Button
          onClick={() => {
            handleCancel();
            navigate('/company-overview');
          }}
        >
          {t('hereCreateCompany')}
        </Button>
      </Modal>
    );
  }

  return (
    <Modal
      title={t('sourceUpdateMonitoring.createNewDialogTitle')}
      open={showCreateModal}
      onOk={handleOkCreateNew}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          rules={[{ required: true, message: t('enterTitleMessage') }]}
          style={{ marginBottom: '0.5rem' }}
        >
          <BaseInput maxLength={50} showCount placeholder={t('sourceUpdateMonitoring.title')} />
        </Form.Item>

        <Form.Item name="description">
          <BaseInput placeholder={t('sourceUpdateMonitoring.descriptionOptional')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
