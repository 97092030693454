import { httpApi } from '@app/api/http.api';
import { getErrorText } from '@app/utils/apiHelpers';
import { setGlobalSessionExpired } from '@app/services/reduxStorage.service';
import { DocumentChecklist, DocumentChecklistCreateModel } from '@app/types/documentCheckTypes';

export const readAllChecklistsOfCompany = (companyId: string): Promise<DocumentChecklist[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/documentChecklist/${companyId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readAllChecklistsOfCompany failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const createDocumentChecklist = (documentChecklistData: DocumentChecklistCreateModel): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(`/api/v1/documentChecklist`, documentChecklistData);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('createDocumentChecklist failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const updateDocumentChecklist = (
  documentChecklistId: string,
  documentChecklistData: Partial<DocumentChecklistCreateModel>,
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(
        `/api/v1/documentChecklist/update/${documentChecklistId}`,
        documentChecklistData,
      );
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('update checklist failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const deleteDocumentChecklist = (documentChecklistId: string): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.delete(`/api/v1/documentChecklist/${documentChecklistId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('delete checklist failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};
