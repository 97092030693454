import { Space } from 'antd';
import styled from 'styled-components';

export const UserEditInfo = styled.div`
  margin-bottom: 1rem;
`;

export const InfoTextRow = styled(Space)`
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: var(--background-base-color);
  margin-bottom: 1rem;
`;
