import styled from 'styled-components';
import { Table as AntdTable } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

export const Table = styled(AntdTable)`
  & .ant-table-thead .ant-table-cell {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.md};
    font-weight: 600;
  }

  & .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-color: var(--lightgrey);
  }
`;
