import { useAppSelector } from '@app/hooks/reduxHooks';
import React, { useEffect, useState } from 'react';
import { Button, Col, Drawer, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons-react';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { IconDownload } from '@tabler/icons-react';
import { themeObject } from '@app/styles/themes/themeVariables';
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { RenderShowSearchPopoverProps, SingleKeyword, searchPlugin } from '@react-pdf-viewer/search';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { NavigationWrapper } from './PdfViewer.styles';
import { stringToShortSnippetsArray } from '@app/utils/utils';
import { HighlightTextBlock } from './SourceAuditDrawer';
import { SearchOutlined } from '@ant-design/icons';
import PuffLoader from 'react-spinners/PuffLoader';

export interface PropTypes {
  open: boolean;
  title: string;
  url: string;
  onClose: () => void;
  highlightBlock?: HighlightTextBlock;
  navigateToNextTextMatch?: (step: number) => void;
  showNavigationBlock?: boolean;
  currentTextMatchIndex?: number;
  highlightsCount?: number;
  loadingHighlitingData?: boolean;
}

const PdfViewer: React.FC<PropTypes> = ({
  open,
  title,
  url,
  onClose,
  highlightBlock,
  navigateToNextTextMatch,
  showNavigationBlock,
  currentTextMatchIndex,
  highlightsCount,
  loadingHighlitingData,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { mobileOnly } = useResponsive();
  const { t } = useTranslation();
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);

  const searchPluginInstance = searchPlugin();
  const { highlight, clearHighlights, ShowSearchPopover } = searchPluginInstance;

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  useEffect(() => {
    if (isDocumentLoaded && highlightBlock) {
      try {
        const highlightData: RegExp[] = [];

        if (highlightBlock.pageNumber) {
          jumpToPage(highlightBlock.pageNumber - 1);
        }

        if (highlightBlock.relevantSenteces.length) {
          for (const source of highlightBlock.relevantSenteces) {
            const regexString = source.replace(/\s+/g, '\\s*');
            const regex = new RegExp(regexString, 'g');
            highlightData.push(regex);
          }

          clearHighlights();
          setTimeout(async () => {
            const matchRes = await highlight(highlightData);
            if (matchRes.length === 0) {
              const highlightDataByLines: SingleKeyword[] = [];
              for (const content of highlightBlock.relevantSenteces) {
                const lines = stringToShortSnippetsArray(content);
                lines.forEach((keyword) => {
                  if (keyword.length > 1) {
                    const regexString = keyword.replace(/\s+/g, '\\s*');
                    const regex = new RegExp(regexString, 'g');
                    highlightDataByLines.push(regex);
                  }
                });
              }

              const matchRes2 = await highlight(highlightDataByLines);
              if (matchRes2.length === 0) {
                fallbackHighlighting();
              }
            }
          }, 1000);
        } else {
          fallbackHighlighting();
        }
      } catch (e) {
        console.log('****** s1 highlight passages error: ', e);
        fallbackHighlighting();
      }
    }
  }, [isDocumentLoaded, highlightBlock]);

  const fallbackHighlighting = () => {
    if (highlightBlock?.pageContent) {
      try {
        const highlightData: SingleKeyword[] = [];

        const shortSnippets = stringToShortSnippetsArray(highlightBlock.pageContent);

        // Sortiere die Keywords nach ihrer Länge in absteigender Reihenfolge
        const sortedKeywords = shortSnippets.sort((a, b) => b.length - a.length);

        // Berechne die Anzahl der Top 70% Keywords
        const topPercentCount = Math.ceil(sortedKeywords.length * 0.7);

        // Wähle die Top 70% Keywords aus
        const topPercentKeywords = sortedKeywords.slice(0, topPercentCount);

        topPercentKeywords.forEach((keyword) => {
          if (keyword.length > 1) {
            highlightData.push({ keyword, matchCase: true });
          }
        });
        clearHighlights();
        setTimeout(async () => {
          const matchRes = await highlight(highlightData);
          if (matchRes.length === 0) {
            // TODO: handle not able to highlight anything
          }
        }, 1000);
      } catch (error) {
        console.log('****** s2 highlight passages error: ', error);
      }
    }
  };

  return (
    <Drawer
      width={mobileOnly ? '90%' : '80%'}
      title={
        <Row justify={'space-between'} align={'middle'} gutter={20}>
          <Col>
            <Space>
              <NormalText size="l" semiBold>
                {t('documentTitle', { docTitle: title })}
              </NormalText>
              {loadingHighlitingData && <PuffLoader color={themeObject[theme].primary} loading={true} size={16} />}
            </Space>
          </Col>
          <Col>
            <Space>
              <div style={{ marginRight: 100 }}>
                <ShowSearchPopover>
                  {(props: RenderShowSearchPopoverProps) => (
                    <Button type="link" size="small" onClick={props.onClick} icon={<SearchOutlined />}>
                      {t('pdfSearch')}
                    </Button>
                  )}
                </ShowSearchPopover>
              </div>
              <Button
                onClick={() => {
                  window.open(url, '_blank');
                }}
                type="text"
              >
                <IconDownload style={{ width: '1.25rem', height: '1.25rem', color: themeObject[theme].primary }} />
              </Button>
            </Space>
          </Col>
        </Row>
      }
      placement="right"
      onClose={() => {
        setDocumentLoaded(false);
        onClose();
      }}
      open={open}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          fileUrl={url}
          plugins={[searchPluginInstance, pageNavigationPluginInstance]}
          onDocumentLoad={() => setDocumentLoaded(true)}
          defaultScale={SpecialZoomLevel.PageWidth}
        />
      </Worker>
      {showNavigationBlock && navigateToNextTextMatch && (
        <NavigationWrapper>
          <Button type="primary" onClick={() => navigateToNextTextMatch(-1)}>
            <IconArrowNarrowLeft />
          </Button>
          <NormalText size="l" horizontalPadding>{`${currentTextMatchIndex} / ${highlightsCount}`}</NormalText>
          <Button type="primary" onClick={() => navigateToNextTextMatch(1)}>
            <IconArrowNarrowRight />
          </Button>
        </NavigationWrapper>
      )}
    </Drawer>
  );
};

export default PdfViewer;
