import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { Form, Input } from 'antd';
import * as S from './CompanyOverview.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { createCompanyData, updateCompanyData } from '@app/store/slices/companySlice';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { Button as GradientButton } from '@app/components/common/buttons/Button/Button';

export const CompanyData: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const companyState = useAppSelector((state) => state.company);

  const dispatch = useAppDispatch();

  useEffect(() => {
    form.setFieldsValue({
      ...companyState,
    });
  }, [form, companyState]);

  const onResetFields = () => {
    form.resetFields();
  };

  const onSave = async () => {
    const companyName = form.getFieldValue('companyName');
    const companyDescription = form.getFieldValue('companyDescription');
    const companyWhy = form.getFieldValue('companyWhy');
    if (companyState._id) {
      await dispatch(updateCompanyData({ companyName, companyDescription, companyWhy }));
      notificationController.success({ message: t('common.success') });
    } else {
      await dispatch(createCompanyData({ companyName, companyDescription, companyWhy }));
      notificationController.success({ message: t('common.success') });
    }
  };

  return (
    <>
      {!companyState._id && <NormalText>{t('adminCreateCompany')}</NormalText>}
      <Col xs={24} xl={16}>
        <Form form={form}>
          <Form.Item
            key={'companyName'}
            labelCol={{ span: 24 }}
            label={t('companyName')}
            name={'companyName'}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            key={'companyDescription'}
            labelCol={{ span: 24 }}
            label={t('whatDoesCompanyDo')}
            name={'companyDescription'}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item key={'companyWhy'} labelCol={{ span: 24 }} label={t('whyOfCompany')} name={'companyWhy'}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
        <S.FooterContainer>
          <Row justify={'space-between'}>
            <Button type="dashed" onClick={() => onResetFields()}>
              {t('resetAll')}
            </Button>
            <GradientButton type="primary" onClick={() => onSave()}>
              {t('save')}
            </GradientButton>
          </Row>
        </S.FooterContainer>
      </Col>
    </>
  );
};
