import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import PageContainer from '@app/components/layouts/main/PageContainer/PageContainer';
import { CompanyOverview } from '@app/components/company/CompanyOverview';

const CompanyOverviewPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle>{t('company.headerTitle')}</PageTitle>
      <CompanyOverview />
    </PageContainer>
  );
};

export default CompanyOverviewPage;
