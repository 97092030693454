import React, { useEffect, useState } from 'react';
import { Checkbox, Input, List } from 'antd';
import { IconSearch } from '@tabler/icons-react';
import { DocumentFilterType } from '@app/types/documentFilterTypes';
import { useTranslation } from 'react-i18next';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { debounce } from 'lodash';

type SearchListProps = {
  listData: DocumentFilterType[];
  isLoadingData: boolean;
  selectedItems: DocumentFilterType[];
  handleItemSelect: (itemId: string, checked: boolean) => void;
};

export const SearchList: React.FC<SearchListProps> = ({ listData, isLoadingData, selectedItems, handleItemSelect }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [list, setList] = useState<DocumentFilterType[]>([]);

  const { t } = useTranslation();

  const debouncedSearch = debounce(setSearchValue, 300);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  useEffect(() => {
    const listToSet = searchValue
      ? listData.filter((listItem) => listItem.label.toLowerCase().includes(searchValue.toLowerCase()))
      : listData;
    setList(listToSet);
  }, [searchValue, listData]);

  return (
    <>
      <Input
        placeholder={t('searchByNamePlaceholder')}
        onChange={(e) => handleSearch(e)}
        style={{ borderRadius: '8px 8px 0 0' }}
        suffix={<IconSearch size={16} />}
      />
      <List
        bordered
        dataSource={list}
        loading={isLoadingData}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <Checkbox
              checked={selectedItems.some((si) => si.id === item.id)}
              onChange={(e) => handleItemSelect(item.id, e.target.checked)}
            >
              <NormalText light>{item.label}</NormalText>
            </Checkbox>
          </List.Item>
        )}
        size={'small'}
        style={{ borderRadius: '0 0 8px 8px', maxHeight: 300, overflowY: 'scroll' }}
      />
    </>
  );
};
