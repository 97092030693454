import { IconCheck, IconClipboard, IconDownload } from '@tabler/icons-react';
import { FC, memo, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { useTranslation } from 'react-i18next';
import { generateRandomString, programmingLanguages } from '@app/utils/codeblock';
import { CodeBlockButton, CodeBlockButtons, CodeBlockTitle, CodeBlockTop, StyledCodeBlock } from './CodeBlock.styles';

interface Props {
  language: string;
  value: string;
}

export const CodeBlock: FC<Props> = memo(({ language, value }) => {
  const { t } = useTranslation('markdown');
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyToClipboard = () => {
    if (!navigator.clipboard || !navigator.clipboard.writeText) {
      return;
    }

    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };
  const downloadAsFile = () => {
    const fileExtension = programmingLanguages[language] || '.file';
    const suggestedFileName = `file-${generateRandomString(3, true)}${fileExtension}`;
    const fileName = window.prompt(t('Enter file name') || '', suggestedFileName);

    if (!fileName) {
      // user pressed cancel on prompt
      return;
    }

    const blob = new Blob([value], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  return (
    <StyledCodeBlock>
      <CodeBlockTop>
        <CodeBlockTitle>{language}</CodeBlockTitle>

        <CodeBlockButtons>
          <CodeBlockButton onClick={copyToClipboard}>
            {isCopied ? t('Copied!') : ''}
            {isCopied ? <IconCheck size={18} /> : <IconClipboard size={18} />}
          </CodeBlockButton>
          <CodeBlockButton onClick={downloadAsFile}>
            <IconDownload size={18} />
          </CodeBlockButton>
        </CodeBlockButtons>
      </CodeBlockTop>

      <SyntaxHighlighter language={language} style={oneDark} customStyle={{ margin: 0 }}>
        {value}
      </SyntaxHighlighter>
    </StyledCodeBlock>
  );
});
CodeBlock.displayName = 'CodeBlock';
