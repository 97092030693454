import { useCallback, useEffect, useMemo, useState } from 'react';
import { message, UploadFile } from 'antd';
import { IUploadedDocument } from '@app/domain/UploadedDocumentModel';
import {
  createUploadedDocument,
  deleteUploadedFileByPath,
  UploadFileResult,
  getAllChatUploadedDocuments,
  deleteUploadedFilesByChatId,
} from '@app/api/uploadedDocument.api';
import { debounce } from 'lodash';
import moment from 'moment';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { useTranslation } from 'react-i18next';
import { updateChatHistory } from '@app/api/chatHistory.api';
import { setLoadedChatHistory } from '@app/store/slices/chatBoxSlice';

type useHandleMultipleFileUploadsInChatProps = {
  selectedChatId?: string;
  onProcessQueueError?: (error: any) => void;
};

export const useHandleMultipleFileUploadsInChat = ({
  selectedChatId,
  onProcessQueueError,
}: useHandleMultipleFileUploadsInChatProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [uploaderSelectedFiles, setUploaderSelectedFiles] = useState<UploadFile[]>([]);

  const [uploadedFiles, setUploadedFiles] = useState<IUploadedDocument[]>([]);
  const [pendingFiles, setPendingFiles] = useState<IUploadedDocument[]>([]);

  const [someProcessingFileExists, setSomeProcessingFileExists] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const alreadyUploadedFiles = useMemo(() => [...pendingFiles, ...uploadedFiles], [pendingFiles, uploadedFiles]);

  const user = useAppSelector((state) => state.user.user);
  const companyState = useAppSelector((state) => state.company);
  const loadedChatHistory = useAppSelector((state) => state.chatBox.loadedChatHistory);

  const readAllFiles = useCallback(
    async (updateChathistory: boolean) => {
      if (!selectedChatId || !companyState._id || !user?._id) return;
      try {
        const result = await getAllChatUploadedDocuments(companyState._id, user._id, selectedChatId);

        if (result) {
          const processingFileExists = result.some((item) => documentIsProcessing(item.processingState));
          setSomeProcessingFileExists(processingFileExists);
          setUploadedFiles(result);
          setPendingFiles((prevPending) =>
            prevPending.filter((pf) => {
              const processingTime = moment().diff(moment.unix(pf.uploadedAt), 'minutes');
              const processingError = documentIsProcessing(pf.processingState) && processingTime > 5;
              return !result.some((uq) => uq.title === pf.title) && !processingError;
            }),
          );
        }
        if (updateChathistory) {
          const uploadedFiles = result ? result.length : 0;
          dispatch(
            setLoadedChatHistory({
              value: loadedChatHistory.map((item) => (item._id === selectedChatId ? { ...item, uploadedFiles } : item)),
            }),
          );
          await updateChatHistory(selectedChatId, { uploadedFiles });
        }
        return result;
      } catch (error) {
        console.log('****** readAllFiles error: ', error);
        if (error instanceof Error) {
          notificationController.error({ message: error.message });
        } else if (typeof error === 'string') {
          notificationController.error({ message: error });
        } else {
          notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
        }
      }
    },
    [selectedChatId, companyState, user],
  );

  const debouncedReadAllFiles = debounce(readAllFiles, 500);

  useEffect(() => {
    if (selectedChatId) {
      debouncedReadAllFiles(false);
    }
  }, [selectedChatId, companyState, user]);

  const documentIsProcessing = (processingState?: string) => {
    return processingState ? ['WAITING_FOR_PROCESSING', 'PROCESSING'].includes(processingState) : false;
  };

  const onNewFileUploaded = async (file: UploadFile) => {
    if (uploadedFiles.some((f) => f.title === file.name) || uploaderSelectedFiles.some((f) => f.name === file.name)) {
      message.warn('This file is already selected');
      return;
    }
    if (uploadedFiles.length + uploaderSelectedFiles.length >= 5) {
      message.warn('Max. 5 files can be selected');
      return;
    }
    setUploaderSelectedFiles((prev) => [...prev, file]);
  };

  const uploadChatFiles = async () => {
    try {
      if (!selectedChatId) {
        notificationController.error({ message: `Error: missing chatId` });
        return [];
      }
      if (uploaderSelectedFiles.length) {
        setUploadInProgress(true);
        const formData = new FormData();
        for (let index = 0; index < uploaderSelectedFiles.length; index++) {
          const fileToUpload = uploaderSelectedFiles[index];

          formData.append('files', fileToUpload.originFileObj as Blob);

          const uploadedDocument: IUploadedDocument = {
            title: fileToUpload.name,
            url: '',
            thematicAreaId: undefined,
            knowledgeAreaId: undefined,
            entityType: 'AI_CHAT',
            entityId: selectedChatId,
            companyId: companyState._id ?? '',
            createdByUserId: user?._id,
            uploadedAt: moment().unix(),
            ingestedInVectorDb: false,
            userComment: '',
            validFrom: 0,
            validUntil: 0,
          };
          formData.append(`uploadedDocumentsData[${index}]`, JSON.stringify(uploadedDocument));
        }

        const uploadResults: UploadFileResult = await createUploadedDocument(formData);
        if (uploadResults?.processingErrors?.length) {
          uploadResults.processingErrors.forEach((error) => {
            onProcessQueueError?.(`Error during processing the file "${error.documentTitle}": ${error.errorText}`);
            setPendingFiles((prevPending) => prevPending.filter((pf) => pf.title !== error.documentTitle));
          });
        }
        setUploaderSelectedFiles([]);
        return await readAllFiles(true);
      } else {
        return [];
      }
    } catch (error) {
      onProcessQueueError?.(`Error during file upload "${error}"`);
    } finally {
      setUploadInProgress(false);
    }
  };

  const onDeleteUploadedFile = async (title: string) => {
    try {
      const fileStoragePath = alreadyUploadedFiles.find((item) => item.title === title)?.fileStoragePath;
      if (!fileStoragePath) {
        notificationController.error({ message: `Error during deletion: invalid configuration` });
        return;
      }
      await deleteUploadedFileByPath(fileStoragePath);
      readAllFiles(true);
    } catch (error) {
      notificationController.error({ message: `Error during deletion ${error}` });
    }
  };

  const onDeleteUploaderSelectedFile = async (name: string) => {
    setUploaderSelectedFiles((prev) => prev.filter((file) => file.name !== name));
  };

  const onDeleteAllChatFiles = async () => {
    try {
      if (alreadyUploadedFiles.length) {
        if (!selectedChatId) {
          notificationController.error({ message: `Error during deletion: missing chatId` });
          return;
        }
        await deleteUploadedFilesByChatId(selectedChatId);
      }
      setUploaderSelectedFiles([]);
      setUploadedFiles([]);
      setPendingFiles([]);
    } catch (error) {
      notificationController.error({ message: `Error during deletion ${error}` });
    }
  };

  return {
    alreadyUploadedFiles,
    uploaderSelectedFiles,
    someProcessingFileExists,
    uploadChatFiles,
    readAllFiles,
    debouncedReadAllFiles,
    uploadInProgress,
    onDeleteUploadedFile,
    onDeleteUploaderSelectedFile,
    onDeleteAllChatFiles,
    onNewFileUploaded,
  };
};
