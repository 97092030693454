import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { useResponsive } from '@app/hooks/useResponsive';
import { SendMessageSuperDoWrapper } from './SendMessage.styles';
import { useTranslation } from 'react-i18next';
import { IconPlayerStop } from '@tabler/icons-react';
import { SendOutlined } from '@ant-design/icons';

interface SuperDoSendMessageProps {
  messageIsProcessing: boolean;
  onSendMessage: (message: string) => void;
  handleOnStop?: () => void;
}

const SuperDoSendMessage: React.FC<SuperDoSendMessageProps> = ({
  messageIsProcessing,
  onSendMessage,
  handleOnStop,
}) => {
  const [message, setMessage] = useState('');

  const { mobileOnly } = useResponsive();
  const { t } = useTranslation();

  const sendMessage = async () => {
    if (messageIsProcessing) return;
    const trimmedMessage = message.trim();
    if (trimmedMessage === '') {
      return;
    }
    onSendMessage(trimmedMessage);
    setMessage('');
    //setFileList([]);
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey) {
      setMessage(message);
    } else if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <SendMessageSuperDoWrapper style={{ position: 'sticky', bottom: '0px', zIndex: 1000, margin: '0 12px' }}>
      <div
        style={{
          backgroundColor: 'white',
          border: '2px solid var(--border-color)',
          borderRadius: 8,
          boxShadow: '0 6px 6px 0 rgba(0, 0, 0, 0.1)',
        }}
      >
        <Row justify={'space-between'} align={'middle'}>
          <Col span={22}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextArea
                autoSize={{ minRows: 1, maxRows: 6 }}
                size={mobileOnly ? 'middle' : 'large'}
                placeholder={t('messageToKiAssistantSuperDo')}
                allowClear
                value={message}
                bordered={false}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                onKeyDown={(e) => handleKeyPress(e)}
              />
            </div>
          </Col>
          <Col span={2}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginRight: '1rem' }}>
              <Button
                //loading={messageIsProcessing}
                type="primary"
                onClick={() => (messageIsProcessing && handleOnStop ? handleOnStop() : sendMessage())}
                icon={messageIsProcessing ? <IconPlayerStop /> : <SendOutlined />}
              />
            </div>
          </Col>
        </Row>
      </div>
    </SendMessageSuperDoWrapper>
  );
};

export default SuperDoSendMessage;
