export type SessionInfo = {
  ice_servers2: RTCIceServer[];
  sdp: RTCSessionDescriptionInit;
  session_id: string;
};

const apiKey = process.env.REACT_APP_HEYGEN_API_KEY || '';
const SERVER_URL = 'https://api.heygen.com';

const requestOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': apiKey,
  },
};

export async function createSession(quality: string, avatar_name: string, voice_id: string): Promise<SessionInfo> {
  const response = await fetch(`${SERVER_URL}/v1/streaming.new`, {
    ...requestOptions,
    body: JSON.stringify({
      quality,
      avatar_name,
      voice: {
        voice_id: voice_id,
      },
    }),
  });
  const data = await response.json();
  if (data.code === 10007) {
    throw new Error('reach user session limit');
  }
  return data.data;
}

export async function startSession(session_id: string, sdp: RTCSessionDescriptionInit) {
  const response = await fetch(`${SERVER_URL}/v1/streaming.start`, {
    ...requestOptions,
    body: JSON.stringify({ session_id, sdp }),
  });
  const data = await response.json();
  return data.data;
}

export async function handleICE(session_id: string, candidate: RTCIceCandidateInit) {
  const response = await fetch(`${SERVER_URL}/v1/streaming.ice`, {
    ...requestOptions,
    body: JSON.stringify({ session_id, candidate }),
  });
  if (response.status === 500) {
    console.log('Server Error. Error in handleIce function');
    throw new Error('Server error');
  } else {
    const data = await response.json();
    return data;
  }
}

export async function talkAvatar(session_id: string, text: string) {
  const response = await fetch(`${SERVER_URL}/v1/streaming.task`, {
    ...requestOptions,
    body: JSON.stringify({ session_id, text }),
  });

  const data = await response.json();
  return data.data;
}

export async function stopSession(session_id: string) {
  const response = await fetch(`${SERVER_URL}/v1/streaming.stop`, {
    ...requestOptions,
    body: JSON.stringify({ session_id }),
  });
  const data = await response.json();
  return data.data;
}
