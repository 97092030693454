import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { updateKnowledgeArea } from '@app/api/knowledgeArea.api';
import { Modal, Form, Select } from 'antd';
import { setLoadedKnowledgeAreas } from '@app/store/slices/knowledgeAreaSlice';
import { DocumentParseStrategy, KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { setTriggerEventId } from '@app/store/slices/socketAppEventSlice';
import { useErrorHandling } from '@app/hooks/useErrorHandling';

type ModalSettingsKnowledgeAreaProps = {
  showModalSettingsKnowledgeArea: boolean;
  onCloseModal: () => void;
  selectedKnowledgeArea: KnowledgeAreaModel | null;
  setSelectedArea?: (knowledgeItem: KnowledgeAreaModel) => void;
};

export const ModalSettingsKnowledgeArea: React.FC<ModalSettingsKnowledgeAreaProps> = ({
  showModalSettingsKnowledgeArea,
  onCloseModal,
  selectedKnowledgeArea,
  setSelectedArea,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);

  const [form] = Form.useForm();

  const { handleApiError } = useErrorHandling();

  useEffect(() => {
    selectedKnowledgeArea &&
      form.setFieldsValue({ documentParseStrategy: selectedKnowledgeArea.settings?.documentParseStrategy || 'NORMAL' });
  }, [selectedKnowledgeArea]);

  const handleCloseModal = () => {
    form.resetFields();
    onCloseModal();
  };

  const handleOkEdit = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(values);
        try {
          if (selectedKnowledgeArea?._id) {
            const documentParseStrategy: DocumentParseStrategy =
              values.documentParseStrategy === 'OPTIMISED_FOR_STRUCTURE' ? 'OPTIMISED_FOR_STRUCTURE' : 'NORMAL';

            const knowledgeItem: KnowledgeAreaModel = {
              ...selectedKnowledgeArea,
              settings: {
                documentParseStrategy,
              },
            };
            await updateKnowledgeArea(selectedKnowledgeArea?._id, { settings: knowledgeItem.settings });

            dispatch(
              setLoadedKnowledgeAreas({
                value: loadedKnowledgeAreas.map((item) => (item._id === knowledgeItem._id ? knowledgeItem : item)),
              }),
            );
            if (setSelectedArea) {
              setSelectedArea(knowledgeItem);
            }
            dispatch(setTriggerEventId({ value: 'KNOWLEDGE_AREA_CHANGES' }));
            handleCloseModal();
          }
        } catch (error) {
          handleApiError(error);
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title={t('settingsKnowledgeArea')}
      open={showModalSettingsKnowledgeArea}
      onOk={handleOkEdit}
      onCancel={handleCloseModal}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="documentParseStrategy"
          label={t('documentParseStrategy')}
          rules={[{ required: true, message: t('pleaseSelectDocumentParseStrategy') }]}
        >
          <Select>
            <Select.Option value="NORMAL">{t('parseStrategyNormal')}</Select.Option>
            <Select.Option value="OPTIMISED_FOR_STRUCTURE">{t('parseStrategyOptimisedForStructure')}</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
