import { useEffect, useState } from 'react';
import GptChatBox from '@app/components/gptchatbox/GptChatBox';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import NoPaddingPageContainer from '@app/components/layouts/main/NoPaddingPageContainer/NoPaddingPageContainer';
import { Button, Divider, Dropdown, Menu, Row, Switch, Tabs, TabsProps, Radio } from 'antd';
import { TabBarRow } from '../Legal/AnalyzeDocument.styles';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { setTriggerActionId } from '@app/store/slices/chatBoxSlice';
import {
  SettingsButtonWrapper,
  ThinSettingsButtonWrapperWithIcon,
} from '@app/components/gptchatbox/GptChatBoxAnalyze.styles';
import { PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { AddTabButton, TabBarWrapper } from './GPTKnowledgebaseSearch.styles';
import { themeObject } from '@app/styles/themes/themeVariables';
import AutomationsOverview from '@app/components/gptchatbox/AutomationsOverview';
import VoiceAssistant from '@app/components/gptchatbox/VoiceAssistant';
import { useResponsive } from '@app/hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { ShowVoiceAssistantDemo } from '@app/constants/config/appLayout';
import {
  setContextSizeVoiceAssistant,
  setTurboSpeechModelActive,
  setWaitSpeechTextActive,
} from '@app/store/slices/settingsSlice';
import { useUserAccessRight } from '@app/hooks/useUserAccessRight';
import { useUserAccessRightToKnowledgeArea } from '@app/hooks/useUserAccessRightToKnowledgeArea';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { CobrainIcon } from '@app/components/common/icons/CobrainIcon';
import { AvatarGroup } from '@app/components/common/AvatarGroup/AvatarGroup';
import LlmSettingsModal from '@app/components/gptchatbox/LlmSettingsModal';
import { GPT_MODEL_SELECTION } from '@app/utils/constants';
import { LlmIcon } from '@app/components/common/icons/LlmIcon';

const ShowFurtherTabActions = false;

function GPTKnowledgeSearch() {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState<string>('1');
  const [selectedKnowledgeArea, setSelectedKnowledgeArea] = useState<KnowledgeAreaModel | null>(null);

  const theme = useAppSelector((state) => state.theme.theme);
  const { turboSpeechModelActive, waitSpeechTextActive, contextSizeVoiceAssistant, gptChatBoxLLM } = useAppSelector(
    (state) => state.settings,
  );
  const { isPublicAccessMode } = useAppSelector((state) => state.authExternalAccessToken);
  const companyState = useAppSelector((state) => state.company);

  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);
  const { id: selectedKnowledgeAreaId } = useParams();
  const { mobileOnly } = useResponsive();
  const isAccessToWrite = useUserAccessRight(selectedKnowledgeAreaId ?? '');
  const { accessRightTeamMembers } = useUserAccessRightToKnowledgeArea(
    selectedKnowledgeAreaId ?? '',
    companyState._id ?? '',
  );

  useEffect(() => {
    const foundKnowledgeArea = loadedKnowledgeAreas.find((item) => item._id === selectedKnowledgeAreaId);
    setSelectedKnowledgeArea(foundKnowledgeArea || null);
    setTabIndex('1');
  }, [selectedKnowledgeAreaId, loadedKnowledgeAreas]);

  const voiceAssistantTab = [
    {
      key: '3',
      label: t('voiceAssistant'),
      children: (
        <NoPaddingPageContainer>
          <VoiceAssistant />
        </NoPaddingPageContainer>
      ),
    },
    {
      key: '4',
      label: 'AI Avatar',
      children: <></>,
    },
  ];

  const standardTabs: TabsProps['items'] = [
    {
      key: '1',
      label: t('knowledgeAssistant'),
      children: (
        <NoPaddingPageContainer>
          <GptChatBox chatMode="KNOWLEDGE" />
        </NoPaddingPageContainer>
      ),
    },
    {
      key: '2',
      label: t('automations'),
      children: (
        <NoPaddingPageContainer>
          <AutomationsOverview />
        </NoPaddingPageContainer>
      ),
    },
  ];

  const tabs: TabsProps['items'] = ShowVoiceAssistantDemo ? [...standardTabs, ...voiceAssistantTab] : standardTabs;
  const items: TabsProps['items'] = isPublicAccessMode ? tabs.filter((tab) => tab.key === '1') : tabs;

  const renderSettingsButton = () => {
    return (
      <Row gutter={50} align={'middle'} style={{ marginRight: '1rem', marginBottom: '0.5rem' }}>
        {!mobileOnly && <AvatarGroup style={{ marginRight: '1rem' }} teamMembers={accessRightTeamMembers} />}
        {!mobileOnly && isAccessToWrite && !isPublicAccessMode && (
          <ThinSettingsButtonWrapperWithIcon
            onClick={() => {
              navigation('/zen/dataroom-selected', { state: selectedKnowledgeArea });
            }}
            icon={<CobrainIcon color={themeObject[theme].primary} style={{ marginRight: '0.5rem' }} />}
          >
            <NormalText semiBold size="s" colorType="primary">
              {t('dataRoom')}
            </NormalText>
          </ThinSettingsButtonWrapperWithIcon>
        )}
        {mobileOnly && isAccessToWrite && (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => navigation('/zen/dataroom-selected', { state: selectedKnowledgeArea })}>
                  {t('dataRoom')}
                </Menu.Item>
              </Menu>
            }
          >
            <ThinSettingsButtonWrapperWithIcon
              icon={<CobrainIcon color={themeObject[theme].primary} style={{ marginRight: '0.5rem' }} />}
            />
          </Dropdown>
        )}
      </Row>
    );
  };

  const renderSpeechSettingsButton = () => {
    return (
      <Row gutter={50} align={'middle'} style={{ marginRight: '1rem' }}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.ItemGroup style={{ padding: '0rem 0.5rem 0.5rem 0.5rem' }}>
                Enable Turbo Model{' '}
                <Switch
                  checked={turboSpeechModelActive}
                  onChange={() => dispatch(setTurboSpeechModelActive({ value: !turboSpeechModelActive }))}
                />
              </Menu.ItemGroup>
              <Menu.ItemGroup style={{ padding: '0rem 0.5rem 0.5rem 0.5rem' }}>
                {'Enable "Please wait phrase" '}
                <Switch
                  checked={waitSpeechTextActive}
                  onChange={() => dispatch(setWaitSpeechTextActive({ value: !waitSpeechTextActive }))}
                />
              </Menu.ItemGroup>
              <Divider />
              <Menu.ItemGroup>
                Conext Size{' '}
                <Radio.Group
                  onChange={(e) => dispatch(setContextSizeVoiceAssistant({ value: e.target.value }))}
                  defaultValue={contextSizeVoiceAssistant}
                >
                  <Radio.Button value="small">Small</Radio.Button>
                  <Radio.Button value="normal">Normal</Radio.Button>
                  <Radio.Button value="large">Large</Radio.Button>
                </Radio.Group>
              </Menu.ItemGroup>
            </Menu>
          }
        >
          <SettingsButtonWrapper>
            <SettingOutlined />
          </SettingsButtonWrapper>
        </Dropdown>
      </Row>
    );
  };

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <>
      <TabBarWrapper>
        <NormalText size="l" bold colorType="primary">
          # {selectedKnowledgeArea?.title}
        </NormalText>
      </TabBarWrapper>
      <TabBarRow justify={'space-between'} align={'middle'}>
        <DefaultTabBar {...props} />

        {tabIndex === '1' && renderSettingsButton()}
        {tabIndex === '3' && renderSpeechSettingsButton()}
      </TabBarRow>
    </>
  );

  return (
    <>
      <PageTitle>{selectedKnowledgeArea?.title || t('knowledgeBase.shortTitle')}</PageTitle>
      <Tabs
        tabBarStyle={{
          backgroundColor: themeObject[theme].background,
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 0,
          marginBottom: 0,
        }}
        tabBarExtraContent={
          ShowFurtherTabActions && (
            <AddTabButton>
              <Row gutter={10}>
                {!mobileOnly && (
                  <Button type="text" onClick={() => alert('Bald sind hier weitere Funktionen verfügbar 🙌')}>
                    <PlusCircleOutlined style={{ width: 40, height: 40 }} />
                  </Button>
                )}
              </Row>
            </AddTabButton>
          )
        }
        renderTabBar={renderTabBar}
        defaultActiveKey="1"
        items={items}
        onChange={(key) => {
          if (key === '4') {
            navigation(
              isPublicAccessMode
                ? `/public/demo-streaming-avatar/${selectedKnowledgeAreaId ?? ''}`
                : `/zen/demo-streaming-avatar/${selectedKnowledgeAreaId ?? ''}`,
            );
            return;
          }
          setTabIndex(key);
        }}
        activeKey={tabIndex}
      />

      <LlmSettingsModal chatMode="KNOWLEDGE" />
    </>
  );
}

export default GPTKnowledgeSearch;
