import { notificationController } from '@app/controllers/notificationController';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

export const useErrorHandling = () => {
  const { t } = useTranslation();

  const handleApiError = (error: any, useAntMessage = false) => {
    let errorMessage = '';
    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else {
      errorMessage = t('common.unknownApiError');
    }

    if (useAntMessage) {
      message.error(errorMessage);
    } else {
      notificationController.error({ message: errorMessage });
    }
  };
  return { handleApiError };
};
