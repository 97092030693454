import { ApiIntegrationType } from '@app/utils/constants';
import { AgentActionMessage, KnowledgeAnswerAnalysisResult } from './socketMessageTypes';
import { FileType } from '@app/domain/UploadedDocumentModel';

export type SupportedDocumentType = FileType | 'website' | ApiIntegrationType;

export const isTypeDocumentOrWebsite = (type: SupportedDocumentType) =>
  type === 'pdf' ||
  type === 'word' ||
  type === 'excel' ||
  type === 'txt' ||
  type === 'powerpoint' ||
  type === 'website';

export interface FilterSourceDocument {
  title: string;
  type: SupportedDocumentType;
}

export interface FilterValdityPeriod {
  //document validity period
  validFrom?: number;
  validUntil?: number;
}

export interface SourceDocumentType {
  docTitle: string;
  source: string;
  pageContent: string;
  // pageContent and relevantSenteces optimised by AI for source checking. Can contain Markdown highlighting.
  highlightedPageContent?: string;
  relevantSenteces?: string[];

  type: SupportedDocumentType;
  documentCreatedDate?: string;
  ingestedDate?: string;
  fileStoragePath?: string;
  page?: number;
  chunkId?: string;
  shortChunkId?: string;
  // if the source document where retrieved by a dedicated AI agent (like SQLDatabaseAgent) the agent steps are stored in agentToolSteps
  agentToolSteps?: string;
}

export interface SourceDocumentsSorted {
  title: string;
  documents: SourceDocumentType[];
  type: SupportedDocumentType;
  loadingHighlitings?: boolean;
  highlitingsAlreadyLoaded?: boolean;
  agentToolSteps?: string;
}

export interface InlineSourceDocument {
  source: SourceDocumentType;
  messageId: string;
  question: string;
  answer: string;
}

export type KnowledgeAnswerAnalysis = {
  loading: boolean;
} & KnowledgeAnswerAnalysisResult;

export interface MessageType {
  id: string;
  text: string;
  name: string;
  avatar: string;
  createdAt: number;
  uid: string;
  attachedFileName?: string;
  isGenerating?: boolean;
  wasCancelled?: boolean;
  errorOnMessageProcessing?: boolean;
  messageIsLoading?: boolean;
  numberSourceDocumentsFromHistory?: number;
  sourceDocumentsUnique?: SourceDocumentType[] | null;
  sourceDocumentsAll?: SourceDocumentType[] | null;
  agentToolMessages?: AgentActionMessage[] | null;
  imageUrls?: string[];
  knowledgeAnswerAnalysis?: KnowledgeAnswerAnalysis;
}
