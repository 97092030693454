import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  IframeCommandResultStatus,
  setIframeMessage,
  setIframeMessageStatus,
} from '@app/store/slices/iframeMessagesSlice';
import { setWebBrowserSpeechRecognitionActive } from '@app/store/slices/settingsSlice';
import { FunctionDefinition, IframeMessage, IframeToParentMessage } from '@app/types/iframeTypes';
import { checkMaxMinParams } from '@app/utils/utils';
import { useIframeDebug } from './useIframeDebug';
import { isInIframe } from '@app/utils/iframeHelper';

export const useIframe = () => {
  const dispatch = useAppDispatch();
  const { videoAssistantLanguage } = useAppSelector((state) => state.settings);
  const { iframeMessageStatus } = useAppSelector((state) => state.iframeMessages);
  const [instanceId] = useState<string>(uuidv4());
  const [isIframeUsed, setIsIframeUsed] = useState<boolean>(false);
  const [isAvatarReady, setIsAvatarReady] = useState<boolean>(false);
  const [iframeLanguage, setIframeLanguage] = useState<string>('');
  const [appLanguages, setAppLanguages] = useState<string[]>([]);
  const [iframePromptMessage, setIframePromptMessage] = useState<string>('');
  const [isShowListenButton, setIsShowListenButton] = useState<boolean>(true);
  const [isShowInputText, setIsShowInputText] = useState<boolean>(true);
  const [isShowOutputText, setIsShowOutputText] = useState<boolean>(true);
  const [isShowAvatar, setIsShowAvatar] = useState<boolean>(true);
  const [avatarHeight, setAvatarHeight] = useState<number | undefined>();
  const [avatarVolume, setAvatarVolume] = useState<number | undefined>();
  const [contextInfo, setContextInfo] = useState<string[]>([]);
  const [functionDefinitions, setFunctionDefinitions] = useState<FunctionDefinition[]>([]);
  const [isShowSettings, setIsShowSettings] = useState<boolean>(true);
  const [isSpeechRecognitionOn, setIsSpeechRecognitionOn] = useState<boolean>(true);

  const { setIsIframeDebugOn, handleIframeDebugMessages } = useIframeDebug();
  const [searchParams] = useSearchParams();

  const urlParamLanguage = searchParams.get('lang');
  const urlParamHideButtonListen = searchParams.get('hide-button-listen');
  const urlParamHideInputText = searchParams.get('hide-input-text');
  const urlParamHideOutputText = searchParams.get('hide-output-text');
  const urlParamHideAvatar = searchParams.get('hide-avatar');
  const urlParamAvatarSize = searchParams.get('avatar-size');
  const urlParamAvatarVolume = searchParams.get('avatar-volume');

  useEffect(() => {
    dispatch(
      setIframeMessage({
        value: {
          isWaitStatus: false,
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (isAvatarReady) {
      const isFrame = isInIframe();
      console.log('****** isFrame: ', isFrame);
      if (isFrame) {
        const connectMessageToParent: IframeToParentMessage = {
          type: 'aiAvatarMessage',
          payload: {
            interactionType: 'avatarLoaded',
            instanceId: instanceId,
          },
        };
        window.parent.postMessage(connectMessageToParent, '*');
      }
    }
  }, [isAvatarReady]);

  useEffect(() => {
    if (urlParamLanguage) {
      setIframeLanguage(urlParamLanguage);
    }
    if (urlParamHideButtonListen === 'true') {
      setIsShowListenButton(false);
    }
    if (urlParamHideOutputText === 'true') {
      setIsShowOutputText(false);
    }
    if (urlParamHideInputText === 'true') {
      setIsShowInputText(false);
    }
    if (urlParamHideAvatar === 'true') {
      setIsShowAvatar(false);
    }
    if (Number(urlParamAvatarSize)) {
      const avatarHeight = checkMaxMinParams(Number(urlParamAvatarSize), 10, 100);
      setAvatarHeight(avatarHeight);
    }
    if (Number(urlParamAvatarVolume)) {
      const volume = checkMaxMinParams(Number(urlParamAvatarVolume), 0.1, 1);
      setAvatarVolume(volume);
    }
  }, [
    urlParamLanguage,
    urlParamHideButtonListen,
    urlParamHideInputText,
    urlParamHideOutputText,
    urlParamHideAvatar,
    urlParamAvatarSize,
    urlParamAvatarVolume,
  ]);

  //eslint-disable-next-line
  const handleMessage = (event: MessageEvent) => {
    const data: IframeMessage = event.data;
    const { payload, type } = data;

    if (type === 'aiAvatarMessage' && payload.interactionType) {
      if (payload.interactionType === 'parentConnected') {
        setIsIframeUsed(true);
      }

      if (payload.interactionType === 'command' && payload.instanceId === instanceId && payload.actionId) {
        handleCommandResult(iframeMessageStatus, payload, instanceId);
        handleDebug(payload);

        switch (payload.actionId) {
          case 'lang':
            if (payload.lang) {
              setIframeLanguage(payload.lang);
            }
            break;
          case 'app-langs':
            if (payload.lang) {
              const languages = payload.lang.split(';');
              setAppLanguages(languages);
              dispatch(setIframeMessageStatus({ value: false }));
            }
            break;
          case 'prompt':
            setIframePromptMessage('');
            if (payload.text) {
              setIframePromptMessage(payload.text);
            }
            break;
          case 'hide-button-listen':
            setIsShowListenButton(Boolean(payload.actionState));
            dispatch(setIframeMessageStatus({ value: false }));
            break;
          case 'hide-input-text':
            setIsShowInputText(Boolean(payload.actionState));
            dispatch(setIframeMessageStatus({ value: false }));
            break;
          case 'hide-output-text':
            setIsShowOutputText(Boolean(payload.actionState));
            dispatch(setIframeMessageStatus({ value: false }));
            break;
          case 'hide-avatar':
            setIsShowAvatar(Boolean(payload.actionState));
            dispatch(setIframeMessageStatus({ value: false }));
            break;
          case 'avatar-size':
            setAvatarHeight(payload.avatarSize);
            dispatch(setIframeMessageStatus({ value: false }));
            break;
          case 'avatar-volume':
            setAvatarVolume(payload.avatarVolume);
            break;
          case 'add-context-info':
            setContextInfo((prevContext) => [...prevContext, payload.contextInfo ?? '']);
            break;
          case 'add-function-defintions':
            if (Array.isArray(payload.functions)) {
              const defintions = functionDefinitions;
              payload.functions.forEach((item) => defintions.push(item));
              setFunctionDefinitions(defintions);
              dispatch(setIframeMessageStatus({ value: false }));
            }
            break;
          case 'hide-settings':
            setIsShowSettings(Boolean(payload.actionState));
            dispatch(setIframeMessageStatus({ value: false }));
            break;
          case 'continuous-speech-recognition':
            dispatch(setWebBrowserSpeechRecognitionActive({ value: Boolean(payload.actionState) }));
            dispatch(setIframeMessageStatus({ value: false }));
            break;
          case 'pause-continuous-speech-recognition':
            setIsSpeechRecognitionOn(false);
            dispatch(setIframeMessageStatus({ value: false }));
            break;
          case 'continue-continuous-speech-recognition':
            setIsSpeechRecognitionOn(true);
            dispatch(setIframeMessageStatus({ value: false }));
            break;
          case 'enable-debug-messages':
            setIsIframeDebugOn(Boolean(payload.actionState));
            dispatch(setIframeMessageStatus({ value: false }));
            break;
          default:
            console.log('Sorry unknown iframe action');
        }
      }
    }
  };

  useEffect(() => {
    if (iframeMessageStatus && iframeMessageStatus.messageId && !iframeMessageStatus?.isWaitStatus) {
      const statusMessageToParent: IframeToParentMessage = {
        type: 'aiAvatarMessage',
        payload: {
          interactionType: 'command',
          status: iframeMessageStatus.errorText ? 'error' : 'success',
          messageId: iframeMessageStatus.messageId,
          actionId: 'command-result',
          instanceId: instanceId,
          errorText: iframeMessageStatus.errorText,
        },
      };
      sendCommandResultMessage(statusMessageToParent);
    }
  }, [iframeMessageStatus]);

  useEffect(() => {
    if (videoAssistantLanguage && isIframeUsed) {
      const connectMessageToParent: IframeMessage = {
        type: 'aiAvatarMessage',
        payload: {
          interactionType: 'command',
          instanceId: instanceId,
          lang: videoAssistantLanguage,
        },
      };
      window.parent.postMessage(connectMessageToParent, '*');
    }
  }, [videoAssistantLanguage, isIframeUsed]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const handleCommandResult = (
    iframeMessageStatus: IframeCommandResultStatus | null,
    payload: IframeMessage['payload'],
    instanceId: string,
  ) => {
    if (iframeMessageStatus?.isWaitStatus) {
      sendCommandResultMessage({
        type: 'aiAvatarMessage',
        payload: {
          interactionType: 'command',
          status: 'canceled',
          messageId: iframeMessageStatus.messageId,
          actionId: 'command-result',
          instanceId: instanceId,
        },
      });
    }

    dispatch(
      setIframeMessage({
        value: {
          isWaitStatus: true,
          messageId: payload.messageId,
        },
      }),
    );
  };

  const handleDebug = (payload: IframeMessage['payload']) => {
    const messageToSave = JSON.stringify(payload);
    handleIframeDebugMessages(messageToSave);
  };

  const sendCommandResultMessage = (messageToParent: IframeToParentMessage) => {
    console.log('****** command result message: ', messageToParent);
    window.parent.postMessage(messageToParent, '*');
  };

  const sendCallFunctionToParent = (functionName: string) => {
    const messageToParent: IframeToParentMessage = {
      type: 'aiAvatarMessage',
      payload: {
        interactionType: 'command',
        instanceId: instanceId,
        actionId: 'to-call-function',
        functionName,
      },
    };
    console.log('****** send to-call-function: ', functionName);
    window.parent.postMessage(messageToParent, '*');
  };

  const handleAvatarIsReady = () => {
    setIsAvatarReady(true);
  };

  return {
    isIframeUsed,
    iframeLanguage,
    iframePromptMessage,
    isShowListenButton,
    isShowInputText,
    isShowOutputText,
    isShowAvatar,
    avatarHeight,
    avatarVolume,
    functionDefinitions,
    sendCallFunctionToParent,
    isShowSettings,
    isSpeechRecognitionOn,
    setIsSpeechRecognitionOn,
    handleIframeDebugMessages,
    handleAvatarIsReady,
    isAvatarReady,
  };
};
