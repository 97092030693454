import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Select, Form, Space, Dropdown, Menu } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { SuperDoInputElementType } from '../templateDataInputMapping';
import {
  PageLayout,
  SelectionOptions,
  OptionInput,
  ElementConfiguration,
  ElementCard,
  RemoveIconButton,
  OptionItem,
  EmptyContainer,
  AddInputContainer,
} from './SuperDoLayoutConstructor.styles';
import { InputElement } from '../GPTTemplateCreate';
import { useStrictDroppable } from '@app/hooks/useDebounce';
import { SingleFileUploader } from '@app/components/singleFileUploader/SingleFileUploader';
import { debounce } from 'lodash';
import { convertToUpperCaseId } from '@app/utils/stringHelpers';
import { IconCheck, IconPencil } from '@tabler/icons-react';

type SuperDoLayoutConstructorProps = {
  addInputElement: (type: SuperDoInputElementType) => void;
  setElements: (name: string, value: InputElement[]) => void;
  getFieldValue: (name: (string | number)[]) => InputElement;
  getElements: () => InputElement[];
  updateInputIds: () => void;
  numberElements: number;
};

const { Option } = Select;

export const SuperDoLayoutConstructor: React.FC<SuperDoLayoutConstructorProps> = ({
  addInputElement,
  setElements,
  getFieldValue,
  getElements,
  updateInputIds,
  numberElements,
}) => {
  const { t } = useTranslation();
  const [enabled] = useStrictDroppable(false);

  const [inputIdEditMode, setInputIdEditMode] = useState<null | number>(null);

  useEffect(() => {
    setInputIdEditMode(null);
  }, [numberElements]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const elements = getElements();
    const newElements = [...elements];
    const [removed] = newElements.splice(result.source.index, 1);
    newElements.splice(result.destination.index, 0, removed);
    setElements('elements', newElements);
  };

  const addOptionToSelection = (index: number) => {
    const newElements = getElements();
    const element = newElements[index];
    const optionValue = '';
    element.defaultSelectionValues = [...(element.defaultSelectionValues || []), optionValue];
    setElements('elements', newElements);
  };

  const removeOptionFromSelection = (elementIndex: number, optionIndex: number) => {
    const newElements = getElements();
    const element = newElements[elementIndex];
    if (element.defaultSelectionValues) {
      element.defaultSelectionValues.splice(optionIndex, 1);
      setElements('elements', newElements);
    }
  };

  const getElementTitle = (elementType: SuperDoInputElementType) => {
    if (elementType === 'SELECTION') {
      return t('createSuperDo.selection');
    } else if (elementType === 'TEXTAREA') {
      return t('createSuperDo.textArea');
    } else if (elementType === 'FILE_UPLOAD') {
      return t('createSuperDo.fileUploader');
    }

    return t('createSuperDo.textInput');
  };

  const getCardStyle = (style: any, snapshot: any) => {
    if (!snapshot.isDragging) {
      return style;
    }
    return {
      ...style,
      boxShadow: '0px 8px 10px -10px #000000',
    };
  };

  const menu = (
    <Menu onClick={({ key }) => addInputElement(key as SuperDoInputElementType)}>
      <Menu.Item key="TEXTINPUT">{t('createSuperDo.textInput')}</Menu.Item>
      <Menu.Item key="TEXTAREA">{t('createSuperDo.textArea')}</Menu.Item>
      <Menu.Item key="SELECTION">{t('createSuperDo.selection')}</Menu.Item>
      <Menu.Item key="FILE_UPLOAD">{t('createSuperDo.fileUploader')}</Menu.Item>
    </Menu>
  );

  const updateInputId = (inputLabel: string, fieldIndex: number) => {
    const updatedElements = getElements();
    const element = updatedElements[fieldIndex];
    if (element) {
      const inputId = convertToUpperCaseId(inputLabel);
      const sameInputIdCount = updatedElements.filter((item) => item.inputId === inputId).length;
      if (sameInputIdCount > 0) {
        element.inputId = `${element.inputId}_${sameInputIdCount + 1}`;
      } else {
        element.inputId = inputId;
      }
      setElements('elements', updatedElements);
      updateInputIds();
    }
  };

  const debouncedSetInputId = useRef(debounce(updateInputId, 1000));

  const onLabelChanged = (e: React.ChangeEvent<HTMLInputElement>, fieldIndex: number) => {
    const label = e.target.value;
    debouncedSetInputId.current(label, fieldIndex);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <PageLayout>
        {enabled && (
          <Droppable droppableId="droppable">
            {(provided) => (
              <ElementConfiguration {...provided.droppableProps} ref={provided.innerRef}>
                <Form.List name={'elements'}>
                  {(fields, { remove }) => (
                    <>
                      {fields.map(({ name }) => {
                        const elementFields: InputElement = getFieldValue(['elements', name]);
                        if (!elementFields) return null;
                        return (
                          <Draggable key={elementFields?._id} draggableId={elementFields?._id ?? ''} index={name}>
                            {(provided, snapshot) => (
                              <ElementCard
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getCardStyle(provided.draggableProps.style, snapshot)}
                              >
                                <RemoveIconButton
                                  type="text"
                                  onClick={() => {
                                    remove(name);
                                  }}
                                >
                                  <CloseCircleOutlined style={{ fontSize: '1.2rem' }} />
                                </RemoveIconButton>
                                <NormalText centered semiBold size="m">
                                  {getElementTitle(elementFields?.type ?? '')}
                                </NormalText>
                                <Form.Item
                                  label={t('createSuperDo.label')}
                                  name={[name, 'inputTitle']}
                                  labelCol={{ span: 24 }}
                                  rules={[{ required: true, message: t('createSuperDo.errors.elementLabelInput') }]}
                                >
                                  <Input onChange={(e) => onLabelChanged(e, name)} />
                                </Form.Item>
                                {elementFields.inputId && inputIdEditMode !== name && (
                                  <Space style={{ marginBottom: '0.5rem' }}>
                                    <NormalText size="s" semiBold>{`Input Id: ${elementFields.inputId}`}</NormalText>
                                    <Button
                                      type="text"
                                      onClick={() => setInputIdEditMode(name)}
                                      icon={
                                        <IconPencil
                                          size={16}
                                          style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                                        />
                                      }
                                    ></Button>
                                  </Space>
                                )}
                                {inputIdEditMode === name && (
                                  <>
                                    <Form.Item
                                      label={t('createSuperDo.inputId')}
                                      name={[name, 'inputId']}
                                      labelCol={{ span: 24 }}
                                      rules={[
                                        { required: true, message: t('createSuperDo.errors.emptyElementIdInput') },
                                        { pattern: /^[^\s]*$/, message: t('createSuperDo.errors.noSpacesAllowed') },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                    <Button
                                      type="text"
                                      onClick={() => setInputIdEditMode(null)}
                                      icon={
                                        <IconCheck
                                          size={16}
                                          style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
                                        />
                                      }
                                    ></Button>
                                  </>
                                )}
                                {elementFields?.type === 'SELECTION' && (
                                  <>
                                    <Form.Item label={t('createSuperDo.defaultValue')}>
                                      <Select
                                        onChange={(value) => {
                                          const newElements = getElements();
                                          const elementToUpdate = newElements[name];
                                          const elementToSave: InputElement = {
                                            ...elementToUpdate,
                                            defaultValue: value,
                                          };
                                          newElements[name] = elementToSave;
                                          setElements('elements', newElements);
                                        }}
                                      >
                                        {elementFields.defaultSelectionValues?.map((option: string) => (
                                          <Option key={option} value={option}>
                                            {option}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item label={t('createSuperDo.selectionOptions')}>
                                      <SelectionOptions>
                                        {elementFields.defaultSelectionValues?.map((option, optionIndex) => (
                                          <OptionItem key={optionIndex}>
                                            <OptionInput
                                              value={option}
                                              onChange={(e) => {
                                                const newElements = getElements();
                                                const elementToUpdate = newElements[name];
                                                if (elementToUpdate.defaultSelectionValues) {
                                                  elementToUpdate.defaultSelectionValues[optionIndex] = e.target.value;
                                                  setElements('elements', newElements);
                                                }
                                              }}
                                            />
                                            <CloseCircleOutlined
                                              onClick={() => removeOptionFromSelection(name, optionIndex)}
                                            />
                                          </OptionItem>
                                        ))}
                                        <Button type="dashed" onClick={() => addOptionToSelection(name)}>
                                          {t('createSuperDo.addOptionButton')}
                                        </Button>
                                      </SelectionOptions>
                                    </Form.Item>
                                  </>
                                )}
                                {elementFields?.type === 'FILE_UPLOAD' && <SingleFileUploader />}
                              </ElementCard>
                            )}
                          </Draggable>
                        );
                      })}
                    </>
                  )}
                </Form.List>
                {provided.placeholder}
              </ElementConfiguration>
            )}
          </Droppable>
        )}
        {numberElements === 0 && (
          <EmptyContainer>
            <NormalText centered size="m" topMargin="l" colorType="light">
              {t('createSuperDo.noInputElementsYet')}
            </NormalText>
          </EmptyContainer>
        )}
        <AddInputContainer>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="dashed">{t('createSuperDo.addNewInputElement')}</Button>
          </Dropdown>
        </AddInputContainer>
      </PageLayout>
    </DragDropContext>
  );
};
