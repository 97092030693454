import { createGlobalStyle } from 'styled-components';
import { resetCss } from './resetCss';
import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, media } from './themes/constants';
import {
  antOverrideCssVariables,
  commonThemeVariables,
  darkThemeVariables,
  lightThemeVariables,
} from './themes/themeVariables';

export default createGlobalStyle`

  ${resetCss}
  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  [data-theme='dark'] {
    ${darkThemeVariables}
  }

  :root {
    ${commonThemeVariables};
    ${antOverrideCssVariables};

    --ck-border-radius: 10px;
  }

  // CKEditor
  .ck.ck-content ul,
  .ck.ck-content ul li {
    list-style-type: inherit;
  }

  /* .ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    border: 1px solid var(--border-color);
  } */

    /* Font sizes and vertical rhythm for common elements (headings, lists, paragraphs, etc.) */
  .ck-content h1 {
    font-size: 2em;
  }
  .ck-content h2 {
    font-size: 1.75em;
  }
  .ck-content h3 {
    font-size: 1.4em;
  }
  .ck-content h4 {
    font-size: 1.2em;
  }
  .ck-content h5 {
    font-size: 1.05em;
  }
  .ck-content h6 {
    font-size: 1em;
  }
  .ck-content h1,
  .ck-content h2,
  .ck-content h3,
  .ck-content h4,
  .ck-content h5,
  .ck-content h6 {
    line-height: 1.2em;
    padding-top: 0.4em;
    margin-bottom: 0.2em;
  }

  .ck.ck-content ul {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
  }

  .ck.ck-content ol,
  .ck.ck-content ol li {
    list-style-type: decimal;
  }
  .ck.ck-content ol {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
  }

  [data-no-transition] * {
    transition: none !important;
  }

  .range-picker {
    & .ant-picker-panels {
      @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .ant-picker-input > input {
    font-size: 1rem;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    font-size: 0.75rem;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    font-size: 0.75rem;
  }

  .ant-select-selection-item {
    font-size: 1rem;
  }

  .ant-select-selection-placeholder {
    font-size: 1rem;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    font-size: 1rem;
  }

  // PDF-Viewer SearchPopover
  .rpv-search__popover-input-counter {
    font-size: 1rem;
  }

  .rpv-search__popover-label {
    font-size: 1rem;
  }

  .rpv-search__popover-footer-button--ltr {
    font-size: 1rem;
  }

  .rpv-core__popover-body {
    border-radius: .5rem;
    padding: .75rem;
  }

  .rpv-core__button {
    background-color: var(--primary-light-color);
    color: #2d2d2d; 
  }

  .search-dropdown {
    box-shadow: var(--box-shadow);

    @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px) {
      width: calc(100vw - 16px);
      max-width: 600px;
    }

    @media only screen and ${media.md} {
      max-width: 323px;
    }
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    height: 37px;
  }

  a {
    color: var(--primary-color);

    &:hover, :active {
      color: var(--ant-primary-color-hover);
    }
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--text-light-color) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--text-light-color) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--text-light-color) !important;
  }
  
  @keyframes cursor-blink {
    0% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 1;}
  }

  .animate-pulse-cursor {
    animation-name: cursor-blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  // antd

  .ant-select-selection-placeholder {
    color: var(--text-light-color) !important;
  }

  .ant-dropdown-menu-title-content {
    font-size: 1rem;
  }
  
  .ant-modal-title {
    padding-right: 2rem; 
  }

  .ant-modal-content {
    border-radius: 8px;
  }
 
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }

  .ant-form-item-explain-error {
    font-size: 1rem;
  }

  .ant-avatar-lg-string, .ant-avatar-sm-string, .ant-avatar-string {
    font-size: 1rem;
  }
  
  .d-none {
    display: none;
  }

  .ant-picker-cell {
    color: var(--text-main-color);
  }

  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: var(--text-main-color);
    font-weight: ${FONT_WEIGHT.bold};
  }

  .ant-picker svg {
    color: var(--text-light-color);
  }

  .ant-table-container {
    border: 1.5px solid #CBE6FA !important;
    border-radius: 8px !important;
  }

  .ant-table-thead .ant-table-cell {
    background-color: var(--secondary-background-color);
  }

  .ant-table-thead .ant-table-cell:first-child {
    border-top-left-radius: 8px !important;
  }

  .ant-table-thead .ant-table-cell:last-child {
    border-top-right-radius: 8px !important;
  }
  
  .ant-card-meta-title {
    white-space: pre-line;
  }

  .ant-table {
    font-size: ${FONT_SIZE.xs};
  }

  .ant-table-tbody > tr > td {
    max-width: 400px;
  }

  .ant-upload.ant-upload-drag {
    border-radius: 8px;
  }

  .ant-radio-button-wrapper {
    font-size: 1rem;
  }

  // notifications start
  .ant-notification-notice {
    width: 36rem;
    padding: 2rem;
    min-height: 6rem;

    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-bottom: 0;
      margin-left: 2.8125rem;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin-left: 4.375rem;
      margin-top: 0;
    }

    .ant-notification-notice-icon {
      font-size: 2.8125rem;
      margin-left: 0
    }

    .ant-notification-notice-close {
      top: 1.25rem;
      right: 1.25rem;
    }

    .ant-notification-notice-close-x {
      display: flex;
      font-size: 0.9375rem;
    }

    .notification-without-description {
      .ant-notification-notice-close {
        top: 1.875rem;
      }

      .ant-notification-notice-with-icon .ant-notification-notice-description {
        margin-top: 0.625rem;
      }
    }

    .title {
      font-size: ${FONT_SIZE.xxl};
      height: 3rem;
      margin-left: 1.5rem;
      display: flex;
      align-items: center;
      font-weight: ${FONT_WEIGHT.bold};

      &.title-only {
        color: var(--text-main-color);
        font-size: ${FONT_SIZE.md};
        height: auto;
        max-height: 500px;
        line-height: 1.5rem;
        margin-left: 0.75rem;
        font-weight: ${FONT_WEIGHT.semibold};
      }
    }

    .description {
      color: #404040;
      font-size: ${FONT_SIZE.md};
      font-weight: ${FONT_WEIGHT.semibold};
      line-height: 1.375rem;
    }

    &.ant-notification-notice-success {
      //border: 1px solid var(--success-color);
      border-radius: 8px;
      background: var(--notification-success-color);

      .title {
        color: var(--success-color);
      }
    }

    &.ant-notification-notice-info {
      //border: 1px solid var(--primary-color);
      border-radius: 8px;
      background: var(--notification-primary-color);

      .title {
        color: var(--primary-color);
      }
    }

    &.ant-notification-notice-warning {
      //border: 1px solid var(--warning-color);
      border-radius: 8px;
      background: var(--notification-warning-color);

      .title {
        color: var(--warning-color);
      }
    }

    &.ant-notification-notice-error {
      //border: 1px solid var(--error-color);
      border-radius: 8px;
      background: var(--notification-error-color);

      .title {
        color: var(--error-color);
      }
    }

    .success-icon {
      color: var(--success-color);
    }

    .info-icon {
      color: var(--primary-color);
    }

    .warning-icon {
      color: var(--warning-color);
    }

    .error-icon {
      color: var(--error-color);
    }
  }

  .ant-menu-inline, .ant-menu-vertical {
    border-right: 0;
  }

  .ant-picker {
    height: 50px;
  }

  .ant-picker-range {
    height: 50px;
  }

  .ant-btn {
    font-size: 1rem;
  }

  .ant-input {
    font-size: 1rem;
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn, .ant-input-affix-wrapper {
    font-size: 1rem;
  }

  .ant-tabs-tab {
    font-size: 1rem;
  }

  .ant-upload-drag-icon {
    height: 3rem;
  }

  .ant-form-item-label>label { 
    font-size: 1rem;
  }

  .ant-form label {
    font-size: 1rem;
  }

  .ant-select-item-option-content {
    font-size: 1rem;
  }

  .ant-checkbox-wrapper {
    font-size: 1rem;
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 12px;
    font-size: 1rem;
  }

  .ant-upload-list-picture .ant-upload-list-item-name, .ant-upload-list-text .ant-upload-list-item-name {
    font-size: 1rem;
  }

  .ant-tooltip-inner {
    font-size: 0.75rem;
    font-weight: 300;
  }

  .ant-upload-list {
    display: flex;
    gap: 1rem;
  }

  .ant-upload-list-text-container {
    max-width: 300px;
  }

  // notifications end

  // react-multi-email start
  .react-multi-email > input {
    background-color: transparent !important;
  }

  .react-multi-email.empty > span {
    font-size: 1rem;
  }

  // react-multi-email end
`;
