import React, { ReactElement, ReactNode } from 'react';
import { Col, Row, Space } from 'antd';
import BackButton from '../buttons/Button/BackButton';
import {
  HeaderContainer,
  StyledHeaderSubTitle,
  StyledHeaderTextContainer,
  StyledHeaderTitle,
} from './NavigationHeader.styles';

type NavigationHeaderProps = {
  title: string;
  description?: ReactNode | string;
  rightComponent?: ReactElement;
  onGoBack?: () => void;
};

const NavigationHeader: React.FC<NavigationHeaderProps> = ({ title, description, rightComponent, onGoBack }) => {
  return (
    <HeaderContainer>
      <Row align={'top'} justify={'space-between'}>
        <Col span={18}>
          <Space size={'middle'} align="center">
            <BackButton marginLeft="1rem" onGoBack={onGoBack} />
            <StyledHeaderTextContainer>
              <StyledHeaderTitle>{title}</StyledHeaderTitle>
              <br />
              <StyledHeaderSubTitle>{description}</StyledHeaderSubTitle>
            </StyledHeaderTextContainer>
          </Space>
        </Col>
        <Col span={6}>
          <Row align={'middle'} justify={'end'}>
            {rightComponent}
          </Row>
        </Col>
      </Row>
    </HeaderContainer>
  );
};

export default NavigationHeader;
