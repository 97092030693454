import { useState } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { useErrorHandling } from './useErrorHandling';
import { loadChatHistory, setLoadedChatHistoryPage } from '@app/store/slices/chatBoxSlice';

export const ChatHistoryItemsPerPage = 50;

export const useHandleChatHistory = () => {
  const [historyResultLoading, setHistoryResultLoading] = useState<boolean>(false);

  const { loadedChatHistory, loadedChatHistoryPage, moreHistoryResultsAvailable } = useAppSelector(
    (state) => state.chatBox,
  );

  const dispatch = useAppDispatch();
  const { handleApiError } = useErrorHandling();

  const loadMoreResults = async () => {
    setHistoryResultLoading(true);
    const newPage = loadedChatHistoryPage + 1;
    dispatch(setLoadedChatHistoryPage({ value: newPage }));
    try {
      await dispatch(loadChatHistory(newPage, ChatHistoryItemsPerPage));
    } catch (error) {
      handleApiError(error);
    }
    setHistoryResultLoading(false);
  };

  return {
    loadedChatHistory,
    historyResultLoading,
    loadMoreResults,
    moreHistoryResultsAvailable,
  };
};
