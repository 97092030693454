import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { AccessRightTeamMember } from '@app/types/accessRightTypes';

type AvatarGroupProps = {
  teamMembers: AccessRightTeamMember[];
  style?: React.CSSProperties;
};

export const AvatarGroup: React.FC<AvatarGroupProps> = ({ teamMembers, style }) => (
  <Avatar.Group
    maxCount={4}
    maxStyle={{ color: '#A2A2A2', backgroundColor: '#EAEAEA', cursor: 'pointer' }}
    maxPopoverTrigger="click"
    size={'small'}
    style={style}
  >
    {teamMembers.map((teamMember) => (
      <Tooltip key={teamMember.userId} title={teamMember.teamMemberName} placement="top">
        <Avatar src={teamMember.imageUrl} size={'small'}>
          {teamMember.teamMemberName ? teamMember.teamMemberName.slice(0, 2).toUpperCase() : ''}
        </Avatar>
      </Tooltip>
    ))}
  </Avatar.Group>
);
