import React from 'react';
import { Form } from 'antd';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '../../common/selects/BaseSelect/BaseSelect';
import { EmptyContainer, PageLayout } from './SuperDoLayoutConstructor.styles';
import { InputElement } from '../GPTTemplateCreate';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { useTranslation } from 'react-i18next';
import { SingleFileUploader } from '@app/components/singleFileUploader/SingleFileUploader';

type SuperDoLayoutPreviewProps = {
  elements: InputElement[];
};

export const SuperDoLayoutPreview: React.FC<SuperDoLayoutPreviewProps> = ({ elements }) => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      {elements?.map((input) => {
        if (input.type === 'SELECTION') {
          return (
            <Form.Item key={input._id} labelCol={{ span: 24 }} label={input.inputTitle} name={input.inputId}>
              <BaseSelect
                options={
                  input.defaultSelectionValues
                    ? input.defaultSelectionValues.map((item) => ({ label: item, value: item }))
                    : []
                }
                defaultValue={input.defaultValue}
              />
            </Form.Item>
          );
        } else if (input.type === 'TEXTINPUT') {
          return (
            <Form.Item key={input._id} labelCol={{ span: 24 }} label={input.inputTitle} name={input.inputId}>
              <BaseInput readOnly />
            </Form.Item>
          );
        } else if (input.type === 'TEXTAREA') {
          return (
            <Form.Item key={input._id} labelCol={{ span: 24 }} label={input.inputTitle} name={input.inputId}>
              <BaseInput.TextArea readOnly style={{ height: 100 }} />
            </Form.Item>
          );
        } else if (input.type === 'FILE_UPLOAD') {
          return (
            <Form.Item key={input._id} labelCol={{ span: 24 }} label={input.inputTitle} name={input.inputId}>
              <SingleFileUploader />
            </Form.Item>
          );
        }
        return null;
      })}
      {(!elements || elements.length === 0) && (
        <EmptyContainer>
          <NormalText centered size="m" topMargin="l" colorType="light">
            {t('createSuperDo.noInputElementsYet')}
          </NormalText>
        </EmptyContainer>
      )}
    </PageLayout>
  );
};
