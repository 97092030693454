import styled, { css } from 'styled-components';
import { Button } from 'antd';

export const SelectTypeButton = styled(Button)<{ selected: boolean }>`
  height: auto;
  min-width: 62px;
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  margin-right: 1rem;
  color: var(--primary-color);
  font-weight: normal;
  ${({ selected }) =>
    selected &&
    css`
      background-color: #bad6ff;
      border-color: transparent;
    `};

  &:hover {
    ${({ selected }) =>
      selected &&
      css`
        background-color: #bad6ff;
      `};
  }
`;

export const DocumentFilterWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
