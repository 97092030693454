import { readUserIdAndCompanyId } from '@app/services/reduxStorage.service';
import { getErrorText } from '@app/utils/apiHelpers';
import { setGlobalSessionExpired } from '@app/services/reduxStorage.service';
import { MenuNavigationStructure, MenuNode } from '@app/types/menuFolderTypes';
import { httpApi } from './http.api';

export const readNestedMenu = (): Promise<MenuNavigationStructure & { _id: string }> => {
  return new Promise(async (resolve, reject) => {
    try {
      const userData = readUserIdAndCompanyId();
      const result = await httpApi.get(`/api/v1/nestedMenu/${userData.companyId}`);
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('nested menu api call failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const updateNestedMenu = (nestedMenuId: string, isGlobal: boolean, menuNodes: MenuNode[]): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const nestedMenuData = {
        _id: nestedMenuId,
        isGlobal,
        nodes: menuNodes,
      };
      const result = await httpApi.post(`/api/v1/nestedMenu/update/${nestedMenuId}`, nestedMenuData);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('update checklist failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const updateFolder = (menuId: string, menuFolderId: string, name: string, hexColor: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const folderData = { name, hexColor };
      const result = await httpApi.post(`/api/v1/nestedMenu/updateFolder/${menuId}/${menuFolderId}`, folderData);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('update folder failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const addNodeItem = (
  menuId: string,
  nodeItemToAdd: MenuNode,
  index: number,
  parentFolderId?: string,
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(`/api/v1/nestedMenu/addNode/${menuId}`, {
        nodeItemToAdd,
        index,
        parentFolderId,
      });
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('add folder failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const removeNodeItem = (menuId: string, toRemoveItemId: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(`/api/v1/nestedMenu/removeNode/${menuId}`, {
        toRemoveItemId,
      });
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('remove node item failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};
