import React, { useCallback, useEffect, useState } from 'react';
import * as S from './DocumentTemplateContainer.styles';
import { Col } from 'antd';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { IUploadedDocumentTemplate } from './DocumentTemplateContainer';
import { BaseForm } from '../common/forms/BaseForm/BaseForm';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { baseURL } from '@app/api/http.api';

interface DocumentTemplateEditContainerProps {
  index: number | null;
}

export const DocumentTemplateEditContainer: React.FC<DocumentTemplateEditContainerProps> = ({ index }) => {
  const currentSelectedTemplateFileName = useAppSelector(
    (state) => state.uploadedDocuments.currentSelectedTemplateFileName,
  );

  const [templateFile, setTemplateFile] = useState<IUploadedDocumentTemplate | null>(null);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  const readTemplateFile = useCallback(async () => {
    const fetchUrl = `${baseURL}/api/v1/documenttemplates/read/${currentSelectedTemplateFileName}`;

    try {
      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();

      console.log('****** readTemplateFile result.data: ', result.data);
      if (result.data) {
        setTemplateFile(result.data);
      }
    } catch (error) {
      console.log('****** loadDataRoom error: ', error);
    }
  }, [currentSelectedTemplateFileName]);

  useEffect(() => {
    if (currentSelectedTemplateFileName) readTemplateFile();
  }, [currentSelectedTemplateFileName, readTemplateFile]);

  const handleSubmit = (formData: any) => {
    const createNewDoc = async () => {
      if (templateFile?._id) {
        const fetchUrl = `${baseURL}/api/v1/documenttemplates/docfromtemplate`;
        try {
          const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              templateDocumentId: templateFile._id,
              placeholders: formData,
            }),
          });
          const result = await response.blob();
          const href = window.URL.createObjectURL(result);
          setDownloadUrl(href);

          console.log('****** storeInDb result: ', result);
        } catch (error) {
          console.log('****** storeInDb error: ', error);
        }
      }
    };

    createNewDoc();
    console.log('****** formData: ', formData);
  };

  return (
    <S.ContainerEditWrapper>
      <Col>
        <NormalText size="xl">{'Vorlage editieren'}</NormalText>
        <NormalText size="l" verticalPadding>
          {currentSelectedTemplateFileName}
        </NormalText>
        {templateFile && (
          <BaseForm onFinish={handleSubmit}>
            {templateFile?.placeholders.map((placeholder) => (
              <BaseForm.Item
                key={placeholder.key}
                labelCol={{ span: 24 }}
                label={placeholder.label}
                name={placeholder.key}
                rules={[{ required: true, message: 'Pflichtfeld' }]}
              >
                <BaseInput />
              </BaseForm.Item>
            ))}
            <BaseForm.Item noStyle>
              <S.SaveButton type="primary" htmlType="submit">
                Vertrag erstellen
              </S.SaveButton>
            </BaseForm.Item>
          </BaseForm>
        )}
        {downloadUrl && (
          <a href={downloadUrl} download={`Doc_${templateFile?.title}`}>
            <S.DonwloadButton type="dashed">Hier Datei runterladen</S.DonwloadButton>
          </a>
        )}
      </Col>
    </S.ContainerEditWrapper>
  );
};
