import styled from 'styled-components';
import { IconInputAi } from '@tabler/icons-react';

export const AiIcon = styled(IconInputAi)`
  position: relative;
  top: 6px;
  margin: 0 1rem;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
