import React from 'react';
import GptTemplatesOverview from '@app/components/gpttemplates/GptTemplatesOverview';
import PageContainer from '@app/components/layouts/main/PageContainer/PageContainer';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';

function GPTTemplates() {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle>{t('gptTemplates.shortTitle')}</PageTitle>
      <GptTemplatesOverview />
    </PageContainer>
  );
}

export default GPTTemplates;
