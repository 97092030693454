import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import PageContainer from '@app/components/layouts/main/PageContainer/PageContainer';
import { SourceUpdateMonitoringOverview } from '@app/components/sourceUpdateMonitoring/SourceUpdateMonitoringOverview';

const SourceUpdateMonitoringOverviewPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle>{t('sourceUpdateMonitoring.headerTitle')}</PageTitle>
      <SourceUpdateMonitoringOverview />
    </PageContainer>
  );
};

export default SourceUpdateMonitoringOverviewPage;
