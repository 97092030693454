import React from 'react';
import styled from 'styled-components';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { Divider, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { IconFileTypeDocx, IconFileTypePdf, IconFileTypeTxt, IconFileTypeXls } from '@tabler/icons-react';
import { SupportedDocumentType } from '@app/types/chatBoxTypes';

export const SourceTooltipContainer = ({
  sourceTitle,
  page,
  bodyText,
  type,
}: {
  sourceTitle: string;
  page: string;
  bodyText: string;
  type?: SupportedDocumentType;
}) => {
  const { t } = useTranslation();

  const getFileIcon = () => {
    if (type === 'pdf') {
      return <IconFileTypePdf size={'1.5rem'} />;
    } else if (type === 'word') {
      return <IconFileTypeDocx size={'1.5rem'} />;
    } else if (type === 'excel') {
      return <IconFileTypeXls size={'1.5rem'} />;
    } else if (type === 'txt') {
      return <IconFileTypeTxt size={'1.5rem'} />;
    }
  };

  return (
    <Container>
      <NormalText semiBold size="s" style={{ color: '#fff' }}>
        {sourceTitle}
      </NormalText>
      <Space align="start">
        {getFileIcon()}
        {page && (
          <NormalText size="s" style={{ color: '#fff' }}>
            {t('pageNumber', { page })}
          </NormalText>
        )}
      </Space>
      <Divider style={{ backgroundColor: '#fff', padding: 0, margin: '6px 0' }} />
      <NormalText size="s" style={{ color: '#fff' }}>
        {bodyText}
      </NormalText>
    </Container>
  );
};

const Container = styled.div`
  background-color: #3f3f3f;
  padding: 2px 4px;
`;
