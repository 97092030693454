import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '@app/store/slices';
import { errorLoggingMiddleware } from '@app/store/middlewares/errorLogging.middleware';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const encKey = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY ?? 'dummy-4788-suBer-69-seCret-keJ';

const encryptor = encryptTransform({
  secretKey: `cPx083${encKey}yEfX`,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings', 'appLayout', 'user', 'knowledgeAreaFilter'],
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(errorLoggingMiddleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
