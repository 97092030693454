import { baseURL } from '@app/api/http.api';
import { Socket, io } from 'socket.io-client';

const socketUrl = `${baseURL}/api/stream`;
let socketIOChat: Socket | null = null;

const createChatSocketInstance = (socketInitCallback: (socketIO: Socket | null) => void) => {
  if (!socketIOChat || !socketIOChat.connected) {
    socketIOChat = io(socketUrl, { autoConnect: true, reconnectionAttempts: 10 });

    socketIOChat.on('connect', () => {
      console.log('Websocket is connected');
      socketInitCallback(socketIOChat);
    });

    socketIOChat.on('error', (error) => {
      console.error('Error occurred with the socket connection:', error);
    });
  } else {
    console.log('Websocket WAS already connected');
    socketInitCallback(socketIOChat);
  }
};

export { createChatSocketInstance, socketUrl };
