import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  padding-top: 1rem;
`;

export const OverviewWrapper = styled.div`
  padding: 1rem;
  overflow-y: auto;
`;

export const NoDataWrapper = styled.div`
  padding: 1rem;
  padding-top: 10%;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;
