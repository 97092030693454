import React, { ReactNode } from 'react';
import { List, Dropdown, Menu } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { IconDotsVertical } from '@tabler/icons-react';

type AppRightsRowItemProps = {
  appId: string;
  label: string;
  description: string;
  icon: ReactNode;
  onEdit: (appId: string) => void;
};

export const AppRightsRowItem: React.FC<AppRightsRowItemProps> = ({ appId, label, description, icon, onEdit }) => {
  const { t } = useTranslation();
  const theme = useAppSelector((state) => state.theme.theme);

  const menu = (
    <Menu onClick={() => onEdit(appId)}>
      <Menu.Item key="editAppRights">{t('editAppRights')}</Menu.Item>
    </Menu>
  );

  return (
    <List.Item
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '0.5rem',
        backgroundColor: themeObject[theme].backgroundColorBase,
        padding: '1rem',
      }}
    >
      <List.Item.Meta
        avatar={icon}
        title={<NormalText semiBold>{label}</NormalText>}
        description={<NormalText colorType="light">{description}</NormalText>}
      />
      <Dropdown overlay={menu}>
        <div style={{ padding: '0.5rem', cursor: 'pointer' }}>
          <IconDotsVertical color={themeObject[theme].textLight} />
        </div>
      </Dropdown>
    </List.Item>
  );
};
