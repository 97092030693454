import { useEffect, useState } from 'react';
import { useAppSelector } from './reduxHooks';
import { useErrorHandling } from './useErrorHandling';
import { UserModel } from '@app/domain/UserModel';
import { getUsersByIds } from '@app/api/company.api';
import { DeletedUser } from '@app/utils/constants';
import { IChecklistReviewResults } from '@app/domain/ChecklistReviewResultsModel';
import { deleteChecklistReviewResult, getChecklistReviewResultsByCompanyId } from '@app/api/checklistReviewResults.api';

const ItemsPerPage = 25;

export const useHandleChecklistResults = () => {
  const [checklistResults, setChecklistResults] = useState<IChecklistReviewResults[]>([]);
  const [userCreators, setUserCreators] = useState<UserModel[]>([]);
  const [checklistResultLoading, setChecklistResultLoading] = useState<boolean>(true);
  const [moreChecklistResultsAvailable, setMoreChecklistResultsAvailable] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const companyState = useAppSelector((state) => state.company);

  const { handleApiError } = useErrorHandling();

  const getChecklistResults = async (page: number) => {
    try {
      return await getChecklistReviewResultsByCompanyId(companyState._id ?? '', page, ItemsPerPage);
    } catch (error) {
      console.log(error);
      handleApiError(error);
    } finally {
      setChecklistResultLoading(false);
    }
  };

  const loadAndSetChecklistResults = async () => {
    if (companyState) {
      const results = await getChecklistResults(page);
      setChecklistResults(results ?? []);
      if (results) {
        setMoreChecklistResultsAvailable(results.length === ItemsPerPage);
        const userIds = results.map((item) => item.createdBy);
        if (userIds.length) {
          const users: UserModel[] = await getUsersByIds(userIds);
          setUserCreators(users);
        }
      }
    }
  };

  const loadMoreResults = async () => {
    setChecklistResultLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    const moreResults = await getChecklistResults(newPage);
    if (moreResults) {
      setMoreChecklistResultsAvailable(moreResults.length === ItemsPerPage);
      setChecklistResults((prevResults) => [...prevResults, ...moreResults]);
      const userIds = moreResults.map((item) => item.createdBy);
      if (userIds.length) {
        const users: UserModel[] = await getUsersByIds(userIds);
        setUserCreators((prevUsers) => [...prevUsers, ...users]);
      }
    }
    setChecklistResultLoading(false);
  };

  useEffect(() => {
    loadAndSetChecklistResults();
  }, [companyState]);

  const onDeleteChecklistResults = async (documentChecklistId: string) => {
    try {
      await deleteChecklistReviewResult(documentChecklistId);
      const checklistsResultsToSave = checklistResults.filter((result) => result._id !== documentChecklistId);
      setChecklistResults(checklistsResultsToSave);
    } catch (error) {
      console.log('****** onDeleteDocumentChecklist error: ', error);
      handleApiError(error);
    }
  };

  const getUserExecutorOfChecklist = (userId: string) => {
    return userCreators.find((item) => item._id === userId) || DeletedUser;
  };

  return {
    checklistResults,
    checklistResultLoading,
    onDeleteChecklistResults,
    getUserExecutorOfChecklist,
    loadMoreResults,
    loadAndSetChecklistResults,
    moreChecklistResultsAvailable,
  };
};
