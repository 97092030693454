import React, { useRef, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Dropdown, Menu, Divider, Switch, Radio, Button, message } from 'antd';
import { SettingOutlined, ReloadOutlined } from '@ant-design/icons';
import { IconMicrophone, IconMicrophoneOff, IconPlayerStop } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';
import { LoadingSpin } from '@app/components/common/LoadingSpin';
import { transcribeSpeech } from '@app/api/speech.api';
import {
  knowledgeVoiceAssistantQueryAsStream,
  knowledgeVoiceAssistantQueryAsStreamPublic,
} from '@app/api/gptVoiceAssistant.api';
import { createSocketInstance } from '@app/services/socketIO.service';
import { ChatResponseMessage } from '@app/types/socketMessageTypes';
import { Socket } from 'socket.io-client';
import { MessageType } from '@app/types/chatBoxTypes';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import {
  setWaitSpeechVideoAssistantTextActive,
  setContextSizeVideoAssistant,
  setVideoAssistantVideoQuality,
  setVideoAssistantLanguage,
  setWebBrowserSpeechRecognitionActive,
  setConversationMemoryVideoAssistantActive,
} from '@app/store/slices/settingsSlice';
import { setIframeMessageStatus } from '@app/store/slices/iframeMessagesSlice';
import { AvatarVideoElement } from './AvatarVideoElement';
import {
  VideoWrapper,
  VideoElementWrapper,
  SpinnerWrapper,
  ChatWrapper,
  UserChat,
  TextBox,
  SpeakerDescription,
  SettingButtonsContainer,
  SettingsButton,
  MenuItemWrapper,
  SpeechRecognitionButton,
} from './AiAvatar.styles';
import { EXTERNAL_USER_ID } from '@app/utils/constants';
import { getErrorText } from '@app/utils/apiHelpers';
import { useIframe } from '@app/hooks/useIframe';
import { readKnowledgeArea, readKnowledgeAreaPublic } from '@app/api/knowledgeArea.api';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

const SPEECH_KEY = process.env.REACT_APP_MICROSOFT_COGNITIVESERVICES_SPEECH_KEY || '';
const SPEECH_REGION = process.env.REACT_APP_MICROSOFT_COGNITIVESERVICES_SPEECH_REGION || '';

export const AiAvatar: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    waitSpeechVideoAssistantTextActive,
    contextSizeVideoAssistant,
    videoAssistantVideoQuality,
    videoAssistantLanguage,
    webBrowserSpeechRecognitionActive,
    conversationMemoryVideoAssistantActive,
  } = useAppSelector((state) => state.settings);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState<boolean>(false);
  const {
    isIframeUsed,
    iframeLanguage,
    iframePromptMessage,
    isShowListenButton,
    isShowInputText,
    isShowOutputText,
    isShowAvatar,
    avatarHeight,
    avatarVolume,
    functionDefinitions,
    sendCallFunctionToParent,
    isShowSettings,
    isSpeechRecognitionOn,
    setIsSpeechRecognitionOn,
    handleIframeDebugMessages,
    handleAvatarIsReady,
    isAvatarReady,
  } = useIframe();
  const { knowledgeAreaId } = useParams();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const presetLanguage = searchParams.get('lang');
  const greetingText = searchParams.get('greeting');
  const presetPleaseWaitPhrase = searchParams.get('please-wait-phrase');
  const presetConversationMemory = searchParams.get('conversation-memory');
  const presetContinuousSpeechRecognition = searchParams.get('continuous-speech-recognition');
  const presetKnowledgeContextSize = searchParams.get('knowledge-context-size');
  const presetVideoQuality = searchParams.get('video-quality');

  const user = useAppSelector((state) => state.user.user);
  const { appName } = useAppSelector((state) => state.appLayout);
  const companyState = useAppSelector((state) => state.company);
  const { externalAccessToken, isPublicAccessMode } = useAppSelector((state) => state.authExternalAccessToken);

  const [isPermissionToMicrophone, setPermissionToMicrophone] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [recordingStatus, setRecordingStatus] = useState<string>('inactive');
  const [userQuestion, setUserQuestion] = useState<string>('');
  const [aiAnswer, setAiAnswer] = useState<string>('');
  const aiAnswerRef = useRef<string>('');
  const [avatarText, setAvatarText] = useState<string>('');
  const [initialGreetingText, setInitialGreetingText] = useState<string>(greetingText ?? '');
  const [isListenQuestion, setIsListenQuestion] = useState<boolean>(false);
  const [audioChunks, setAudioChunks] = useState<Array<Blob>>([]);
  const [initialized, setInitialized] = useState(false);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [isRefreshAvatar, setIsRefreshAvatar] = useState<boolean>(false);
  const isSpeechRecognitionRecordRef = useRef<boolean>(true);
  const [knowledgeAreaName, setKnowledgeAreaName] = useState<string>('');
  const [avatarSpeechRestartTimeoutId, setAvatarSpeechRestartTimeoutId] = useState<number>(0);

  const questionCounter = useRef<number>(0);

  const updatedMessages = useRef<MessageType[]>([]);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const chatId = useRef<string>('');
  const socketIO = useRef<Socket | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const speechConfig = useRef<null | any>(null);
  const audioConfig = useRef<null | any>(null);
  const recognizerRef = useRef<null | any>(null);

  const showListenButton = isShowListenButton;
  const showInputText = isShowInputText;
  const showOutputText = isShowOutputText;
  const showAvatar = isShowAvatar;

  useEffect(() => {
    if (knowledgeAreaId) {
      const readKnowledgeAreaName = async () => {
        try {
          let knowledgeArea: KnowledgeAreaModel | null = null;
          if (isPublicAccessMode && externalAccessToken?.accessToken) {
            knowledgeArea = await readKnowledgeAreaPublic(knowledgeAreaId);
          } else {
            knowledgeArea = await readKnowledgeArea(knowledgeAreaId);
          }
          setKnowledgeAreaName(knowledgeArea?.title);
        } catch (error) {
          console.error(error);
        }
      };
      readKnowledgeAreaName();
    }
  }, [knowledgeAreaId]);

  useEffect(() => {
    if (isIframeUsed && iframePromptMessage) {
      setAvatarText(iframePromptMessage);
    }
  }, [isIframeUsed, iframePromptMessage]);

  useEffect(() => {
    if (!isLoadingAvatar && videoAssistantLanguage && initialized) {
      questionCounter.current = 0;
      setIsRefreshAvatar(true);
    }
  }, [videoAssistantLanguage, initialized]);

  useEffect(() => {
    if (isIframeUsed && iframeLanguage) {
      if (iframeLanguage === 'en' || iframeLanguage === 'de') {
        dispatch(setVideoAssistantLanguage({ value: iframeLanguage }));
        dispatch(setIframeMessageStatus({ value: false }));
      }
    }
  }, [isIframeUsed, iframeLanguage]);

  useEffect(() => {
    const handleVoiceRecord = async () => {
      if (navigator && navigator.mediaDevices) {
        try {
          const streamData = await navigator.mediaDevices.getUserMedia({ audio: true });
          setPermissionToMicrophone(true);
          setStream(streamData);
        } catch (err) {
          console.log(err);
          const errorText = `Error during acitvating MediaRecorder API: ${getErrorText(err)}`;
          handleIframeDebugMessages(errorText);
          message.error(errorText);
        }
      } else {
        console.log('The MediaRecorder API is not supported in your browser.');
        handleIframeDebugMessages('The MediaRecorder API is not supported in your browser.');
        message.error('The MediaRecorder API is not supported in your browser.');
      }
    };

    const initializeChat = async () => {
      if (!chatId.current) {
        chatId.current = uuidv4();
        setInitialized(true);
      }
    };

    const presetSettings = async () => {
      if (presetLanguage) {
        const language: 'en' | 'de' = presetLanguage === 'de' ? 'de' : 'en';
        dispatch(setVideoAssistantLanguage({ value: language }));
      }
      if (initialGreetingText && initialGreetingText.length > 0) {
        setTimeout(() => {
          setAiAnswer(initialGreetingText);
          aiAnswerRef.current = initialGreetingText;
          setAvatarText(initialGreetingText);
        }, 5000);
      }
      if (presetPleaseWaitPhrase) {
        const isPleaseWaitPhraseOn = presetPleaseWaitPhrase === 'true';
        dispatch(setWaitSpeechVideoAssistantTextActive({ value: isPleaseWaitPhraseOn }));
      }
      if (presetConversationMemory) {
        const isConversationMemoryOn = presetConversationMemory === 'true';
        dispatch(setConversationMemoryVideoAssistantActive({ value: isConversationMemoryOn }));
      }
      if (presetContinuousSpeechRecognition) {
        const isContinuousSpeechRecognitionOn = presetContinuousSpeechRecognition === 'true';
        dispatch(setWebBrowserSpeechRecognitionActive({ value: isContinuousSpeechRecognitionOn }));
      }
      if (
        presetKnowledgeContextSize === 'small' ||
        presetKnowledgeContextSize === 'normal' ||
        presetKnowledgeContextSize === 'large'
      ) {
        dispatch(setContextSizeVideoAssistant({ value: presetKnowledgeContextSize }));
      }
      if (presetVideoQuality === 'low' || presetVideoQuality === 'medium' || presetVideoQuality === 'high') {
        dispatch(setVideoAssistantVideoQuality({ value: presetVideoQuality }));
      }
    };

    presetSettings();
    initializeChat();
    handleVoiceRecord();

    return () => {
      if (avatarSpeechRestartTimeoutId) {
        clearTimeout(avatarSpeechRestartTimeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (initialized) {
      if (webBrowserSpeechRecognitionActive) {
        speechConfig.current = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
        speechConfig.current.speechRecognitionLanguage = videoAssistantLanguage === 'en' ? 'en-US' : 'de-DE';

        audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();
        recognizerRef.current = new sdk.SpeechRecognizer(speechConfig.current, audioConfig.current);

        const processRecognizedTranscript = (event: any) => {
          const result = event?.result;

          if (result?.reason === sdk.ResultReason.RecognizedSpeech) {
            const transcript = result?.text;

            const preparedAiAnswer = aiAnswerRef.current.trim().toLowerCase();
            const preparedSpeechTranscription = transcript.trim().toLowerCase();
            if (preparedAiAnswer.includes(preparedSpeechTranscription)) {
              console.log('****** recognized avatar speech loop, return');
              return;
            }

            setUserQuestion(transcript);
            setAiAnswer('');
            setIsListenQuestion(false);

            stopContinuousSpeechRecognition();
          }
        };

        recognizerRef.current.recognized = (s: any, e: any) => processRecognizedTranscript(e);

        setTimeout(() => {
          recognizerRef.current?.startContinuousRecognitionAsync(() => {
            console.log('Speech recognition started.');
            setUserQuestion('');
            setIsListenQuestion(true);
          });
        }, 1000);
      } else if (isSpeechRecognitionRecordRef.current) {
        stopContinuousSpeechRecognition();
        setIsListenQuestion(false);
        setUserQuestion('');
      }
    }

    if (initialized && !webBrowserSpeechRecognitionActive && recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync();
    }
    return () => {
      if (recognizerRef.current) {
        recognizerRef.current.stopContinuousRecognitionAsync();
      }
    };
  }, [initialized, webBrowserSpeechRecognitionActive, videoAssistantLanguage]);

  useEffect(() => {
    if (initialized && chatId.current) {
      connectWebSocket(chatId.current);
      return () => {
        console.log('### cleanup user-leaved', chatId.current);
        socketIO.current?.emit('user-leaved', { userId: user?._id, chatId: chatId.current });
        socketIO.current?.off('new-message', onNewMessageFromSocket);
        socketIO.current?.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  useEffect(() => {
    const getChatAnswer = async () => {
      try {
        if (waitSpeechVideoAssistantTextActive && userQuestion.length > 20) {
          const phrases = {
            en: [
              'Ok, one moment please',
              'One moment, I will take a look quickly',
              'Please hold on for a second',
              'Give me a short while to check',
            ],
            de: [
              'Ok, einen Moment bitte',
              'Einen Moment, ich schaue kurz nach!',
              'Bitte warten Sie einen Augenblick',
              'Geben Sie mir kurz Zeit, um das zu prüfen',
            ],
          };

          const randomIndex = Math.floor(Math.random() * phrases.en.length);
          const phrasesSelector = videoAssistantLanguage === 'de' ? 'de' : 'en';
          setAvatarText(phrases[phrasesSelector][randomIndex]);
        }

        abortControllerRef.current = new AbortController();

        let maxRetrievalDocs = 4;
        if (contextSizeVideoAssistant === 'normal') {
          maxRetrievalDocs = 8;
        } else if (contextSizeVideoAssistant === 'large') {
          maxRetrievalDocs = 20;
        }

        const toUseFunctionsDescription = functionDefinitions
          ? `You can also use this tools:
${functionDefinitions.map((item) => `${item.name}: ${item.description}`).join('\n')}
In case you want to use a tool just return the toolName ${functionDefinitions.map((item) => `"${item.name}"`).join(', ')}.`
          : undefined;

        if (isPublicAccessMode && externalAccessToken?.accessToken) {
          await knowledgeVoiceAssistantQueryAsStreamPublic(
            {
              queryText: userQuestion,
              knowledgeAreaId: knowledgeAreaId ?? '',
              accessToken: externalAccessToken.accessToken,
              chatId: chatId.current,
              aiModelName: 'gpt-3.5-turbo',
              maxRetrievalDocs,
              selectedLanguage: videoAssistantLanguage,
              enableHistory: conversationMemoryVideoAssistantActive,
              userId: EXTERNAL_USER_ID,
              companyId: externalAccessToken.companyId,
              toUseFunctionsDescription,
            },
            abortControllerRef.current.signal,
          );
        } else {
          await knowledgeVoiceAssistantQueryAsStream(
            {
              queryText: userQuestion,
              knowledgeAreaId: knowledgeAreaId ?? '',
              chatId: chatId.current,
              aiModelName: 'gpt-3.5-turbo',
              maxRetrievalDocs,
              selectedLanguage: videoAssistantLanguage,
              enableHistory: conversationMemoryVideoAssistantActive,
              userId: user?._id,
              companyId: companyState._id,
              toUseFunctionsDescription,
            },
            abortControllerRef.current.signal,
          );
        }
      } catch (e) {
        console.log(`Error occurred during getting chat answer. Error: ${e}`);
        const errorText = `Error occurred during getting ai answer: ${getErrorText(e)}`;
        handleIframeDebugMessages(errorText);
        message.error(errorText);
      }
    };

    if (userQuestion) getChatAnswer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userQuestion]);

  const handleLLMNewToken = (token: string, isFirstMessage: boolean) => {
    if (isFirstMessage) {
      const updatedMessagesNew = updatedMessages.current.filter((m) => m.text !== '`▍`');
      const newMessage: MessageType = {
        text: token,
        name: appName,
        avatar: '',
        createdAt: Date.now(),
        uid: 'chatgptagen123',
        isGenerating: true,
        messageIsLoading: false,
        id: uuidv4(),
      };
      updatedMessages.current = [...updatedMessagesNew, newMessage];
      setMessages(updatedMessages.current);
    } else {
      updatedMessages.current = updatedMessages.current.map((message) => {
        return message.isGenerating ? { ...message, text: message.text + token } : { ...message };
      });
      setMessages(updatedMessages.current);
    }
  };

  const handleStreamDone = async () => {
    setMessages(
      updatedMessages.current.map((message) =>
        message.isGenerating
          ? {
              ...message,
              isGenerating: false,
            }
          : { ...message },
      ),
    );

    if (updatedMessages.current.length) {
      const text = updatedMessages.current[updatedMessages.current.length - 1].text;

      if (functionDefinitions.length) {
        if (functionDefinitions.some((item) => item.name === text)) {
          sendCallFunctionToParent(text);
          if (isSpeechRecognitionRecordRef.current) {
            restartVoiceRecognition(5000);
          }
          return;
        }
      }

      aiAnswerRef.current = text;
      setAvatarText(text);

      setTimeout(() => setAiAnswer(text), 1500);

      const countWords = text.split(' ');

      if (isSpeechRecognitionRecordRef.current) {
        restartVoiceRecognition(Math.max(countWords.length * 0.9 * 1000, 5000));
      }
    }
  };

  const restartVoiceRecognition = (delayMs: number) => {
    const avatarRestartRecognitionTimeoutId = setTimeout(() => {
      try {
        console.log('****** recognition.start()');
        recognizerRef.current?.startContinuousRecognitionAsync(() => {
          setIsListenQuestion(true);
          setUserQuestion('');
        });
      } catch (error) {
        console.log('****** Restart VoiceRecognition error: ', error);
        handleIframeDebugMessages(`Restart VoiceRecognition error: ${getErrorText(error)}`);
      }
    }, delayMs);
    setAvatarSpeechRestartTimeoutId(avatarRestartRecognitionTimeoutId as unknown as number);
  };

  const onNewMessageFromSocket = (value: ChatResponseMessage) => {
    const { isFirstMessage, token, messageType } = value;
    if (messageType === 'NEW_TOKEN') {
      handleLLMNewToken(token ?? '', isFirstMessage || false);
    } else if (messageType === 'STREAM_DONE') {
      handleStreamDone();
    }
  };

  const startContinuousSpeechRecognition = () => {
    try {
      console.log('****** recognition.start()');
      recognizerRef.current?.startContinuousRecognitionAsync();
    } catch (error) {
      const errorText = `Error on start CSR: ${getErrorText(error)}`;
      message.error(errorText);
      handleIframeDebugMessages(errorText);
    }
  };

  const stopContinuousSpeechRecognition = () => {
    try {
      console.log('****** recognition.stop()');
      recognizerRef.current?.stopContinuousRecognitionAsync();
    } catch (error) {
      const errorText = `Error on stop CSR: ${getErrorText(error)}`;
      console.log(errorText);
      handleIframeDebugMessages(errorText);
    }
  };

  const connectWebSocket = (_chatId: string, connectedCallback?: () => void) => {
    createSocketInstance((socket) => {
      socketIO.current?.off('new-message', onNewMessageFromSocket);
      socketIO.current = socket;
      socketIO.current?.on('new-message', onNewMessageFromSocket);
      socketIO.current?.emit('user-joined', { userId: user?._id, chatId: _chatId });
      connectedCallback?.();
    });
  };

  const startRecordingAudio = async () => {
    setRecordingStatus('recording');
    setUserQuestion('');
    setAiAnswer('');
    setAvatarText('');
    aiAnswerRef.current = '';
    setInitialGreetingText('');
    setIsListenQuestion(true);

    if (stream) {
      const media = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      mediaRecorder.current = media;
      mediaRecorder.current.start();
      const localAudioChunks: Array<Blob> = [];
      mediaRecorder.current.ondataavailable = (event) => {
        if (typeof event.data === 'undefined') return;
        if (event.data.size === 0) return;
        localAudioChunks.push(event.data);
      };
      setAudioChunks(localAudioChunks);
    }
  };

  const stopRecordingAudio = () => {
    setRecordingStatus('inactive');
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      mediaRecorder.current.onstop = async () => {
        try {
          const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });
          setAudioChunks([]);

          const formData = new FormData();
          formData.append('file', audioBlob as Blob);
          videoAssistantLanguage && formData.append('language', videoAssistantLanguage);
          const result = await transcribeSpeech(formData);
          setUserQuestion(result);
          setIsListenQuestion(false);
        } catch (e) {
          console.log(`Error with speech transcribe: ${e}`);
          handleIframeDebugMessages(`Error with speech transcribe: ${getErrorText(e)}`);
          message.error('There was a problem processing the voice. Please try again.');
        }
      };
    }
  };

  return (
    <VideoWrapper>
      <VideoElementWrapper>
        <SettingButtonsContainer>
          <SettingsButton onClick={() => setIsRefreshAvatar(true)}>
            <ReloadOutlined />
          </SettingsButton>
          <Dropdown
            menu={{
              items: [
                { label: 'English ', key: 'en' },
                { label: 'German', key: 'de' },
              ],
              onClick: ({ key }) => {
                if (key === 'en' || key === 'de') {
                  dispatch(setVideoAssistantLanguage({ value: key }));
                }
              },
            }}
            trigger={['click']}
            placement="bottomRight"
          >
            <SettingsButton>
              <NormalText>{videoAssistantLanguage === 'de' ? 'De' : 'En'}</NormalText>
            </SettingsButton>
          </Dropdown>
          {isShowSettings && (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.ItemGroup>
                    <MenuItemWrapper>Knowledge Space: {knowledgeAreaName || knowledgeAreaId}</MenuItemWrapper>
                  </Menu.ItemGroup>
                  <Divider style={{ margin: 0 }} />
                  <Menu.ItemGroup>
                    <MenuItemWrapper>
                      {'Enable "Please wait phrase" '}
                      <Switch
                        checked={waitSpeechVideoAssistantTextActive}
                        onChange={() =>
                          dispatch(
                            setWaitSpeechVideoAssistantTextActive({ value: !waitSpeechVideoAssistantTextActive }),
                          )
                        }
                      />
                    </MenuItemWrapper>
                  </Menu.ItemGroup>
                  <Divider style={{ margin: 0 }} />
                  <Menu.ItemGroup>
                    <MenuItemWrapper>
                      {'Enable conversation memory '}
                      <Switch
                        checked={conversationMemoryVideoAssistantActive}
                        onChange={() =>
                          dispatch(
                            setConversationMemoryVideoAssistantActive({
                              value: !conversationMemoryVideoAssistantActive,
                            }),
                          )
                        }
                      />
                    </MenuItemWrapper>
                  </Menu.ItemGroup>
                  <Divider style={{ margin: 0 }} />
                  <Menu.ItemGroup>
                    <MenuItemWrapper>
                      {'Continuous Speech Recognition '}
                      <Switch
                        checked={webBrowserSpeechRecognitionActive}
                        onChange={() =>
                          dispatch(setWebBrowserSpeechRecognitionActive({ value: !webBrowserSpeechRecognitionActive }))
                        }
                      />
                    </MenuItemWrapper>
                  </Menu.ItemGroup>
                  <Divider style={{ margin: 0 }} />
                  <Menu.ItemGroup>
                    <MenuItemWrapper>
                      Context Size
                      <Radio.Group
                        onChange={(e) => dispatch(setContextSizeVideoAssistant({ value: e.target.value }))}
                        defaultValue={contextSizeVideoAssistant}
                        size="small"
                      >
                        <Radio.Button value="small">Small</Radio.Button>
                        <Radio.Button value="normal">Normal</Radio.Button>
                        <Radio.Button value="large">Large</Radio.Button>
                      </Radio.Group>
                    </MenuItemWrapper>
                  </Menu.ItemGroup>
                  <Divider style={{ margin: 0 }} />
                  <Menu.ItemGroup>
                    <MenuItemWrapper>
                      Video Quality
                      <Radio.Group
                        onChange={(e) => dispatch(setVideoAssistantVideoQuality({ value: e.target.value }))}
                        defaultValue={videoAssistantVideoQuality}
                        size="small"
                      >
                        <Radio.Button value="low">Low</Radio.Button>
                        <Radio.Button value="medium">Medium</Radio.Button>
                        <Radio.Button value="high">High</Radio.Button>
                      </Radio.Group>
                    </MenuItemWrapper>
                  </Menu.ItemGroup>
                </Menu>
              }
            >
              <SettingsButton>
                <SettingOutlined />
              </SettingsButton>
            </Dropdown>
          )}
        </SettingButtonsContainer>
        {isLoadingAvatar && (
          <SpinnerWrapper>
            <LoadingSpin />
          </SpinnerWrapper>
        )}
        {initialized && (
          <AvatarVideoElement
            text={avatarText}
            setIsLoadingAvatar={setIsLoadingAvatar}
            isRefreshAvatar={isRefreshAvatar}
            refreshAvatarCallback={() => setIsRefreshAvatar(false)}
            showAvatar={showAvatar}
            avatarHeight={avatarHeight}
            avatarVolume={avatarVolume}
            handleIframeDebugMessages={handleIframeDebugMessages}
            onAvatarIsReadyCallback={handleAvatarIsReady}
          />
        )}
        {isAvatarReady && (
          <ChatWrapper>
            {(showInputText || showOutputText) && (
              <UserChat>
                {isListenQuestion && !userQuestion && (
                  <NormalText centered>
                    {videoAssistantLanguage === 'en' ? "I'm listening to you..." : 'Ich höre Ihnen zu ...'}
                  </NormalText>
                )}
                {showInputText && userQuestion && (
                  <TextBox>
                    <SpeakerDescription>You:</SpeakerDescription>
                    <span>{isListenQuestion ? '...' : userQuestion}</span>
                  </TextBox>
                )}
                {showOutputText && (userQuestion || initialGreetingText) && (
                  <TextBox>
                    <SpeakerDescription>AI:</SpeakerDescription>
                    <span>{aiAnswer || '...'}</span>
                  </TextBox>
                )}
              </UserChat>
            )}
            {isPermissionToMicrophone && showListenButton && !webBrowserSpeechRecognitionActive && (
              <Button
                type="primary"
                shape="circle"
                size="large"
                danger
                onClick={() => (recordingStatus === 'inactive' ? startRecordingAudio() : stopRecordingAudio())}
                icon={
                  recordingStatus === 'inactive' ? (
                    <IconMicrophone style={{ verticalAlign: 'middle' }} />
                  ) : (
                    <IconPlayerStop style={{ verticalAlign: 'middle' }} />
                  )
                }
              />
            )}
            {isPermissionToMicrophone && showListenButton && webBrowserSpeechRecognitionActive && (
              <SpeechRecognitionButton
                onClick={() => {
                  if (isSpeechRecognitionRecordRef.current) {
                    stopContinuousSpeechRecognition();
                    setIsListenQuestion(false);
                    setUserQuestion('');
                    if (avatarSpeechRestartTimeoutId) {
                      clearTimeout(avatarSpeechRestartTimeoutId);
                    }
                  } else {
                    startContinuousSpeechRecognition();
                    setIsListenQuestion(true);
                    aiAnswerRef.current = '';
                  }
                  isSpeechRecognitionRecordRef.current = !isSpeechRecognitionRecordRef.current;
                  setIsSpeechRecognitionOn(!isSpeechRecognitionOn);
                }}
              >
                {isSpeechRecognitionOn ? (
                  <IconMicrophoneOff style={{ verticalAlign: 'middle' }} />
                ) : (
                  <IconMicrophone style={{ verticalAlign: 'middle' }} />
                )}
              </SpeechRecognitionButton>
            )}
          </ChatWrapper>
        )}
      </VideoElementWrapper>
    </VideoWrapper>
  );
};
