import React, { useEffect, useState } from 'react';
import { Badge, Button, Checkbox, Col, Collapse, Drawer, Modal, Row, Space, message } from 'antd';
import { markRelevantSentences, prepareSourceAuditInfoForApi } from '@app/api/gpt.api';
import {
  InlineSourceDocument,
  SourceDocumentType,
  SourceDocumentsSorted,
  isTypeDocumentOrWebsite,
} from '@app/types/chatBoxTypes';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import {
  IconBrowser,
  IconFileTypeDoc,
  IconFileTypePdf,
  IconFileTypeTxt,
  IconFileTypeXls,
  IconSql,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { themeObject } from '@app/styles/themes/themeVariables';
import SourceEntryItem from './SourceEntryItem';
import { useResponsive } from '@app/hooks/useResponsive';
import { Panel } from '../common/Collapse/Collapse';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { SourceDrawerRelatedText } from './GptChatBox';
import { UnorderedListOutlined } from '@ant-design/icons';
import { FilterActionButtonStyle, SelectSourceRow } from './SourceAuditDrawer.styles';
import { API_INTEGRATIONS } from '@app/utils/constants';
import { createSignedUrlByPath } from '@app/api/uploadedDocument.api';
import PdfViewer from './PdfViewer';
import { setSourceAuditInteractionData } from '@app/store/slices/settingsSlice';
import PuffLoader from 'react-spinners/PuffLoader';
import { getErrorText } from '@app/utils/apiHelpers';

export interface PropTypes {
  showSourceDrawer: boolean;
  toShowDrawerSourceDocuments: SourceDocumentsSorted[];
  sourceDrawerRelatedText: SourceDrawerRelatedText | null;
  onCloseSourceDrawer: () => void;
  onGenerateAnswerWithSelectedSources: (messageId: string, selectedSources: SourceDocumentsSorted[]) => void;
}

export type HighlightTextBlock = {
  pageContent: string;
  relevantSenteces: string[];
  index: number;
  pageNumber?: number;
};

const ShowSourceFilterButton = false;

const SourceAuditDrawer: React.FC<PropTypes> = ({
  showSourceDrawer,
  onCloseSourceDrawer,
  onGenerateAnswerWithSelectedSources,
  toShowDrawerSourceDocuments,
  sourceDrawerRelatedText,
}) => {
  const [activeCollapseKeys, setActiveCollapseKeys] = useState<string>('');
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [toShowDocument, setToShowDocument] = useState<SourceDocumentType | null>(null);

  const [pdfHighlightedTexts, setPdfHighlightedTexts] = useState<HighlightTextBlock[]>([]);
  const [pdfHighlightedTextIndex, setPdfHighlightedTextIndex] = useState<number>(0);

  const [selectionSourcesMode, setSelectionSourcesMode] = useState(false);

  const [loadingHighlitingData, setLoadingHighlitingData] = useState(false);

  const [selectedSources, setSelectedSources] = useState<string[]>([]);

  const companyState = useAppSelector((state) => state.company);
  const user = useAppSelector((state) => state.user.user);

  const { gptChatBoxLLM, sourceAuditInteractionData } = useAppSelector((state) => state.settings);

  const { mobileOnly } = useResponsive();

  const dispatch = useAppDispatch();

  const theme = useAppSelector((state) => state.theme.theme);

  const [localToShowDrawerSourceDocuments, setLocalToShowDrawerSourceDocuments] = useState<SourceDocumentsSorted[]>([]);

  useEffect(() => {
    setLocalToShowDrawerSourceDocuments(toShowDrawerSourceDocuments);
  }, [toShowDrawerSourceDocuments]);

  const onInlinePreviewFile = async (sourceItem: InlineSourceDocument) => {
    if (sourceItem.source.type === 'pdf') {
      if (!sourceItem.source.fileStoragePath) {
        message.error('Fehler: ungültige Konfiguration');
        return;
      }

      try {
        const signedUrl = await createSignedUrlByPath(sourceItem.source.fileStoragePath);

        setLoadingHighlitingData(true);
        setPdfHighlightedTexts([]);
        setToShowDocument({ ...sourceItem.source, source: signedUrl });
        setShowPDFViewer(true);

        const results = await markRelevantSentences({
          queryText: sourceItem.question,
          answer: sourceItem.answer,
          sources: [sourceItem.source.pageContent],
          aiModelName: gptChatBoxLLM,
        });

        let relevantSenteces: string[] = [];

        if (results.length === 1) {
          relevantSenteces = results[0].relevantSenteces;
        }

        const highlightedTextsSource: HighlightTextBlock[] = [
          {
            pageContent: sourceItem.source.pageContent,
            relevantSenteces: relevantSenteces,
            index: 0,
            pageNumber: sourceItem.source.page || 0,
          },
        ];
        setPdfHighlightedTexts(highlightedTextsSource);
        setPdfHighlightedTextIndex(0);
      } finally {
        setLoadingHighlitingData(false);
      }
    } else {
      Modal.confirm({
        title: t('previewOnlyForPDFAvailable'),
        content: t('previewOnlyForPDFAvailableDescription'),
        okText: t('ok'),
      });
    }
  };

  const onPreviewFile = async (docItem: SourceDocumentType, index: number) => {
    if (['pdf', 'word', 'excel', 'txt'].includes(docItem.type)) {
      if (!docItem.fileStoragePath) {
        message.error('Fehler: ungültige Konfiguration');
        return;
      }
      const signedUrl = await createSignedUrlByPath(docItem.fileStoragePath);

      if (['word', 'excel', 'txt'].includes(docItem.type)) {
        window.open(signedUrl, '_blank');
      } else {
        setToShowDocument({ ...docItem, source: signedUrl });
        const selectedDocument = localToShowDrawerSourceDocuments.find(
          (document) => document.title === docItem.docTitle,
        );
        if (selectedDocument) {
          const highlightedTextsSource: HighlightTextBlock[] = selectedDocument.documents.reduce(
            (highlights: HighlightTextBlock[], document, index) => {
              if (document.pageContent) {
                return [
                  ...highlights,
                  { pageContent: document.pageContent, relevantSenteces: document.relevantSenteces || [], index },
                ];
              }

              return highlights;
            },
            [],
          );
          setPdfHighlightedTexts(highlightedTextsSource);
          setPdfHighlightedTextIndex(index);
        }
        setShowPDFViewer(true);
      }
    } else if (docItem.type === 'website') {
      window.open(docItem.source, '_blank');
    }
  };

  useEffect(() => {
    if (sourceAuditInteractionData?.id === 'SHOW_PDF_VIEWER') {
      if (sourceAuditInteractionData.data) {
        onInlinePreviewFile(sourceAuditInteractionData.data);
      }
      dispatch(setSourceAuditInteractionData({ value: null }));
    }
  }, [sourceAuditInteractionData]);

  const { t } = useTranslation();

  const toggleSourceSelection = (title: string) => {
    setSelectedSources((prevSelectedSources) => {
      if (prevSelectedSources.includes(title)) {
        return prevSelectedSources.filter((source) => source !== title);
      } else {
        return [...prevSelectedSources, title];
      }
    });
  };

  const onRegenerateAnswerWithSelectedSourcesClicked = () => {
    const sources = localToShowDrawerSourceDocuments.filter((item) =>
      selectedSources.some((selected) => selected === item.title),
    );
    onGenerateAnswerWithSelectedSources(sourceDrawerRelatedText?.messageId ?? '-', sources);
    onClose();
  };

  const getSourceIcon = (item: SourceDocumentsSorted) => {
    const type = item.documents[0] && item.documents[0].type;
    if (type === 'website') return <IconBrowser size={'1.5rem'} />;
    else if (type === 'pdf') return <IconFileTypePdf size={'1.5rem'} />;
    else if (type === 'word') return <IconFileTypeDoc />;
    else if (type === 'excel') return <IconFileTypeXls />;
    else if (type === 'txt') return <IconFileTypeTxt />;
    else if (type === API_INTEGRATIONS.msSqlServer) return <IconSql />;
    return null;
  };

  const onCollapseChange = async (key: string | string[]) => {
    if (typeof key === 'string') {
      key === activeCollapseKeys ? setActiveCollapseKeys('') : setActiveCollapseKeys(key);
      const keys = key.split('source_item_col_');
      if (keys.length === 2 && sourceDrawerRelatedText) {
        const index = Number.parseInt(keys[1]);
        const toModifySourceDocument = localToShowDrawerSourceDocuments[index];

        const isDocumentOrWebsite = isTypeDocumentOrWebsite(toModifySourceDocument.type);

        if (toModifySourceDocument && !toModifySourceDocument.highlitingsAlreadyLoaded) {
          try {
            setLocalToShowDrawerSourceDocuments((prev) =>
              prev.map((item, i) => (i === index ? { ...item, loadingHighlitings: true } : item)),
            );
            const documents = toShowDrawerSourceDocuments[index].documents;
            let updatedDocuments: SourceDocumentType[] = [];

            if (documents?.length) {
              if (isDocumentOrWebsite) {
                const pageContents = documents.map((item) => item.pageContent);

                const results = await markRelevantSentences({
                  queryText: sourceDrawerRelatedText.question,
                  answer: sourceDrawerRelatedText.answer,
                  sources: pageContents,
                  aiModelName: gptChatBoxLLM,
                });

                updatedDocuments = documents.map((item, docIndex) => ({
                  ...item,
                  highlightedPageContent: results[docIndex].markdownResult,
                  relevantSenteces: results[docIndex].relevantSenteces,
                }));
              } else {
                const allAgentToolSteps = documents
                  .filter((item) => item.agentToolSteps !== undefined)
                  .map((item) => item.agentToolSteps);

                if (allAgentToolSteps.length) {
                  const result = await prepareSourceAuditInfoForApi({
                    currentSourceInfo: allAgentToolSteps.join(' '),
                    apiType: toModifySourceDocument.type,
                    userId: user?._id ?? '',
                    companyId: companyState._id ?? '',
                  });
                  updatedDocuments = documents.map((item) => ({
                    ...item,
                    highlightedPageContent: result,
                  }));
                }
              }
              setLocalToShowDrawerSourceDocuments((prev) =>
                prev.map((item, sdIndex) =>
                  sdIndex === index
                    ? {
                        ...item,
                        documents: updatedDocuments,
                        highlitingsAlreadyLoaded: true,
                      }
                    : item,
                ),
              );
            }
          } catch (error) {
            console.error('Error in onCollapseChange', error);
            message.error(getErrorText(error));
          } finally {
            setLocalToShowDrawerSourceDocuments((prev) =>
              prev.map((item, i) => (i === index ? { ...item, loadingHighlitings: false } : item)),
            );
          }
        }
      }
    } else if (!key) {
      setActiveCollapseKeys('');
    }
  };

  const navigateToTextMatch = (step: number) => {
    let nextHighlightIndex = pdfHighlightedTextIndex + step;

    if (nextHighlightIndex < 0) {
      nextHighlightIndex = pdfHighlightedTexts.length - 1;
    } else if (nextHighlightIndex > pdfHighlightedTexts.length - 1) {
      nextHighlightIndex = 0;
    }

    setPdfHighlightedTextIndex(nextHighlightIndex);
  };

  const onClose = () => {
    setActiveCollapseKeys('');
    onCloseSourceDrawer();
    setSelectionSourcesMode(false);
    setSelectedSources([]);
  };

  const renderHeader = (item: SourceDocumentsSorted) => (
    <Row gutter={10} align={'middle'}>
      <Col xs={2}>{getSourceIcon(item)}</Col>
      <Col xs={20}>
        <Space size={'large'}>
          <NormalText>{item.title}</NormalText>
          <Badge count={item.documents.length} color={themeObject[theme].primary} />
        </Space>
      </Col>
    </Row>
  );

  return (
    <>
      <Drawer
        width={mobileOnly ? '90%' : 500}
        title={t('sourceAudit')}
        placement="right"
        onClose={onClose}
        open={showSourceDrawer}
      >
        <NormalText size="m" bottomMargin={'m'} light>
          {t('sourcesAndExcerptsConsidered')}
        </NormalText>
        {ShowSourceFilterButton && localToShowDrawerSourceDocuments.length > 1 && (
          <Button
            type="text"
            style={{ marginBottom: 8 }}
            onClick={() => setSelectionSourcesMode(!selectionSourcesMode)}
            icon={<UnorderedListOutlined size={18} />}
          >
            {t('filterSources')}
          </Button>
        )}

        {selectionSourcesMode ? (
          <>
            <div style={{ marginBottom: 100 }}>
              {localToShowDrawerSourceDocuments.map((item, index) => (
                <SelectSourceRow gutter={4} align={'middle'} key={`source_item_${index}`}>
                  <Col xs={2}>
                    <Checkbox
                      onChange={() => toggleSourceSelection(item.title)}
                      checked={selectedSources.includes(item.title)}
                    />
                  </Col>
                  <Col xs={20}>{renderHeader(item)}</Col>
                </SelectSourceRow>
              ))}
            </div>
            <div style={FilterActionButtonStyle}>
              <Button type="default" onClick={() => onRegenerateAnswerWithSelectedSourcesClicked()}>
                {t('generateAnswerWithFilterSources')}
              </Button>
            </div>
          </>
        ) : (
          <Collapse accordion expandIconPosition="end" onChange={onCollapseChange} activeKey={activeCollapseKeys}>
            {localToShowDrawerSourceDocuments.map((item, index) => (
              <Panel header={renderHeader(item)} key={`source_item_col_${index}`}>
                {(item.documents[0].documentCreatedDate || item.documents[0].ingestedDate) &&
                  isTypeDocumentOrWebsite(item.documents[0].type) && (
                    <NormalText colorType="light" size="s" verticalPadding bottomMargin="m">
                      {item.documents[0].documentCreatedDate
                        ? t('fileCreatedOn', { date: item.documents[0].documentCreatedDate })
                        : t('contentUpdatedOn', { date: item.documents[0].ingestedDate })}
                    </NormalText>
                  )}
                {item.loadingHighlitings && (
                  <Space align="center" style={{ marginBottom: '0.5rem' }}>
                    <PuffLoader color={themeObject[theme].primary} loading={true} size={16} />
                    <NormalText colorType="light" size="xs">
                      {t('loadingSentenceHigliting')}{' '}
                    </NormalText>
                  </Space>
                )}
                {item.documents.map((docItem, index) => (
                  <SourceEntryItem
                    key={index + '_source_entry'}
                    onPreviewFile={onPreviewFile}
                    index={index}
                    docItem={docItem}
                    item={item}
                  />
                ))}
              </Panel>
            ))}
          </Collapse>
        )}
      </Drawer>
      <PdfViewer
        open={showPDFViewer}
        onClose={() => setShowPDFViewer(false)}
        url={toShowDocument?.source ?? ''}
        title={toShowDocument?.docTitle ?? ''}
        highlightBlock={pdfHighlightedTexts[pdfHighlightedTextIndex]}
        navigateToNextTextMatch={navigateToTextMatch}
        showNavigationBlock={pdfHighlightedTexts.length > 1}
        currentTextMatchIndex={pdfHighlightedTextIndex + 1}
        highlightsCount={pdfHighlightedTexts.length}
        loadingHighlitingData={loadingHighlitingData}
      />
    </>
  );
};

export default SourceAuditDrawer;
