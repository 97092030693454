import { Button, Row } from 'antd';
import styled from 'styled-components';

export const TabBarRow = styled(Row)`
  background-color: var(--background-color);
  border-bottom: 0.5px solid var(--border-color);
`;

export const ResetButton = styled(Button)`
  padding: 0.5rem;
  padding-right: 1rem;
`;
