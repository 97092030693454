import { SuperDoAreaAllKey } from '@app/types/superDoAreaTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SuperDoState {
  activeAreaFilter: string;
}

const initialState: SuperDoState = {
  activeAreaFilter: SuperDoAreaAllKey,
};

export const superDoSlice = createSlice({
  name: 'superDoSlice',
  initialState,
  reducers: {
    setActiveAreaFilter: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.activeAreaFilter = payload.value;
    },
  },
});

export const { setActiveAreaFilter } = superDoSlice.actions;

export default superDoSlice.reducer;
