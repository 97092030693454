import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, UploadFile, message } from 'antd';
import { Form, Input } from 'antd';
import * as S from './PlatformSettingsOverview.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { updateCompanyData } from '@app/store/slices/companySlice';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { Button as GradientButton } from '@app/components/common/buttons/Button/Button';
import { uploadGlobalAppLogo } from '@app/api/company.api';
import Upload, { RcFile, UploadProps } from 'antd/lib/upload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { setAppLogo } from '@app/store/slices/appLayoutSlice';

const MaxPhotoFileSize = 3 * 1024 * 1024;

export const WhiteLabelSettings: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [file, setFile] = useState<UploadFile | null>(null);
  const [fileChanged, setFileChanged] = useState<boolean>(false);

  const user = useAppSelector((state) => state.user.user);
  const companyState = useAppSelector((state) => state.company);
  const { appLogo, appName } = useAppSelector((state) => state.appLayout);

  const dispatch = useAppDispatch();

  useEffect(() => {
    form.setFieldsValue({
      globalAppName: appName,
    });
    setImageUrl(appLogo);
  }, [form, appLogo, appName]);

  useEffect(() => {
    if (file && fileChanged && !loading) {
      const updateImage = async () => {
        setLoading(true);
        try {
          if (!companyState._id || !user?._id) {
            notificationController.error({ message: 'Fehler beim Hochladen: ungültige Konfiguration' });
            return;
          }

          const formData = new FormData();
          formData.append('file', file.originFileObj as Blob);
          formData.append('fileName', file.name);
          formData.append('companyId', companyState._id);
          formData.append('userId', user._id);

          const newUrl = await uploadGlobalAppLogo(formData);
          dispatch(setAppLogo({ value: newUrl }));
          notificationController.success({ message: 'Global app logo was updated' });
        } catch (error) {
          console.log('****** updateImage error: ', error);
          notificationController.error({ message: 'Leider ist beim Hochladen ein unerwarteter Fehler aufgetreten' });
        } finally {
          setLoading(false);
          setFileChanged(false);
        }
      };
      updateImage();
    }
  }, [file, fileChanged, companyState, user, dispatch]);

  const onResetFields = () => {
    form.resetFields();
  };

  const onSave = async () => {
    const globalAppName = form.getFieldValue('globalAppName');
    if (companyState._id) {
      await dispatch(updateCompanyData({ globalAppName }));
      notificationController.success({ message: t('common.success') });
    }
  };

  // Custom file upload method
  const customRequest = (options: any) => {
    setFileChanged(true);
    options.onSuccess('done');
  };

  const beforeUpload = (file: RcFile) => {
    const isLessThanMaxSize = file.size < MaxPhotoFileSize;
    if (!isLessThanMaxSize) {
      message.error(`Die Datei ist zu groß. Die maximale Größe beträgt ${MaxPhotoFileSize / 1024 / 1024}MB.`);
    }
    return isLessThanMaxSize; // Wenn false zurückgegeben wird, wird die Datei nicht hochgeladen.
  };

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    listType: 'picture-card',
    customRequest,
    beforeUpload,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setFile(info.file);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        <NormalText size="s" centered semiBold>
          Upload Global App Logo
        </NormalText>
      </div>
    </div>
  );

  return (
    <>
      <Col xs={24} xl={12}>
        <S.ImgWrapper>
          <Upload {...props}>
            {imageUrl ? (
              <img
                src={imageUrl}
                alt={appName}
                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'contain' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </S.ImgWrapper>
        <Form form={form}>
          <Form.Item
            key={'globalAppName'}
            labelCol={{ span: 24 }}
            label={t('generalSettings.appName')}
            name={'globalAppName'}
            rules={[{ required: true, message: t('requiredField') }]}
          >
            <Input maxLength={30} showCount />
          </Form.Item>
        </Form>
        <S.FooterContainer>
          <Row justify={'space-between'}>
            <Button type="dashed" onClick={() => onResetFields()}>
              {t('resetAll')}
            </Button>
            <GradientButton type="primary" onClick={() => onSave()}>
              {t('save')}
            </GradientButton>
          </Row>
        </S.FooterContainer>
      </Col>
    </>
  );
};
