import { SuperDoTemplateInputInfo } from '@app/components/gpttemplates/templateDataInputMapping';

export type SuperDoTemplate = {
  _id: string;
  companyId: string;
  title: string;
  areaId: string;
  promptTemplate: string;
  description?: string;
  elements: SuperDoTemplateInputInfo[];
  createdBy: string;
  aiToolsToUse?: SuperDoAiTool[];
};

export type SuperDoTemplateStore = {
  title: string;
  promptTemplate: string;
  description?: string;
  elements: SuperDoTemplateInputInfo[];
  aiToolsToUse?: SuperDoAiTool[];
  schemaVersion: number;
};

export type SuperDoAiToolName = 'DALL_E' | 'GOOGLE_SEARCH' | 'WEB_BROWSER';

export type SuperDoAiTool = {
  name: SuperDoAiToolName;
  parameters: Record<string, any>[];
};

export type SuperDoAiToolOption = {
  name: SuperDoAiToolName;
  label: string;
};

export const AiTools: SuperDoAiToolOption[] = [
  { name: 'DALL_E', label: 'Dall-E' },
  { name: 'GOOGLE_SEARCH', label: 'Google Search' },
  { name: 'WEB_BROWSER', label: 'Webbrowser' },
];
