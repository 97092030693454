import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import React, { useMemo, useState } from 'react';
import { Col, Row, Modal, message } from 'antd';
import {
  HeaderRow,
  MainContainer,
  TemplatesContainer,
  EmptyAreaContainer,
  ImageHolder,
  OverviewContainer,
  TopHeaderContainer,
} from './GptTemplatesOverview.styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FilterTagItem from '../common/FilterTagItem/FilterTagItem';
import SuperDoItem from './SuperDoItem/SuperDoItem';
import { setActiveAreaFilter } from '@app/store/slices/superDoSlice';
import { loadSuperDoAreas, setSuperDoAreas } from '@app/store/slices/superDoAreaSlice';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createSuperDoTemplate, deleteSuperDoTemplate } from '@app/api/superDoTemplate.api';
import emptySuperDoAreaImage from '@app/assets/images/guest-overview.svg';
import { Button } from '../common/buttons/Button/Button';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { SuperDoTemplate, SuperDoTemplateStore } from '@app/types/superDoTemplateTypes';
import { IconEdit } from '@tabler/icons-react';
import SuperDoAreaModal from './SuperDoAreaModal';
import { SuperDoAreaAllKey, SuperDoAreaWithoutAssignmentKey } from '@app/types/superDoAreaTypes';
import { LoadingSpin } from '../common/LoadingSpin';
import SuperDoStoreModal from './SuperDoStoreModal';
import { createSuperDoArea } from '@app/api/superDoArea.api';
import { useErrorHandling } from '@app/hooks/useErrorHandling';

const GptTemplatesOverview: React.FC = () => {
  const { activeAreaFilter } = useAppSelector((state) => state.superDos);
  const { superDoAreas } = useAppSelector((state) => state.superDoAreas);

  const user = useAppSelector((state) => state.user.user);
  const companyState = useAppSelector((state) => state.company);

  const [loading, setLoading] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [storeModalVisible, setStoreModalVisible] = useState(false);

  const dispatch = useAppDispatch();

  const { handleApiError } = useErrorHandling();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onShortFilterClick = (filter: string) => {
    dispatch(setActiveAreaFilter({ value: filter }));
  };

  const onAreasChanged = async () => {
    try {
      setEditModalVisible(false);
      setLoading(true);
      await dispatch(loadSuperDoAreas());
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const onImportSuperDoFromStore = async (superDo: SuperDoTemplateStore, areaTitle: string) => {
    try {
      setLoading(true);

      superDo.title;

      const foundTemplate = superDoAreas.find((item) =>
        item.templates.some((template) => template.title === superDo.title),
      );
      if (foundTemplate) {
        message.info(t('createSuperDo.superDoExists', { title: superDo.title }));
        return;
      }

      const foundArea = superDoAreas.find((item) => item.title === areaTitle);

      let areaId = '';
      if (foundArea) {
        areaId = foundArea.areaId;
      } else {
        areaId = await createSuperDoArea(areaTitle, companyState._id ?? '', user?._id ?? '');
      }

      await createSuperDoTemplate({
        ...superDo,
        areaId,
        createdBy: user?._id ?? '',
        companyId: companyState._id ?? '',
      });
      await dispatch(loadSuperDoAreas());
      setStoreModalVisible(false);
      message.success(t('createSuperDo.superDoCreated', { title: superDo.title }));
      dispatch(setActiveAreaFilter({ value: areaId }));
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const filteredSuperDos = useMemo(() => {
    if (activeAreaFilter === SuperDoAreaAllKey) {
      const allTemplates: SuperDoTemplate[] = [];
      superDoAreas.forEach((area) => {
        allTemplates.push(...area.templates);
      });
      return allTemplates;
    } else {
      const activeSuperDoArea = superDoAreas.find((item) => item.areaId === activeAreaFilter);
      console.log(JSON.stringify(activeSuperDoArea));
      return activeSuperDoArea ? activeSuperDoArea.templates : [];
    }
  }, [superDoAreas, activeAreaFilter]);

  const superDoAreasToShow = useMemo(
    () => superDoAreas.filter((item) => item.areaId !== SuperDoAreaWithoutAssignmentKey),
    [superDoAreas],
  );

  const onDeleteConfirmed = async (id?: string) => {
    try {
      if (id) {
        await deleteSuperDoTemplate(id);
        const superDoAreasToTransform = [...superDoAreas];
        const superDoAreasToSave = superDoAreasToTransform.map((superDoArea) => {
          if (superDoArea.areaId === activeAreaFilter) {
            const templatesToSave = superDoArea.templates.filter((template) => template._id !== id);
            return { ...superDoArea, templates: templatesToSave };
          }
          return superDoArea;
        });
        dispatch(setSuperDoAreas({ value: superDoAreasToSave }));
        message.success(t('createSuperDo.superDoTemplateDeletedSuccessfully'));
      }
    } catch (error) {
      console.log('****** onDeleteConfirmed error: ', error);
    }
  };

  const handleDeleteClick = (id?: string) => {
    Modal.confirm({
      title: t('createSuperDo.deleteTemplate'),
      icon: <ExclamationCircleOutlined />,
      content: t('createSuperDo.areYouSureToDeleteTemplate'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        onDeleteConfirmed(id);
      },
      onCancel() {
        console.log('cancel');
      },
    });
  };

  const handleEditClick = (id?: string) => {
    const selectedTemplate = filteredSuperDos.find((superDoTemplate) => superDoTemplate._id === id);
    navigate('/zen/superdo-create', { state: { mode: 'edit', template: selectedTemplate } });
  };

  return (
    <OverviewContainer>
      <TopHeaderContainer>
        <Row gutter={8} justify={'space-between'} align={'middle'}>
          <Col xs={24} md={18}>
            <NormalText bold size="xl">
              {t('gptTemplates.headerTitle')}
            </NormalText>
            <NormalText verticalPadding>{t('gptTemplates.description')}</NormalText>
          </Col>
          <Col xs={24} md={6}>
            <Button type="primary" style={{ float: 'right' }} onClick={() => setStoreModalVisible(true)}>
              {t('createSuperDo.createSuperDoButton')}
            </Button>
          </Col>
        </Row>
      </TopHeaderContainer>
      {loading ? (
        <LoadingSpin marginTop={'5rem'} />
      ) : (
        <MainContainer>
          <HeaderRow>
            <Col span={20}>
              <Row>
                {superDoAreasToShow.map((item) => (
                  <div key={item.areaId} onClick={() => onShortFilterClick(item.areaId)}>
                    <FilterTagItem
                      text={item.areaId === SuperDoAreaAllKey ? t('common.all') : item.title}
                      isSelected={activeAreaFilter === item.areaId}
                    />
                  </div>
                ))}
                {user?.role === 'ADMIN' && (
                  <Button
                    type="text"
                    icon={<IconEdit style={{ marginTop: 4 }} size={18} color="gray" />}
                    onClick={() => setEditModalVisible(true)}
                  />
                )}
              </Row>
            </Col>
            <Col span={4}>
              <div />
            </Col>
          </HeaderRow>
          <TemplatesContainer>
            {filteredSuperDos.length > 0 &&
              filteredSuperDos.map((item) => {
                const linkRoute =
                  item.areaId === 'CUSTOM' ? '/quick-document-review' : `/zen/superdo-details/${item._id}`;
                return (
                  <SuperDoItem
                    key={`${item.title}_${item._id}`}
                    superDoId={item._id}
                    linkRoute={linkRoute}
                    title={item.title}
                    description={item.description ?? ''}
                    imagePath="/icons/template-default.png"
                    onDeleteSuperDoTemplate={(id: string) => handleDeleteClick(id)}
                    onEditSuperDoTemplate={(id: string) => handleEditClick(id)}
                    isShowActionButtons={user?.role === 'ADMIN' || user?._id === item.createdBy}
                    toolNames={item.aiToolsToUse?.map((tool) => tool.name)}
                  />
                );
              })}
            {filteredSuperDos.length === 0 && (
              <EmptyAreaContainer>
                <ImageHolder src={emptySuperDoAreaImage} alt="empty superDo area"></ImageHolder>
                <NormalText verticalPadding topMargin="l" centered colorType="light">
                  {t('gptTemplates.comingSoon')}
                </NormalText>
              </EmptyAreaContainer>
            )}
          </TemplatesContainer>
        </MainContainer>
      )}
      <SuperDoAreaModal
        isVisible={editModalVisible}
        areasToEdit={superDoAreas.filter(
          (area) => area.areaId !== SuperDoAreaAllKey && area.areaId !== SuperDoAreaWithoutAssignmentKey,
        )}
        onCancel={() => setEditModalVisible(false)}
        onSave={() => onAreasChanged()}
      />
      <SuperDoStoreModal
        isVisible={storeModalVisible}
        onAddSuperDo={onImportSuperDoFromStore}
        onCreateNewSuperDo={() => navigate('/zen/superdo-create')}
        onCancel={() => setStoreModalVisible(false)}
      />
    </OverviewContainer>
  );
};

export default GptTemplatesOverview;
