import { useState, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { NodeApi, MoveHandler } from 'react-arborist';
import { MenuNode } from '@app/types/menuFolderTypes';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { loadNestedMenuStructure, setMenuStructure } from '@app/store/slices/menuFolderSlice';
import { addNodeItem, readNestedMenu, removeNodeItem, updateFolder, updateNestedMenu } from '@app/api/nestedMenu.api';
import { useAppEventSocketListener } from './useAppEventSocketListener';
import { GeneralSocketAppEvent } from '@app/types/socketMessageTypes';
import { loadKnowledgeAreas } from '@app/store/slices/knowledgeAreaSlice';
import { setTriggerEventId } from '@app/store/slices/socketAppEventSlice';
import { useErrorHandling } from './useErrorHandling';
import { debounce } from 'lodash';
import moment from 'moment';

const MENU_ITEM_HEIGHT = 36;
// Should start from 25 because first element in the tree has offset in 25px
const TREE_HEIGHT_OFFSET = 25;

export const useMenuTree = (loadedKnowledgeAreas: KnowledgeAreaModel[]) => {
  const [menuTree, setMenuTree] = useState<MenuNode[]>([]);
  const [initialHeight, setInitialHeight] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [openedFoldersIds, setOpenedFoldersIds] = useState<string[]>([]);
  const { menuStructure, isGlobal, _id } = useAppSelector((state) => state.menuFolder);
  const { isAdmin } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const { handleApiError } = useErrorHandling();

  const windowIsShown = useRef<boolean>(false);
  const windowBluredTimestamp = useRef<number>(0);

  const onSockeAppEvent = async (value: GeneralSocketAppEvent) => {
    if (
      ['KNOWLEDGE_AREA_CHANGES', 'KNOWLEDGE_AREA_FOLDER_CHANGED', 'KNOWLEDGE_AREA_RIGHTS_CHANGED'].includes(
        value.appEventId,
      )
    ) {
      debouncedTriggerRefreshMenuTree();
    }
  };

  const triggerRefreshMenuTree = async () => {
    await dispatch(loadNestedMenuStructure());
    await dispatch(loadKnowledgeAreas());
  };

  const debouncedTriggerRefreshMenuTree = debounce(triggerRefreshMenuTree, 1000);

  useAppEventSocketListener({ onSockeAppEvent });

  useEffect(() => {
    window.addEventListener('focus', onWindowFocus);
    window.addEventListener('blur', onWindowBlur);

    return () => {
      window.removeEventListener('focus', onWindowFocus);
      window.removeEventListener('blur', onWindowBlur);
    };
  }, []);

  useEffect(() => {
    const cloneKnowledgeAreas = loadedKnowledgeAreas.slice();

    const knowledgeAreasIds = loadedKnowledgeAreas.map((knowledgeArea) => knowledgeArea._id);

    // Need logic below to filter menu structure through user's knowledge areas
    const clearedMenuStructure = menuStructure.reduce((acc, menuItem) => {
      if (menuItem.isFolder) {
        if (menuItem.children.length === 0) {
          return [...acc, menuItem];
        } else if (menuItem.children.some((child) => knowledgeAreasIds.includes(child.id))) {
          return [
            ...acc,
            { ...menuItem, children: menuItem.children.filter((child) => knowledgeAreasIds.includes(child.id)) },
          ];
        }
        return acc;
      } else if (!menuItem.isFolder && knowledgeAreasIds.includes(menuItem.id)) {
        return [...acc, menuItem];
      }
      return acc;
    }, [] as MenuNode[]);

    const menuFoldersItemsIds: string[] = clearedMenuStructure.reduce((acc, menuItem) => {
      if (menuItem.children) {
        const itemsIds = menuItem.children.map((children) => children.id);
        return [...acc, menuItem.id, ...itemsIds];
      }
      return [...acc, menuItem.id];
    }, [] as string[]);

    const preparedMenuStructure = clearedMenuStructure.map((menuItem) => {
      if (menuItem.isFolder) {
        return {
          ...menuItem,
          children: menuItem.children.map((item) => {
            const actualKnowledgeAreaValue = loadedKnowledgeAreas.find(
              (knowledgeArea) => knowledgeArea._id === item.id,
            );
            if (actualKnowledgeAreaValue)
              return {
                ...item,
                name: actualKnowledgeAreaValue.title,
              };
            return item;
          }),
        };
      }

      const actualKnowledgeAreaValue = loadedKnowledgeAreas.find((knowledgeArea) => knowledgeArea._id === menuItem.id);
      if (actualKnowledgeAreaValue)
        return {
          ...menuItem,
          name: actualKnowledgeAreaValue.title,
        };
      return menuItem;
    });

    const preparedLoadedKnowledgeAreas = cloneKnowledgeAreas.reduce((acc, knowledgeArea) => {
      if (menuFoldersItemsIds.includes(knowledgeArea._id ?? '')) {
        return acc;
      } else {
        return [
          ...acc,
          {
            name: knowledgeArea.title,
            hexColor: '',
            id: knowledgeArea._id ?? '',
            isFolder: false,
            children: [],
          },
        ];
      }
    }, [] as MenuNode[]);

    const menuTreeToSet = [...preparedMenuStructure, ...preparedLoadedKnowledgeAreas];

    const initialTreeHeight = getTreeHeight(menuTreeToSet, openedFoldersIds);
    setInitialHeight(initialTreeHeight);
    setHeight(initialTreeHeight);

    setMenuTree(menuTreeToSet);
    dispatch(setMenuStructure({ value: menuTreeToSet }));
  }, [loadedKnowledgeAreas]);

  const onWindowFocus = () => {
    windowIsShown.current = true;
    if (windowBluredTimestamp.current) {
      const minutesWasBlured = moment().diff(moment.unix(windowBluredTimestamp.current), 'minutes');
      if (minutesWasBlured > 5) {
        debouncedTriggerRefreshMenuTree();
      }
    }
  };

  const onWindowBlur = () => {
    windowIsShown.current = false;
    windowBluredTimestamp.current = moment().unix();
  };

  // caution: only for admins
  const onUpdateNestedMenu = async (
    nestedMenuId: string,
    isGlobal: boolean,
    menuNodes: MenuNode[],
  ): Promise<string> => {
    const result = await updateNestedMenu(nestedMenuId, isGlobal, menuNodes);
    dispatch(setTriggerEventId({ value: 'KNOWLEDGE_AREA_FOLDER_CHANGED' }));
    return result;
  };

  const onUpdateFolder = async (
    nestedMenuId: string,
    menuFolderId: string,
    name: string,
    hexColor: string,
  ): Promise<string> => {
    const result = await updateFolder(nestedMenuId, menuFolderId, name, hexColor);
    dispatch(setTriggerEventId({ value: 'KNOWLEDGE_AREA_FOLDER_CHANGED' }));
    return result;
  };

  const onAddNodeItem = async (
    nestedMenuId: string,
    menuNodeToAdd: MenuNode,
    index: number,
    parentFolderId?: string,
  ): Promise<string> => {
    const result = await addNodeItem(nestedMenuId, menuNodeToAdd, index, parentFolderId);
    dispatch(setTriggerEventId({ value: 'KNOWLEDGE_AREA_FOLDER_CHANGED' }));
    return result;
  };

  const onRemoveNodeItem = async (nestedMenuId: string, toRemoveItemId: string): Promise<string> => {
    const result = await removeNodeItem(nestedMenuId, toRemoveItemId);
    dispatch(setTriggerEventId({ value: 'KNOWLEDGE_AREA_FOLDER_CHANGED' }));
    return result;
  };

  const handleUpdateNodeTree = (treeToSave: MenuNode[]) => {
    setMenuTree(treeToSave);
    dispatch(setMenuStructure({ value: treeToSave }));
  };

  const removeNodeFromTree = (tree: MenuNode[], removedItemId: string): MenuNode[] => {
    const clonedTree = tree.slice();
    const clearedTree = clonedTree.reduce((accTree, node) => {
      if (node.id === removedItemId) return accTree;
      if (node.id !== removedItemId && node.children.some((child) => child.id === removedItemId)) {
        const nodeToSave: MenuNode = { ...node, children: node.children.filter((child) => child.id !== removedItemId) };
        return [...accTree, nodeToSave];
      }
      return [...accTree, node];
    }, [] as MenuNode[]);

    return clearedTree;
  };

  const insertNodeInTree = (
    tree: MenuNode[],
    nodeToInsert: MenuNode,
    index: number,
    parentId: string | null,
  ): MenuNode[] => {
    const clonedTree = tree.slice();
    const updatedTreeToSave = parentId
      ? clonedTree.map((parent) => {
          if (parent.id === parentId) {
            return {
              ...parent,
              children: [...parent.children.slice(0, index), nodeToInsert, ...parent.children.slice(index)],
            };
          }
          return parent;
        })
      : [...clonedTree.slice(0, index), nodeToInsert, ...clonedTree.slice(index)];
    return updatedTreeToSave;
  };

  const handleMove: MoveHandler<MenuNode> = async ({ dragIds, dragNodes, parentId, parentNode, index }) => {
    if (parentNode && !parentNode.data.isFolder) return;
    const [movedItemId] = dragIds;
    const [dragNode] = dragNodes;

    try {
      // TODO: observe if this is not required
      // const clearedTree = removeNodeFromTree(menuTree, movedItemId);
      // const treeToSave = insertNodeInTree(clearedTree, dragNode.data, index, parentId);
      // handleUpdateNodeTree(treeToSave);

      const currentNestedMenu = await readNestedMenu();
      const menuStructureCurrent = currentNestedMenu.nodes;
      const clearedTree = removeNodeFromTree(menuStructureCurrent, movedItemId);
      const treeToSave = insertNodeInTree(clearedTree, dragNode.data, index, parentId);

      handleUpdateNodeTree(treeToSave);

      // Should recalculate tree height on each move,
      // folder open asynchronously so we need run code below with timeout to recalculate height correctly
      setTimeout(() => {
        onActivateFolder(dragNode);
      }, 10);

      await onUpdateNestedMenu(_id, isGlobal, treeToSave);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleFolderCreate = async (menuNode: MenuNode) => {
    const updatedTree = [menuNode, ...menuTree];
    handleUpdateNodeTree(updatedTree);
    updateTreeHeightOnAction();

    try {
      await onAddNodeItem(_id, menuNode, 0);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleFolderUpdate = async (updatedFolder: MenuNode) => {
    if (updatedFolder.isFolder) {
      const foldersToSave = menuTree.map((menuFolder) => {
        if (menuFolder.isFolder && menuFolder.id === updatedFolder.id) {
          return updatedFolder;
        }
        return menuFolder;
      });

      handleUpdateNodeTree(foldersToSave);

      try {
        await onUpdateFolder(_id, updatedFolder.id, updatedFolder.name, updatedFolder.hexColor);
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const onDeleteNestedMenuItem = async (itemId: string) => {
    try {
      const userTree = removeNodeFromTree(menuTree, itemId);
      handleUpdateNodeTree(userTree);
      await onRemoveNodeItem(_id, itemId);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleAddNewKnowledgeAreaToTheFolder = async (
    folderId: string,
    name: string,
    createdKnowledgeItemId?: string,
  ) => {
    const knowledgeAreaToAdd: MenuNode = {
      name,
      isFolder: false,
      hexColor: '',
      id: createdKnowledgeItemId ?? '',
      children: [],
    };

    let updatedTree = menuTree.slice();

    if (!folderId || folderId === '') {
      updatedTree.push(knowledgeAreaToAdd);
    } else {
      updatedTree = updatedTree.map((menuFolder) => {
        if (menuFolder.isFolder && menuFolder.id === folderId) {
          return {
            ...menuFolder,
            children: [knowledgeAreaToAdd, ...menuFolder.children],
          };
        }
        return menuFolder;
      });
    }

    handleUpdateNodeTree(updatedTree);

    updateTreeHeightOnAction();

    try {
      await onAddNodeItem(_id, knowledgeAreaToAdd, 0, folderId);
    } catch (error) {
      handleApiError(error);
    }
  };

  const disableDrag = () => {
    // for manager and user we block drag&drop
    return !isAdmin;
  };

  const disableDrop = (args: { parentNode: NodeApi<MenuNode>; dragNodes: NodeApi<MenuNode>[]; index: number }) => {
    const isParentNodeFolder = args.parentNode.data.isFolder;
    const isChildrenNodeFolder = args.dragNodes[0].data.isFolder;
    const isInsertionFolderInFolder = isParentNodeFolder && isChildrenNodeFolder;
    const isParentFirstLevel = args.parentNode.level === 0;

    if (args.parentNode.level < 0) {
      return false;
    }

    return !isParentFirstLevel || isInsertionFolderInFolder;
  };

  const onActivateFolder = (node: NodeApi<MenuNode>) => {
    const openedFoldersIds: string[] = [];

    for (const property in node.tree.openState) {
      if (node.tree.openState[property]) openedFoldersIds.push(property);
    }

    updateTreeHeight(openedFoldersIds);
  };

  const updateTreeHeightOnAction = () => {
    updateTreeHeight(openedFoldersIds, MENU_ITEM_HEIGHT);
  };

  const getTreeHeight = (menuTree: MenuNode[], openedFoldersIds: string[]) => {
    const heightOfOpenedFolders = menuTree.reduce((acc, menuNode) => {
      if (openedFoldersIds.includes(menuNode.id) && menuNode.isFolder) {
        const childrenHeight = menuNode.children.length * MENU_ITEM_HEIGHT;
        return (acc = acc + childrenHeight + MENU_ITEM_HEIGHT);
      }
      return (acc = acc + MENU_ITEM_HEIGHT);
    }, TREE_HEIGHT_OFFSET);
    return heightOfOpenedFolders;
  };

  const updateTreeHeight = (openedFoldersIds: string[], addOffsetHeight = 0) => {
    const heightOfOpenedFolders = getTreeHeight(menuTree, openedFoldersIds);

    setInitialHeight(heightOfOpenedFolders + addOffsetHeight);
    setHeight(heightOfOpenedFolders + addOffsetHeight);
    setOpenedFoldersIds(openedFoldersIds);
  };

  return {
    menuTree,
    isMenuTreeEmpy: menuTree.length === 0,
    initialHeight,
    height,
    menuItemHeight: MENU_ITEM_HEIGHT,
    setHeight,
    handleMove,
    handleFolderCreate,
    handleFolderUpdate,
    onDeleteNestedMenuItem,
    handleAddNewKnowledgeAreaToTheFolder,
    disableDrag,
    disableDrop,
    onActivateFolder,
  };
};
