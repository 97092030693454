import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Card } from '@app/components/common/Card/Card';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { CompanyData } from './CompanyData';
import { DepartmentOverview } from '../department/DepartmentOverview';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { CompanyDomainSpecificTerms } from './CompanyDomainSpecificTerms';

export const CompanyOverview: React.FC = () => {
  const { t } = useTranslation();

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    { key: '1', label: t('aboutTheCompany'), children: <CompanyData /> },
    { key: '2', label: t('departments'), children: <DepartmentOverview /> },
    { key: '3', label: t('domainSpecificTerms'), children: <CompanyDomainSpecificTerms /> },
  ];

  return (
    <Card>
      <BaseButtonsForm.Item>
        <NormalText bold size="l">
          {t('company.headerTitle')}
        </NormalText>
      </BaseButtonsForm.Item>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Card>
  );
};
