import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Form, Input, Tabs } from 'antd';
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { IframeMessage, IframeAction, FunctionDefinition, IframeToParentMessage } from '@app/types/iframeTypes';
import { ButtonContainer } from './AiAvatarIframe.styles';

export const AiAvatarIframe: React.FC = () => {
  // String to test url params locally
  //  http://localhost:3000/public/demo-streaming-avatar/6597be2a2edb954d0d11ad13?token=ed226556-5477-4835-84ad-162f187e06aa&please-wait-phrase=true&conversation-memory=true&continuous-speech-recognition=false&knowledge-context-size=large&video-quality=low77
  const [iframeSourceUrl, setIframeSourceUrl] = useState<string>('');
  const [isShowIframe, setIsShowIframe] = useState<boolean>(false);
  const [isIframeConnected, setIsIframeConnected] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [instanceId, setInstanceId] = useState<string>('');
  const [showMessagesType, setShowMessagesType] = useState<'parent' | 'iframe'>('parent');
  const [iframeMessages, setIframeMessages] = useState<{ text: string; timestamp: string }[]>([]);
  const [parentMessages, setParentMessages] = useState<{ text: string; timestamp: string }[]>([]);
  const iframeMessagesRef = useRef<{ text: string; timestamp: string }[]>([]);

  const [isShowListenButton, setIsShowListenButton] = useState<boolean>(true);
  const [isShowInputText, setIsShowInputText] = useState<boolean>(true);
  const [isShowOutputText, setIsShowOutputText] = useState<boolean>(true);
  const [isShowAvatar, setIsShowAvatar] = useState<boolean>(true);
  const [avatarHeight, setAvatarHeight] = useState<number>(50);
  const [avatarVolume, setAvatarVolume] = useState<number>(0.2);
  const [isShowSettings, setIsShowSettings] = useState<boolean>(true);
  const [isSpeechRecognitionOn, setIsSpeechRecognitionOn] = useState<boolean>(false);
  const [isDebugMessagesOn, setIsDebugMessagesOn] = useState<boolean>(false);

  const handleParentMessage = (message: IframeMessage) => {
    const parentMessageToSave = JSON.stringify(message);
    setParentMessages([...parentMessages, { text: parentMessageToSave, timestamp: moment().format('HH:mm:ss') }]);
  };

  useEffect(() => {
    const handleSaveMessage = (data: IframeToParentMessage) => {
      const iframeMessageToSave = JSON.stringify(data);
      if (!iframeMessagesRef.current.find((iframeMessage) => iframeMessage.text === iframeMessageToSave)) {
        iframeMessagesRef.current = [
          ...iframeMessagesRef.current,
          { text: iframeMessageToSave, timestamp: moment().format('HH:mm:ss') },
        ];
        setIframeMessages(iframeMessagesRef.current);
      }
    };

    const handleLoad = () => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        const customMessage: IframeMessage = {
          type: 'aiAvatarMessage',
          payload: {
            interactionType: 'parentConnected',
          },
        };
        setTimeout(() => {
          if (iframeRef.current && iframeRef.current.contentWindow) {
            iframeRef.current.contentWindow.postMessage(customMessage, '*');
            handleParentMessage(customMessage);
          }
        }, 2000);
      }
    };

    if (isShowAvatar) {
      window.addEventListener('message', function (event) {
        const data: IframeToParentMessage = event.data;
        if (data && data.type === 'aiAvatarMessage' && data.payload?.instanceId) {
          if (data.payload.interactionType === 'avatarLoaded') {
            handleLoad();
            setIsIframeConnected(true);
            setInstanceId(data.payload.instanceId);
          } else if (data.payload.interactionType === 'command') {
            if (data.payload.actionId === 'to-call-function') {
              alert('Call function: ' + data.payload?.functionName);
            } else if (data.payload.actionId === 'command-result') {
              console.log('****** command-result messageId: ', data.payload.messageId);
            }
          }
          handleSaveMessage(data);
        }
      });
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleLoad);
      }
      setIsIframeConnected(false);
    };
  }, [isShowIframe]);

  const sendActionToTheIframe = ({
    actionId,
    actionState,
    text,
    lang,
    avatarSize,
    avatarVolume,
    contextInfo,
    functions,
  }: {
    actionId: IframeAction;
    actionState?: boolean;
    text?: string;
    lang?: string;
    avatarSize?: number;
    avatarVolume?: number;
    contextInfo?: string;
    functions?: FunctionDefinition[];
  }) => {
    const iframeMessage: IframeMessage = {
      type: 'aiAvatarMessage',
      payload: {
        interactionType: 'command',
        instanceId,
        actionId,
        actionState,
        messageId: uuidv4(),
        text,
        lang,
        avatarSize,
        avatarVolume,
        contextInfo,
        functions,
      },
    };

    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(iframeMessage, '*');
      handleParentMessage(iframeMessage);
    }
  };

  const renderMessages = (messages: { text: string; timestamp: string }[]) => (
    <>
      {messages.map((message) => (
        <div key={message.text}>
          <NormalText>{`Message: ${message.timestamp}`}</NormalText>
          <NormalText size="s">{message.text}</NormalText>
          <div style={{ borderBottom: '1px solid rgba(140, 140, 140, 0.35)' }}></div>
        </div>
      ))}
    </>
  );

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      {!isShowIframe && (
        <div style={{ padding: '2rem' }}>
          <div style={{ maxWidth: '80%', minWidth: '320px' }}>
            <NormalText size="xxl">iFrame AI Avatar Test</NormalText>
            <Form.Item labelCol={{ span: 24 }} label="Public Knowledge Space URL">
              <Input value={iframeSourceUrl} onChange={(event) => setIframeSourceUrl(event.target.value)} />
            </Form.Item>
          </div>
          {iframeSourceUrl.length > 10 && (
            <Button type="primary" onClick={() => setIsShowIframe(!isShowIframe)}>
              Continue
            </Button>
          )}
        </div>
      )}
      <div
        style={{
          width: '100%',
          padding: '1rem 2rem',
        }}
      >
        {isShowIframe && (
          <div style={{ display: 'flex', justifyContent: isIframeConnected ? 'space-between' : 'start' }}>
            <Button
              size="small"
              key={'back'}
              onClick={() => {
                setIsShowIframe(!isShowIframe);
                iframeMessagesRef.current = [];
                setIframeMessages([]);
                setParentMessages([]);
                setShowMessagesType('parent');
              }}
              icon={<ArrowLeftOutlined />}
            >
              Back
            </Button>
            {!isIframeConnected && (
              <NormalText verticalPadding horizontalPadding>
                {`If you don't see the avatar, please go back and check the url`}
              </NormalText>
            )}
            {isIframeConnected && (
              <ButtonContainer>
                <Button
                  size="small"
                  key={'prompt'}
                  onClick={() =>
                    sendActionToTheIframe({ actionId: 'prompt', text: `Hello, now its ${moment().format('HH:mm:ss')}` })
                  }
                >
                  Say hello
                </Button>
                <Button
                  size="small"
                  key={'lang'}
                  onClick={() => sendActionToTheIframe({ actionId: 'lang', lang: 'en' })}
                >
                  Language
                </Button>
                <Button
                  size="small"
                  key={'hide-button-listen'}
                  onClick={() => {
                    setIsShowListenButton(!isShowListenButton);
                    sendActionToTheIframe({ actionId: 'hide-button-listen', actionState: !isShowListenButton });
                  }}
                >
                  Hide button listen
                </Button>
                <Button
                  size="small"
                  key={'hide-input-text'}
                  onClick={() => {
                    setIsShowInputText(!isShowInputText);
                    sendActionToTheIframe({ actionId: 'hide-input-text', actionState: !isShowInputText });
                  }}
                >
                  Hide input text
                </Button>
                <Button
                  size="small"
                  key={'hide-output-text'}
                  onClick={() => {
                    setIsShowOutputText(!isShowOutputText);
                    sendActionToTheIframe({ actionId: 'hide-output-text', actionState: !isShowOutputText });
                  }}
                >
                  Hide output text
                </Button>
                <Button
                  size="small"
                  key={'hide-avatar'}
                  onClick={() => {
                    setIsShowAvatar(!isShowAvatar);
                    sendActionToTheIframe({ actionId: 'hide-avatar', actionState: !isShowAvatar });
                  }}
                >
                  Hide avatar
                </Button>
                <Button
                  size="small"
                  key={'avatar-size'}
                  onClick={() => {
                    const height = avatarHeight + 10;
                    const heightToSet = height > 100 ? 10 : height;
                    setAvatarHeight(heightToSet);
                    sendActionToTheIframe({ actionId: 'avatar-size', avatarSize: heightToSet });
                  }}
                >
                  Avatar size
                </Button>
                <Button
                  size="small"
                  key={'avatar-volume'}
                  onClick={() => {
                    const volume = avatarVolume + 0.2;
                    const volumeToSet = volume > 1 ? 0.2 : volume;
                    setAvatarVolume(volumeToSet);
                    sendActionToTheIframe({ actionId: 'avatar-volume', avatarVolume: volumeToSet });
                  }}
                >
                  Avatar volume
                </Button>
                <Button
                  size="small"
                  key={'add-function-defintions'}
                  onClick={() => {
                    sendActionToTheIframe({
                      actionId: 'add-function-defintions',
                      functions: [
                        {
                          name: 'call_reception',
                          description:
                            "Use this tool if the user ask something like 'please connect me with the reception'",
                        },
                        {
                          name: 'call_meeting_organizer',
                          description:
                            "Use this tool if the user ask something like 'please connect me with the organizer'",
                        },
                      ],
                    });
                  }}
                >
                  Send functions
                </Button>
                <Button
                  size="small"
                  key={'hide-settings'}
                  onClick={() => {
                    setIsShowSettings(!isShowSettings);
                    sendActionToTheIframe({ actionId: 'hide-settings', actionState: !isShowSettings });
                  }}
                >
                  Hide Settings
                </Button>
                <Button
                  size="small"
                  key={'speech-recognition'}
                  onClick={() => {
                    setIsSpeechRecognitionOn(!isSpeechRecognitionOn);
                    sendActionToTheIframe({
                      actionId: 'continuous-speech-recognition',
                      actionState: !isSpeechRecognitionOn,
                    });
                  }}
                >
                  Speech recognition
                </Button>
                <Button
                  size="small"
                  key={'enable-debug-messages'}
                  onClick={() => {
                    setIsDebugMessagesOn(!isDebugMessagesOn);
                    sendActionToTheIframe({
                      actionId: 'enable-debug-messages',
                      actionState: !isDebugMessagesOn,
                    });
                  }}
                >
                  Debug messages
                </Button>
              </ButtonContainer>
            )}
          </div>
        )}
        {isShowIframe && (
          <div style={{ marginBottom: '1rem' }}>
            <Tabs
              defaultActiveKey="parent"
              items={[
                { key: 'parent', label: 'Sent Messages to iFrame' },
                { key: 'iframe', label: 'Received Messages from iFrame' },
              ]}
              onChange={(key) => {
                if (key === 'parent' || key === 'iframe') {
                  setShowMessagesType(key);
                }
              }}
            />
            <div
              style={{
                height: 150,
                overflow: 'auto',
                padding: '0 1rem',
                border: '1px solid rgba(68, 50, 50, 0.35)',
                borderRadius: 8,
              }}
            >
              {renderMessages(showMessagesType === 'parent' ? parentMessages : iframeMessages)}
            </div>
          </div>
        )}
        {isShowIframe && (
          <NormalText size="l" verticalPadding>
            Iframe URL: <NormalText size="s">{iframeSourceUrl}</NormalText>
          </NormalText>
        )}
      </div>

      {isShowIframe && iframeSourceUrl && (
        <div style={{ height: `calc(100% - 354px)` }}>
          <iframe
            ref={iframeRef}
            src={iframeSourceUrl}
            title="Fullscreen Iframe"
            width="100%"
            height="100%"
            sandbox="allow-same-origin allow-scripts"
            allow="microphone"
            style={{ border: 'none' }}
          />
        </div>
      )}
    </div>
  );
};
