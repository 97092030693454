export const shortenString = (str: string, maxLength = 20) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  } else {
    return str;
  }
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const truncateString = (input: string, maxLength: number): string => {
  if (input.length <= maxLength) {
    return capitalizeFirstLetter(input);
  }

  const truncated = input.substr(0, maxLength);

  if (truncated.charAt(truncated.length - 1) === ' ') {
    return capitalizeFirstLetter(truncated.trim());
  }

  const lastSpaceIndex = truncated.lastIndexOf(' ');

  if (lastSpaceIndex === -1) {
    return capitalizeFirstLetter(truncated);
  } else {
    return capitalizeFirstLetter(truncated.substr(0, lastSpaceIndex).trim());
  }
};

export const convertToUpperCaseId = (input: string): string => {
  let formatted = input.toLowerCase();
  formatted = formatted.replace(/ä/g, 'a').replace(/ü/g, 'u').replace(/ö/g, 'o').replace(/ß/g, 'ss');
  formatted = formatted.replace(/\s+/g, '_');

  // Entferne alle Nicht-ASCII-Zeichen
  formatted = formatted.replace(/[^\x00-\x7F]/g, '').toUpperCase();
  return formatted;
};

export const sanitizeString = (input: string): string => {
  return input.trim().replaceAll('\t', ' ').replaceAll('\n', ' ').replace(/\s+/g, ' ');
};
