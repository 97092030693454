import styled, { css } from 'styled-components';
import { media } from '@app/styles/themes/constants';

export const CanvasElement = styled.canvas<{ showAvatar: boolean; avatarHeight: number | undefined }>`
  ${({ avatarHeight }) => !avatarHeight && 'width: 100%;'};
  height: ${({ avatarHeight }) => (avatarHeight ? `${avatarHeight}%` : 'auto')};
  aspect-ratio: 1/1;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0);
  left: 50%;
  z-index: ${({ showAvatar }) => (showAvatar ? '2' : '-2')};

  @media only screen and ${media.md} {
    ${({ avatarHeight }) => !avatarHeight && 'width: 60%;'};
  }

  @media only screen and ${media.xl} {
    ${({ avatarHeight }) => !avatarHeight && 'width: 40%;'};
  }
`;
