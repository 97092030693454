import { UsageAnalytics } from '@app/types/usageAnalyticsTypes';
import { httpApi } from './http.api';

export const getUsageAnalytics = (companyId: string, year: string, usage: string): Promise<UsageAnalytics | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/requestsAnalytics/read/${companyId}?year=${year}&usage=${usage}`);
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('getUsageAnalytics failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};
