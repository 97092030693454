import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// ToDo delete when backend is ready
import { DocumentChecklist } from '@app/types/documentCheckTypes';

export interface DocumentAnalysisState {
  checkListNestedState: { [id: string]: string };
  firstChecklistElementId: string;
  checklists: DocumentChecklist[];
}

const initialState: DocumentAnalysisState = {
  checkListNestedState: {},
  firstChecklistElementId: '',
  checklists: [],
};

export const documentAnalysisSlice = createSlice({
  name: 'documentAnalysisSlice',
  initialState,
  reducers: {
    setCheckListItemValue: (state, { payload }: PayloadAction<{ id: string; value: string }>) => {
      state.checkListNestedState[payload.id] = payload.value;
    },
    setCheckListValues: (state, { payload }: PayloadAction<{ value: { [id: string]: string } }>) => {
      state.checkListNestedState = payload.value;
    },
    setFirstChecklistElementId: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.firstChecklistElementId = payload.value;
    },
    // ToDo delete when backend is ready
    setChecklists: (state, { payload }: PayloadAction<{ value: DocumentChecklist }>) => {
      state.checklists = [...state.checklists, payload.value];
    },
  },
});

export const { setCheckListItemValue, setFirstChecklistElementId, setCheckListValues, setChecklists } =
  documentAnalysisSlice.actions;

export default documentAnalysisSlice.reducer;
