import { Col, Row } from 'antd';
import { NotificationType } from '@app/components/common/Notification/Notification';
import { CurrencyType } from '@app/interfaces/interfaces';
import { Priority } from '@app//constants/enums/priorities';
import { ReactComponent as ETHIcon } from '@app/assets/icons/eth.svg';
import { ReactComponent as BTCIcon } from '@app/assets/icons/btc.svg';

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const numberCurrencyInEuro = (value: number) =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(value);

export const getCurrencyPrice = (
  price: number | string,
  currency: CurrencyType,
  isIcon = true,
): string | React.ReactNode => {
  switch (currency) {
    case 'EUR': {
      return numberCurrencyInEuro(Number.isInteger(price) ? (price as number) : Number.parseInt(price as string));
    }

    case 'USD': {
      return isIcon ? `$${price}` : `${price} USD`;
    }

    case 'BTC': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <BTCIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} BTC`
      );
    }

    case 'ETH': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <ETHIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} ETH`
      );
    }

    default: {
      return isIcon ? `$${price}` : `${price} USD`;
    }
  }
};

type MarkArea = {
  xAxis: number;
};

export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export function throttle<T extends (...args: any[]) => any>(func: T, limit: number): T {
  let lastFunc: ReturnType<typeof setTimeout>;
  let lastRan: number;

  return ((...args) => {
    if (!lastRan) {
      func(...args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(
        () => {
          if (Date.now() - lastRan >= limit) {
            func(...args);
            lastRan = Date.now();
          }
        },
        limit - (Date.now() - lastRan),
      );
    }
  }) as T;
}

export const isValidUrl = (url: string): boolean => {
  try {
    const pattern = new RegExp(
      '^(https?:\\/\\/)' + // protocol (required)
        '((\\w+(-\\w+)*)(\\.\\w+(-\\w+)*)*\\.([a-z]{2,}|[0-9]+))' + // domain name and extension
        '(\\:\\d+)?' + // port (optional)
        '(\\/[-\\w@:%_+.~#?&//=]*)*' + // path (optional)
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string (optional)
        '(\\#[-a-z\\d_]*)?$', // fragment identifier (optional)
      'i', // case-insensitive flag
    );

    return !!pattern.test(url);
  } catch (err) {
    return false;
  }
};

export const getDomain = (url: string): string => {
  let domain;
  if (url.indexOf('://') > -1) {
    domain = url.split('/')[2];
  } else {
    domain = url.split('/')[0];
  }
  domain = domain.split(':')[0];
  return domain;
};

export const stringToLinesArray = (str: string) => {
  // Zuerst teilen wir den String an jedem '\n' auf
  const lines = str.split('\n');
  // Dann entfernen wir leere Zeilen und trimmen den Whitespace von den Zeilenenden
  return lines.map((line) => line.trim()).filter((line) => line.length > 1);
};

export const stringToShortSnippetsArray = (str: string) => {
  const lines = str.split('. ');

  // Hilfsfunktion, um zu überprüfen, ob ein Teil mindestens 30 Zeichen lang ist
  const isValidPart = (part: string) => part.trim().length >= 30;

  // Ergebnis-Array zum Speichern der finalen Zeilen
  const result: string[] = [];

  // Temporäre Variable zum Speichern kurzer Zeilen
  let tempLine = '';

  // Verarbeiten der einzelnen Zeilen
  lines.forEach((line) => {
    const parts = line.split(', ');

    if (parts.length > 1) {
      // Wenn es mehr als ein Teil gibt, überprüfen wir die Länge der Teile zwischen den Kommas
      parts.forEach((part) => {
        if (isValidPart(part)) {
          if (tempLine.length > 0) {
            result.push(tempLine.trim());
            tempLine = '';
          }
          result.push(part.trim());
        } else {
          tempLine += (tempLine.length > 0 ? ', ' : '') + part;
        }
      });
      if (tempLine.length > 0) {
        result.push(tempLine.trim());
        tempLine = '';
      }
    } else {
      // Wenn es nur einen Teil gibt, überprüfen wir die Länge der Zeile
      if (line.trim().length < 20) {
        // Wenn die Zeile kürzer als 20 Zeichen ist, fügen wir sie zur tempLine hinzu
        tempLine += (tempLine.length > 0 ? '. ' : '') + line.trim();
      } else {
        // Wenn die tempLine nicht leer ist, fügen wir sie zuerst hinzu
        if (tempLine.length > 0) {
          result.push(tempLine.trim());
          tempLine = '';
        }
        result.push(line.trim());
      }
    }
  });

  // Falls am Ende noch eine tempLine übrig ist, fügen wir sie hinzu
  if (tempLine.length > 0) {
    result.push(tempLine.trim());
  }

  return result.filter((line) => line.length > 1);
};

export const checkMaxMinParams = (value: number, minValue: number, maxValue: number) => {
  if (value < minValue) return minValue;
  if (value > maxValue) return maxValue;
  return value;
};
