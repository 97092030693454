import { readUserIdAndCompanyId } from '@app/services/reduxStorage.service';
import { httpApi } from './http.api';

export const createAiGeneratedImages = (data: { queryText: string; chatId: string }): Promise<string[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const userData = readUserIdAndCompanyId();
      const result = await httpApi.post(`/api/v1/gpt/dalle`, { ...data, ...userData });
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('dalle api call failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};
