import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import React, { useEffect, useState } from 'react';
import { GPT_MODEL_SELECTION } from '@app/utils/constants';
import { setGptSuperDoLLM } from '@app/store/slices/settingsSlice';
import { useTranslation } from 'react-i18next';
import { setTriggerActionId } from '@app/store/slices/chatBoxSlice';
import { BaseSelect } from '../common/selects/BaseSelect/BaseSelect';
import { Form, Image, Modal, Select, Space } from 'antd';
import { InlineActionContainer } from '../common/Common.styles';
import SystemPromptController from './LlmSetup/SystemPromptController';

const { Option } = Select;

const LlmSuperDoSettingsModal: React.FC = () => {
  const [settingsModalIsVisible, setSettingsModalIsVisible] = useState(false);

  const { gptSuperDoLLM } = useAppSelector((state) => state.settings);
  const { triggerActionId } = useAppSelector((state) => state.chatBox);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (triggerActionId === 'SHOW_LLM_SETTINGS_MODAL') {
      setSettingsModalIsVisible(true);
      dispatch(setTriggerActionId({ value: null }));
    }
  }, [dispatch, triggerActionId]);

  useEffect(() => {
    form.setFieldValue('gptSuperDoLLM', gptSuperDoLLM);
  }, [gptSuperDoLLM]);

  const handleOkSettings = () => {
    form
      .validateFields()
      .then(async (values) => {
        dispatch(setGptSuperDoLLM({ value: values.gptSuperDoLLM }));
        setSettingsModalIsVisible(false);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancelSettings = () => {
    setSettingsModalIsVisible(false);
  };

  return (
    <Modal
      title={t('llmSettings')}
      open={settingsModalIsVisible}
      onOk={handleOkSettings}
      onCancel={handleCancelSettings}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="gptSuperDoLLM" rules={[{ required: true, message: t('pleaseSelectLanguageModel') }]}>
          <BaseSelect defaultValue={gptSuperDoLLM} placeholder={t('selectLanguageModel')}>
            {GPT_MODEL_SELECTION.map((item, index) => (
              <Option key={item.value + index} value={item.value} disabled={item.disabled || false}>
                <Space align="baseline">
                  <Image
                    src={item.image}
                    alt={item.label}
                    preview={false}
                    height={16}
                    width={16}
                    style={{ marginTop: 3, objectFit: 'contain' }}
                  />
                  {item.label}
                </Space>
              </Option>
            ))}
          </BaseSelect>
        </Form.Item>

        <InlineActionContainer>
          <SystemPromptController parentDrawerVisible={settingsModalIsVisible} isSuperDoMode={true} />
        </InlineActionContainer>
      </Form>
    </Modal>
  );
};

export default LlmSuperDoSettingsModal;
