import styled from 'styled-components';
import { BORDER_RADIUS } from '@app/styles/themes/constants';

export const ContainerWrapper = styled.div`
  display: flex;
  //max-height: 100%;
  height: 95vh;
  padding: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  background: 'var(--secondary-background-color)';
  border-radius: ${BORDER_RADIUS};
  overflow-y: auto;
`;

export const TextInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--text-superLight-color);
`;

export const TextDescription = styled.div`
  display: flex;
  margin-left: 1.25rem;
  width: 100%;
`;
