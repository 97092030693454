import React, { useEffect, useState, useRef } from 'react';
import { IconCheck, IconEdit, IconX } from '@tabler/icons-react';
import { ActionButtons, InputStyled, TextItemWrapper, ButtonsWrapper, Text } from './EditableText.styles';

type EditableTextProps = {
  text: string | undefined;
  onTextSave: (text: string) => void;
  maxRowNumber?: number;
  disableEdit?: boolean;
};

const EditableText: React.FC<EditableTextProps> = ({ text, onTextSave, maxRowNumber, disableEdit }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedText, setEditedText] = useState<string>('');
  const [parentHeight, setParentHeight] = useState<number | null>(null);
  const [parentWidth, setParentWidth] = useState<number | null>(null);
  const [editorHeight, setEditorHeight] = useState<number | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const widthMeasureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (text) {
      setEditedText(text);
    }
  }, [text]);

  const onFinishEdit = () => {
    if (editedText) {
      onTextSave(editedText);
    }
    setIsEditMode(false);
  };

  const openEditMode = () => {
    if (disableEdit) return;
    if (wrapperRef.current && wrapperRef.current.parentElement) {
      setParentHeight(wrapperRef.current.parentElement.offsetHeight);
      setEditorHeight(wrapperRef.current.offsetHeight);
    }
    if (widthMeasureRef.current) {
      setParentWidth(widthMeasureRef.current.offsetWidth);
    }
    setIsEditMode(true);
  };

  return (
    <>
      <TextItemWrapper ref={wrapperRef} isEditMode={isEditMode} parentHeight={parentHeight}>
        {isEditMode ? (
          <InputStyled size={'small'} value={editedText} onChange={(e) => setEditedText(e.target.value)} />
        ) : (
          <Text size="s" style={{ minWidth: '120px' }} maxRowNumber={maxRowNumber}>
            {editedText || ' - '}
          </Text>
        )}
        <ButtonsWrapper>
          <ActionButtons isSelected={isEditMode} className="ActionButtons">
            {isEditMode ? (
              <>
                {editedText ? (
                  <IconCheck size={16} onClick={() => onFinishEdit()} />
                ) : (
                  <IconX size={16} onClick={() => setIsEditMode(false)} />
                )}
              </>
            ) : (
              <IconEdit size={16} onClick={openEditMode} />
            )}
          </ActionButtons>
        </ButtonsWrapper>
      </TextItemWrapper>
      <div ref={widthMeasureRef}></div>
      {isEditMode && (
        <div style={{ height: editorHeight ? editorHeight : 'auto', width: parentWidth ? parentWidth : 'auto' }}></div>
      )}
    </>
  );
};

export default EditableText;
