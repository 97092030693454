import React from 'react';
import { Col, Row } from 'antd';
import { DocumentContainer } from '@app/components/documentContainer/DocumentContainer';
import SidebarGptChatBox from '@app/components/gptchatbox/SidebarGptChatBox';
import NoPaddingPageContainer from '@app/components/layouts/main/NoPaddingPageContainer/NoPaddingPageContainer';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { DocumentTemplateContainer } from '@app/components/documentTemplateContainer/DocumentTemplateContainer';
import { DocumentTemplateEditContainer } from '@app/components/documentTemplateContainer/DocumentTemplateEditContainer';
import { DocumentArchivContainer } from '@app/components/documentTemplateContainer/DocumentArchivContainer';

function DraftDocument() {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Mit KI-Assistent`,
      children: (
        <Row>
          <Col span={10}>
            <SidebarGptChatBox />
          </Col>
          <Col span={14}>
            <DocumentContainer index={0} />
          </Col>
        </Row>
      ),
    },
    {
      key: '2',
      label: `Mit Vorlagen`,
      children: (
        <Row>
          <Col span={14}>
            <DocumentTemplateContainer index={0} />
          </Col>
          <Col span={10} style={{ backgroundColor: 'white' }}>
            <DocumentTemplateEditContainer index={0} />
          </Col>
        </Row>
      ),
    },
    {
      key: '3',
      label: `Erstellte Verträge`,
      children: <DocumentArchivContainer />,
    },
  ];

  return (
    <NoPaddingPageContainer>
      <Tabs
        tabBarStyle={{ backgroundColor: 'white', paddingLeft: 20, paddingRight: 20, paddingTop: 10, marginBottom: 0 }}
        defaultActiveKey="1"
        items={items}
      />
    </NoPaddingPageContainer>
  );
}

export default DraftDocument;
