import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AgentImage from 'assets/images/undraw_Visionary_technology_re_jfp7.png';
import { Card } from '@app/components/common/Card/Card';
import * as S from './GptAgentsOverview.styles';
import AutonomousAgent, { Message } from './AutonomousAgent';
import { DEFAULT_MAX_LOOPS_CUSTOM_API_KEY, GPT_4 } from '@app/utils/constants';
import { MemoizedReactMarkdown } from '../common/Markdown/MemoizedReactMarkdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeMathJaxSvg from 'rehype-mathjax';
import { Button, Col, Image, Row, Space, Typography } from 'antd';
import { Checkbox } from '../common/Checkbox/Checkbox';

export const GptAgentsOverview: React.FC = () => {
  const { t } = useTranslation();

  const [goalInput, setGoalInput] = React.useState<string>('');
  const [agent, setAgent] = React.useState<AutonomousAgent | null>(null);

  const [, setShouldAgentStop] = React.useState(false);

  const [agentSessionIsActive, setAgentSessionIsActive] = React.useState(false);

  const [messages, setMessages] = React.useState<Message[]>([]);

  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    nameInputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (agent == null) {
      setShouldAgentStop(false);
    }
  }, [agent]);

  const handleAddMessage = (message: Message) => {
    setMessages((prev) => [...prev, message]);
  };

  const tasks = messages.filter((message) => message.type === 'task');

  const disableDeployAgent = agent != null || goalInput === '';

  const handleNewGoal = () => {
    if (disableDeployAgent) return;
    const name = 'AI-Agent';

    const agent = new AutonomousAgent(
      name,
      goalInput,
      handleAddMessage,
      () => {
        setAgent(null);
      },
      {
        customApiKey: '',
        customModelName: GPT_4,
        customTemperature: 0.7,
        customMaxLoops: DEFAULT_MAX_LOOPS_CUSTOM_API_KEY,
      },
      undefined,
    );
    setAgent(agent);
    setMessages([]);
    setAgentSessionIsActive(true);
    agent.run().then(console.log).catch(console.error);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !disableDeployAgent) {
      if (!e.shiftKey) {
        // Only Enter is pressed, execute the function
        handleNewGoal();
      }
    }
  };

  const getMessagePrefix = (message: Message) => {
    switch (message.type) {
      case 'goal':
        return '🎯 Neues Ziel:';
      case 'task':
        return '💼 Neue Aufgabe erstellt:';
      case 'thinking':
        return '🧠 Die KI denkt...';
      case 'action':
        return message.info ? message.info : '🤖 Führe aus:';
    }
  };

  const handleStopAgent = () => {
    setShouldAgentStop(true);
    agent?.stopAgent();
  };

  const handleStartNewAgent = () => {
    setAgentSessionIsActive(false);
    setGoalInput('');
  };

  return agentSessionIsActive ? (
    <Card>
      <Row justify={'center'}>
        <Col span={24} style={{ textAlign: 'center', width: '100%' }}>
          <Typography.Title level={3}>
            {t(agent?.isRunning ? 'gptAgents.headerTitleActive' : 'gptAgents.headerTitleFinished')}
          </Typography.Title>
        </Col>
        <Col lg={10} xs={24} style={{ textAlign: 'center' }}>
          <S.GoalDescription>{`Ziel: ${goalInput}`}</S.GoalDescription>
        </Col>
      </Row>
      <Row justify={'center'}>
        <Col span={6} offset={18} style={{ position: 'absolute', top: 20 }}>
          <Image src={AgentImage} alt="Start agent" preview={false} height={140} style={{ objectFit: 'contain' }} />
        </Col>
      </Row>

      <Row justify={'center'}>
        <Col lg={16} xs={24}>
          <S.MessageContainer isActive={agent?.isRunning}>
            {messages.map((message, index) => (
              <div key={`${index}-${message.type}`}>
                <p>
                  <strong>{getMessagePrefix(message)}</strong>
                </p>
                <div style={{ marginLeft: 20, maxWidth: '80%' }}>
                  <MemoizedReactMarkdown
                    className="prose"
                    remarkPlugins={[remarkGfm, remarkMath]}
                    rehypePlugins={[rehypeMathJaxSvg]}
                  >
                    {`${message.value}`}
                  </MemoizedReactMarkdown>
                </div>
              </div>
            ))}
            {messages.length === 0 && (
              <Row justify={'center'} style={{ paddingTop: '6rem' }}>
                <S.EmptyText>Noch keine Ergebnisse vorhanden</S.EmptyText>
              </Row>
            )}
          </S.MessageContainer>
        </Col>
      </Row>

      <Row justify={'center'}>
        <Col>
          <Space size={'middle'} style={{ marginTop: '2rem' }}>
            {!agent?.isRunning && (
              <Button type="primary" onClick={() => handleStartNewAgent()} size="middle">
                Neuen Agenten starten
              </Button>
            )}
            {agent?.isRunning && (
              <Button type="dashed" onClick={() => handleStopAgent()} size="middle">
                Agenten Beenden
              </Button>
            )}
            {agent?.isRunning && (
              <S.NotificationContainer>
                <Checkbox value="notifyOnFinish">Benachrichtigen, sobald der Agent fertig ist</Checkbox>
              </S.NotificationContainer>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  ) : (
    <Card>
      <Row justify={'center'}>
        <Col span={24} style={{ textAlign: 'center', width: '100%' }}>
          <Typography.Title level={3}>{t('gptAgents.headerTitle')}</Typography.Title>
        </Col>
        <Col lg={12} xs={24} style={{ textAlign: 'center', width: '100%' }}>
          <S.Description>
            {agentSessionIsActive
              ? 'Ihr persönlicher KI-Agent ist gerade aktiv ▶️'
              : 'Nutzen Sie den KI-Agenten um komplexere Aufgaben zu lösen.\nDer Agent versucht komplett autonom in mehreren Iterationen die Aufgabe zu lösen. '}
          </S.Description>
        </Col>

        <Row justify={'center'}>
          <Image src={AgentImage} alt="Start agent" preview={false} height={250} style={{ objectFit: 'contain' }} />
        </Row>
      </Row>

      <Row justify={'center'}>
        <Col lg={16} xs={24} style={{ textAlign: 'center', width: '100%' }}>
          <S.StyledInitialInput
            value={goalInput}
            onChange={(e) => setGoalInput(e.target.value)}
            onKeyDown={(e) => handleKeyPress(e)}
            placeholder="Geben Sie hier Ihre Aufgabe ein ..."
          />
        </Col>
      </Row>

      <Row justify={'center'}>
        <Col style={{ marginTop: '2rem' }}>
          <Button type="primary" onClick={() => handleNewGoal()} size="middle">
            {"Los geht's"}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
