import { main_color } from '@app/styles/themes/light/lightTheme';
import React from 'react';

type LlmIconIconProps = {
  color?: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
};

export const LlmIcon: React.FC<LlmIconIconProps> = ({ color, width, height, style }) => (
  <svg
    width={width ? width : '18'}
    height={height ? height : '18'}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      // eslint-disable-next-line react/no-unknown-property
      fill-rule="evenodd"
      // eslint-disable-next-line react/no-unknown-property
      clip-rule="evenodd"
      d="M6.25 1.00077V0.999268C6.25 0.585268 6.586 0.249268 6.99925 0.249268C7.4125 0.249268 7.7485 0.585268 7.7485 0.999268V1.00152C7.7485 2.39352 8.30125 3.72852 9.286 4.71252C10.2625 5.68902 11.584 6.24102 12.964 6.25002C12.976 6.24927 12.9872 6.24927 12.9992 6.24927C13.4125 6.24927 13.7485 6.58527 13.7485 6.99927C13.7485 7.41402 13.4125 7.75002 12.9992 7.75002H12.997C11.605 7.75002 10.27 8.30277 9.286 9.28752C8.30125 10.2715 7.7485 11.6065 7.7485 12.9985C7.7485 13.4133 7.4125 13.75 6.99925 13.75C6.60475 13.75 6.28075 13.4433 6.25225 13.0555C6.25 13.0375 6.2485 13.0188 6.2485 12.9985C6.2485 11.6065 5.69575 10.2715 4.711 9.28752C3.727 8.30277 2.392 7.75002 1 7.75002C0.58675 7.75002 0.25 7.41402 0.25 6.99927C0.25 6.58527 0.585999 6.24927 0.999249 6.24927C1.01125 6.24927 1.0225 6.24927 1.0345 6.25002C2.41375 6.24102 3.73525 5.68902 4.711 4.71252C5.69575 3.72852 6.2485 2.39352 6.2485 1.00152L6.25 1.00077ZM6.9985 4.09227C7.315 4.70727 7.72675 5.27502 8.22475 5.77377C8.7235 6.27177 9.29125 6.68352 9.90625 7.00002C9.29125 7.31652 8.7235 7.72827 8.22475 8.22627C7.72675 8.72502 7.315 9.29277 6.9985 9.90777C6.682 9.29277 6.27025 8.72502 5.77225 8.22627C5.2735 7.72827 4.70575 7.31652 4.09075 7.00002C4.70575 6.68352 5.2735 6.27177 5.77225 5.77377C6.27025 5.27502 6.682 4.70727 6.9985 4.09227Z"
      fill={color ? color : main_color}
    />
  </svg>
);
