import { SupportedDocumentType } from '@app/types/chatBoxTypes';
import { IconBrowser, IconFileTypePdf } from '@tabler/icons-react';
import React from 'react';
import styled from 'styled-components';

type SourceDocumentProps = {
  fileName: string;
  pageContent: string;
  type: SupportedDocumentType;
  onClick: () => void;
};

const SourceDocument: React.FC<SourceDocumentProps> = ({ fileName, type, onClick }) => {
  return (
    <a onClick={() => onClick()}>
      <SourceDocContainer>
        {type === 'pdf' ? (
          <FileIcon style={{ color: 'rgba(98, 98, 98, 1)' }} />
        ) : (
          <BrowserIcon style={{ color: 'rgba(98, 98, 98, 1)' }} />
        )}
        <SourceDocText>
          <span>{fileName}</span>
        </SourceDocText>
      </SourceDocContainer>
    </a>
  );
};

export default SourceDocument;

export const SourceDocContainer = styled('div')({
  'background-color': 'rgba(245, 245, 245, 1)',
  width: '100%',
  height: 'auto',
  display: 'flex',
  'align-items': 'center',
  gap: '10px',
  padding: '6px 8px',
  'border-radius': '6px',
  'border-width': '1px',
  'border-color': 'rgba(219, 219, 219, 1)',
  'border-style': 'solid',
  overflow: 'hidden',
  cursor: 'pointer',
});

export const SourceDocContainerHistory = styled('div')({
  'background-color': 'rgba(245, 245, 245, 1)',
  width: '100%',
  height: 'auto',
  display: 'flex',
  'align-items': 'center',
  gap: '10px',
  padding: '6px 8px',
  'border-radius': '6px',
  overflow: 'hidden',
  cursor: 'pointer',
});

const FileIcon = styled(IconFileTypePdf)({
  width: '1rem',
  height: '1rem',
});

const BrowserIcon = styled(IconBrowser)({
  width: '1rem',
  height: '1rem',
});

export const SourceDocText = styled('span')({
  color: 'rgba(98, 98, 98, 1)',
  height: 'auto',
  'text-align': 'center',
  'line-height': 'normal',
  'font-size': '0.875rem',
  'font-stretch': 'normal',
  'font-style': 'Medium',
  'font-weight': 400,
  'text-decoration': 'none',
});
