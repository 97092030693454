import React, { useState } from 'react';
import * as S from './AnalyzeTechOfferDocumentContainer.styles';
import { Col, Input, List, Row, Table, Tabs } from 'antd';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { FilePdfOutlined } from '@ant-design/icons';
import type { TabsProps, UploadFile } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import moment from 'moment';
import { TechOfferAnalysisResult } from '@app/types/techOfferTypes';
import { addTechOfferAnalysisResults } from '@app/store/slices/techOfferSlice';
import SidebarTechOfferAnalyzeResults from './SidebarTechOfferAnalyzeResults';
import Fuse from 'fuse.js';
import { executeTechOfferComponentsAnalysis } from '@app/api/gpt.api';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { FileUploader } from '../fileUploader/FileUploader';

interface AnalyzeDocumentContainerProps {
  index: number | null;
}

export const AnalyzeTechOfferDocumentContainer: React.FC<AnalyzeDocumentContainerProps> = ({ index }) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [filteredResults, setFilteredResults] = useState<number[]>([]);

  const techOfferAnalysisResults = useAppSelector((state) => state.techOffer).techOfferAnalysisResults;

  const [selectedAnalysisResult, setSelectedAnalysisResult] = useState<TechOfferAnalysisResult | null>(null);

  const onNewFileUploaded = async (file: UploadFile, fileUrl: string) => {
    if (!loading) {
      setLoading(true);
      setSelectedAnalysisResult(null);

      const formData = new FormData();
      formData.append('files', file.originFileObj as Blob);

      try {
        const result = await executeTechOfferComponentsAnalysis(formData);
        try {
          const updatedData = result.replace('```json', '').replace('```', '');
          const parsedResult = JSON.parse(updatedData) as TechOfferAnalysisResult;
          if (parsedResult) {
            parsedResult.dateUploaded = moment().format('DD/MM/YY HH:mm');
            parsedResult.fileUrl = fileUrl;
            setSelectedAnalysisResult(parsedResult);
            dispatch(addTechOfferAnalysisResults({ value: parsedResult }));
          }
        } catch (error) {
          notificationController.error({ message: 'Leider ist beim Laden ein unerwarteter Fehler aufgetreten' });
          console.log('****** caveatDocumentAnalysis 1 error: ', error);
        }
      } catch (error) {
        console.log('****** caveatDocumentAnalysis error: ', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const onSearch = (value: string) => {
    console.log(value);

    try {
      console.log('calll new Fuse ...');

      const fuse = new Fuse(techOfferAnalysisResults, {
        keys: ['company', 'componentsData'],
        shouldSort: true,
      });

      console.log('calll fuse.search ...');

      const searchResult = fuse.search(value);
      const refIndexes: number[] = [];
      searchResult.forEach((item) => refIndexes.push(item.refIndex));

      console.log('****** refIndexes: ', refIndexes);

      setFilteredResults(refIndexes);
    } catch (error) {
      console.log('****** error: ', error);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Upload & Analyse`,
      children: (
        <Row>
          <Col span={12}>
            <S.ContainerWrapper>
              <Col>
                <NormalText size="xl">{'Technische Angebote für KI-Analyse hochladen'}</NormalText>

                <FileUploader onProcessUploadedFile={onNewFileUploaded} />
              </Col>
            </S.ContainerWrapper>
          </Col>
          <Col span={12}>
            <SidebarTechOfferAnalyzeResults analysisResult={selectedAnalysisResult} loading={loading} />
          </Col>
        </Row>
      ),
    },
    {
      key: '2',
      label: `Angebots Datenbank`,
      children: (
        <Col span={24}>
          <S.ContainerWrapper>
            <Col>
              <NormalText size="xxl" verticalPadding>
                {'Bereits hochgeladene Angebote'}
              </NormalText>

              <Input.Search
                placeholder="Suchen nach Produkten & Komponenten"
                onSearch={onSearch}
                style={{ width: 600 }}
              />

              <Table
                style={{ marginTop: 20, minWidth: '60vw' }}
                dataSource={
                  filteredResults.length
                    ? techOfferAnalysisResults.filter((item, index) => filteredResults.includes(index))
                    : techOfferAnalysisResults
                }
                rowKey={(item) => `${item.fileUrl}`}
                columns={[
                  {
                    title: 'Angebot-PDF',
                    dataIndex: 'fileUrl',
                    key: 'fileUrl',
                    render: (_, record) => (
                      <a href={record.fileUrl} download={`Doc_${record.company}`}>
                        <FilePdfOutlined style={{ fontSize: '2rem' }} />
                      </a>
                    ),
                  },
                  {
                    title: 'Kunde',
                    dataIndex: 'company',
                    key: 'company',
                  },
                  {
                    title: 'Kunden-Adresse',
                    dataIndex: 'companyAddress',
                    key: 'companyAddress',
                  },
                  {
                    title: 'Produkt-Daten',
                    key: 'componentsData',
                    render: (_, record) => (
                      <List
                        size="small"
                        bordered
                        dataSource={record.componentsData}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </S.ContainerWrapper>
        </Col>
      ),
    },
  ];

  return (
    <Tabs
      tabBarStyle={{
        backgroundColor: 'white',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        marginBottom: 0,
      }}
      defaultActiveKey="1"
      items={items}
    />
  );
};
