import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { message, Input, Dropdown, List, Button, Modal, Tooltip, Menu, Space, Row, Switch } from 'antd';
import { createSystemPrompt, readCompanySystemPrompts, deleteSystemPrompt } from '@app/api/systemPrompts.api';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { SystemPrompt } from '@app/types/systemPromptsTypes';
import { setCustomSuperDoSystemPrompt, setCustomSystemPrompt } from '@app/store/slices/settingsSlice';
import { IconPlus, IconHistory, IconTrash, IconCheck, IconUser, IconInfoCircle } from '@tabler/icons-react';
import { PromptsContainer } from './LlmSetupDrawer.styles';
import moment from 'moment';
import { shortenString } from '@app/utils/stringHelpers';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { useCurrentTheme } from '@app/hooks/useCurrentTheme';
import { LLM_SYSTEM_PROMPT } from '@app/utils/constants';

interface SystemPromptControllerProps {
  isSuperDoMode?: boolean;
  parentDrawerVisible?: boolean;
}

const SystemPromptController: React.FC<SystemPromptControllerProps> = ({ isSuperDoMode, parentDrawerVisible }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleApiError } = useErrorHandling();

  const companyState = useAppSelector((state) => state.company);
  const user = useAppSelector((state) => state.user.user);

  const { customSystemPrompt, customSuperDoSystemPrompt } = useAppSelector((state) => state.settings);

  const { currentTheme } = useCurrentTheme();

  const [systemPrompts, setSystemPrompts] = useState<SystemPrompt[]>([]);
  const [showPrompts, setShowPrompts] = useState(false);

  useEffect(() => {
    const fetchSystemPrompts = async () => {
      if (companyState._id) {
        try {
          const prompts = await readCompanySystemPrompts(companyState._id);
          setSystemPrompts(prompts);
        } catch (error) {
          handleApiError(error, true);
        }
      }
    };

    fetchSystemPrompts();
  }, [companyState]);

  useEffect(() => {
    if (parentDrawerVisible === false) {
      setShowPrompts(false);
    }
  }, [parentDrawerVisible]);

  const currentSystemPrompt = useMemo(() => {
    if (isSuperDoMode) return customSuperDoSystemPrompt;
    else return customSystemPrompt;
  }, [isSuperDoMode, customSystemPrompt, customSuperDoSystemPrompt]);

  const handleMenuClick = async (e: any) => {
    const visibility = e.key === 'only_me' ? 'ONLY_CREATOR' : 'WHOLE_COMPANY';
    const trimmedPrompt = currentSystemPrompt.trim();
    if (trimmedPrompt.length < 5) {
      message.warn(t('llmSetup.systemPromptToShort'));
      return;
    }
    try {
      const prompt: SystemPrompt = {
        systemPrompt: trimmedPrompt,
        companyId: companyState._id ?? '',
        createdBy: user?._id ?? '',
        timestamp: moment().unix(),
        visibility,
      };
      prompt._id = await createSystemPrompt(prompt);
      setSystemPrompts((prev) => [...prev, prompt]);
      message.success(t('llmSetup.systemPromptSaved'));
    } catch (error) {
      handleApiError(error, true);
    }
  };

  const handleDeletePrompt = async (promptId: string) => {
    try {
      await deleteSystemPrompt(promptId);
      setSystemPrompts(systemPrompts.filter((prompt) => prompt._id !== promptId));
      message.success(t('llmSetup.systemPromptDeleted'));
    } catch (error) {
      handleApiError(error);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="only_me">{t('llmSetup.addSystemPromptOnlyMe')}</Menu.Item>
      <Menu.Item key="whole_company">{t('llmSetup.addSystemPromptAll')}</Menu.Item>
    </Menu>
  );

  const onPromptChanged = (text: string) => {
    if (isSuperDoMode) {
      dispatch(setCustomSuperDoSystemPrompt({ value: text }));
    } else {
      dispatch(setCustomSystemPrompt({ value: text }));
    }
  };

  return (
    <>
      <Row justify={'space-between'}>
        <Space>
          <NormalText verticalPadding color={currentTheme.primary} size="m">
            {t('llmSetup.systemPrompt')}
          </NormalText>
          <Tooltip title={t('llmSetup.systemPromptHint')}>
            <IconInfoCircle size={'1rem'} color="gray" />
          </Tooltip>
        </Space>
        <Space>
          <NormalText
            verticalPadding
            color={
              currentSystemPrompt === undefined || currentSystemPrompt === 'default' ? currentTheme.primary : undefined
            }
            size="m"
          >
            {t('llmSetup.automatically')}
          </NormalText>
          <Switch
            checked={currentSystemPrompt === undefined || currentSystemPrompt === 'default'}
            onChange={(checked) => {
              const sysPrompt: LLM_SYSTEM_PROMPT = checked ? 'default' : '';
              onPromptChanged(sysPrompt);
            }}
            size={'small'}
          />
        </Space>
      </Row>
      {currentSystemPrompt !== undefined && currentSystemPrompt !== 'default' && (
        <>
          <Input.TextArea value={currentSystemPrompt} onChange={(e) => onPromptChanged(e.target.value)} rows={4} />
          <Space style={{ margin: '0 0 0 0.5rem' }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <IconPlus size={'1rem'} style={{ cursor: 'pointer' }} />
            </Dropdown>
            <IconHistory
              size={'1rem'}
              style={{ cursor: 'pointer', color: showPrompts ? currentTheme.primary : undefined }}
              onClick={() => setShowPrompts(!showPrompts)}
            />
          </Space>
          {showPrompts && (
            <PromptsContainer>
              <List
                dataSource={systemPrompts}
                style={{ maxHeight: 220, overflowY: 'auto' }}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        key={'use_' + item._id}
                        type="link"
                        size="small"
                        onClick={() => onPromptChanged(item.systemPrompt)}
                      >
                        <IconCheck size={16} />
                      </Button>,
                      <Button
                        key={'trash_' + item._id}
                        type="link"
                        size="small"
                        danger
                        onClick={() => {
                          Modal.confirm({
                            title: t('llmSetup.confirmDelete'),
                            onOk: () => handleDeletePrompt(item._id ?? ''),
                          });
                        }}
                      >
                        <IconTrash size={16} />
                      </Button>,
                    ]}
                  >
                    <NormalText size="s">
                      {shortenString(item.systemPrompt, 200)}
                      {item.visibility === 'ONLY_CREATOR' && (
                        <Tooltip title={t('llmSetup.visibleOnlyForYou')}>
                          <IconUser size={12} style={{ marginLeft: 4, color: 'var(--text-light-color)' }} />
                        </Tooltip>
                      )}
                    </NormalText>
                  </List.Item>
                )}
              />
            </PromptsContainer>
          )}
        </>
      )}
    </>
  );
};

export default SystemPromptController;
