import { FONT_SIZE } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const Container = styled('div')({
  display: 'flex',
  position: 'relative',
  'align-items': 'flex-start',
  'flex-direction': 'column',
  'justify-content': 'flex-start',
  cursor: 'pointer',
});

export const Div = styled('div')({
  'background-color': 'rgba(255, 255, 255, 1)',
  width: '279px',
  height: 'auto',
  display: 'flex',
  'justify-content': 'center',
  'align-items': 'center',
  padding: '24px',
  'border-radius': '16px',
  'border-width': '1px',
  'border-color': 'rgba(226, 232, 240, 1)',
  'border-style': 'solid',
  position: 'relative',
  overflow: 'hidden',
  '-webkit-box-shadow': '0px 5px 9px 0px rgba(0,0,0,0.075)',
  'box-shadow': '0px 5px 9px 0px rgba(0,0,0,0.075)',
  ':hover': {
    '-webkit-box-shadow': '0px 5px 9px 0px rgba(0,0,0,0.15)',
    'box-shadow': '0px 5px 9px 0px rgba(0,0,0,0.15)',
  },
});

export const DivContent = styled('div')({
  width: '231px',
  height: '210px',
  display: 'flex',
  'align-items': 'flex-start',
  'flex-shrink': '0',
  'border-color': 'transparent',
  position: 'relative',
  'flex-direction': 'column',
});

export const Text = styled('span')({
  color: 'rgba(51, 65, 85, 1)',
  height: 'auto',
  'text-align': 'left',
  'line-height': '24px',
  'margin-right': '0',
  'margin-bottom': '0',
  'align-self': 'auto',
  'font-size': FONT_SIZE.lg,
  'font-stretch': 'normal',
  'font-style': 'SemiBold',
  'font-weight': 600,
  'text-decoration': 'none',
});

export const TextDescription = styled('span')({
  color: 'rgba(100, 116, 139, 1)',
  width: '200px',
  height: 'auto',
  'text-align': 'left',
  'line-height': '24px',
  'margin-right': '0',
  'margin-bottom': '0',
  'align-self': 'auto',
  'margin-top': 12,
  'margin-buttom': 12,
  'font-size': FONT_SIZE.md,
  'font-stretch': 'normal',
  'font-style': 'Regular',
  'font-weight': 400,
  'text-decoration': 'none',
});

export const ImageContainer = styled('div')({
  display: 'flex',
  'justify-content': 'center',
  'align-items': 'center',
  padding: '6px',
  'border-radius': '6px',
  'border-color': 'transparent',
});

export const HeaderImage = styled('img')({
  width: '30px',
  height: '30px',
  position: 'relative',
});

export const MenuWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  padding: 0 1rem;
  background-color: var(--secondary-background-color);
  cursor: auto;
`;
