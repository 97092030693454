import axios from 'axios';

export const transcribeSpeech = async (data: FormData): Promise<string> => {
  // TODO: move to backend
  //return sendPostRequest<FormData, string>('/api/v1/speech/transcribeSpeech', data);
  const useAzure = false;

  if (useAzure) {
    const AZURE_OPENAI_ENDPOINT = process.env.REACT_APP_AZURE_OPENAI_ENDPOINT ?? '';
    const AZURE_OPENAI_KEY = process.env.REACT_APP_AZURE_OPENAI_KEY ?? '';
    const deploymentName = 'whisper';

    const response = await axios.post(
      `${AZURE_OPENAI_ENDPOINT}/openai/deployments/${deploymentName}/audio/transcriptions?api-version=2023-09-01-preview`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'api-key': AZURE_OPENAI_KEY,
          accept: '*/*',
        },
      },
    );
    if (response.data?.text) return response.data?.text;
    else throw new Error(`Speech api error: ${response.status}`);
  } else {
    data.append('model', 'whisper-1');

    const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', data, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AZURE_OPENAI_KEY}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data?.text) return response.data?.text;
    else throw new Error(`Speech api error: ${response.status}`);
  }
};
