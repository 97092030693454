import React, { useEffect, useState } from 'react';
import { UserModel } from '@app/domain/UserModel';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload, UploadFile } from 'antd';
import type { UploadProps } from 'antd/es/upload/interface';
import * as S from './ProfileInfo.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { uploadProfilePhoto } from '@app/store/slices/userSlice';
import { notificationController } from '@app/controllers/notificationController';
import { RcFile } from 'antd/lib/upload';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

const MaxPhotoFileSize = 3 * 1024 * 1024;

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [file, setFile] = useState<UploadFile | null>(null);
  const [fileChanged, setFileChanged] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const companyState = useAppSelector((state) => state.company);

  useEffect(() => {
    if (user) {
      setImageUrl(user.profileImage?.bigUrl);
    }
  }, [user]);

  useEffect(() => {
    if (file && fileChanged && !loading) {
      const updateImage = async () => {
        setLoading(true);
        try {
          if (!companyState._id || !user?._id) {
            notificationController.error({ message: 'Fehler beim Hochladen: ungültige Konfiguration' });
            return;
          }

          const formData = new FormData();
          formData.append('file', file.originFileObj as Blob);
          formData.append('fileName', file.name);
          formData.append('companyId', companyState._id);
          formData.append('userId', user._id);

          await dispatch(uploadProfilePhoto(formData));
          notificationController.success({ message: 'Ihr Profilbild wurde aktualisiert' });
        } catch (error) {
          console.log('****** updateImage error: ', error);
          notificationController.error({ message: 'Leider ist beim Hochladen ein unerwarteter Fehler aufgetreten' });
        } finally {
          setLoading(false);
          setFileChanged(false);
        }
      };
      updateImage();
    }
  }, [file, fileChanged, companyState, user, dispatch]);

  // Custom file upload method
  const customRequest = (options: any) => {
    setFileChanged(true);
    options.onSuccess('done');
  };

  const beforeUpload = (file: RcFile) => {
    const isLessThanMaxSize = file.size < MaxPhotoFileSize;
    if (!isLessThanMaxSize) {
      message.error(`Die Datei ist zu groß. Die maximale Größe beträgt ${MaxPhotoFileSize / 1024 / 1024}MB.`);
    }
    return isLessThanMaxSize; // Wenn false zurückgegeben wird, wird die Datei nicht hochgeladen.
  };

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    listType: 'picture-card',
    customRequest,
    beforeUpload,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setFile(info.file);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        <NormalText size="s" centered semiBold>
          Upload Photo
        </NormalText>
      </div>
    </div>
  );

  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <Upload {...props}>
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'contain' }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </S.ImgWrapper>
    </S.Wrapper>
  ) : null;
};
