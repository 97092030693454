import React, { useEffect, useState } from 'react';
import { IconCheck, IconEdit, IconPaperclip, IconTrash, IconX } from '@tabler/icons-react';
import { ActionButtons, ConversationItemWrapper, EditInput, TextContent } from './ConversationItem.styles';
import { Space } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';

type ConversationItemProps = {
  text: string;
  uploadedFiles: number;
  isSelected: boolean;
  onClick: () => void;
  onDeleteClick: () => void;
  onTitleEditFinished: (title: string) => void;
};

const ConversationItem = ({
  text,
  uploadedFiles,
  isSelected,
  onClick,
  onDeleteClick,
  onTitleEditFinished,
}: ConversationItemProps) => {
  const [editMode, setEditMode] = useState(false);
  const [editedTitle, setEditedTitle] = useState(text);

  useEffect(() => {
    setEditedTitle(text);
  }, [text]);

  useEffect(() => {
    if (editMode && !isSelected) {
      setEditMode(false);
    }
  }, [isSelected]);

  const onFinishEdit = () => {
    onTitleEditFinished(editedTitle);
    setEditMode(false);
  };

  return (
    <ConversationItemWrapper isSelected={isSelected} onClick={onClick}>
      {editMode ? (
        <EditInput
          style={{ backgroundColor: 'transparent', border: 0, borderBottom: '1px solid', borderRadius: 0 }}
          maxLength={30}
          value={editedTitle}
          onChange={(e) => setEditedTitle(e.target.value)}
        />
      ) : (
        <TextContent isSelected={isSelected}>{text}</TextContent>
      )}
      {editMode ? (
        <ActionButtons isSelected={isSelected}>
          <IconCheck size={16} onClick={() => onFinishEdit()} />
          <IconX size={16} onClick={() => setEditMode(false)} />
        </ActionButtons>
      ) : (
        <ActionButtons isSelected={isSelected}>
          <IconTrash size={16} onClick={onDeleteClick} />
          <IconEdit size={16} onClick={() => setEditMode(true)} />
        </ActionButtons>
      )}
      {uploadedFiles > 0 && (
        <Space size={'small'}>
          <IconPaperclip size={12} style={{ color: 'var(--text-light-color)' }} />
          <NormalText size="s" colorType="light">
            {uploadedFiles} {uploadedFiles > 1 ? 'upladed files' : 'upladed file'}
          </NormalText>
        </Space>
      )}
    </ConversationItemWrapper>
  );
};

export default ConversationItem;
