import { SuperDoArea, SuperDoAreaAllKey } from '@app/types/superDoAreaTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { readAllSuperDoAreas } from '@app/api/superDoArea.api';

export interface SuperDoAreaState {
  superDoAreas: SuperDoArea[];
}

const initialState: SuperDoAreaState = {
  superDoAreas: [],
};

export const superDoAreaSlice = createSlice({
  name: 'superDoAreaSlice',
  initialState,
  reducers: {
    setSuperDoAreas: (state, { payload }: PayloadAction<{ value: SuperDoArea[] }>) => {
      state.superDoAreas = payload.value;
    },
  },
});

export const loadSuperDoAreas = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (getState().company._id) {
      const superDoAreasAreas = await readAllSuperDoAreas(getState().company._id ?? '');
      if (superDoAreasAreas?.length) {
        dispatch(
          setSuperDoAreas({
            value: [{ title: 'All', areaId: SuperDoAreaAllKey, templates: [] }, ...superDoAreasAreas],
          }),
        );
      }
    }
  } catch (error) {
    console.log('****** loadSuperDoAreas error: ', error);
  }
};

export const { setSuperDoAreas } = superDoAreaSlice.actions;

export default superDoAreaSlice.reducer;
