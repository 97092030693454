import { useAppSelector } from '@app/hooks/reduxHooks';
import React from 'react';
import { Button, Col, Drawer, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { IconExternalLink } from '@tabler/icons-react';
import { themeObject } from '@app/styles/themes/themeVariables';
import '@react-pdf-viewer/core/lib/styles/index.css';

export interface PropTypes {
  open: boolean;
  content: string;
  url: string;
  onClose: () => void;
}

const WebsiteScannedContentViewer: React.FC<PropTypes> = ({ open, content, url, onClose }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { mobileOnly } = useResponsive();
  const { t } = useTranslation();

  return (
    <Drawer
      width={mobileOnly ? '90%' : '50%'}
      title={
        <Row justify={'space-between'} align={'middle'} gutter={20}>
          <Col></Col>
          <Col>
            <Button
              onClick={() => {
                window.open(url, '_blank');
              }}
              type="text"
            >
              <IconExternalLink style={{ width: '1.25rem', height: '1.25rem', color: themeObject[theme].primary }} />
            </Button>
          </Col>
        </Row>
      }
      placement="right"
      onClose={() => onClose()}
      open={open}
    >
      <Space size={'large'} direction="vertical">
        <div>
          <NormalText semiBold>{t('rawWebsiteContentDescription', { url })}</NormalText>
          <a>
            <NormalText colorType="primary" semiBold>
              {url}
            </NormalText>
          </a>
        </div>
        <NormalText>{content}</NormalText>
      </Space>
    </Drawer>
  );
};

export default WebsiteScannedContentViewer;
