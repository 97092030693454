import styled from 'styled-components';

export const StyledCodeBlock = styled.div`
  position: relative;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  max-width: 900px;
  width: calc(100% - 100px);
  margin-bottom: 0.5rem;
`;

export const CodeBlockTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`;

export const CodeBlockTitle = styled.span`
  font-size: 0.75rem;
  text-transform: lowercase;
  color: var(--text-main-color);
`;

export const CodeBlockButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const CodeBlockButton = styled.div`
  display: flex;
  gap: 1.5em;
  align-items: center;
  margin-left: 2px;
  margin-right: 6px;
  font-size: 0.75rem;
  color: var(--text-main-color);
  cursor: pointer;
`;
