import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input, Button, Alert, Progress } from 'antd';
import { buildSuperDoTemplate } from '@app/api/gpt.api';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { notificationController } from '@app/controllers/notificationController';
import { AiIcon, ProgressContainer } from './ModalCreateAiConstructor.styles';
import { SuperDoFormValuesProps } from '../GPTTemplateCreate';
import { v4 as uuidv4 } from 'uuid';
import { SuperDoTemplateInputInfo } from '../templateDataInputMapping';

type ModalCreateAiConstructorProps = {
  isModalVisible: boolean;
  closeModal: () => void;
  setGeneratedFields: (values: SuperDoFormValuesProps) => void;
};

function parseFormFieldValues(valuesData: any): SuperDoFormValuesProps {
  const initialValues: SuperDoFormValuesProps = {
    title: '',
    description: '',
    promptTemplate: '',
    areaId: '',
    elements: [],
    aiToolsToUse: [],
  };

  const formValues: SuperDoFormValuesProps = initialValues;
  const initialValuesKeys = Object.keys(initialValues) as Array<keyof SuperDoFormValuesProps>;

  initialValuesKeys.forEach((key) => {
    if (valuesData?.[key] && key !== 'elements') {
      formValues[key] = valuesData[key];
    } else if (valuesData?.[key] && key === 'elements') {
      const elements = valuesData[key] as SuperDoTemplateInputInfo[];
      const validElements = elements.filter((element) => {
        return (
          element.inputId &&
          element.inputTitle &&
          (element.type === 'SELECTION' ||
            element.type === 'TEXTINPUT' ||
            element.type === 'TEXTAREA' ||
            element.type === 'FILE_UPLOAD')
        );
      });

      const elementsToSave = validElements.map((element) => ({ ...element, _id: uuidv4() }));

      formValues.elements = elementsToSave;
    }
  });

  return formValues;
}

export const ModalCreateAiConstructor: React.FC<ModalCreateAiConstructorProps> = ({
  isModalVisible,
  closeModal,
  setGeneratedFields,
}) => {
  const { t } = useTranslation();
  const [promptText, setPromptText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [aiProgress, setAiProgress] = useState(0);

  const getSuperDoTemplate = async () => {
    if (promptText) {
      try {
        setIsLoadingTemplate(true);
        setAiProgress(0);
        setInterval(() => {
          setAiProgress((progress) => Math.min(progress + 5, 95));
        }, 1000);
        const data = await buildSuperDoTemplate({ userTask: promptText, aiModelName: 'gpt-4o' });
        setAiProgress(100);
        const parsedFormFields = parseFormFieldValues(data);
        setGeneratedFields(parsedFormFields);
        closeModal();
        notificationController.success({ message: t('createSuperDo.layoutSuccessfullyCreated') });
      } catch (error) {
        console.log('Error during AI template generation', error);
        setErrorMessage(t('createSuperDo.somethingWentWrongError'));
      } finally {
        setIsLoadingTemplate(false);
      }
    } else {
      setErrorMessage(t('createSuperDo.emptyPromptError'));
    }
  };

  return (
    <Modal
      title={
        <div>
          <AiIcon />
          {t('createSuperDo.letBuildSuperDo')}
        </div>
      }
      open={isModalVisible}
      okText={t('create')}
      onCancel={() => closeModal()}
      footer={
        isLoadingTemplate
          ? null
          : [
              <Button key="back" onClick={() => closeModal()}>
                {t('common.cancel')}
              </Button>,
              <Button key="submit" type="primary" onClick={() => getSuperDoTemplate()}>
                {t('create')}
              </Button>,
            ]
      }
    >
      {isLoadingTemplate ? (
        <>
          <ProgressContainer>
            <Progress percent={aiProgress} style={{ width: '80%', marginTop: '1rem', color: 'gray' }} />
            <NormalText centered style={{ marginTop: '2rem' }} light>
              {t('createSuperDo.aiAssistantIsWorking')}
            </NormalText>
          </ProgressContainer>
        </>
      ) : (
        <>
          <NormalText centered>{t('createSuperDo.describeYourTaskAndGoal')}</NormalText>
          <Input.TextArea
            style={{
              height: 200,
              marginTop: '1rem',
              border: '1px solid var(--primary-color)',
              borderRadius: 8,
            }}
            value={promptText}
            onChange={(event) => {
              setPromptText(event.target.value);
              setErrorMessage('');
            }}
          />
          {errorMessage && <Alert message={errorMessage} type="error" style={{ margin: '1rem 0 0' }} />}
        </>
      )}
    </Modal>
  );
};
