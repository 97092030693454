import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import * as S from './TeamOverview.styles';
import { Button, Divider, Form, Modal, Tabs, Col } from 'antd';
import moment from 'moment';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { createTeamMemberInvitation } from '@app/api/teamMemberInvitation.api';
import { notificationController } from '@app/controllers/notificationController';

import { UserRole } from '@app/domain/UserModel';
import {
  IUserDetailedAccessRight,
  IUserDetailedAccessRights,
  AccessRight,
  AppEntityIds,
} from '@app/domain/AccessRightsModel';
import { TeamMemberInvitationModel } from '@app/domain/TeamMemberInvitationModel';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { readAccessRightsByUser, updateAccessRightForUser } from '@app/api/accessRights.api';
import { KnowledgeAreaRowHeader } from './KnowledgeAreaRowHeader';
import { KnowledgeAreaSettingsRowItem } from './KnowledgeAreaSettingsRowItem';
import { InfoCircleOutlined } from '@ant-design/icons';
import { InfoTextRow, UserEditInfo } from './ModalInviteTeamMember.styles';
import { TeamMemberItemProps } from './TeamOverview';
import { readAllKnowledgeAreaForUser } from '@app/api/knowledgeArea.api';
import { updateUser } from '@app/api/user.api';

type ModalInviteTeamMemberProps = {
  isModalVisible: boolean;
  isEditMode: boolean;
  isAdmin: boolean;
  handleCancel: () => void;
  setInvitationData: (data: TeamMemberInvitationModel[]) => void;
  invitationData: TeamMemberInvitationModel[];
  selectedUser?: TeamMemberItemProps | null;
  onUserUpdate: (
    userId: string,
    userRole: UserRole,
    knowledgeAreasAccessRights: IUserDetailedAccessRight[],
    appAreasAccessRights: IUserDetailedAccessRight[],
  ) => void;
};

interface UserKnowledgeAreaAccess extends IUserDetailedAccessRight {
  title: string;
  description?: string;
  userAccessRight: AccessRight | '';
}

const { TabPane } = Tabs;

export const ModalInviteTeamMember: React.FC<ModalInviteTeamMemberProps> = ({
  isModalVisible,
  handleCancel,
  setInvitationData,
  invitationData,
  selectedUser,
  isEditMode,
  isAdmin,
  onUserUpdate,
}) => {
  const { t } = useTranslation();
  const appAreas: UserKnowledgeAreaAccess[] = [
    {
      title: t('dashboard.shortTitle'),
      description: '',
      entityId: AppEntityIds.SecureChatGpt,
      userAccessRight: 'WRITE_AND_READ',
      entityType: 'APP',
      accessRightId: '',
      accessRight: 'READ_ONLY',
    },
    {
      title: t('gptTemplates.shortTitle'),
      description: '',
      entityId: AppEntityIds.SuperDos,
      userAccessRight: 'WRITE_AND_READ',
      entityType: 'APP',
      accessRightId: '',
      accessRight: 'READ_ONLY',
    },
    {
      title: t('contractAnalyze.shortTitle'),
      description: '',
      entityId: AppEntityIds.BasicDocumentAnalysis,
      userAccessRight: 'WRITE_AND_READ',
      entityType: 'APP',
      accessRightId: '',
      accessRight: 'READ_ONLY',
    },
    {
      title: t('sourceUpdateMonitoring.shortTitle'),
      description: '',
      entityId: AppEntityIds.WebsiteMonitoring,
      userAccessRight: 'WRITE_AND_READ',
      entityType: 'APP',
      accessRightId: '',
      accessRight: 'READ_ONLY',
    },
  ];

  const [userKnowledgeAreas, setUserKnowledgeAreas] = useState<UserKnowledgeAreaAccess[]>([]);
  const [userAppAreas, setUserAppAreas] = useState<UserKnowledgeAreaAccess[]>(appAreas);

  const [loadedKnowledgeAreas, setLoadedKnowledgeAreas] = useState<KnowledgeAreaModel[]>([]);

  const [initialLoaded, setInitialLoaded] = useState(false);

  const [allowEditRoles, setAllowEditRoles] = useState(true);

  const departments = useAppSelector((state) => state.department.departments);
  const companyState = useAppSelector((state) => state.company);
  const { user } = useAppSelector((state) => state.user);

  const selectRef = useRef(null);

  const [form] = Form.useForm();

  const onAreaKnowledgeSelect = (
    areaId: string,
    accessRight: AccessRight,
    status: boolean,
    userAreaBase: UserKnowledgeAreaAccess[],
    setFunction: (updatedAreaBase: UserKnowledgeAreaAccess[]) => void,
  ) => {
    const foundKnowledgeArea = userAreaBase.find((area) => area.entityId === areaId);
    if (foundKnowledgeArea) {
      let userAccessRight: AccessRight | '' = '';
      if (status) {
        userAccessRight = accessRight;
      } else {
        if (accessRight === 'READ_ONLY') {
          userAccessRight = '';
        } else if (accessRight === 'WRITE_AND_READ' && foundKnowledgeArea?.userAccessRight === 'WRITE_AND_READ') {
          userAccessRight = 'READ_ONLY';
        } else {
          userAccessRight = '';
        }
      }
      const updatedUserKnowledgeAreas = userAreaBase.map((userKnowledgeArea) => {
        if (userKnowledgeArea.entityId === areaId) {
          return { ...userKnowledgeArea, userAccessRight };
        }
        return userKnowledgeArea;
      });
      setFunction(updatedUserKnowledgeAreas);
    }
  };

  const checkHasSelectedArea = (areas: UserKnowledgeAreaAccess[]): boolean => {
    return areas.some((area) => area.userAccessRight === 'READ_ONLY' || area.userAccessRight === 'WRITE_AND_READ');
  };

  const toggleAreasCheckboxes = (entityType: string, hasSelectedArea: boolean) => {
    const userAccess: AccessRight | '' = hasSelectedArea ? '' : 'WRITE_AND_READ';
    const areaToUpdate = entityType === 'KNOWLEDGE_AREA' ? userKnowledgeAreas : userAppAreas;
    const areaToSave = areaToUpdate.map((knowledgeArea) => ({
      ...knowledgeArea,
      userAccessRight: userAccess,
    }));

    if (entityType === 'KNOWLEDGE_AREA') {
      setUserKnowledgeAreas(areaToSave);
    } else if (entityType === 'APP') {
      setUserAppAreas(areaToSave);
    }
  };

  const getUserKnowledgeAreas = (loadedKnowledgeAreas: KnowledgeAreaModel[]): UserKnowledgeAreaAccess[] => {
    const userKnowledgeAreas: UserKnowledgeAreaAccess[] = loadedKnowledgeAreas.map((knowledgeArea) => {
      return {
        title: knowledgeArea.title,
        description: knowledgeArea.description,
        entityType: 'KNOWLEDGE_AREA',
        entityId: knowledgeArea._id || '',
        userAccessRight: '',
        accessRight: 'READ_ONLY',
        accessRightId: '',
      };
    });

    return userKnowledgeAreas;
  };

  const mapUserAreas = (userArea: UserKnowledgeAreaAccess[]) => {
    return userArea
      .filter((knowledgeArea) => knowledgeArea.userAccessRight)
      .map(
        (knowledgeArea) =>
          ({
            entityType: knowledgeArea.entityType,
            entityId: knowledgeArea.entityId,
            accessRight: knowledgeArea.userAccessRight,
            accessRightId: knowledgeArea.accessRightId,
          }) as IUserDetailedAccessRight,
      );
  };

  const handleUpdateUser = () => {
    form.validateFields().then(async (values) => {
      if (companyState._id && user?._id) {
        const knowledgeAreaRights = mapUserAreas(userKnowledgeAreas);
        const appRights = mapUserAreas(userAppAreas);

        try {
          await updateAccessRightForUser(companyState._id, user?._id, selectedUser?._id || '', [
            ...knowledgeAreaRights,
            ...appRights,
          ]);
          if (values.role) {
            await updateUser(selectedUser?._id || '', { role: values.role });
          }
          handleCancel();

          onUserUpdate(selectedUser?._id || '', values.role, knowledgeAreaRights, appRights);
          form.resetFields();
        } catch (error) {
          if (error instanceof Error) {
            notificationController.error({ message: error.message });
          } else if (typeof error === 'string') {
            notificationController.error({ message: error });
          } else {
            notificationController.error({
              message: `Fehler beim Verschicken der Einaldung. Bitte versuchen Sie es nochmal.`,
            });
          }
        }
      }
    });
  };

  const handleSendInvitation = async () => {
    form.validateFields().then(async (values) => {
      if (companyState._id && user?._id) {
        const knowledgeAreaRights = mapUserAreas(userKnowledgeAreas);
        const appRights = mapUserAreas(userAppAreas);

        const email = values['email'];
        const departmentIds = values['departmentIds'];
        const teamMemberRole = values['role'] as UserRole;

        const invitation: TeamMemberInvitationModel = {
          companyId: companyState._id,
          invitedByUserId: user?._id,
          teamMemberBelongsToDepartmentIds: departmentIds || [],
          teamMemberEmail: email,
          timestampCreated: moment().unix(),
          teamMemberRole,
          teamMemberAccessRights: [...knowledgeAreaRights, ...appRights],
        };

        try {
          const id = await createTeamMemberInvitation(invitation);
          invitation._id = id;
          setInvitationData([...invitationData, invitation]);
          notificationController.success({ message: t('invitationSent', { email }) });
          handleCancel();
          form.resetFields();
        } catch (error) {
          if (error instanceof Error) {
            notificationController.error({ message: error.message });
          } else if (typeof error === 'string') {
            notificationController.error({ message: error });
          } else {
            notificationController.error({
              message: `Fehler beim Verschicken der Einaldung. Bitte versuchen Sie es nochmal.`,
            });
          }
        }
      }
    });
  };

  useEffect(() => {
    const readKnowledgeAreas = async () => {
      const knowledgeAreas = await readAllKnowledgeAreaForUser(companyState._id ?? '', user?._id ?? '', true);
      setLoadedKnowledgeAreas(knowledgeAreas);
      setInitialLoaded(true);
    };
    readKnowledgeAreas();
  }, []);

  useEffect(() => {
    const readUserAccessRightsForEditMode = async () => {
      try {
        const selectedUserAccessRights: IUserDetailedAccessRights | null = await readAccessRightsByUser(
          companyState._id || '',
          selectedUser?._id || '',
        );
        if (selectedUserAccessRights) {
          const selectedUserKnowledgeAreas = selectedUserAccessRights.accessRights.filter(
            (userAccessRight) => userAccessRight.entityType === 'KNOWLEDGE_AREA',
          );
          const selectedUserAppAreas = selectedUserAccessRights.accessRights.filter(
            (userAccessRight) => userAccessRight.entityType === 'APP',
          );

          const userKnowledgeAreas: UserKnowledgeAreaAccess[] = loadedKnowledgeAreas.map((knowledgeArea) => {
            const selectedUserKnowledgeArea = selectedUserKnowledgeAreas.find(
              (userKnowledgeArea) => userKnowledgeArea.entityId === knowledgeArea._id,
            );

            return {
              title: knowledgeArea.title,
              description: knowledgeArea.description,
              entityType: 'KNOWLEDGE_AREA',
              entityId: knowledgeArea._id || '',
              userAccessRight: selectedUserKnowledgeArea ? selectedUserKnowledgeArea.accessRight : '',
              accessRight: 'READ_ONLY',
              accessRightId: '',
            };
          });

          const userApps: UserKnowledgeAreaAccess[] = appAreas.map((appArea) => {
            const selectedUserApp = selectedUserAppAreas.find(
              (userKnowledgeArea) => userKnowledgeArea.entityId === appArea.entityId,
            );

            return {
              ...appArea,
              userAccessRight: selectedUserApp ? selectedUserApp.accessRight : '',
            };
          });

          setUserKnowledgeAreas(userKnowledgeAreas);
          setUserAppAreas(userApps);
        }
      } catch (error) {
        if (error instanceof Error) {
          notificationController.error({ message: error.message });
        } else if (typeof error === 'string') {
          notificationController.error({ message: error });
        } else {
          notificationController.error({
            message: `Fehler beim Laden. Bitte versuchen Sie es nochmal.`,
          });
        }
      }
    };

    if (initialLoaded) {
      if (isEditMode && selectedUser?._id) {
        readUserAccessRightsForEditMode();
        form.setFieldValue('role', selectedUser?.role || 'EMPLOYEE');
        setAllowEditRoles(isAdmin);
      } else {
        setUserAppAreas(appAreas);
        setUserKnowledgeAreas(getUserKnowledgeAreas(loadedKnowledgeAreas));
        setAllowEditRoles(true);
      }
    }
  }, [isEditMode, loadedKnowledgeAreas, initialLoaded, selectedUser?._id]);

  return (
    <Modal
      title={isEditMode ? t('settings.updateTeamMember') : t('inviteNewTeamMember')}
      open={isModalVisible}
      okText={isEditMode ? t('save') : t('sendInvitation')}
      onOk={isEditMode ? handleUpdateUser : handleSendInvitation}
      onCancel={() => {
        handleCancel();
        form.resetFields();
      }}
    >
      <Form
        form={form}
        initialValues={{
          email: '',
          departmentIds: undefined,
          role: 'EMPLOYEE',
        }}
      >
        {isEditMode ? (
          <UserEditInfo>
            <NormalText centered semiBold size="l" colorType="primary">
              {selectedUser?.name}
            </NormalText>
            <NormalText centered semiBold size="s" colorType="primary">
              {selectedUser?.email}
            </NormalText>
          </UserEditInfo>
        ) : (
          <>
            <Form.Item name="email" rules={[{ required: true, type: 'email', message: t('enterValidEmail') }]}>
              <BaseInput placeholder={t('email')} />
            </Form.Item>
            <Form.Item name="departmentIds" rules={[{ required: true, message: t('selectAtLeastOneDepartment') }]}>
              <BaseSelect
                ref={selectRef}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder={t('selectDepartment')}
                options={departments.map((item) => ({ value: item._id, label: item.name }))}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div style={{ textAlign: 'end' }}>
                      <Button
                        type="link"
                        onClick={() => {
                          //@ts-expect-error ignore
                          selectRef.current?.blur();
                        }}
                      >
                        {t('confirmSelection')}
                      </Button>
                    </div>
                  </>
                )}
              />
            </Form.Item>
          </>
        )}
        <Tabs centered defaultActiveKey="1">
          <TabPane tab={t('settings.roleTabLabel')} key="1">
            {!isAdmin && (
              <InfoTextRow>
                <InfoCircleOutlined />
                <NormalText size="s" centered>
                  {t('someRolesCanBeSetOnlyByAdmin')}
                </NormalText>
              </InfoTextRow>
            )}
            <Form.Item name="role" rules={[{ required: true, message: 'Please select role' }]}>
              <RadioGroup size="large" value={'manager'}>
                <Col>
                  <Radio disabled={!isAdmin} value="ADMIN">
                    {'Admin'}
                  </Radio>
                  <S.Description>{t('adminRoleDescription')}</S.Description>
                </Col>
                <Col>
                  <Radio disabled={!allowEditRoles} value="MANAGER">
                    {'Manager'}
                  </Radio>
                  <S.Description>{t('managerRoleDescription')}</S.Description>
                </Col>
                <Col>
                  <Radio disabled={!allowEditRoles} value="EMPLOYEE">
                    {t('employeeRole')}
                  </Radio>
                  <S.Description>{t('userRoleDescription')}</S.Description>
                </Col>
              </RadioGroup>
            </Form.Item>
          </TabPane>
          <TabPane tab={t('settings.knoledgeAreaTabLabel')} key="2">
            {userKnowledgeAreas.length === 0 && (
              <S.TabScrollableWrapper>
                <NormalText verticalPadding centered light colorType="light">
                  {t('settings.noKnoledgeAreas')}
                </NormalText>
              </S.TabScrollableWrapper>
            )}
            {userKnowledgeAreas.length > 0 && (
              <S.TabScrollableWrapper>
                <KnowledgeAreaRowHeader
                  entityType="KNOWLEDGE_AREA"
                  toggleAreasCheckboxes={toggleAreasCheckboxes}
                  hasSelectedArea={checkHasSelectedArea(userKnowledgeAreas)}
                />
                {userKnowledgeAreas.map((area) => (
                  <KnowledgeAreaSettingsRowItem
                    key={area.entityId + area.title}
                    areaId={area.entityId}
                    canRead={area.userAccessRight === 'READ_ONLY' || area.userAccessRight === 'WRITE_AND_READ'}
                    canWrite={area.userAccessRight === 'WRITE_AND_READ'}
                    title={area.title}
                    description={area.description}
                    onAreaSelect={(areaId: string, accessRight: AccessRight, status: boolean) => {
                      onAreaKnowledgeSelect(areaId, accessRight, status, userKnowledgeAreas, setUserKnowledgeAreas);
                    }}
                  />
                ))}
              </S.TabScrollableWrapper>
            )}
          </TabPane>
          <TabPane tab={t('settings.appTabLabel')} key="3">
            <S.TabScrollableWrapper>
              <KnowledgeAreaRowHeader
                entityType="APP"
                toggleAreasCheckboxes={toggleAreasCheckboxes}
                hasSelectedArea={checkHasSelectedArea(userAppAreas)}
              />
              {userAppAreas.map((app) => (
                <KnowledgeAreaSettingsRowItem
                  key={app.entityId + app.title}
                  areaId={app.entityId}
                  canRead={app.userAccessRight === 'READ_ONLY' || app.userAccessRight === 'WRITE_AND_READ'}
                  canWrite={app.userAccessRight === 'WRITE_AND_READ'}
                  title={app.title}
                  description={app.description}
                  onAreaSelect={(areaId: string, accessRight: AccessRight, status: boolean) => {
                    onAreaKnowledgeSelect(areaId, accessRight, status, userAppAreas, setUserAppAreas);
                  }}
                />
              ))}
            </S.TabScrollableWrapper>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};
