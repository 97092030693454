import React from 'react';
import { Avatar, Space, Col, Dropdown, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Table } from './TeamMembersTable.style';
import { UserRole } from '@app/domain/UserModel';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { IconDotsVertical } from '@tabler/icons-react';
import { IUserOverviewAccessRight } from '@app/domain/AccessRightsModel';
import { IconTrash } from '@tabler/icons-react';

type TeamMemberItemProps = {
  name: string;
  email: string;
  _id: string;
  title?: string | null;
  imageUrl?: string;
  role?: UserRole;
  userAccessRight?: IUserOverviewAccessRight[];
};

type TeamMemberTableProps = {
  teamMembers: TeamMemberItemProps[];
  allowEditRights: boolean;
  currentUserId: string;
  onDeleteUser?: (userId: string) => void;
  openEditModal?: (user: TeamMemberItemProps) => void;
  isInvitationTable?: boolean;
  onDeleteInvitation?: (email: string) => void;
};

export const TeamMemberTable: React.FC<TeamMemberTableProps> = ({
  teamMembers,
  allowEditRights,
  currentUserId,
  onDeleteUser,
  openEditModal,
  isInvitationTable,
  onDeleteInvitation,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { t } = useTranslation();

  const menuItems = [
    { label: 'Edit ', key: 'edit' },
    { label: 'Delete', key: 'delete' },
  ];

  const KnowledgeAreaItem: React.FC<{ areaItem: IUserOverviewAccessRight }> = ({ areaItem }) => {
    const pluralMark = areaItem.allowedItems > 1 ? 's' : '';

    return (
      <NormalText size="s" horizontalPadding>{`${areaItem.allowedItems} ${
        areaItem.entityType === 'KNOWLEDGE_AREA' ? 'AI Space' : 'AI App'
      }${pluralMark}`}</NormalText>
    );
  };

  const columns = [
    {
      title: `${isInvitationTable ? t('settings.invited') : t('settings.activeMembers')} (${teamMembers.length})`,
      key: 'name',
      defaultSortOrder: null,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (item: TeamMemberItemProps) => (
        <Space>
          <Avatar alt="User" shape="circle" size={'large'} src={item.imageUrl}>
            {item.name ? item.name.slice(0, 2).toUpperCase() : ''}
          </Avatar>
          <Col>
            <NormalText size="m">{item.name ?? '-'}</NormalText>
            <NormalText colorType="light" size="s">
              {item.title ?? 'Noch keine Position angegeben'}
            </NormalText>
          </Col>
        </Space>
      ),
    },
    {
      title: t('settings.userRole'),
      key: 'userRole',
      defaultSortOrder: null,
      sorter: (a: any, b: any) => (a.role && b.role ? a.role.localeCompare(b.role) : 0),
      render: (item: TeamMemberItemProps) => (
        <NormalText size="s" style={{ textTransform: 'capitalize' }}>
          {item.role ? item.role.toLowerCase() : '-'}
        </NormalText>
      ),
    },
    {
      title: t('settings.spacesApps'),
      key: 'editMember',
      render: (item: TeamMemberItemProps) =>
        item.userAccessRight?.map((accessRight, index) => (
          <KnowledgeAreaItem key={'active_spaces_' + index} areaItem={accessRight} />
        )),
    },
    {
      title: '',
      key: 'editMember',
      render: (item: TeamMemberItemProps) =>
        isInvitationTable ? (
          <Button type="text" onClick={() => onDeleteInvitation && onDeleteInvitation(item.email)}>
            <IconTrash size={18} />
          </Button>
        ) : (
          allowEditRights &&
          currentUserId !== item._id && (
            <Dropdown
              menu={{
                items: menuItems,
                onClick: ({ key }) => {
                  if (key === 'edit') openEditModal && openEditModal(item);
                  if (key === 'delete') onDeleteUser && onDeleteUser(item._id);
                },
              }}
              trigger={['click']}
            >
              <div style={{ padding: '0.5rem', cursor: 'pointer' }}>
                <IconDotsVertical color={themeObject[theme].textLight} />
              </div>
            </Dropdown>
          )
        ),
    },
  ];

  return (
    <Table
      bordered={false}
      pagination={false}
      columns={columns}
      dataSource={teamMembers.map((member) => ({ ...member }))}
    />
  );
};
