import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import * as S from './DepartmentOverview.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { List } from 'antd';
import { IDepartment } from '@app/domain/DepartmentModel';
import { createDepartment, deleteDepartment, updateDepartment } from '@app/api/department.api';
import { DepartmentRowItem } from './DepartmentRowItem';
import { notificationController } from '@app/controllers/notificationController';
import moment from 'moment';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { loadDepartments, setDepartments } from '@app/store/slices/departmentSlice';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';

export const DepartmentOverview: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const departments = useAppSelector((state) => state.department.departments);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toEditDepartmentId, setToEditDepartmentId] = useState<string | null>(null);

  const { isAdmin } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const companyState = useAppSelector((state) => state.company);

  useEffect(() => {
    const readDepartments = async () => {
      if (companyState._id) {
        dispatch(loadDepartments());
      }
    };
    readDepartments();
  }, [companyState]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setToEditDepartmentId(null);
  };

  const handleSave = async () => {
    form.validateFields().then(async (values) => {
      try {
        let name = values['name'];
        let description = values['description'];

        name = name.trim();
        description = description ? description.trim() : '';

        if (departments.some((item) => item.name === name)) {
          notificationController.error({ message: t('departmentAlreadyExists', { name }) });
          return;
        }

        if (companyState._id) {
          if (toEditDepartmentId) {
            const foundDep = departments.find((dep) => dep._id === toEditDepartmentId);
            if (foundDep) {
              await updateDepartment(toEditDepartmentId, {
                name,
                description,
              });
              dispatch(
                setDepartments({
                  value: departments.map((item) => {
                    if (item._id === toEditDepartmentId) return { ...item, name, description };
                    else return item;
                  }),
                }),
              );
              notificationController.success({ message: t('common.success') });
            }
          } else {
            const newDepartment: IDepartment = {
              companyId: companyState._id,
              name: name.trim(),
              description: description ? description.trim() : '',
              timestampLastUpdatedAt: moment().unix(),
            };
            const id = await createDepartment({
              companyId: companyState._id,
              name,
              description,
              timestampLastUpdatedAt: moment().unix(),
            });
            newDepartment._id = id;
            const updatedDepartments = [...departments, newDepartment];
            dispatch(setDepartments({ value: updatedDepartments }));
            notificationController.success({ message: t('common.success') });
            form.resetFields();
          }
        }
      } catch (error) {
        console.log('****** handleSave error: ', error);
        if (error instanceof Error) {
          notificationController.error({ message: error.message });
        } else if (typeof error === 'string') {
          notificationController.error({ message: error });
        } else {
          notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
        }
      } finally {
        setIsModalVisible(false);
        setToEditDepartmentId(null);
      }
    });
  };

  const handleAdd = () => {
    setIsModalVisible(true);
  };

  const handleEditClick = (id?: string) => {
    if (id) {
      const foundDep = departments.find((dep) => dep._id === id);
      form.setFieldValue('name', foundDep?.name);
      form.setFieldValue('description', foundDep?.description);
      setToEditDepartmentId(id);
    }
  };

  const onDeleteConfirmed = async (id?: string) => {
    try {
      if (id) {
        await deleteDepartment(id);
        dispatch(setDepartments({ value: departments.filter((item) => item._id !== id) }));
        notificationController.success({ message: t('common.successDelete') });
      }
    } catch (error) {
      console.log('****** onDeleteConfirmed error: ', error);
      if (error instanceof Error) {
        notificationController.error({ message: error.message });
      } else if (typeof error === 'string') {
        notificationController.error({ message: error });
      } else {
        notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
      }
    }
  };

  const handleDeleteClick = (id?: string) => {
    if (isAdmin) {
      Modal.confirm({
        title: t('deleteDepartment'),
        icon: <ExclamationCircleOutlined />,
        content: t('areYouSureToDeleteDepartment'),
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        onOk() {
          onDeleteConfirmed(id);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      Modal.confirm({
        title: t('missingRights'),
        icon: <InfoCircleOutlined />,
        content: t('youHaveToBeAdmin'),
        okText: t('ok'),
      });
    }
  };

  return (
    <>
      <NormalText semiBold size="m" style={{ marginTop: '1rem' }}>
        {t('manageYourDepartments')}
      </NormalText>

      <List
        dataSource={departments}
        renderItem={(item, index) => (
          <DepartmentRowItem
            key={index + '_active_tm'}
            name={item.name}
            description={item.description}
            id={item._id}
            onEdit={(id) => handleEditClick(id)}
            onDelete={(id) => handleDeleteClick(id)}
          />
        )}
      />
      <Modal
        title={toEditDepartmentId !== null ? t('editDepartment') : t('addNewDepartment')}
        open={toEditDepartmentId !== null || isModalVisible}
        onOk={handleSave}
        okText={t('save')}
        onCancel={handleCancel}
      >
        <Form form={form}>
          <Form.Item name="name" rules={[{ required: true, message: t('enterName') }]}>
            <BaseInput placeholder={t('departmentName')} />
          </Form.Item>
          <Form.Item name="description">
            <BaseInput placeholder={t('shortDescriptionOptional')} />
          </Form.Item>
        </Form>
      </Modal>
      <S.InviteButton type="primary" htmlType="submit" onClick={handleAdd}>
        {t('addNewDepartment')}
      </S.InviteButton>
    </>
  );
};
