import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Error.styles';
import { Button } from 'antd';

interface ErrorProps {
  img: string;
  msg: string;
  resetErrorBoundary?: () => void;
}

export const Error: React.FC<ErrorProps> = ({ img, msg, resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.Image preview={false} src={img} />
      <S.ContentWrapper>
        <S.Title>{t('common.oops')}</S.Title>
        <S.Text>{t('common.errorText', { error: msg })}</S.Text>

        <Button type="primary" onClick={() => (resetErrorBoundary ? resetErrorBoundary() : {})}>
          {t('common.reloadPage')}
        </Button>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
