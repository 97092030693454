import styled from 'styled-components';
import { FONT_SIZE, media } from '@app/styles/themes/constants';
import avatarBackground from '@app/assets/images/abstract-blur-hotel-lobby.jpg';

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${avatarBackground});
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

export const MenuItemWrapper = styled.div`
  width: 100%;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const VideoElementWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const CanvasElement = styled.canvas`
  width: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0);
  left: 50%;

  @media only screen and ${media.md} {
    width: 60%;
  }

  @media only screen and ${media.xl} {
    width: 40%;
  }
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ChatWrapper = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: end;
`;

export const UserChat = styled.div`
  width: 100%;
  margin-right: 1rem;
  padding: 1rem;
  opacity: 0.5;
  background-color: var(--background-color);
  border-radius: 8px;
  max-height: 7rem;
  overflow-y: scroll;

  @media only screen and ${media.md} {
    width: 60%;
    max-width: 800px;
    max-height: 12rem;
  }
`;

export const TextBox = styled.p`
  display: flex;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xxs};

  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xs};
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.md};
  }
`;

export const SpeakerDescription = styled.span`
  display: inline-block;
  margin-right: 0.5rem;
  font-weight: 700;
  min-width: 25px;

  @media only screen and ${media.md} {
    min-width: 40px;
    margin-right: 1rem;
  }
`;

export const SettingButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 0.2rem;
  margin: 1rem;
`;

export const SettingsButton = styled.button`
  margin-left: 1rem;
  padding: 0 0.4rem;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: var(--background-color);
  opacity: 0.2;
  cursor: pointer;
`;

export const SpeechRecognitionButton = styled.button`
  width: 66px;
  height: 66px;
  border: none;
  border-radius: 33px;
  color: #a7a7a7;
  background-color: #e8edf3;
  cursor: pointer;
  opacity: 0.5;
  background-color: var(--background-color);
`;
