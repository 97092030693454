import React from 'react';
import styled from 'styled-components';
import { Col, Spin } from 'antd';
import { NormalText } from './BaseTexts/BaseTexts';
import { GlobalSpinner } from './GlobalSpinner';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';

interface LoadingSpinProps {
  marginTop?: string | number;
  text?: string;
}

export const LoadingSpin: React.FC<LoadingSpinProps> = ({ marginTop, text }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const spinnerColor = themeObject[theme].spinnerBase;

  return (
    <SpinnerContainer>
      <Col>
        <GlobalSpinner size={'6rem'} color={spinnerColor} marginTop={marginTop} />
      </Col>
      {text && (
        <Col>
          <NormalText size="m" verticalPadding colorType="light">
            {text}
          </NormalText>
        </Col>
      )}
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Spinner = styled(Spin)<LoadingSpinProps>`
  margin-top: ${(props) => props.marginTop || 0};
`;
