import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import guestOverviewImage from '@app/assets/images/guest-overview.svg';

export const InviteExternalUserButton = styled(Button)`
  margin: 1.5rem 0 1.5rem 0;
  padding: 0 1.5rem;

  @media only screen and ${media.md} {
    max-width: 10rem;
  }

  @media only screen and ${media.xl} {
    max-width: unset;
  }
`;

export const NoDataImageContainer = styled.div`
  width: 100%;
  height: 40vh;
  max-height: 250px;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: end;
  background-image: url(${guestOverviewImage});
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
`;
