import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import * as S from './AuthLayout.styles';
import loginBackground from '@app/assets/images/login-bg-image.png';

const AuthLayout: React.FC = () => {
  const { t } = useTranslation();
  const { appName } = useAppSelector((state) => state.appLayout);

  return (
    <S.Wrapper>
      <S.BackgroundWrapper>
        <S.ImageWrapper>
          <S.Image src={loginBackground} />
          <S.Description>
            <S.DescriptionCommon>{t('login.loginDescriptionCommon')}</S.DescriptionCommon>
            <S.DescriptionMain>{t('login.loginDescriptionMain', { appName: appName })}</S.DescriptionMain>
          </S.Description>
        </S.ImageWrapper>
      </S.BackgroundWrapper>
      <S.LoginWrapper>
        <Outlet />
      </S.LoginWrapper>
    </S.Wrapper>
  );
};

export default AuthLayout;
