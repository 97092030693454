import React, { useEffect, useState } from 'react';
import { Col, Image, Row, Space } from 'antd';
import AddData from 'assets/images/undraw_new_entries_re_cffr.svg';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { LoadingContainer } from '../gpttemplates/GptTemplatesDetail.styles';
import { LoadingSpin } from '../common/LoadingSpin';
import { ContainerWrapper, NoDataWrapper, OverviewWrapper } from './SourceUpdateMonitoringOverview.styles';
import { Button } from '../common/buttons/Button/Button';
import { ISourceUpdateMonitoring } from '@app/domain/SourceUpdateMonitoringModel';
import { readSourceUpdateMonitoringByCompany } from '@app/api/sourceUpdateMonitoring.api';
import { ModalCreateNewUpdateMonitoring } from './ModalCreateNewUpdateMonitoring';
import SourceUpdateCardItem from './SourceUpdateCardItem';
import moment from 'moment';

export const SourceUpdateMonitoringOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [initialLoading, setInitialLoading] = useState(true);

  const [loadedMonitorings, setLoadedMonitoring] = useState<ISourceUpdateMonitoring[]>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const companyState = useAppSelector((state) => state.company);

  useEffect(() => {
    if (companyState._id && initialLoading) {
      const readData = async () => {
        const data = await readSourceUpdateMonitoringByCompany(companyState._id || '');
        setLoadedMonitoring(data || []);
        setInitialLoading(false);
      };
      readData();
    }
  }, [companyState, initialLoading]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const inactiveExists = loadedMonitorings.some((item) => !item.isActive);

  return (
    <ContainerWrapper>
      <BaseButtonsForm.Item>
        <Row justify={'space-between'} align={'bottom'}>
          <Col>
            <NormalText bold size="xl">
              {t('sourceUpdateMonitoring.headerTitle')}
            </NormalText>
            <NormalText verticalPadding>{t('sourceUpdateMonitoring.headerDescription')}</NormalText>
          </Col>
          <Col>
            <Button type="primary" style={{ float: 'right' }} onClick={showModal}>
              {t('sourceUpdateMonitoring.createNewButtonTitle')}
            </Button>
          </Col>
        </Row>
      </BaseButtonsForm.Item>
      {initialLoading ? (
        <LoadingContainer>
          <LoadingSpin />
        </LoadingContainer>
      ) : (
        <OverviewWrapper>
          <NormalText size="l" semiBold verticalPadding horizontalPadding bottomMargin="s" color="#4fc854">
            Active
          </NormalText>
          <Space direction="vertical">
            {loadedMonitorings
              ?.filter((item) => item.isActive)
              .map((item, index) => (
                <SourceUpdateCardItem
                  key={index + '_kai'}
                  onClick={() => {
                    navigation('/source-monitoring-selected/' + item._id);
                  }}
                  id={item._id ?? ''}
                  title={item.title}
                  description={item.description ?? ''}
                  executeInterval={
                    item.intervalCheckForUpdatesInHours === 24
                      ? t('sourceUpdateMonitoring.dailyExecution')
                      : t('sourceUpdateMonitoring.weeklyExecution')
                  }
                  allSuccessful={item.lastScanWasSuccessful || false}
                  numberFailedScans={item.lastScanErrors?.length || 0}
                  lastScanDate={
                    item.timestampLastScanedAt ? moment.unix(item.timestampLastScanedAt).format('D. MMMM') : ''
                  }
                  isActive={item.isActive}
                />
              ))}
          </Space>
          {inactiveExists && (
            <>
              <NormalText size="l" semiBold verticalPadding horizontalPadding bottomMargin="s" color="#f09e00">
                Inactive
              </NormalText>
              <Space direction="vertical">
                {loadedMonitorings
                  ?.filter((item) => !item.isActive)
                  .map((item, index) => (
                    <SourceUpdateCardItem
                      key={index + '_kai'}
                      onClick={() => {
                        navigation('/source-monitoring-selected/' + item._id);
                      }}
                      id={item._id ?? ''}
                      title={item.title}
                      description={item.description ?? ''}
                      executeInterval={
                        item.intervalCheckForUpdatesInHours === 24
                          ? t('sourceUpdateMonitoring.dailyExecution')
                          : t('sourceUpdateMonitoring.weeklyExecution')
                      }
                      allSuccessful={item.lastScanWasSuccessful || false}
                      numberFailedScans={item.lastScanErrors?.length || 0}
                      lastScanDate={
                        item.timestampLastScanedAt ? moment.unix(item.timestampLastScanedAt).format('D. MMMM') : ''
                      }
                      isActive={item.isActive}
                    />
                  ))}
              </Space>
            </>
          )}
          {!initialLoading && loadedMonitorings.length === 0 && (
            <NoDataWrapper>
              <Image
                src={AddData}
                alt="Add new entry"
                preview={false}
                height={220}
                style={{ objectFit: 'contain', backgroundColor: 'transparent', paddingBottom: '2rem' }}
              />
              <NormalText centered verticalPadding size="l">
                {t('sourceUpdateMonitoring.addNewMontitoringEntry')}
              </NormalText>
            </NoDataWrapper>
          )}
          <ModalCreateNewUpdateMonitoring
            showCreateModal={isModalVisible}
            numberExistentMonitorings={loadedMonitorings.length}
            onCloseModal={(createdItem) => {
              setIsModalVisible(false);
              if (createdItem) {
                setLoadedMonitoring([...loadedMonitorings, createdItem]);
              }
            }}
          />
        </OverviewWrapper>
      )}
    </ContainerWrapper>
  );
};
