import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { AppEntityIds, IAccessRights, IUserAccessRight } from '@app/domain/AccessRightsModel';
import { useTranslation } from 'react-i18next';
import { AccessRightTeamMember } from './OverviewAccessRights';
import { updateAccessRight } from '@app/api/accessRights.api';
import SearchUsersAccessRights from './SearchUsersAccessRights';
import { NormalText } from '../common/BaseTexts/BaseTexts';

type SearchUsersAccessRightsModalProps = {
  activeAccessRight: IAccessRights | null;
  activeAccessRightTeamMembers: AccessRightTeamMember[];
  isModalVisible: boolean;
  entityLabel?: string;
  handleCloseModal: () => void;
  handleOnChangeRights: (updatedAccessRight: IAccessRights) => void;
};

const SearchUsersAccessRightsModal: React.FC<SearchUsersAccessRightsModalProps> = ({
  activeAccessRight,
  activeAccessRightTeamMembers,
  isModalVisible,
  entityLabel,
  handleCloseModal,
  handleOnChangeRights,
}) => {
  const { t } = useTranslation();
  const [accessRightTeamMembers, setAccessRightTeamMembers] = useState<AccessRightTeamMember[]>([]);

  const [missingSpaceOwner, setMissingSpaceOwner] = useState(false);
  const [hideWriteCheckbox, setHideWriteCheckbox] = useState(false);

  const [readAccessLabel, setReadAccessLabel] = useState('Read');
  const [writeAccessLabel, setWriteAccessLabel] = useState('Write');

  useEffect(() => {
    setHideWriteCheckbox(
      activeAccessRight?.entityType == 'APP' && activeAccessRight.entityId === AppEntityIds.SecureChatGpt,
    );
    activeAccessRight?.entityType == 'APP'
      ? setReadAccessLabel(t('settings.readAccessLabelApp'))
      : setReadAccessLabel(t('settings.readAccessLabel'));
    activeAccessRight?.entityType == 'APP'
      ? setWriteAccessLabel(t('settings.writeAccessLabelApp'))
      : setWriteAccessLabel(t('settings.writeAccessLabel'));
  }, [activeAccessRight]);

  useEffect(() => {
    setMissingSpaceOwner(!accessRightTeamMembers.some((item) => item.accessRight === 'WRITE_AND_READ'));
  }, [accessRightTeamMembers]);

  useEffect(() => {
    setAccessRightTeamMembers([...activeAccessRightTeamMembers]);
  }, [activeAccessRightTeamMembers]);

  const handleCancel = () => {
    setAccessRightTeamMembers([...activeAccessRightTeamMembers]);
    handleCloseModal();
  };

  const handleOk = async () => {
    try {
      if (missingSpaceOwner) {
        message.warn(t('missingKnowladgeSpaceOwner'));
        return;
      }
      if (activeAccessRight?._id) {
        const specificUserRights: IUserAccessRight[] = [];
        for (const right of accessRightTeamMembers) {
          specificUserRights.push({ userId: right.userId, accessRight: right.accessRight });
        }
        const updatedAccessRight = { ...activeAccessRight, specificUserRights };
        await updateAccessRight(activeAccessRight._id, updatedAccessRight);
        handleOnChangeRights(updatedAccessRight);
      }
    } catch (error) {
      console.log('****** handleOk error: ', error);
      if (error instanceof Error) {
        notificationController.error({ message: error.message });
      } else if (typeof error === 'string') {
        notificationController.error({ message: error });
      } else {
        notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
      }
    }
    handleCloseModal();
  };

  return (
    <Modal
      title={entityLabel ? t('editTeamMembersAccessFor', { entityLabel }) : t('editTeamMembersAccess')}
      open={isModalVisible}
      onOk={() => handleOk()}
      onCancel={handleCancel}
      bodyStyle={{ maxHeight: '60vh', overflowY: 'scroll' }}
    >
      <SearchUsersAccessRights
        hideWriteCheckbox={hideWriteCheckbox}
        accessRightTeamMembers={accessRightTeamMembers}
        readAccessLabel={readAccessLabel}
        writeAccessLabel={writeAccessLabel}
        setAccessRightTeamMembers={(rights) => setAccessRightTeamMembers(rights)}
      />
      {missingSpaceOwner && activeAccessRight?.entityType === 'KNOWLEDGE_AREA' && (
        <NormalText centered size="s" verticalPadding colorType="warning" style={{ marginTop: '1rem' }}>
          {t('missingKnowladgeSpaceOwner')}
        </NormalText>
      )}
    </Modal>
  );
};

export default SearchUsersAccessRightsModal;
