import { DocumentChecklist, DocumentChecklistElement } from '@app/types/documentCheckTypes';
import { useEffect, useState } from 'react';
import { useAppSelector } from './reduxHooks';
import {
  createDocumentChecklist,
  deleteDocumentChecklist,
  readAllChecklistsOfCompany,
  updateDocumentChecklist,
} from '@app/api/documentChecklist.api';
import { useErrorHandling } from './useErrorHandling';
import { UserModel } from '@app/domain/UserModel';
import { getUsersByIds } from '@app/api/company.api';
import { DeletedUser } from '@app/utils/constants';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const useHandleChecklists = () => {
  const [documentChecklists, setDocumentChecklists] = useState<DocumentChecklist[]>([]);
  const [userCreators, setUserCreators] = useState<UserModel[]>([]);
  const [documentChecklistLoading, setDocumentChecklistLoading] = useState<boolean>(true);

  const companyState = useAppSelector((state) => state.company);

  const { handleApiError } = useErrorHandling();

  const { t } = useTranslation();

  const getDocumentChecklist = async () => {
    try {
      return await readAllChecklistsOfCompany(companyState._id ?? '');
    } catch (error) {
      console.log(error);
      handleApiError(error);
    } finally {
      setDocumentChecklistLoading(false);
    }
  };

  const loadAndSetChecklists = async () => {
    if (companyState) {
      const checklist = await getDocumentChecklist();
      setDocumentChecklists(checklist ?? []);
      if (checklist) {
        const userIds = checklist.map((item) => item.createdBy);
        if (userIds.length) {
          const users: UserModel[] = await getUsersByIds(userIds);
          setUserCreators(users);
        }
      }
    }
  };

  useEffect(() => {
    loadAndSetChecklists();
  }, [companyState]);

  const onDuplicate = async (item: DocumentChecklist) => {
    const documentChecklistData: DocumentChecklist = {
      ...item,
      name: `${item.name} (2)`,
      timestamp: moment().unix(),
    };
    delete documentChecklistData._id;
    const id = await createDocumentChecklist(documentChecklistData);
    if (id) {
      message.success(t('documentChecker.checklistDuplicated'));
      documentChecklistData._id = id;
      //documentChecklists.push(documentChecklistData);
      setDocumentChecklists([...documentChecklists, documentChecklistData]);
    }
  };

  const onDeleteDocumentChecklist = async (documentChecklistId: string) => {
    try {
      await deleteDocumentChecklist(documentChecklistId);
      const checklistsToSave = documentChecklists.filter(
        (documentChecklist) => documentChecklist._id !== documentChecklistId,
      );
      setDocumentChecklists(checklistsToSave);
    } catch (error) {
      console.log('****** onDeleteDocumentChecklist error: ', error);
      handleApiError(error);
    }
  };

  const getUserCreatorOfChecklist = (userId: string) => {
    return userCreators.find((item) => item._id === userId) || DeletedUser;
  };

  const updateChecklistElements = async (selectedChecklistId: string, updatedElements: DocumentChecklistElement[]) => {
    try {
      setDocumentChecklistLoading(true);
      await updateDocumentChecklist(selectedChecklistId, { elements: updatedElements });
      // setDocumentChecklists((prev) => [
      //   ...prev.map((item) => {
      //     if (item._id === selectedChecklistId) {
      //       return { ...item, elements: updatedElements };
      //     }
      //     return item;
      //   }),
      // ]);
      loadAndSetChecklists();
    } catch (error) {
      console.log('****** onDeleteDocumentChecklist error: ', error);
      handleApiError(error);
    } finally {
      setDocumentChecklistLoading(false);
    }
  };

  return {
    documentChecklists,
    documentChecklistLoading,
    onDeleteDocumentChecklist,
    getUserCreatorOfChecklist,
    updateChecklistElements,
    onDuplicate,
  };
};
