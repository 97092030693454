import { useState, useEffect, useMemo, useRef } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { UserModel } from '@app/domain/UserModel';
import { DeletedUser } from '@app/utils/constants';
import { readOverview } from '@app/api/knowledgeArea.api';
import { KonwledgeAreBaseInfo } from '@app/types/knowledgeAreaTypes';

type FolderItemType = { folderName: string; id: string; hexColor?: string };

type DataroomTableColumnsDataType = FolderItemType & {
  knowledgeAreaId: string;
  knowledgeAreaTitle: string;
  usersWithAccess: number;
  isExternalAccess: boolean;
  createdByUserId: string;
  userTitle: string;
  timestampLastUpdatedAt: number;
  userImageUrl?: string;
};

const getUserTitle = (user: UserModel) => {
  return `${user.firstName} ${user.lastName}`;
};

export const useDataroomTableData = () => {
  const [dataroomTableFields, setDataroomTableFields] = useState<DataroomTableColumnsDataType[]>([]);
  const [isLoadingDataroomData, setIsLoadingDataroomData] = useState<boolean>(true);
  const isLoadingRef = useRef<boolean>(false);

  const userAccessRights = useAppSelector((state) => state.user.userRights);
  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);
  const { menuStructure } = useAppSelector((state) => state.menuFolder);
  const companyState = useAppSelector((state) => state.company);
  const { handleApiError } = useErrorHandling();

  const foldersStructure: FolderItemType[] = useMemo(() => {
    return menuStructure.reduce((acc, nodeItem) => {
      if (nodeItem.isFolder) {
        const knowledgeAreasWithFolderData = nodeItem.children.map((knowledgeArea) => {
          return { folderName: nodeItem.name, hexColor: nodeItem.hexColor, id: knowledgeArea.id };
        });
        return [...acc, ...knowledgeAreasWithFolderData];
      }

      return [...acc, { folderName: '-', id: nodeItem.id }];
    }, [] as FolderItemType[]);
  }, [menuStructure]);

  const userKnowledgeAreasIdsWithWriteAccess = userAccessRights?.accessRights
    .filter(
      (accessRights) => accessRights.entityType === 'KNOWLEDGE_AREA' && accessRights.accessRight === 'WRITE_AND_READ',
    )
    .map((accessRights) => accessRights.entityId);

  const userLoadedKnowledgeAreasWithWriteAccess = useMemo(() => {
    return foldersStructure.reduce(
      (acc, folderItem) => {
        const knowledgeArea = loadedKnowledgeAreas.find((knowledgeArea) => knowledgeArea._id === folderItem.id);
        if (!knowledgeArea || !userKnowledgeAreasIdsWithWriteAccess?.includes(knowledgeArea._id ?? '')) {
          return acc;
        }

        return [
          ...acc,
          {
            knowledgeAreaId: knowledgeArea._id ?? '',
            knowledgeAreaTitle: knowledgeArea.title,
            createdByUserId: knowledgeArea.createdByUserId,
            timestampLastUpdatedAt: knowledgeArea.timestampLastUpdatedAt,
            ...folderItem,
          },
        ];
      },
      [] as (FolderItemType &
        Pick<
          DataroomTableColumnsDataType,
          'knowledgeAreaId' | 'knowledgeAreaTitle' | 'createdByUserId' | 'timestampLastUpdatedAt'
        >)[],
    );
  }, [foldersStructure]);

  useEffect(() => {
    const getFieldsData = async () => {
      try {
        if (isLoadingRef.current === true) return;

        setIsLoadingDataroomData(true);
        isLoadingRef.current = true;

        const knowledgeAreas: KonwledgeAreBaseInfo[] = userLoadedKnowledgeAreasWithWriteAccess.map((knowledgeArea) => {
          return { id: knowledgeArea.knowledgeAreaId, createdByUserId: knowledgeArea.createdByUserId };
        });

        const overviewData = await readOverview(companyState._id ?? '', knowledgeAreas);

        const data = overviewData.map((knowledgeAreaInfo) => {
          const usersWithAccess = knowledgeAreaInfo.usersWithsAccess;

          const isExternalAccess = knowledgeAreaInfo.externalAccessEnabled;

          const foundUser = knowledgeAreaInfo.createdBy;

          const userTitle = foundUser ? getUserTitle(foundUser) : getUserTitle(DeletedUser);

          const userImageUrl = foundUser ? foundUser.profileImage?.smallUrl : DeletedUser.profileImage?.smallUrl;

          const knowledgeArea = userLoadedKnowledgeAreasWithWriteAccess.find(
            (item) => item.knowledgeAreaId === knowledgeAreaInfo.knowledgeAreaId,
          ) ?? {
            folderName: '-',
            createdByUserId: '-',
            id: '-',
            knowledgeAreaId: '-',
            knowledgeAreaTitle: '-',
            timestampLastUpdatedAt: 0,
          };

          return { ...knowledgeArea, usersWithAccess, isExternalAccess, userTitle, userImageUrl };
        });

        setDataroomTableFields(data);
      } catch (e) {
        handleApiError(e);
      } finally {
        setIsLoadingDataroomData(false);
        isLoadingRef.current = false;
      }
    };

    getFieldsData();
  }, [companyState._id, userLoadedKnowledgeAreasWithWriteAccess.length, menuStructure.length]);

  return { isLoadingDataroomData, dataroomTableFields };
};
