import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuNode } from '@app/types/menuFolderTypes';
import { readNestedMenu } from '@app/api/nestedMenu.api';
import { AppDispatch, RootState } from '../store';

export interface MenuNavigationStructureState {
  _id: string;
  isGlobal: boolean;
  menuStructure: MenuNode[];
}

const initialState: MenuNavigationStructureState = {
  _id: '',
  isGlobal: true,
  menuStructure: [],
};

export const menuFolderSlice = createSlice({
  name: 'menuFolderSlice',
  initialState,
  reducers: {
    setMenuNavigationStructure: (state, { payload }: PayloadAction<{ value: MenuNavigationStructureState }>) => {
      state._id = payload.value._id;
      state.isGlobal = payload.value.isGlobal;
      state.menuStructure = payload.value.menuStructure;
    },
    setMenuStructure: (state, { payload }: PayloadAction<{ value: MenuNode[] }>) => {
      state.menuStructure = payload.value;
    },
  },
});

export const loadNestedMenuStructure = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (getState().company._id) {
      const nestedMenu = await readNestedMenu();

      if (nestedMenu) {
        const menuStructureToSave = {
          _id: nestedMenu._id,
          isGlobal: nestedMenu.isGlobal,
          menuStructure: nestedMenu.nodes,
        };
        dispatch(setMenuNavigationStructure({ value: menuStructureToSave }));
      }
    }
  } catch (error) {
    console.log('****** loadNestedMenuStructure error: ', error);
  }
};

export const { setMenuNavigationStructure, setMenuStructure } = menuFolderSlice.actions;

export default menuFolderSlice.reducer;
