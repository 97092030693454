import React from 'react';
import GptChatBox from '@app/components/gptchatbox/GptChatBox';
import NoPaddingPageContainer from '@app/components/layouts/main/NoPaddingPageContainer/NoPaddingPageContainer';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import LlmSettingsModal from '@app/components/gptchatbox/LlmSettingsModal';

function GPTSearch() {
  const { t } = useTranslation();
  return (
    <NoPaddingPageContainer>
      <PageTitle>{t('dashboard.shortTitle')}</PageTitle>
      <GptChatBox chatMode={'CHAT_GPT'} />
      <LlmSettingsModal />
    </NoPaddingPageContainer>
  );
}

export default GPTSearch;
