import { notificationController } from '@app/controllers/notificationController';
import React, { useEffect, useState } from 'react';
import * as S from './AnalyzeTechOfferDocumentContainer.styles';
import { List, Space, Spin, Tag } from 'antd';
import { TechOfferAnalysisResult } from '@app/types/techOfferTypes';

type Props = {
  analysisResult: TechOfferAnalysisResult | null;
  loading: boolean;
};

const SidebarTechOfferAnalyzeResults: React.FC<Props> = ({ analysisResult, loading }) => {
  const [customer, setCustomer] = useState<string>('');
  const [customerAdress, setCustomerAdress] = useState<string>('');
  const [componentsData, setComponentsData] = useState<string[]>([]);

  useEffect(() => {
    if (analysisResult) {
      try {
        setCustomer(analysisResult.company);
        setCustomerAdress(analysisResult.companyAddress);
        setComponentsData(analysisResult.componentsData);
      } catch (error) {
        notificationController.error({ message: 'Leider ist beim Laden ein unerwarteter Fehler aufgetreten' });
      }
    }
  }, [analysisResult]);

  return (
    <S.SidebarContainer>
      <S.TopHeaderText size="l">Auswertung des Angebots</S.TopHeaderText>
      {analysisResult ? (
        <>
          <S.AnalyzeHeader>
            <p>Kunden-Daten</p>
          </S.AnalyzeHeader>
          <S.TextDescription>{customer}</S.TextDescription>
          <S.TextDescription>{customerAdress}</S.TextDescription>

          <S.AnalyzeHeader>
            <p>Produkt-Daten</p>
          </S.AnalyzeHeader>

          <List
            size="small"
            bordered
            dataSource={componentsData}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </>
      ) : (
        <>
          {loading ? (
            <S.LoadingContainer>
              <Spin size="large" />
              <S.LoadingText>Angebot wird verarbeitet, bitte warten ...</S.LoadingText>
            </S.LoadingContainer>
          ) : (
            <S.EmptyText size="m">Noch kein Dokument hochgeladen</S.EmptyText>
          )}
        </>
      )}
    </S.SidebarContainer>
  );
};

export default SidebarTechOfferAnalyzeResults;
