import React, { useState } from 'react';
import { Col, Row, Table, Space, Avatar } from 'antd';
import * as S from './KnowledgeFiles.styles';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useDataroomTableData } from '@app/hooks/useDataroomTableData';
import moment from 'moment';
import { themeObject } from '@app/styles/themes/themeVariables';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { LoadingContainer } from '../gpttemplates/GptTemplatesDetail.styles';
import { LoadingSpin } from '../common/LoadingSpin';
import { ModalCreateNewDataroom } from './ModalCreateNewDataroom';
import { ContainerWrapper, AreaTableCell, KnowledgeAreaLink } from './KnowledgeAreaOverview.styles';
import { Button } from '../common/buttons/Button/Button';

export const KnowledgeAreaOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const { isLoadingDataroomData, dataroomTableFields } = useDataroomTableData();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading] = useState(false);
  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);
  const theme = useAppSelector((state) => state.theme.theme);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <ContainerWrapper>
      <BaseButtonsForm.Item>
        <Row justify={'space-between'} align={'bottom'}>
          <Col>
            <NormalText bold size="xl">
              {t('dataroom.headerTitle')}
            </NormalText>
            <NormalText verticalPadding>{t('dataroom.headerDescription')}</NormalText>
          </Col>
          <Col>
            <Button type="primary" style={{ float: 'right' }} onClick={showModal}>
              {t('createKnowledgeArea')}
            </Button>
          </Col>
        </Row>
      </BaseButtonsForm.Item>
      {loading ? (
        <LoadingContainer>
          <LoadingSpin />
        </LoadingContainer>
      ) : (
        <S.KnowledgeAreWrapper>
          <Row gutter={16} align={'top'}>
            <Table
              dataSource={dataroomTableFields}
              loading={isLoadingDataroomData}
              pagination={{ hideOnSinglePage: true, defaultPageSize: 50 }}
              rowKey={(item) => `${item.knowledgeAreaId}_${item.knowledgeAreaTitle}`}
              columns={[
                {
                  title: t('dataroomTable.area') as string,
                  dataIndex: 'area',
                  key: 'area',
                  render: (_, record) => (
                    <>
                      <AreaTableCell hexColor={record.hexColor} />
                      <NormalText centered size="s" style={{ whiteSpace: 'nowrap', position: 'relative' }}>
                        {record.folderName}
                      </NormalText>
                    </>
                  ),
                  defaultSortOrder: null,
                  sorter: (a, b) => a.folderName.localeCompare(b.folderName),
                },
                {
                  title: t('dataroomTable.knowledgeArea') as string,
                  dataIndex: 'knowledgeAreaTitle',
                  key: 'knowledgeAreaTitle',
                  render: (_, record) => (
                    <KnowledgeAreaLink
                      onClick={() =>
                        navigation('/zen/dataroom-selected', {
                          state: loadedKnowledgeAreas.find((item) => item._id === record.knowledgeAreaId),
                        })
                      }
                      size="s"
                    >
                      {record.knowledgeAreaTitle}
                    </KnowledgeAreaLink>
                  ),
                  defaultSortOrder: null,
                  sorter: (a, b) => a.knowledgeAreaTitle.localeCompare(b.knowledgeAreaTitle),
                },
                {
                  title: t('dataroomTable.userHeader') as string,
                  dataIndex: 'user',
                  key: 'user',
                  render: (_, record) => (
                    <NormalText size="s">
                      {record.usersWithAccess ? `${record.usersWithAccess} ${t('dataroomTable.user')}` : '-'}
                    </NormalText>
                  ),
                  defaultSortOrder: null,
                  sorter: (a, b) => a.knowledgeAreaTitle.localeCompare(b.knowledgeAreaTitle),
                },
                {
                  title: t('dataroomTable.externalUser') as string,
                  dataIndex: 'isExternalAccess',
                  key: 'isExternalAccess',
                  render: (_, record) => (
                    <NormalText size="s">
                      {record.isExternalAccess ? t('dataroomTable.yesViaLink') : t('dataroomTable.noDisabled')}
                    </NormalText>
                  ),
                  defaultSortOrder: null,
                },
                // {
                //   title: 'Contents and last updated',
                //   dataIndex: 'content',
                //   key: 'content',
                //   defaultSortOrder: null,
                //   render: (_, record) => <NormalText size="s">{'-'}</NormalText>,
                // },
                {
                  title: t('dataroomTable.createdBy') as string,
                  dataIndex: 'userTitle',
                  key: 'userTitle',
                  defaultSortOrder: null,
                  sorter: (a, b) => a.userTitle.localeCompare(b.userTitle),
                  render: (_, record) => (
                    <Space>
                      <Avatar
                        alt="User"
                        shape="circle"
                        size={'default'}
                        src={record.userImageUrl}
                        style={{ backgroundColor: themeObject[theme].avatarBg }}
                      >
                        {record.userTitle ? record.userTitle.slice(0, 2).toUpperCase() : ''}
                      </Avatar>
                      <NormalText size="s">{record.userTitle ?? '-'}</NormalText>
                    </Space>
                  ),
                },
                {
                  title: t('dataroomTable.updatedOn') as string,
                  dataIndex: 'timestampLastUpdatedAt',
                  key: 'timestampLastUpdatedAt',
                  defaultSortOrder: null,
                  sorter: (a, b) => a.timestampLastUpdatedAt - b.timestampLastUpdatedAt,
                  render: (_, record) => (
                    <NormalText size="s">
                      {moment.unix(record.timestampLastUpdatedAt).format('DD.MM.YY HH:mm')}
                    </NormalText>
                  ),
                },
              ]}
            />
          </Row>
          <ModalCreateNewDataroom
            showCreateNewDataRoomModal={isModalVisible}
            onCloseModal={() => setIsModalVisible(false)}
          />
        </S.KnowledgeAreWrapper>
      )}
    </ContainerWrapper>
  );
};
