export type DocumentCheckOutputFormatOptions = 'Table' | 'Text';

export type DocumentSource = {
  pageContent: string;
  pageNumber: number;
  lineFrom: number;
  lineTo: number;
};

export const PostiveAnswerType = 'POSITIVE_ANSWER';
export const NegativeAnswerType = 'NEGATIVE_ANSWER';
export const UnsureAnswerType = 'NOT_CLEAR_ANSWER';
export const ErrorAnswerType = 'ERROR_WHILE_PROCESSING';

export type DocumentCheckResult = {
  topic?: string;
  criteria: string;
  isOptional: boolean;
  type?: ChecklistCriteriaType;
  answer?: string;
  answerType?: string;
  allSources?: string[];
  sources?: DocumentSource[];
  children?: DocumentCheckResult[];
  editedByUser?: string;
  editedAt?: number;
};

export type DocumentCheckFullResult = {
  foundQuestions: string[];
  results: DocumentCheckResult[];
};

export type SingleDocumentCheckFullResult = {
  documentName: string;
  result: DocumentCheckFullResult;
};

export type MultipleDocumentsCheckFullResult = {
  results: SingleDocumentCheckFullResult[];
};

export type DocumentChecklistResult = string[];

export type ChecklistCriteriaType = 'MUST' | 'SHOULD' | 'OPTIONAL';

export type DocumentChecklistElement = {
  _id: string;
  topic: string;
  criteria: string;
  furtherContext?: string;
  isActive: boolean;
  isOptional: boolean; // deprecated
  type?: ChecklistCriteriaType;
  children?: DocumentChecklistElement[];
};

export type DocumentCheckCategory = 'LEGAL_DOCUMENT' | 'TECHNICAL_DOCUMENT' | 'OTHER_DOCUMENT';

export type DocumentChecklist = {
  _id?: string;
  name: string;
  description?: string;
  createdBy: string;
  companyId: string;
  elements: DocumentChecklistElement[];
  timestamp?: number;
  category: DocumentCheckCategory;
  schemaVersion?: number;
};

export type ChecklistImportResult = {
  topic: string;
  questionOrCriteria: string;
  furtherContext: string;
};

// prepared for later development
export type DocumentChecklistPair = {
  documentName: string;
  checklist: DocumentChecklist;
};

export type DocumentReviewFlow = {
  _id?: string;
  name: string;
  description?: string;
  createdBy: string;
  companyId: string;
  documentChecklists: DocumentChecklistPair[];
  additionalContex: string;
  executeCrossCheckOnEqualCriterias: boolean;
};

type DocumentChecklistElementCreateModel = Omit<DocumentChecklistElement, '_id'>;

export interface DocumentChecklistCreateModel
  extends Pick<DocumentChecklist, 'name' | 'description' | 'category' | 'createdBy' | 'companyId' | 'schemaVersion'> {
  elements: DocumentChecklistElementCreateModel[];
}
