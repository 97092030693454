import styled from 'styled-components';
import { Row, Typography } from 'antd';
import { media } from '@app/styles/themes/constants';

export const OverviewContainer = styled.div`
  padding-top: 1rem;
`;

export const TopHeaderContainer = styled.div`
  padding: 0 40px;
`;

export const MainContainer = styled.div`
  padding: 40px;
  margin: 0 auto;
  max-width: 1600px;
`;

export const HeaderRow = styled(Row)`
  margin-bottom: 16px;
`;

export const TemplatesContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const ComingSoonText = styled(Typography.Paragraph)`
  text-align: center;
  margin-top: 60px;
  margin-bottom: 20px;
  color: var(--text-light-color);
`;

export const EmptyAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
`;

export const ImageHolder = styled.img`
  object-fit: contain;
  max-width: 50%;

  @media only screen and ${media.md} {
    max-width: 25%;
  }
`;
