import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import PageContainer from '@app/components/layouts/main/PageContainer/PageContainer';
import { TermsOfUse } from '@app/components/termsOfUseAndPrivacy/TermsOfUse';

const TermsOfUsePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer style={{ backgroundColor: 'var(--secondary-background-color)', height: '100vh' }}>
      <PageTitle>{t('signup.termOfUse')}</PageTitle>
      <TermsOfUse />
    </PageContainer>
  );
};

export default TermsOfUsePage;
