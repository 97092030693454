import React from 'react';
import { AnalyzeCaveatDocumentContainer } from '@app/components/analyzeCaveatDocumentContainer/AnalyzeCaveatDocumentContainer';
import { Layout } from 'antd';

function AnalyzeCaveatDocument() {
  return (
    <Layout.Content>
      <AnalyzeCaveatDocumentContainer index={0} />
    </Layout.Content>
  );
}

export default AnalyzeCaveatDocument;
