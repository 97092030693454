import React from 'react';

type StopRecognitionIconProps = {
  color?: string;
  width?: number;
  height?: number;
};

export const StopRecognitionIcon: React.FC<StopRecognitionIconProps> = ({ color, width, height }) => (
  <svg
    width={width ? width : '26'}
    height={height ? height : '26'}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.125 8H9.875C9.37772 8 8.90081 8.19754 8.54917 8.54917C8.19754 8.90081 8 9.37772 8 9.875V16.125C8 16.6223 8.19754 17.0992 8.54917 17.4508C8.90081 17.8025 9.37772 18 9.875 18H16.125C16.6223 18 17.0992 17.8025 17.4508 17.4508C17.8025 17.0992 18 16.6223 18 16.125V9.875C18 9.37772 17.8025 8.90081 17.4508 8.54917C17.0992 8.19754 16.6223 8 16.125 8Z"
      fill={color ? color : '#0179D0'}
    />
    <path
      d="M1 13C1 14.5759 1.31039 16.1363 1.91345 17.5922C2.5165 19.0481 3.40042 20.371 4.51472 21.4853C5.62902 22.5996 6.95189 23.4835 8.4078 24.0866C9.86371 24.6896 11.4241 25 13 25C14.5759 25 16.1363 24.6896 17.5922 24.0866C19.0481 23.4835 20.371 22.5996 21.4853 21.4853C22.5996 20.371 23.4835 19.0481 24.0866 17.5922C24.6896 16.1363 25 14.5759 25 13C25 11.4241 24.6896 9.86371 24.0866 8.4078C23.4835 6.95189 22.5996 5.62902 21.4853 4.51472C20.371 3.40042 19.0481 2.5165 17.5922 1.91345C16.1363 1.31039 14.5759 1 13 1C11.4241 1 9.86371 1.31039 8.4078 1.91345C6.95189 2.5165 5.62902 3.40042 4.51472 4.51472C3.40042 5.62902 2.5165 6.95189 1.91345 8.4078C1.31039 9.86371 1 11.4241 1 13Z"
      stroke={color ? color : '#0179D0'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
