import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setEditMessageId } from '@app/store/slices/chatBoxSlice';
import { MessageType } from '@app/types/chatBoxTypes';

const useMessageActions = (
  message: MessageType,
  onUpdateMessageText?: (messageId: string, newText: string) => void,
) => {
  const [messagedCopied, setMessageCopied] = useState(false);
  const [messagedEditMode, setMessagedEditMode] = useState(false);
  const [messagedEditText, setMessagedEditText] = useState('');
  const { editMessageId } = useAppSelector((state) => state.chatBox);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setMessagedEditText(message.text);
  }, [message.text]);

  useEffect(() => {
    if (editMessageId && editMessageId !== message.id) {
      setMessagedEditMode(false);
      setMessagedEditText(message.text);
    }
  }, [editMessageId, message.id, message.text]);

  const copyOnClick = () => {
    if (!navigator.clipboard) return;
    navigator.clipboard.writeText(message.text).then(() => {
      setMessageCopied(true);
      setTimeout(() => {
        setMessageCopied(false);
      }, 2000);
    });
  };

  const onEditClicked = () => {
    setMessagedEditMode(!messagedEditMode);
    dispatch(setEditMessageId({ value: message.id }));
  };

  const saveNewMessageText = () => {
    setMessagedEditMode(!messagedEditMode);
    onUpdateMessageText?.(message.id, messagedEditText);
  };

  return {
    messagedCopied,
    messagedEditMode,
    messagedEditText,
    setMessagedEditText,
    setMessagedEditMode,
    copyOnClick,
    onEditClicked,
    saveNewMessageText,
  };
};

export default useMessageActions;
