import React, { useEffect, useState } from 'react';
import * as S from './DocumentTemplateContainer.styles';
import { Col, Space, Table } from 'antd';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import type { UploadFile } from 'antd';
import { setCurrentSelectedTemplateFileName } from '@app/store/slices/uploadedDocumentsSlice';
import { notificationController } from '@app/controllers/notificationController';
import moment from 'moment';
import { FileUploader } from '../fileUploader/FileUploader';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { baseURL } from '@app/api/http.api';

interface AnalyzeDocumentContainerProps {
  index: number | null;
}

export interface ITemplatePlaceholder {
  key: string;
  label: string;
}

export interface IUploadedDocumentTemplate {
  _id: string;
  title: string;
  url: string;
  documentType: string;
  uploadedAt: number;
  placeholders: ITemplatePlaceholder[];
}

export const DocumentTemplateContainer: React.FC<AnalyzeDocumentContainerProps> = ({ index }) => {
  const dispatch = useAppDispatch();

  const [alreadyUploadedFiles, setAlreadyUploadedFiles] = useState<IUploadedDocumentTemplate[]>([]);

  const readAllFiles = async () => {
    const fetchUrl = `${baseURL}/api/v1/documenttemplates/readall`;

    try {
      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();

      if (result.data) {
        const uploadedFiles: IUploadedDocumentTemplate[] = [];
        result.data.map((file: IUploadedDocumentTemplate) => uploadedFiles.push(file));
        setAlreadyUploadedFiles(uploadedFiles);
      }
    } catch (error) {
      console.log('****** loadDataRoom error: ', error);
    }
  };

  useEffect(() => {
    readAllFiles();
  }, []);

  const onLoadFile = (title: string) => {
    dispatch(setCurrentSelectedTemplateFileName({ value: title }));
    notificationController.success({ message: `Die Template-Datei ${title} wurde geladen.` });
  };

  const storeInDb = async (file: UploadFile, fileUrl: string) => {
    const fetchUrl = `${baseURL}/api/v1/documenttemplates/update`;

    try {
      // TODO: adapt api for FormData
      const response = await fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: file.name,
          url: fileUrl,
          documentType: 'EMPLOYMENT_CONTRACT',
        }),
      });
      const result = await response.json();

      console.log('****** storeInDb result: ', result);
    } catch (error) {
      console.log('****** storeInDb error: ', error);
    }
  };

  const onDeleteFile = async (title: string, url: string) => {
    const fetchUrl = `${baseURL}/api/v1/documenttemplates/delete`;
    try {
      const response = await fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url,
        }),
      });
      const result = await response.json();

      console.log('****** delete result: ', result);
      readAllFiles();
    } catch (error) {
      notificationController.error({ message: `Fehler beim löschen ${error}` });
    }

    alert('TODO: add file deletion from firebase in API documenttemplates/delete');
  };

  return (
    <S.ContainerWrapper>
      <Col>
        <NormalText size="xl">{'Template-Dokument hochladen'}</NormalText>

        <FileUploader onProcessUploadedFile={storeInDb} />

        {alreadyUploadedFiles.length > 0 && (
          <Table
            dataSource={alreadyUploadedFiles.map((file) => ({
              dateUploaded: moment.unix(file.uploadedAt).format('DD/MM/YY HH:mm'),
              ...file,
            }))}
            columns={[
              {
                title: 'Name',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Hochgeladen am',
                dataIndex: 'dateUploaded',
                key: 'dateUploaded',
              },
              {
                title: 'Optionen',
                key: 'action',
                render: (_, record) => (
                  <Space size="middle">
                    <a onClick={() => onLoadFile(record.title)}>Laden</a>
                    <a onClick={() => onDeleteFile(record.title, record.url)}>Löschen</a>
                  </Space>
                ),
              },
            ]}
          />
        )}
      </Col>
    </S.ContainerWrapper>
  );
};
