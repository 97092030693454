import React from 'react';
import { Col, Row } from 'antd';
import { PasswordForm } from './passwordForm/PasswordForm/PasswordForm';

export const SecuritySettings: React.FC = () => (
  <Row gutter={[30, 0]}>
    <Col xs={24} xl={10}>
      <PasswordForm />
    </Col>
  </Row>
);
