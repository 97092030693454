import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Card } from '@app/components/common/Card/Card';
import { Tabs, Row, Col } from 'antd';
import type { TabsProps } from 'antd';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { SecuritySettings } from '@app/components/profile/profileCard/profileFormNav/nav/SecuritySettings/SecuritySettings';
import { Notifications } from '@app/components/profile/profileCard/profileFormNav/nav/notifications/Notifications/Notifications';
import { PersonalInfo } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/PersonalInfo';
import { ProfileInfo } from '@app/components/profile/profileCard/ProfileInfo/ProfileInfo';
import { BuildVersion } from '@app/constants/Build';

export const ProfileLayout: React.FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);

  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();

  const isTitleShown = isTabletOrHigher || (mobileOnly && location.pathname === '/profile');
  const isMenuShown = isTabletOrHigher || (mobileOnly && location.pathname !== '/profile');

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('profile.nav.personalInfo.title'),
      children: (
        <Row>
          {isTabletOrHigher && (
            <div style={{ marginRight: '3rem' }}>
              <ProfileInfo profileData={user} />
            </div>
          )}
          <Col span={isTabletOrHigher ? 18 : 24}>
            <PersonalInfo />
          </Col>
        </Row>
      ),
    },
    { key: '2', label: t('profile.nav.securitySettings.title'), children: <SecuritySettings /> },
    { key: '3', label: t('profile.nav.notifications.title'), children: <Notifications /> },
  ];

  return (
    <>
      <Card>
        <BaseButtonsForm.Item>
          <NormalText bold size="l">
            {t('myAccount.headerTitle')}
          </NormalText>
        </BaseButtonsForm.Item>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </Card>
      <div style={{ textAlign: 'right', marginRight: '0.5rem' }}>
        <NormalText size="xs" light colorType="light">
          Build-Version: {BuildVersion}
        </NormalText>
      </div>
    </>
  );
};
