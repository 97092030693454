import styled from 'styled-components';
import { Upload } from 'antd';
const { Dragger } = Upload;

export const FileName = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.75rem;
`;

export const FileUpladPlaceholder = styled.p`
  font-size: 1rem;
  color: var(--primary-color);
`;

export const ButtonContainer = styled.div`
  font-size: 1rem;
  color: rgb(1, 121, 208); // Color from Ant design
`;

export const DraggerStyled = styled(Dragger)`
  height: auto !important;
  border-radius: 16px;
`;
