import { useState } from 'react';

export const useIframeDebug = () => {
  const [isIframeDebugOn, setIsIframeDebugOn] = useState<boolean>(false);

  const handleIframeDebugMessages = (message: string) => {
    if (isIframeDebugOn) {
      console.log(message);
    }
  };

  return {
    setIsIframeDebugOn,
    handleIframeDebugMessages,
  };
};
