import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Button, Divider, Form } from 'antd';
import {
  ElementSelection,
  PillsWrapper,
  Pill,
  ScrollableWrapper,
  HeaderWrapper,
  IconButtonWrapper,
} from './SuperDoConstructor.styles';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '../../common/selects/BaseSelect/BaseSelect';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { SuperDoArea, SuperDoAreaAllKey, SuperDoAreaWithoutAssignmentKey } from '@app/types/superDoAreaTypes';
import { IconInputAi } from '@tabler/icons-react';
import { themeObject } from '@app/styles/themes/themeVariables';
import { AiTools } from '@app/types/superDoTemplateTypes';

type SuperDoConstructorProps = {
  inputIds: string[];
  promptValue: string;
  superDoAreas: SuperDoArea[];
  handleIsModalVisible: (state: boolean) => void;
  addInputIdToThePrompt: (inputId: string) => void;
};

export const SuperDoConstructor: React.FC<SuperDoConstructorProps> = ({
  inputIds,
  promptValue,
  superDoAreas,
  handleIsModalVisible,
  addInputIdToThePrompt,
}) => {
  const { t } = useTranslation();
  const theme = useAppSelector((state) => state.theme.theme);

  const valuesForPills = inputIds.filter((inputId) => Boolean(inputId));

  const selectRef = useRef(null);

  return (
    <ScrollableWrapper>
      <ElementSelection>
        <HeaderWrapper>
          <NormalText centered semiBold size="xl" bottomMargin="m">
            {t('createSuperDo.createNewSuperDo')}
          </NormalText>
          <IconButtonWrapper onClick={() => handleIsModalVisible(true)}>
            <IconInputAi color={themeObject[theme].primary} />
          </IconButtonWrapper>
        </HeaderWrapper>
        <Form.Item
          name={'title'}
          label={t('createSuperDo.superDoName')}
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: t('createSuperDo.errors.nameInput') }]}
        >
          <BaseInput />
        </Form.Item>
        <Form.Item name={'description'} label={t('createSuperDo.superDoDescription')} labelCol={{ span: 24 }}>
          <BaseInput.TextArea />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label={t('createSuperDo.area')}
          name={'areaId'}
          rules={[{ required: true, message: t('createSuperDo.errors.areaInput') }]}
        >
          <BaseSelect
            options={superDoAreas
              .filter((area) => ![SuperDoAreaAllKey, SuperDoAreaWithoutAssignmentKey].includes(area.areaId))
              .map((item) => ({ label: item.title, value: item.areaId }))}
          />
        </Form.Item>
        <Form.Item
          name={'promptTemplate'}
          label={t('createSuperDo.promptTemplate')}
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: t('createSuperDo.errors.promptInput') }]}
        >
          <BaseInput.TextArea rows={4} />
        </Form.Item>
        <PillsWrapper>
          {valuesForPills.map((value, index) => (
            <Pill
              key={`${index}_${value}`}
              isAddedToPrompt={promptValue.includes(value)}
              onClick={() => addInputIdToThePrompt(value)}
            >
              <NormalText size="xs">{value}</NormalText>
            </Pill>
          ))}
        </PillsWrapper>
        <Form.Item name="aiToolsToUse" label={t('createSuperDo.aiToolsToUse')} labelCol={{ span: 24 }}>
          <BaseSelect
            mode="multiple"
            ref={selectRef}
            allowClear
            style={{ width: '100%' }}
            placeholder={t('createSuperDo.aiToolsToUsePlaceholder')}
            options={AiTools.map((item) => ({ value: item.name, label: item.label }))}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <div style={{ textAlign: 'end' }}>
                  <Button
                    type="link"
                    onClick={() => {
                      //@ts-expect-error ignore
                      selectRef.current?.blur();
                    }}
                  >
                    {t('confirmSelection')}
                  </Button>
                </div>
              </>
            )}
          />
        </Form.Item>
      </ElementSelection>
    </ScrollableWrapper>
  );
};
