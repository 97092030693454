import styled from 'styled-components';

export const ColorsContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(6, 1fr);
`;

export const ColorItem = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 6px;
  background-color: ${({ color }) => color};
  cursor: pointer;
`;

export const ColorPickerWrapper = styled.div`
  width: 38px;
  height: 38px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bec0c6;
  border-radius: 8px;
`;
