import React, { useEffect } from 'react';
import * as S from './DocumentContainer.styles';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { useTranslation } from 'react-i18next';
import { Avatar, Col, Dropdown, Modal, Row, Space, Table } from 'antd';
import { Button } from '../common/buttons/Button/Button';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { useHandleChecklistResults } from '@app/hooks/useHandleChecklistResults';
import { IChecklistReviewResults } from '@app/domain/ChecklistReviewResultsModel';
import moment from 'moment';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { createSignedUrlById } from '@app/api/uploadedDocument.api';
import { shortenString } from '@app/utils/stringHelpers';

export const ChecklistReviewResultsOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useAppSelector((state) => state.theme.theme);

  const {
    checklistResults,
    checklistResultLoading,
    getUserExecutorOfChecklist,
    onDeleteChecklistResults,
    loadMoreResults,
    loadAndSetChecklistResults,
    moreChecklistResultsAvailable,
  } = useHandleChecklistResults();

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (checklistResults.length) {
      const someInProgress = () =>
        checklistResults.some(
          (result) => result.processingState === 'WAITING' || result.processingState === 'IN_PROGRESS',
        );
      if (someInProgress()) {
        interval = setInterval(() => {
          someInProgress() && loadAndSetChecklistResults();
        }, 10000);
      }
    }
    return () => interval && clearInterval(interval);
  }, [checklistResults, loadAndSetChecklistResults]);

  const menuItems = [{ label: 'Delete', key: 'delete', icon: <IconTrash size={16} /> }];

  const onDelete = async (item: IChecklistReviewResults) => {
    Modal.confirm({
      title: t('documentChecker.deleteCheckResults'),
      icon: <ExclamationCircleOutlined />,
      content: t('documentChecker.areYouSureToDeleteCheckResults'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        onDeleteChecklistResults(item._id ?? '');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const getProcessingText = (item: IChecklistReviewResults) => {
    if (item.processingState === 'WAITING') {
      return 'Waiting';
    } else if (item.processingState === 'IN_PROGRESS') {
      return 'Processing';
    } else if (item.processingState === 'FINISHED') {
      return 'Done';
    } else if (item.processingState === 'PARTLY_WITH_ERRORS') {
      return 'Partly with errors';
    } else {
      return 'Error';
    }
  };

  const getProcessingColor = (item: IChecklistReviewResults) => {
    if (item.processingState === 'WAITING') {
      return 'cornflowerblue';
    } else if (item.processingState === 'IN_PROGRESS') {
      return 'orange';
    } else if (item.processingState === 'FINISHED') {
      return 'green';
    } else if (item.processingState === 'PARTLY_WITH_ERRORS') {
      return 'rgb(190, 122, 36)';
    } else {
      return 'red';
    }
  };

  const processingInfo = (item: IChecklistReviewResults) => {
    const text = getProcessingText(item);
    const color = getProcessingColor(item);
    const showLoading = item.processingState === 'WAITING' || item.processingState === 'IN_PROGRESS';

    return (
      <Space>
        <NormalText size="s" semiBold style={{ color: color }}>
          {text}
        </NormalText>
        {showLoading && <LoadingOutlined style={{ color: color }} />}
      </Space>
    );
  };

  return (
    <S.ChecklistContainerWrapper>
      <S.HeaderContainerWrapper>
        <Row justify={'space-between'} align={'bottom'}>
          <Col>
            <NormalText bold size="xl">
              {t('documentChecker.overviewResults')}
            </NormalText>
            <NormalText verticalPadding>{t('documentChecker.overviewResultsDescription')}</NormalText>
          </Col>
          <Col>
            <Space size={'middle'}>
              <Button icon={<ReloadOutlined />} onClick={() => loadAndSetChecklistResults()}></Button>
              <Button type="primary" style={{ float: 'right' }} onClick={() => navigate('/contractanalyze?tab=1')}>
                {t('documentChecker.startNewReview')}
              </Button>
            </Space>
          </Col>
        </Row>
      </S.HeaderContainerWrapper>
      <Table
        dataSource={checklistResults}
        loading={checklistResultLoading}
        pagination={{ hideOnSinglePage: true, defaultPageSize: 50 }}
        rowKey={(item) => `${item._id}`}
        columns={[
          {
            title: t('documentChecker.documentName') as string,
            dataIndex: 'documentName',
            key: 'documentName',
            render: (_, record) => (
              <>
                {record.reviewTasks.length &&
                  record.reviewTasks[0].uploadedDocuments.map((item, index) => (
                    <Button
                      type="text"
                      key={`rev_doc_index_${index}`}
                      onClick={async () => {
                        const documentUrl = await createSignedUrlById(item._id);
                        const uploadedDocumentIds = record.reviewTasks[0].uploadedDocuments.map((item) => item._id);
                        navigate('/zen/document-review-results', {
                          state: {
                            reviewResult: record,
                            documentTitle: item.title,
                            documentUrl,
                            uploadedDocumentIds,
                          },
                        });
                      }}
                    >
                      <NormalText semiBold size="s">
                        {item.title ? shortenString(item.title, 60) : '-'}
                      </NormalText>
                    </Button>
                  ))}
              </>
            ),
            defaultSortOrder: null,
            // sorter: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: t('documentChecker.processingState') as string,
            dataIndex: 'processingState',
            key: 'processingState',
            defaultSortOrder: null,
            sorter: (a, b) => {
              const aCompare = a.processingState || 'WAITING';
              const bCompare = b.processingState || 'WAITING';
              return aCompare.localeCompare(bCompare);
            },
            render: (_, record) => processingInfo(record),
          },
          {
            title: t('documentChecker.executedBy') as string,
            dataIndex: 'createdBy',
            key: 'createdBy',
            defaultSortOrder: null,
            sorter: (a, b) => {
              const userA = getUserExecutorOfChecklist(a.createdBy);
              const userB = getUserExecutorOfChecklist(b.createdBy);
              return userA.firstName.localeCompare(userB.firstName);
            },
            render: (_, record) => {
              const user = getUserExecutorOfChecklist(record.createdBy);
              return (
                <Space>
                  <Avatar
                    alt="User"
                    shape="circle"
                    size={'default'}
                    src={user?.profileImage?.smallUrl}
                    style={{ backgroundColor: themeObject[theme].avatarBg }}
                  >
                    {user?.firstName ? user?.firstName.slice(0, 2).toUpperCase() : ''}
                  </Avatar>
                  <NormalText size="s">{user?.firstName ? `${user.firstName} ${user.lastName}` : '-'}</NormalText>
                </Space>
              );
            },
          },
          {
            title: t('documentChecker.processingDate') as string,
            dataIndex: 'processingDate',
            key: 'processingDate',
            defaultSortOrder: 'descend',
            sorter: (a, b) => (a.timestamp && b.timestamp ? a.timestamp - b.timestamp : 0),
            render: (_, record) => (
              <NormalText size="s">
                {record.timestamp ? moment.unix(record.timestamp).format('DD.MM.YY HH:mm') : '-'}
              </NormalText>
            ),
          },
          {
            title: t('documentChecker.checklistActions') as string,
            key: 'actions',
            width: '50px',
            render: (_, item) => (
              <Dropdown
                menu={{
                  items: menuItems,
                  onClick: ({ key }) => {
                    if (key === 'delete') onDelete(item);
                  },
                }}
                trigger={['click']}
              >
                <div style={{ padding: '0.5rem', cursor: 'pointer' }}>
                  <IconDotsVertical color={themeObject[theme].textLight} />
                </div>
              </Dropdown>
            ),
          },
        ]}
      />
      {moreChecklistResultsAvailable && (
        <Row justify="center" style={{ marginTop: '20px' }}>
          <Button type="ghost" onClick={loadMoreResults} disabled={checklistResultLoading}>
            {t('documentChecker.loadMore')}
          </Button>
        </Row>
      )}
    </S.ChecklistContainerWrapper>
  );
};
