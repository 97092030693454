import styled from 'styled-components';
import { BORDER_RADIUS } from '@app/styles/themes/constants';
import { Col, Tag } from 'antd';
import { NormalText } from '../common/BaseTexts/BaseTexts';

export const ContainerWrapper = styled.div`
  display: flex;
  //max-height: 90vh;
  //height: 95vh;
  padding: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background: 'var(--secondary-background-color)';
  border-radius: ${BORDER_RADIUS};
  overflow-y: auto;
`;

export const TextInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--text-superLight-color);
`;

export const TextDescription = styled.div`
  display: flex;
  margin-left: 1.25rem;
  width: 100%;
`;

export const TopHeaderText = styled(NormalText)`
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
`;

export const AnalyzeHeader = styled.div`
  margin-top: 2rem;
  font-size: 1.1rem;
  font-weight: 600;
`;

export const EmptyText = styled(NormalText)`
  margin-top: 6rem;
  font-weight: 400;
  color: gray;
  text-align: center;
`;

export const LoadingContainer = styled(Col)`
  margin-top: 6rem;
  margin-left: auto;
  margin-left: auto;
  text-align: center;
`;

export const StyledTag = styled(Tag)`
  border-radius: 6px;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
`;

export const LoadingText = styled.p`
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: gray;
  text-align: center;
`;

export const SidebarContainer = styled.div`
  padding: 20px;
  min-width: 25%;
  height: 92vh;
  margin: 0em;
  background-color: #fff;
  border-right: 2px solid #7cc5d9;
  overflow: auto;
`;
