import React from 'react';

type ExcelIconProps = {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
};

export const ExcelIcon: React.FC<ExcelIconProps> = ({ width, height, style }) => (
  <svg
    width={width ? width : '18'}
    height={height ? height : '17'}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M17.4375 14.625H9.5625C9.252 14.625 9 14.373 9 14.0625C9 13.752 9 3.123 9 2.8125C9 2.502 9.252 2.25 9.5625 2.25H17.4375C17.748 2.25 18 2.502 18 2.8125V14.0625C18 14.373 17.748 14.625 17.4375 14.625Z"
      fill="#ECEFF1"
    />
    <path
      d="M11.8125 5.625H9.5625C9.252 5.625 9 5.373 9 5.0625C9 4.752 9.252 4.5 9.5625 4.5H11.8125C12.123 4.5 12.375 4.752 12.375 5.0625C12.375 5.373 12.123 5.625 11.8125 5.625Z"
      fill="#388E3C"
    />
    <path
      d="M11.8125 7.875H9.5625C9.252 7.875 9 7.623 9 7.3125C9 7.002 9.252 6.75 9.5625 6.75H11.8125C12.123 6.75 12.375 7.002 12.375 7.3125C12.375 7.623 12.123 7.875 11.8125 7.875Z"
      fill="#388E3C"
    />
    <path
      d="M11.8125 10.125H9.5625C9.252 10.125 9 9.873 9 9.5625C9 9.252 9.252 9 9.5625 9H11.8125C12.123 9 12.375 9.252 12.375 9.5625C12.375 9.873 12.123 10.125 11.8125 10.125Z"
      fill="#388E3C"
    />
    <path
      d="M11.8125 12.375H9.5625C9.252 12.375 9 12.123 9 11.8125C9 11.502 9.252 11.25 9.5625 11.25H11.8125C12.123 11.25 12.375 11.502 12.375 11.8125C12.375 12.123 12.123 12.375 11.8125 12.375Z"
      fill="#388E3C"
    />
    <path
      d="M15.1875 5.625H14.0625C13.752 5.625 13.5 5.373 13.5 5.0625C13.5 4.752 13.752 4.5 14.0625 4.5H15.1875C15.498 4.5 15.75 4.752 15.75 5.0625C15.75 5.373 15.498 5.625 15.1875 5.625Z"
      fill="#388E3C"
    />
    <path
      d="M15.1875 7.875H14.0625C13.752 7.875 13.5 7.623 13.5 7.3125C13.5 7.002 13.752 6.75 14.0625 6.75H15.1875C15.498 6.75 15.75 7.002 15.75 7.3125C15.75 7.623 15.498 7.875 15.1875 7.875Z"
      fill="#388E3C"
    />
    <path
      d="M15.1875 10.125H14.0625C13.752 10.125 13.5 9.873 13.5 9.5625C13.5 9.252 13.752 9 14.0625 9H15.1875C15.498 9 15.75 9.252 15.75 9.5625C15.75 9.873 15.498 10.125 15.1875 10.125Z"
      fill="#388E3C"
    />
    <path
      d="M15.1875 12.375H14.0625C13.752 12.375 13.5 12.123 13.5 11.8125C13.5 11.502 13.752 11.25 14.0625 11.25H15.1875C15.498 11.25 15.75 11.502 15.75 11.8125C15.75 12.123 15.498 12.375 15.1875 12.375Z"
      fill="#388E3C"
    />
    <path
      d="M9.92138 0.130133C9.79313 0.023258 9.621 -0.022867 9.459 0.010883L0.459 1.69838C0.192375 1.74788 0 1.97963 0 2.25076V14.6258C0 14.8958 0.192375 15.1286 0.459 15.1781L9.459 16.8656C9.49275 16.8724 9.52763 16.8758 9.5625 16.8758C9.693 16.8758 9.82013 16.8308 9.92138 16.7464C10.0508 16.6395 10.125 16.4798 10.125 16.3133V0.563258C10.125 0.395633 10.0508 0.237008 9.92138 0.130133Z"
      fill="#2E7D32"
    />
    <path
      d="M7.73608 10.3168L5.95745 8.28395L7.75633 5.97095C7.94758 5.7257 7.90258 5.37245 7.65845 5.1812C7.41433 4.98995 7.06108 5.03495 6.8687 5.27908L5.20258 7.42108L3.79858 5.81683C3.5927 5.58058 3.2372 5.5592 3.00545 5.76395C2.77145 5.9687 2.74783 6.3242 2.95258 6.55708L4.49945 8.32558L2.9312 10.3416C2.73995 10.5868 2.78495 10.9401 3.02908 11.1313C3.13258 11.2112 3.2552 11.2495 3.37558 11.2495C3.5432 11.2495 3.70858 11.1752 3.81995 11.0323L5.25433 9.18733L6.89008 11.056C7.00145 11.1842 7.1567 11.2495 7.31308 11.2495C7.4447 11.2495 7.57633 11.2033 7.6832 11.11C7.9172 10.9052 7.94083 10.5497 7.73608 10.3168Z"
      fill="#FAFAFA"
    />
  </svg>
);
