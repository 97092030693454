import React from 'react';
import { message, Row, Button, Col } from 'antd';
import type { UploadProps, UploadFile } from 'antd';
import { IconFile, IconFileTypePdf, IconUpload } from '@tabler/icons-react';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { ButtonContainer, DraggerStyled, FileUpladPlaceholder } from './SingleFileUploader.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';

interface SingleFileUploaderProps {
  fileData?: LocalStoredFile;
  showPreviewButton?: boolean;
  acceptedFileTypes?: string;
  description?: string;
  secondDescription?: string;
  backgroundColor?: string;
  onFileUploaded?: (file: LocalStoredFile) => void;
  onFileRemove?: (uid: string) => void;
  onFilePreview?: (uid: string) => void;
}

export interface LocalStoredFile extends UploadFile {
  url: string;
  inputId?: string;
}

export const SingleFileUploader: React.FC<SingleFileUploaderProps> = ({
  fileData,
  showPreviewButton,
  acceptedFileTypes,
  backgroundColor,
  description,
  secondDescription,
  onFileUploaded,
  onFileRemove,
  onFilePreview,
}) => {
  const validateFileType = ({ type }: UploadFile) => {
    const allowedTypes = acceptedFileTypes ? acceptedFileTypes.split(', ') : ['application/pdf', 'pdf'];
    if (type) {
      return allowedTypes.some((allowed) => type.includes(allowed));
    }
  };

  const theme = useAppSelector((state) => state.theme.theme);

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: acceptedFileTypes ?? 'application/pdf, pdf', //allow to select just pdf files
    showUploadList: false,
    maxCount: 1,
    disabled: !onFileUploaded && !onFileRemove,
    fileList: [],
    beforeUpload: (file: UploadFile) => {
      const isAllowedType = validateFileType(file);
      if (!isAllowedType) {
        message.error('Invalid file type');
      }

      //Return false to stop request to the server. File stored locally
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // SingleFileUploader contain just one file
        const [file] = info.fileList;
        const fileData = file.originFileObj;
        const url = fileData ? URL.createObjectURL(fileData) : '';
        onFileUploaded?.({ ...info.fileList[0], url });
      }
      if (status === 'done') {
        // This case wouldn't work because we stopped request to the server in beforeUpload function
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <DraggerStyled
      {...props}
      style={{
        padding: '0 0.5rem',
        minHeight: '70px',
        borderStyle: 'solid',
        backgroundColor: backgroundColor || '#fff',
      }}
    >
      {fileData ? (
        <>
          <Row gutter={4}>
            {!acceptedFileTypes || acceptedFileTypes.includes('pdf') ? (
              <IconFileTypePdf size={'1.25rem'} />
            ) : (
              <IconFile size={'1.25rem'} />
            )}
            <NormalText size="s">{fileData?.name}</NormalText>
          </Row>
          <ButtonContainer>
            {showPreviewButton && (
              <Button
                type="link"
                size="small"
                style={{ paddingTop: '0', paddingBottom: '0', fontSize: '0.75rem' }}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation();
                  onFilePreview?.(fileData?.uid);
                }}
              >
                preview
              </Button>
            )}
            {showPreviewButton && '|'}
            <Button
              type="link"
              size="small"
              style={{ paddingTop: '0', paddingBottom: '0', fontSize: '0.75rem' }}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                onFileRemove?.(fileData?.uid);
              }}
            >
              delete
            </Button>
          </ButtonContainer>
        </>
      ) : (
        <>
          <Row justify={'center'} align={'middle'} gutter={4}>
            <Col>
              <IconUpload size={16} color={themeObject[theme].primary} />
            </Col>
            <Col>
              <FileUpladPlaceholder>{description || 'Upload document here'}</FileUpladPlaceholder>
            </Col>
          </Row>
          {secondDescription && (
            <NormalText size="s" light colorType="light">
              {secondDescription}
            </NormalText>
          )}
        </>
      )}
    </DraggerStyled>
  );
};
