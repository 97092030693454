import { Typography } from 'antd';
import styled from 'styled-components';

export const StyledCentredTitle = styled(Typography.Title)`
  text-align: center;
  margin-bottom: 20px;
`;

export const Title = styled(Typography.Title)`
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;
