import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Tabs, Button } from 'antd';
import type { TabsProps } from 'antd';
import { useResponsive } from '@app/hooks/useResponsive';
import { readApiIntegrationByKnowledgeArea } from '@app/api/apiIntegration.api';
import { getAllIngestedUploadedDocuments } from '@app/api/uploadedDocument.api';
import { notificationController } from '@app/controllers/notificationController';
import { FileType } from '@app/domain/UploadedDocumentModel';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { resetKnowledgeAreaFilter } from '@app/store/slices/knowledgeAreaFilterSlice';
import { getErrorText } from '@app/utils/apiHelpers';
import { setKnowledgeAreaFilter, KnowledgeAreaFilter } from '@app/store/slices/knowledgeAreaFilterSlice';
import { DocumentFilter } from './DocumentFilter';
import { WebsiteFilter } from './WebsiteFilter';
import { DrawerStyled, ResetButton } from './FilterDrawer.styles';
import { DocumentFilterType } from '@app/types/documentFilterTypes';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';

export interface FilterDrawerProps {
  onCloseFilterDrawer: () => void;
  showFilterDrawer: boolean;
  showValidityPeriodFields: boolean;
  selectedKnowledgeArea: KnowledgeAreaModel | null;
}

const initialFilterValues: KnowledgeAreaFilter = {
  selectedDocuments: [],
  filterDocumentTypes: [],
  selectedWebsites: [],
};

const FilterDrawer: React.FC<FilterDrawerProps> = ({
  showFilterDrawer,
  showValidityPeriodFields,
  onCloseFilterDrawer,
  selectedKnowledgeArea,
}) => {
  const [loadedFilter, setLoadedFilter] = useState<KnowledgeAreaFilter>(initialFilterValues);
  const [isLoadingWebsites, setIsLoadingWebsites] = useState<boolean>(false);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false);
  const [websites, setWebsites] = useState<DocumentFilterType[]>([]);
  const [documents, setDocuments] = useState<DocumentFilterType[]>([]);

  const knowledgeAreaFilters = useAppSelector((state) => state.knowledgeAreaFilter);

  const { mobileOnly } = useResponsive();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onChange = (key: string) => {
    console.log(key);
  };

  const updateFilter = (valuesToSave: Partial<KnowledgeAreaFilter>) => {
    const filterToSave: KnowledgeAreaFilter = { ...loadedFilter, ...valuesToSave };
    setLoadedFilter(filterToSave);
    dispatch(setKnowledgeAreaFilter({ knowledgeAreaId: selectedKnowledgeArea?._id ?? '', value: filterToSave }));
  };

  const handleResetButton = () => {
    setLoadedFilter(initialFilterValues);
    dispatch(resetKnowledgeAreaFilter({ value: selectedKnowledgeArea?._id ?? '' }));
  };

  useEffect(() => {
    const readIntegrations = async () => {
      if (selectedKnowledgeArea?._id) {
        try {
          setIsLoadingWebsites(true);
          const integrations = await readApiIntegrationByKnowledgeArea(selectedKnowledgeArea?._id);
          if (integrations.length) {
            const dataSourceToSave: DocumentFilterType[] = integrations
              .filter((item) => item.type === 'website')
              .map((source) => {
                return {
                  label: source.title,
                  id: source._id ?? '',
                  url: source.sourceUrl ?? '',
                  type: 'website',
                };
              });
            setWebsites(dataSourceToSave);
          }
        } catch (error) {
          console.log('****** readIntegrations error: ', error);
          notificationController.error({ message: getErrorText(error) });
        } finally {
          setIsLoadingWebsites(false);
        }
      }
    };

    const readAllDocuments = async () => {
      if (selectedKnowledgeArea?._id) {
        try {
          setIsLoadingDocuments(true);
          let result = await getAllIngestedUploadedDocuments(selectedKnowledgeArea?._id ?? '');
          if (result) {
            result = result.sort((a, b) => {
              return b.uploadedAt - a.uploadedAt;
            });
            setDocuments(
              result.map((document) => ({
                label: document.title,
                id: document._id ?? '',
                type: document.fileType,
                validFrom: document.validFrom,
                validUntil: document.validUntil,
              })),
            );
          }
        } catch (error) {
          console.log('****** readAllDocuments error: ', error);
          notificationController.error({ message: getErrorText(error) });
        } finally {
          setIsLoadingDocuments(false);
        }
      }
    };

    readIntegrations();
    readAllDocuments();
  }, [selectedKnowledgeArea?._id]);

  const filteredAlreadyUploadedFiles = useMemo(() => {
    return documents.filter((document) => {
      const shouldValidateType =
        document.type && document.type !== 'website' && loadedFilter.filterDocumentTypes.length > 0;
      const isValidtype = shouldValidateType
        ? document.type && loadedFilter.filterDocumentTypes.includes(document.type as FileType)
        : true;
      const shouldValidateDateFrom = document.validFrom && loadedFilter.validFrom;
      const isValidFrom = shouldValidateDateFrom
        ? document.validFrom && loadedFilter.validFrom && document.validFrom <= loadedFilter.validFrom
        : true;
      const shouldValidateDateUntil = document.validFrom && loadedFilter.validUntil;
      const isValidUntil = shouldValidateDateUntil
        ? document.validUntil && loadedFilter.validUntil && document.validUntil >= loadedFilter.validUntil
        : true;
      return isValidtype && isValidFrom && isValidUntil;
    });
  }, [documents, loadedFilter.filterDocumentTypes, loadedFilter.validFrom, loadedFilter.validUntil]);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `${t('documents')}${loadedFilter.selectedDocuments.length > 0 ? ` (${loadedFilter.selectedDocuments.length})` : ''}`,
      children: (
        <DocumentFilter
          documents={filteredAlreadyUploadedFiles}
          isLoadingDocuments={isLoadingDocuments}
          updateFilter={updateFilter}
          filterDocumentTypes={loadedFilter.filterDocumentTypes}
          selectedDocuments={loadedFilter.selectedDocuments}
          validFromDate={loadedFilter.validFrom}
          validUntilDate={loadedFilter.validUntil}
          showValidityPeriodFields={showValidityPeriodFields}
        />
      ),
    },
    {
      key: '2',
      label: `${t('websites')}${loadedFilter.selectedWebsites.length > 0 ? ` (${loadedFilter.selectedWebsites.length})` : ''}`,
      children: (
        <WebsiteFilter
          websites={websites}
          updateFilter={updateFilter}
          isLoadingWebsites={isLoadingWebsites}
          selectedWebsites={loadedFilter.selectedWebsites}
        />
      ),
    },
  ];

  useEffect(() => {
    if (selectedKnowledgeArea) {
      const knowledgeAreaFilter = knowledgeAreaFilters[selectedKnowledgeArea._id ?? ''];

      if (knowledgeAreaFilter) {
        setLoadedFilter(knowledgeAreaFilter);
      }
    }
  }, [selectedKnowledgeArea, knowledgeAreaFilters]);

  return (
    <DrawerStyled
      width={mobileOnly ? '80%' : 470}
      title={t('quickFilter')}
      placement="right"
      onClose={onCloseFilterDrawer}
      open={showFilterDrawer}
      destroyOnClose
      extra={
        <ResetButton onClick={handleResetButton} size={'small'}>
          {t('filterReset')}
        </ResetButton>
      }
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <NormalText size="m" bottomMargin={'m'} light>
        {t('whichDataShouldBeUsed')}
      </NormalText>

      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      <Button type="primary" onClick={() => onCloseFilterDrawer()}>
        {t('apply')}
      </Button>
    </DrawerStyled>
  );
};

export default FilterDrawer;
