import { IUploadedDocument, IUploadedDocumentChangeableFields } from '@app/domain/UploadedDocumentModel';
import { httpApi } from '@app/api/http.api';
import { getErrorText } from '@app/utils/apiHelpers';
import { setGlobalSessionExpired } from '@app/services/reduxStorage.service';

export type DocumentProcessingError = {
  documentTitle: string;
  errorText: string;
};

export interface UploadFileResult {
  numberProcessedFiles: number;
  processingErrors: DocumentProcessingError[];
}

export const createUploadedDocument = (formData: FormData): Promise<UploadFileResult> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/uploadedDocument/uploadAnalysisFiles', formData);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('createUploadedDocument failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const createUploadedDocumentAndIngestToVectorDb = (formData: FormData): Promise<UploadFileResult> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/uploadedDocument/uploadKnowledgeFiles', formData);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('createUploadedDocumentAndIngestToPinecone failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const createSignedUrlByPath = (fileStoragePath: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/uploadedDocument/createSignedUrlByPath', {
        fileStoragePath,
      });
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('createUploadedDocumentAndIngestToPinecone failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const createSignedUrlById = (id: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/uploadedDocument/createSignedUrlById', {
        id,
      });
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('createUploadedDocumentAndIngestToPinecone failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const deleteUploadedFileByPath = (fileStoragePath: string): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    try {
      // TODO: add api key check
      const result = await httpApi.post('/api/v1/uploadedDocument/deleteFileByPath', {
        fileStoragePath,
      });
      if (result.data?.data) return resolve();
      if (result.data?.error) return reject(result.data?.error);
      reject('deleteUploadedKnowledgeDocument failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const deleteUploadedFilesByChatId = (chatId: string): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    try {
      // TODO: add api key check
      const result = await httpApi.post('/api/v1/uploadedDocument/deleteFilesByChatId', {
        chatId,
      });
      if (result.data?.data) return resolve();
      if (result.data?.error) return reject(result.data?.error);
      reject('deleteUploadedKnowledgeDocument failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const updateFileName = (id: string, newTitle: string): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/uploadedDocument/updateFileName', {
        id,
        newTitle,
      });
      if (result.data?.data) return resolve();
      if (result.data?.error) return reject(result.data?.error);
      reject('deleteUploadedKnowledgeDocument failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const getUploadedDocumentByTitle = (title: string): Promise<IUploadedDocument> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/uploadedDocument/read/${title}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('getUploadedDocumentByTitle failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const getAllNotIngestedUploadedDocuments = (companyId: string, userId: string): Promise<IUploadedDocument[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/uploadedDocument/readAllNotIngested/${companyId}/${userId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('getAllNotIngestedUploadedDocuments failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const getAllChatUploadedDocuments = (
  companyId: string,
  userId: string,
  chatId: string,
): Promise<IUploadedDocument[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/uploadedDocument/readAllChat/${companyId}/${userId}/${chatId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('getAllNotIngestedUploadedDocuments failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const getAllIngestedUploadedDocuments = (knowledgeAreaId: string): Promise<IUploadedDocument[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      // TODO: add api key check
      const result = await httpApi.get(`/api/v1/uploadedDocument/readAllUploadedDocuments/${knowledgeAreaId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('getAllIngestedUploadedDocuments failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const updateDocumentData = (id: string, updatedFields: IUploadedDocumentChangeableFields): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/uploadedDocument/update', {
        id,
        ...updatedFields,
      });
      if (result.data?.data) return resolve();
      if (result.data?.error) return reject(result.data?.error);
      reject('deleteUploadedKnowledgeDocument failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};
