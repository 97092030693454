import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { readTeamMemberInvitation } from '@app/api/teamMemberInvitation.api';
import { TeamMemberInvitationModel } from '@app/domain/TeamMemberInvitationModel';
import { Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface SignUpFormData {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  termOfUse: boolean;
  invitationId?: string;
}

const ShowTermsOfUse = true;
const ShowDataPrivacy = false;

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');

  const [isLoading, setLoading] = useState(false);
  const [initValues, setInitValues] = useState<SignUpFormData>({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    termOfUse: false,
  });
  const [foundInvitation, setFoundInvitation] = useState<TeamMemberInvitationModel | null>(null);

  const { appName } = useAppSelector((state) => state.appLayout);

  const { t } = useTranslation();

  useEffect(() => {
    const checkInvitation = async () => {
      if (invitationId && !foundInvitation) {
        try {
          const invitation = await readTeamMemberInvitation(invitationId);
          setFoundInvitation(invitation);
        } catch (error: any) {
          console.error(error);
        }
      }
    };
    checkInvitation();
  }, [invitationId, foundInvitation, initValues]);

  const handleSubmit = (values: SignUpFormData) => {
    if (values.password.length <= 10) {
      Modal.confirm({
        title: t('chooseSecurePassword'),
        icon: <InfoCircleOutlined />,
        content: t('passwordLengthRequirement'),
        okText: t('ok'),
      });
      return;
    }
    if (!values.termOfUse) {
      Modal.confirm({
        title: t('common.info'),
        icon: <InfoCircleOutlined />,
        content: t('common.requiredTermsOfUseField'),
        okText: t('ok'),
      });
      return;
    }
    setLoading(true);
    dispatch(
      doSignUp(
        foundInvitation
          ? { ...values, email: foundInvitation.teamMemberEmail, invitationId: foundInvitation._id }
          : values,
      ),
    )
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('auth.signUpEmailVerification'),
          description: foundInvitation ? '' : t('auth.signUpEmailVerificationDescription'),
        });
        navigate('/');
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{t(foundInvitation ? 'common.signUpInvitation' : 'common.signUp', { appName: appName })}</S.Title>
        {!foundInvitation && (
          <Auth.FormItem
            name="companyName"
            label={t('common.companyName')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder={t('common.companyName')} />
          </Auth.FormItem>
        )}
        <Auth.FormItem
          name="firstName"
          label={t('common.firstName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.firstName')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="lastName"
          label={t('common.lastName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.lastName')} />
        </Auth.FormItem>
        {!foundInvitation && (
          <Auth.FormItem
            name="email"
            label={t('common.email')}
            rules={[
              { required: true, message: t('common.requiredField') },
              {
                type: 'email',
                message: t('common.notValidEmail'),
              },
            ]}
          >
            <Auth.FormInput placeholder={t('common.email')} />
          </Auth.FormItem>
        )}
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPasswordWithIndicator
            settings={{
              colorScheme: {
                levels: ['#ff4033', '#fe9a0d', '#fece0d', '#599006', '#6ecc3a'],
                noLevel: 'lightgrey',
              },
              height: 6,
              alwaysVisible: false,
            }}
            className="password"
            placeholder={t('common.password')}
          />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.confirmPassword')}
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true, message: t('common.requiredField') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
        </Auth.FormItem>
        {ShowTermsOfUse && (
          <Auth.ActionsWrapper>
            <BaseForm.Item
              name="termOfUse"
              valuePropName="checked"
              noStyle
              rules={[{ required: true, message: t('common.requiredTermsOfUseField') }]}
            >
              <Auth.FormCheckbox>
                <Auth.Text>
                  {t('signup.agree')}{' '}
                  <Link to="/terms-of-use" target={'_blank'}>
                    <Auth.LinkText>{t('signup.termOfUse')}</Auth.LinkText>
                  </Link>{' '}
                  {ShowDataPrivacy && (
                    <>
                      and{' '}
                      <Link to="/data-privacy" target={'_blank'}>
                        <Auth.LinkText>{t('signup.privacyOPolicy')}</Auth.LinkText>
                      </Link>
                    </>
                  )}
                </Auth.Text>
              </Auth.FormCheckbox>
            </BaseForm.Item>
          </Auth.ActionsWrapper>
        )}
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.signUp', { appName: appName })}
          </Auth.SubmitButton>
        </BaseForm.Item>
        {!foundInvitation && (
          <Auth.FooterWrapper>
            <Auth.Text>
              {t('signup.alreadyHaveAccount')}{' '}
              <Link to="/auth/login">
                <Auth.LinkText>{t('common.here')}</Auth.LinkText>
              </Link>
            </Auth.Text>
          </Auth.FooterWrapper>
        )}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
