import styled from 'styled-components';
import { Typography } from 'antd';

export const NavigationHeaderHeight = 70;

export const HeaderContainer = styled.div`
  padding: 12px;
  width: '100%';
  background-color: #fff;
  border-bottom: 2px solid var(--border-color);
  height: 70px;
`;

export const StyledHeaderSubTitle = styled(Typography.Text)`
  color: var(--text-light-color);
  font-size: 0.875rem;
`;

export const StyledHeaderTextContainer = styled.div`
  line-height: 1.5rem;
  margin-right: 4rem;
`;

export const StyledHeaderTitle = styled(Typography.Text)`
  color: var(--primary-color);
  font-size: 1.125rem;
`;
