import React from 'react';
import { Modal, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { createExternalAccess } from '@app/api/externalAccess.api';
import { ExternalAccessCreateModel } from '@app/types/externalAccessTypes';

type ExternalAccessModalProps = {
  isModalVisible: boolean;
  companyId: string;
  knowledgeAreaId: string;
  handleCloseModal: () => void;
  getExternalAccess: () => void;
};

export const ExternalAccessModal: React.FC<ExternalAccessModalProps> = ({
  isModalVisible,
  companyId,
  knowledgeAreaId,
  handleCloseModal,
  getExternalAccess,
}) => {
  const { t } = useTranslation();
  const { handleApiError } = useErrorHandling();

  const [form] = Form.useForm();

  const handleCancel = () => {
    handleCloseModal();
  };

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      try {
        const externalAccessData: ExternalAccessCreateModel = {
          title: values.title,
          companyId,
          entityType: 'KNOWLEDGE_AREA',
          entityId: knowledgeAreaId,
        };
        await createExternalAccess(externalAccessData);
        getExternalAccess();
        handleCloseModal();
      } catch (error) {
        if (error instanceof Error && error?.message.includes('not unique')) {
          handleApiError(t('externalAccess.titleNotUnique'));
          form.setFields([{ name: 'title', errors: [t('externalAccess.titleNotUnique')] }]);
          return;
        }
        handleApiError(error);
      }
    });
  };

  return (
    <Modal
      title={t('externalAccess.createExternalAccess')}
      open={isModalVisible}
      onOk={() => handleOk()}
      onCancel={handleCancel}
      bodyStyle={{ maxHeight: '60vh', overflowY: 'scroll' }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label={t('externalAccess.accessTitle')}
          rules={[{ required: true, message: t('externalAccess.titleRequired') }]}
        >
          <BaseInput placeholder={t('externalAccess.titlePlaceholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
