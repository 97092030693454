import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { Form, Input } from 'antd';
import * as S from './CompanyOverview.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { updateCompanyDomainSpecificTerms } from '@app/store/slices/companySlice';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { Button as GradientButton } from '@app/components/common/buttons/Button/Button';

export const CompanyDomainSpecificTerms: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const companyState = useAppSelector((state) => state.company);

  const dispatch = useAppDispatch();

  useEffect(() => {
    form.setFieldsValue({
      ...companyState,
    });
  }, [form, companyState]);

  const onResetFields = () => {
    form.resetFields();
  };

  const onSave = async () => {
    const domainSpecificTerms = form.getFieldValue('domainSpecificTerms');
    if (companyState._id) {
      await dispatch(updateCompanyDomainSpecificTerms({ domainSpecificTerms }));
      notificationController.success({ message: t('common.success') });
    } else {
      notificationController.warning({ message: t('pleaseCreateCompanyFirst') });
    }
  };

  return (
    <>
      {!companyState._id && <NormalText>{t('adminCreateCompany')}</NormalText>}
      <Col xs={24} xl={16}>
        <Form form={form}>
          <NormalText semiBold style={{ marginTop: '1rem' }}>
            {t('listDomainSpecificTerms')}
          </NormalText>
          <NormalText light colorType="light" bottomMargin="m">
            {t('listDomainSpecificTermsDescription')}
          </NormalText>
          <Form.Item key={'domainSpecificTerms'} labelCol={{ span: 24 }} name={'domainSpecificTerms'}>
            <Input.TextArea rows={12} />
          </Form.Item>
        </Form>
        <S.FooterContainer>
          <Row justify={'space-between'}>
            <Button type="dashed" onClick={() => onResetFields()}>
              {t('resetAll')}
            </Button>
            <GradientButton type="primary" onClick={() => onSave()}>
              {t('save')}
            </GradientButton>
          </Row>
        </S.FooterContainer>
      </Col>
    </>
  );
};
