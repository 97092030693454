import { Row, Space, SwitchProps } from 'antd';
import * as S from './Switch.styles';
import { NormalText } from '../BaseTexts/BaseTexts';
import React, { ReactNode } from 'react';

type CustomSwitchProps = { spaceBetween?: boolean; toolTip?: ReactNode } & SwitchProps;

export const Switch: React.FC<CustomSwitchProps> = (props) => {
  const Title = () => {
    if (props.toolTip && props.title) {
      return (
        <Space>
          <NormalText>{props.title}</NormalText>
          {props.toolTip}
        </Space>
      );
    } else if (props.title) {
      return <NormalText>{props.title}</NormalText>;
    } else {
      return null;
    }
  };

  if (props.spaceBetween && props.title) {
    return (
      <Row justify={'space-between'}>
        <Title />
        <S.Switch {...props} />
      </Row>
    );
  }
  return props.title ? (
    <Space size={'large'}>
      <Title />
      <S.Switch {...props} />
    </Space>
  ) : (
    <S.Switch {...props} />
  );
};
