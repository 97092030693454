import React from 'react';
import { Card, Col, Row, Space } from 'antd';
import * as S from './SourceUpdateCardItem.styles';
import { lightColorsTheme } from '@app/styles/themes/light/lightTheme';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { useTranslation } from 'react-i18next';

const { Meta } = Card;

type SourceUpdateCardItemProps = {
  id: string;
  title: string;
  description: string;
  executeInterval: string;
  isActive: boolean;
  lastScanDate: string;
  allSuccessful?: boolean;
  numberFailedScans?: number;
  onClick: (id: string) => void;
};

const SourceUpdateCardItem: React.FC<SourceUpdateCardItemProps> = ({
  id,
  title,
  description,
  executeInterval,
  isActive,
  lastScanDate,
  allSuccessful,
  numberFailedScans,
  onClick,
}) => {
  const { t } = useTranslation();

  const statusIcon = allSuccessful ? (
    <CheckCircleOutlined style={{ color: '#4fc854' }} />
  ) : (
    <ExclamationCircleOutlined style={{ color: '#FF7B7B' }} />
  );

  const statusText = allSuccessful
    ? t('allScansSuccessful')
    : numberFailedScans === 1
      ? `${numberFailedScans} ${t('failedScan')}`
      : `${numberFailedScans} ${t('failedScans')}`;

  return (
    <S.StyledCard
      isActive={isActive}
      bodyStyle={{
        padding: '1.0rem',
      }}
      style={{
        backgroundColor: lightColorsTheme.background,
      }}
      hoverable
      onClick={() => onClick(id)}
    >
      <Meta
        style={{
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
        }}
        title={
          <Row justify={'space-between'}>
            <Col sm={24} md={12}>
              <S.CardTitle>
                <Space direction="vertical">
                  <Row>
                    {isActive ? (
                      <Space>
                        <S.StatusBadge />
                        <NormalText semiBold size="s" color="#4fc854">
                          {executeInterval}
                        </NormalText>
                      </Space>
                    ) : (
                      <Space>
                        <S.StatusBadgeInactive />
                        <NormalText semiBold size="s" color="#B4B4B4">
                          {executeInterval}
                        </NormalText>
                      </Space>
                    )}
                  </Row>
                  {title}
                </Space>
              </S.CardTitle>
            </Col>
            <Col>
              {isActive && lastScanDate.length > 0 && (
                <Row justify={'space-between'} align={'top'} gutter={16} style={{ marginTop: '0.25rem' }}>
                  <Col>
                    <S.CardSmallDescription>{t('lastScanAt', { date: lastScanDate })}</S.CardSmallDescription>
                  </Col>
                  <Col>
                    <S.CardSmallDescription>
                      {statusIcon} {statusText}
                    </S.CardSmallDescription>
                  </Col>
                </Row>
              )}
              {isActive && lastScanDate.length === 0 && (
                <S.CardSmallDescription>{t('notScannedYet')}</S.CardSmallDescription>
              )}
            </Col>
          </Row>
        }
      />
    </S.StyledCard>
  );
};

export default SourceUpdateCardItem;
