import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SockeAppEventIds } from '@app/types/socketMessageTypes';

export type APPLICATION_CLOSED_EVENT_ID = 'APPLICATION_CLOSED_EVENT_ID';

export interface SocketAppEventState {
  triggerEventId: SockeAppEventIds | APPLICATION_CLOSED_EVENT_ID | null;
}

const initialState: SocketAppEventState = {
  triggerEventId: null,
};

export const socketAppEventSlice = createSlice({
  name: 'socketAppEventSlice',
  initialState,
  reducers: {
    setTriggerEventId: (
      state,
      { payload }: PayloadAction<{ value: SockeAppEventIds | APPLICATION_CLOSED_EVENT_ID | null }>,
    ) => {
      state.triggerEventId = payload.value;
    },
  },
});

export const { setTriggerEventId } = socketAppEventSlice.actions;

export default socketAppEventSlice.reducer;
