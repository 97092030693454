export const CaveatExampleCategories = `1. Themen-Kategorie: Umweltverträglichkeit/Qualität des UVP-Berichts
Immissionsbelastung
Datenerfassungssystem DES
Fluglärm
Bodenlärm – insbes. Vorfeldausbau
Flug- und Bodenlärmgutachten
Medizinische Aspekte
Keine Gesamtbetrachtung für Lärm
Luftschadstoffe und Gerüche
Luftqualitätsgutachten
Fehlende Gesamtbetrachtung für Luftschadstoffe (div. Quellen)
Lichtimmissionen
Sonstige Immissionen

2. Themen-Kategorie: Klima  lokal, regional, global
Landschaftspflegerischer Begleitplan, Eingriffsregelung
NATURA 2000-Schutzgebiete/FFH-Verträglichkeit; Screening
Artenschutzprüfung/Artenschutzrechtlicher Fachbeitrag
Biotopschutz

3. Themen-Kategorie: Gewässerschutz
Entwässerungsplanung
Hochwasserrisiko und -schutz
Grundwasserbelastungen
Verunreinigung von Oberflächengewässern

4. Themen-Kategorie: sonstige Einwendungen
Wirtschaftliche Aspekte
Bauphase
Allgemeine Kritik am Luftverkehr
Allgemeine politische oder rechtliche Bedenken zum Flughafenausbau
Sonstiges`;
