import styled from 'styled-components';
import { BORDER_RADIUS } from '@app/styles/themes/constants';

export const ChecklistContainerWrapper = styled.div`
  padding: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
`;

export const HeaderContainerWrapper = styled.div`
  padding-bottom: 2rem;
`;

export const FooterContainerWrapper = styled.div`
  padding: 2rem 0;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  height: calc(100vh - 58px);
  padding: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: ${BORDER_RADIUS};
  overflow-y: auto;
  background-color: var(--background-color);
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const CenteredContentWrapper = styled.div`
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
`;

export const TextInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--text-superLight-color);
`;

export const MenuItemsContainer = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  padding: 1rem 0.5rem;
  background-color: var(--secondary-background-color);
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

export const MenuItemWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const TextDescription = styled.div`
  display: flex;
  margin-left: 1.25rem;
  width: 100%;
`;

export const ChooseFileWrapper = styled('div')`
  display: grid;
  justify-items: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;
