import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { updateKnowledgeArea } from '@app/api/knowledgeArea.api';
import { Modal, Form, Input } from 'antd';
import { setLoadedKnowledgeAreas } from '@app/store/slices/knowledgeAreaSlice';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { setTriggerEventId } from '@app/store/slices/socketAppEventSlice';
import { useErrorHandling } from '@app/hooks/useErrorHandling';

type ModalEditKnowledgeAreaProps = {
  showModalEditKnowledgeArea: boolean;
  onCloseModal: () => void;
  selectedKnowledgeArea: KnowledgeAreaModel | null;
  setSelectedArea?: (knowledgeItem: KnowledgeAreaModel) => void;
};

export const ModalEditKnowledgeArea: React.FC<ModalEditKnowledgeAreaProps> = ({
  showModalEditKnowledgeArea,
  onCloseModal,
  selectedKnowledgeArea,
  setSelectedArea,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);

  const [form] = Form.useForm();

  const { handleApiError } = useErrorHandling();

  useEffect(() => {
    selectedKnowledgeArea && form.setFieldsValue({ ...selectedKnowledgeArea });
  }, [selectedKnowledgeArea]);

  const handleCloseModal = () => {
    form.resetFields();
    onCloseModal();
  };

  const handleOkEdit = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(values);
        try {
          if (selectedKnowledgeArea?._id) {
            const knowledgeItem: KnowledgeAreaModel = {
              ...selectedKnowledgeArea,
              title: values.title.trim(),
              description: values.description,
            };
            await updateKnowledgeArea(selectedKnowledgeArea?._id, knowledgeItem);

            dispatch(
              setLoadedKnowledgeAreas({
                value: loadedKnowledgeAreas.map((item) => (item._id === knowledgeItem._id ? knowledgeItem : item)),
              }),
            );
            if (setSelectedArea) {
              setSelectedArea(knowledgeItem);
            }
            dispatch(setTriggerEventId({ value: 'KNOWLEDGE_AREA_CHANGES' }));
            handleCloseModal();
          }
        } catch (error) {
          handleApiError(error);
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title={t('editKnowledgeArea')}
      open={showModalEditKnowledgeArea}
      onOk={handleOkEdit}
      onCancel={handleCloseModal}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="title" rules={[{ required: true, message: t('pleaseEnterTitle') }]}>
          <Input maxLength={50} showCount placeholder={t('knowledgeAreaTitle')} />
        </Form.Item>
        <Form.Item name="description">
          <Input placeholder={t('description')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
