import React, { useState } from 'react';
import * as S from './AnalyzeCaveatDocumentContainer.styles';
import { Col, Input, Row, Space, Table, Tabs } from 'antd';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import type { TabsProps, UploadFile } from 'antd';
import SidebarCaveatAnalyzeResults from './SidebarCaveatAnalyzeResults';
import { addCaveatAnalysisResults } from '@app/store/slices/caveatSlice';
import { notificationController } from '@app/controllers/notificationController';
import { CaveatAnalysisResult } from '@app/types/caveatTypes';
import moment from 'moment';
import { CaveatExampleCategories } from './exampleData';
import { executeCaveatDocumentAnalysis } from '@app/api/gpt.api';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { FileUploader } from '../fileUploader/FileUploader';

interface AnalyzeDocumentContainerProps {
  index: number | null;
}

export const AnalyzeCaveatDocumentContainer: React.FC<AnalyzeDocumentContainerProps> = ({ index }) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [definedCategries, setDefinedCategries] = useState(CaveatExampleCategories);

  const caveatAnalysisResults = useAppSelector((state) => state.caveat).caveatAnalysisResults;
  const [selectedAnalysisResult, setSelectedAnalysisResult] = useState<CaveatAnalysisResult | null>(null);

  const executeAnalysis = async (file: UploadFile, fileUrl: string) => {
    setLoading(true);
    setSelectedAnalysisResult(null);

    try {
      // TODO: adapt api for FormData
      const result = await executeCaveatDocumentAnalysis({
        docTitle: file.name,
        docUrl: fileUrl,
        themeCategories: definedCategries,
      });
      try {
        const parsedResult = JSON.parse(result) as CaveatAnalysisResult;
        if (parsedResult) {
          parsedResult.fileName = file.name;
          parsedResult.dateUploaded = moment().format('DD/MM/YY HH:mm');
          setSelectedAnalysisResult(parsedResult);
          dispatch(addCaveatAnalysisResults({ value: parsedResult }));
        }
      } catch (error) {
        notificationController.error({ message: 'Leider ist beim Laden ein unerwarteter Fehler aufgetreten' });
      }
    } catch (error) {
      console.log('****** caveatDocumentAnalysis error: ', error);
    } finally {
      setLoading(false);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Upload & Analyse`,
      children: (
        <Row>
          <Col span={12}>
            <S.ContainerWrapper>
              <Col>
                <NormalText size="xxl">{'Einwände-Dokumente für KI-Analyse hochladen'}</NormalText>

                <FileUploader onProcessUploadedFile={executeAnalysis} />
              </Col>
            </S.ContainerWrapper>
          </Col>
          <Col span={12}>
            <SidebarCaveatAnalyzeResults analysisResult={selectedAnalysisResult} loading={loading} />
          </Col>
        </Row>
      ),
    },
    {
      key: '2',
      label: `Definierte Kategorien`,
      children: (
        <Col span={16}>
          <S.ContainerWrapper>
            <Col>
              <NormalText size="xxl">{'Themen & Kategorien für Einwände'}</NormalText>
              <Input.TextArea
                style={{
                  height: 600,
                  width: 1200,
                  marginTop: '1rem',
                  border: '1px solid var(--primary-color)',
                  borderRadius: 8,
                }}
                value={definedCategries}
                onChange={(event) => setDefinedCategries(event.target.value)}
              />
            </Col>
          </S.ContainerWrapper>
        </Col>
      ),
    },
    {
      key: '3',
      label: `Archiv Datenbank`,
      children: (
        <Col span={24}>
          <S.ContainerWrapper>
            <Col>
              <NormalText size="xxl">{'Bereits hochgeladene Einwände'}</NormalText>

              <Table
                style={{ marginTop: 20, minWidth: '60vw' }}
                dataSource={caveatAnalysisResults}
                rowKey={(item) => `${item.fileName}`}
                columns={[
                  {
                    title: 'Dateiname',
                    dataIndex: 'fileName',
                    key: 'fileName',
                  },
                  {
                    title: 'Hochgeladen am',
                    dataIndex: 'dateUploaded',
                    key: 'dateUploaded',
                  },
                  {
                    title: 'Kategorien',
                    key: 'matchedThemes',
                    render: (_, record) => (
                      <Space size="small" wrap={true} onClick={() => setSelectedAnalysisResult(record)}>
                        {record.matchedThemes.map((item, index) => (
                          <S.StyledTag key={'tp_' + index}>{item}</S.StyledTag>
                        ))}
                      </Space>
                    ),
                  },
                ]}
              />
            </Col>
          </S.ContainerWrapper>
        </Col>
      ),
    },
  ];

  return (
    <Tabs
      tabBarStyle={{
        backgroundColor: 'white',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        marginBottom: 0,
      }}
      defaultActiveKey="1"
      items={items}
    />
  );
};
