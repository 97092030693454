import React from 'react';
import { Layout } from 'antd';
import { AnalyzeTechOfferDocumentContainer } from '@app/components/analyzeTechOfferDocumentContainer/AnalyzeTechOfferDocumentContainer';

function AnalyzeTechOfferDocument() {
  return (
    <Layout.Content>
      <AnalyzeTechOfferDocumentContainer index={0} />
    </Layout.Content>
  );
}

export default AnalyzeTechOfferDocument;
