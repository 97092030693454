import React from 'react';
import { Col, Row } from 'antd';
import { ChatIdeaBlock, ExampleHeaderWrapper, ExampleQuestionsWrapper } from './GptChatBox.styles';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { LoadingSpin } from '../common/LoadingSpin';
import { useResponsive } from '@app/hooks/useResponsive';
import { useTranslation } from 'react-i18next';

export interface PropTypes {
  knowledgeBaseMode: boolean;
  agentSearchMode: boolean;
  title: string;
  refreshing: boolean;
  onSendMessage: (message: string) => void;
}

const Examples = {
  knowledgeBaseMode: [
    'Wie funktioniert Datenschutz bei Co-Brain?',
    'Erstelle mir eine E-Mail auf Basis des Kunden-Templates',
    'Welche Projekte sind gerade aktiv?',
    'Wie erkläre ich Co-Brain Core am besten einem Kunden?',
  ],
  agentSearchMode: [
    'Welche Produkte bietet Siemens im IoT Bereich an?',
    'Welche Prognosen gibt es zu der Apple Aktie?',
    'Finde mir einen Beispielvertrag für einen IT-Freelancer auf google',
  ],
  gptSearchMode: [
    'Was sind wichtige Schritte für die Einführung eines neuen Produkts?',
    'Erzeuge eine Checkliste für die routinemäßige Wartung und Inspektion eines CNC-Drehzentrums.',
    'Schreibe einen Blog über aktuelle KI-Trends in der Industrie.',
    'Erstelle einen Projektplan für ein neues Fertigungssystem.',
  ],
};

const showExamples = false;

const EmptyGptChatBox: React.FC<PropTypes> = ({
  title,
  knowledgeBaseMode,
  agentSearchMode,
  onSendMessage,
  refreshing,
}) => {
  const toShowExamples = showExamples
    ? knowledgeBaseMode
      ? Examples.knowledgeBaseMode
      : agentSearchMode
        ? Examples.agentSearchMode
        : Examples.gptSearchMode
    : [];
  const { tabletOnly, desktopOnly } = useResponsive();
  const { t } = useTranslation();
  return (
    <div style={{ width: '100%', height: `70vh`, padding: 32, paddingTop: '25vh' }}>
      <ExampleQuestionsWrapper>
        <NormalText size={'xl'} colorType="light" light centered>
          {title}
        </NormalText>
        {knowledgeBaseMode && (
          <NormalText size={'m'} colorType="light" light centered>
            {t('enterQueryIntoSearch')}
          </NormalText>
        )}

        {!!toShowExamples.length && !refreshing && (tabletOnly || desktopOnly) && (
          <ExampleHeaderWrapper>
            <NormalText size="l" verticalPadding>
              Beispiel Fragen und Aufgaben
            </NormalText>
            <Row align={'middle'} justify={'center'} gutter={12}>
              {toShowExamples.map((example, index) => (
                <Col key={'example_question_' + index}>
                  <ChatIdeaBlock onClick={() => onSendMessage(example)}>
                    <NormalText light size="s">
                      {example}
                    </NormalText>
                  </ChatIdeaBlock>
                </Col>
              ))}
            </Row>
          </ExampleHeaderWrapper>
        )}
        {refreshing && <LoadingSpin marginTop={'5rem'} />}
      </ExampleQuestionsWrapper>
    </div>
  );
};

export default EmptyGptChatBox;
