import { SuperDoTemplate, SuperDoTemplateStore } from './superDoTemplateTypes';

export const SuperDoAreaAllKey = 'SuperDoAreaAllKey';
export const SuperDoAreaWithoutAssignmentKey = 'SuperDoAreaWithoutAssignmentKey';

export type SuperDoArea = {
  areaId: string;
  title: string;
  templates: SuperDoTemplate[];
};

export type SuperDoAreaTemplates = {
  templateAreaId: string;
  title: string;
  schemaVersion: number;
  templates: SuperDoTemplateStore[];
};

export type SuperDoAreaAllTemplates = {
  de: SuperDoAreaTemplates[];
  en: SuperDoAreaTemplates[];
};
