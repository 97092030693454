import { Space, Collapse, Image } from 'antd';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setGptChatBoxLLM } from '@app/store/slices/settingsSlice';
import { LLM_MODEL_SELECTION } from '@app/utils/constants';
import { themeObject } from '@app/styles/themes/themeVariables';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';

const { Panel } = Collapse;

export const LlmModelSection = () => {
  const { gptChatBoxLLM } = useAppSelector((state) => state.settings);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const getPanelHeader = (title: string, image: string, llmModels: { value: string; label: string }[]) => {
    const selectedModel = llmModels.find((model) => model.value === gptChatBoxLLM);
    const titlePostfix = selectedModel ? selectedModel.label : '';
    return (
      <Space align="baseline">
        <Image src={image} alt={title} preview={false} height={16} width={16} style={{ marginTop: 3 }} />
        {title}
        {titlePostfix && <NormalText color={themeObject[theme].primary}>{`- ${titlePostfix}`}</NormalText>}
      </Space>
    );
  };

  return (
    <>
      {LLM_MODEL_SELECTION.map((llmModel, index) => (
        <Collapse key={llmModel.label + index} expandIconPosition={'end'} style={{ marginBottom: '1rem' }}>
          <Panel header={getPanelHeader(llmModel.label, llmModel.image, llmModel.models)} key="1">
            {llmModel.models.map((model) => (
              <div key={model.value}>
                <Checkbox
                  checked={gptChatBoxLLM === model.value}
                  disabled={model.disabled}
                  style={{ fontSize: 12 }}
                  onChange={() => dispatch(setGptChatBoxLLM({ value: model.value }))}
                >
                  {model.label}
                </Checkbox>
              </div>
            ))}
          </Panel>
        </Collapse>
      ))}
    </>
  );
};
