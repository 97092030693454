import styled from 'styled-components';

export const NavigationWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  min-width: 210px;
  background-color: var(--secondary-background-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
`;

export const HintBoxWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  min-width: 210px;
  background-color: var(--secondary-background-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
`;
