import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import React, { useRef, useState } from 'react';
import Message from './Message';
import SendMessage from './SendMessage';
import { notificationController } from '@app/controllers/notificationController';
import { setAiGeneratedContent } from '@app/store/slices/legalDraftSlice';
import * as S from './SidebarGptChatBoxAnalyze.styles';
import { Layout } from 'antd';
import { MessageWrapper } from './GptChatBox.styles';
import { executeLegalDraftAgent } from '@app/api/gpt.api';
import { MessageType } from '@app/types/chatBoxTypes';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { v4 as uuidv4 } from 'uuid';

const waitText = 'Co-Brain schreibt ...';

const SidebarGptChatBox: React.FC = () => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const scroll = useRef<HTMLSpanElement>();

  const user = useAppSelector((state) => state.user.user);
  const { appName } = useAppSelector((state) => state.appLayout);

  const dispatch = useAppDispatch();

  const onSendMessage = (message: string) => {
    const newMessage: MessageType = {
      text: message,
      name: user?.firstName ?? 'User',
      avatar: user?.profileImage?.smallUrl ?? '',
      createdAt: Date.now(),
      uid: user?._id ?? '?',
      id: uuidv4(),
    };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);

    const sendToApi = async () => {
      const waitMessage: MessageType = {
        text: waitText,
        name: appName,
        avatar: '',
        createdAt: Date.now(),
        uid: 'chatgptagen123',
        id: uuidv4(),
      };

      setTimeout(() => {
        setMessages([...updatedMessages, waitMessage]);
        scroll.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);

      try {
        dispatch(setAiGeneratedContent({ value: 'Bitte warten ...' }));

        const data = await executeLegalDraftAgent({ queryText: message });

        if (data) {
          dispatch(setAiGeneratedContent({ value: data ?? '' }));

          const newMessage: MessageType = {
            text: 'Erledigt',
            name: appName,
            avatar: '',
            createdAt: Date.now(),
            uid: 'chatgptagen123',
            id: uuidv4(),
          };
          const updatedMessagesNew = updatedMessages.filter((m) => m.text !== waitText);
          setMessages([...updatedMessagesNew, newMessage]);
          scroll.current?.scrollIntoView({ behavior: 'smooth' });
        }
        console.log('****** data: ', data);
      } catch (error) {
        const updatedMessagesNew = updatedMessages.filter((m) => m.text !== waitText);
        setMessages([...updatedMessagesNew]);
        notificationController.error({
          message: 'Das hat leider nicht geklappt. Bitte wiederhole die Anfrage erneut.',
        });
      }
    };
    sendToApi();
  };

  return (
    <S.SidebarLeftContainer>
      <Layout style={{ width: '100%', height: `calc(100vh - 6rem)` }}>
        <Layout.Content style={S.ContentStyle}>
          <NormalText size="xxl">Dokument erstellen</NormalText>
          <MessageWrapper>
            {messages?.map((message: any) => (
              <Message key={message.id} message={message} isGenerating={message.text === waitText} />
            ))}
          </MessageWrapper>
        </Layout.Content>
        <Layout.Footer style={{ position: 'sticky', bottom: '2rem', width: '100%', padding: '4px' }}>
          <SendMessage
            scroll={scroll}
            onSendMessage={onSendMessage}
            loadingMessageExists={false}
            messageIsProcessing={false}
          />
        </Layout.Footer>
      </Layout>
    </S.SidebarLeftContainer>
  );
};

export default SidebarGptChatBox;
