import React from 'react';
import { Notifications } from '@app/components/profile/profileCard/profileFormNav/nav/notifications/Notifications/Notifications';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import PageContainer from '@app/components/layouts/main/PageContainer/PageContainer';

const NotificationsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle>{t('profile.nav.notifications.title')}</PageTitle>
      <Notifications />
    </PageContainer>
  );
};

export default NotificationsPage;
