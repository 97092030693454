import styled from 'styled-components';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';

export const ContainerWrapper = styled.div`
  padding-top: 1rem;
`;

export const AreaTableCell = styled.div<{ hexColor?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: ${({ hexColor }) => (hexColor ? hexColor : null)};
`;

export const KnowledgeAreaLink = styled(NormalText)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: var(--primary-color);
  }
`;
