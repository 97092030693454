import React, { useEffect, useState } from 'react';
import * as S from './DocumentTemplateContainer.styles';
import { Col, Space, Table } from 'antd';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import moment from 'moment';
import { BaseForm } from '../common/forms/BaseForm/BaseForm';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '../common/selects/BaseSelect/BaseSelect';
import { baseURL } from '@app/api/http.api';

export interface IDocumentPreviewValues {
  key: string;
  value: string;
}

export interface IDocumentFromTemplate {
  title: string;
  url: string;
  documentType: string;
  templateId: string;
  uploadedAt: number;
  previewValues: IDocumentPreviewValues[];
  // generated, TODO change this
  COMPANY_NAME: string;
  JOB_POSITION: string;
  GROSS_SALARY_MONTH: string;
  LOCATION: string;
}

export const DocumentArchivContainer: React.FC = () => {
  const dispatch = useAppDispatch();

  const [alreadyUploadedFiles, setAlreadyUploadedFiles] = useState<IDocumentFromTemplate[]>([]);

  const readAllFiles = async () => {
    const fetchUrl = `${baseURL}/api/v1/documentfromtemplate/readall`;

    try {
      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();

      if (result.data) {
        const uploadedFiles: IDocumentFromTemplate[] = [];
        result.data.map((file: IDocumentFromTemplate) =>
          uploadedFiles.push({
            ...file,
            COMPANY_NAME: file.previewValues.find((item) => item.key === 'COMPANY_NAME')?.value ?? '',
            JOB_POSITION: file.previewValues.find((item) => item.key === 'JOB_POSITION')?.value ?? '',
            GROSS_SALARY_MONTH: `${
              file.previewValues.find((item) => item.key === 'GROSS_SALARY_MONTH')?.value ?? '-'
            } €`,
            LOCATION: file.previewValues.find((item) => item.key === 'LOCATION')?.value ?? '',
          }),
        );
        setAlreadyUploadedFiles(uploadedFiles);
      }
    } catch (error) {
      console.log('****** loadDataRoom error: ', error);
    }
  };

  useEffect(() => {
    readAllFiles();
  }, []);

  const onSearch = (value: string) => console.log(value);

  return (
    <S.ContainerWrapper>
      <Col>
        <NormalText size="xl">{'Erstellte Dokumente und Verträge'}</NormalText>
        <S.HeaderSpacing />
        <BaseForm layout="horizontal">
          <Space direction="horizontal" size={'large'}>
            <BaseForm.Item key={'documentType'} name={'documentType'}>
              <BaseSelect
                options={[
                  { label: 'Arbeitsvertrag Mitarbeiter', key: 'EMPLOYMENT_CONTRACT' },
                  { label: 'Arbeitsvertrag Management', key: 'EMPLOYMENT_CONTRACT_MANAGEMENT' },
                ]}
                defaultValue={'Arbeitsvertrag Mitarbeiter'}
              />
            </BaseForm.Item>
            <BaseForm.Item key={'searchByMandant'} name={'searchByMandant'}>
              <BaseInput.Search placeholder="Suchen nach Mandant" onSearch={onSearch} style={{ width: 400 }} />
            </BaseForm.Item>
          </Space>
        </BaseForm>
        {alreadyUploadedFiles.length > 0 && (
          <Table
            dataSource={alreadyUploadedFiles.map((file) => ({
              dateUploaded: moment.unix(file.uploadedAt).format('DD/MM/YY HH:mm'),
              ...file,
            }))}
            columns={[
              {
                title: 'Unternehmen',
                dataIndex: 'COMPANY_NAME',
                key: 'COMPANY_NAME',
              },
              {
                title: 'Erstellt am',
                dataIndex: 'dateUploaded',
                key: 'dateUploaded',
              },
              {
                title: 'Position',
                dataIndex: 'JOB_POSITION',
                key: 'JOB_POSITION',
              },
              {
                title: 'Arbeitnehmer Brutto (Monat)',
                dataIndex: 'GROSS_SALARY_MONTH',
                key: 'GROSS_SALARY_MONTH',
              },
              {
                title: 'Niederlassung',
                dataIndex: 'LOCATION',
                key: 'LOCATION',
              },
              {
                title: 'Optionen',
                key: 'action',
                render: (_, record) => (
                  <Space size="middle">
                    <a href={record.url} download={`Doc_${record.title}`}>
                      Herunterladen
                    </a>
                  </Space>
                ),
              },
            ]}
          />
        )}
      </Col>
    </S.ContainerWrapper>
  );
};
