import React, { useState } from 'react';
import MainContent from '../MainContent/MainContent';
import * as S from './MainLayout.styles';
import { Outlet } from 'react-router-dom';

const MainLayoutWithoutSider: React.FC = () => {
  const [isTwoColumnsLayout] = useState(false);

  return (
    <S.LayoutMaster>
      <S.LayoutMain>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayoutWithoutSider;
