import styled from 'styled-components';

export const ChartContainer = styled.div`
  max-width: 700px;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InfoBlock = styled.div`
  min-width: 10rem;
  margin-right: 1.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid var(--lightgrey);
  border-radius: 6px;
  color: var(--text-sider-secondary-color);

  &:last-child {
    margin-right: 0;
  }
`;
