import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setLoadedKnowledgeAreas } from './knowledgeAreaSlice';
import { readInitialByAccessToken } from '@app/api/externalAccess.api';
import { ExternalAccess } from '@app/types/externalAccessTypes';

export interface AuthAccessTokenSlice {
  externalAccessToken: ExternalAccess | null;
  isPublicAccessMode: boolean;
}

const initialState: AuthAccessTokenSlice = {
  externalAccessToken: null,
  isPublicAccessMode: false,
};

export const doVerifyAccessToken = createAsyncThunk(
  'authToken/doVerifyAccessToken',
  async ({ accessToken }: { accessToken: string }, { dispatch }) =>
    readInitialByAccessToken(accessToken)
      .then((accessToken) => {
        return accessToken;
      })
      .catch((error) => {
        console.log('****** doVerifyAccessToken error: ', error);
        dispatch(setLoadedKnowledgeAreas({ value: [] }));
        return null;
      }),
);

const authExternalAccessTokenSlice = createSlice({
  name: 'authExternalAccessToken',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doVerifyAccessToken.fulfilled, (state, action) => {
      state.externalAccessToken = action.payload;
      state.isPublicAccessMode = !!action.payload?.accessToken;
    });
  },
});

export default authExternalAccessTokenSlice.reducer;
