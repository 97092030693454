import { media } from '@app/styles/themes/constants';
import { Typography } from 'antd';
import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';

export const Description = styled(Typography.Text)`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 1rem;

  @media only screen and ${media.md} {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

export const FooterContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: '100%';
  background-color: #fff;
`;

export const InviteButton = styled(Button)`
  margin: 1.5rem 0 1.5rem 0;
  padding: 0 1.5rem;

  @media only screen and ${media.md} {
    max-width: 10rem;
  }

  @media only screen and ${media.xl} {
    max-width: unset;
  }
`;
