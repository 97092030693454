import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import PageContainer from '@app/components/layouts/main/PageContainer/PageContainer';
import { KnowledgeAreaOverview } from '@app/components/dataroom/KnowledgeAreaOverview';

const DataroomOverviewPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle>{t('dataroom.headerTitle')}</PageTitle>
      <KnowledgeAreaOverview />
    </PageContainer>
  );
};

export default DataroomOverviewPage;
