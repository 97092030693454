import styled, { css } from 'styled-components';
import { Drawer, Button } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

export const DrawerStyled = styled(Drawer)`
  & .ant-drawer-title {
    color: var(--primary-color);
  }

  & .ant-drawer-header {
    border-bottom: none;
  }

  & .ant-collapse {
    font-size: ${FONT_SIZE.xs};
    background-color: var(--white);
  }

  & .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  & .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

export const PromptsContainer = styled.div`
  background-color: var(--secondary-background-color);
  border-radius: 8px;
  padding: 0 0 0 0.5rem;
`;

export const ResponseLengthButton = styled(Button)<{ selected: boolean }>`
  height: 50px;
  min-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 1rem;
  margin-right: 1rem;
  border-radius: 0;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: 500;
  text-align: center;
  ${({ selected }) =>
    selected &&
    css`
      background-color: #bad6ff;
      border-color: transparent;
      color: var(--primary-color);
    `};

  &:focus {
    ${({ selected }) =>
      selected &&
      css`
        background-color: #bad6ff;
      `};
  }
`;
