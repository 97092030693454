import styled from 'styled-components';

type ImageProps = { small?: boolean };

export const GeneratedImage = styled.img<ImageProps>`
  display: inline-block;
  width: ${(props) => (props.small ? '200px' : '300px')};
  height: ${(props) => (props.small ? '200px' : '300px')};
  height: auto;
  border-radius: 8px;
`;

export const LoadingFailedImage = styled.div<ImageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.small ? '200px' : '300px')};
  height: ${(props) => (props.small ? '200px' : '300px')};
  border-radius: 8px;
  background-color: var(--background-base-color);
`;
