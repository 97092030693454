import React, { useCallback, useEffect, useState } from 'react';
import { Table, Avatar, Space, Row, Modal, message } from 'antd';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { readAccessRightByEntity } from '@app/api/accessRights.api';
import { readAllExternalAccessesForCompanyByKnowledgeArea, deleteExternalAccess } from '@app/api/externalAccess.api';
import { getUsersByIds } from '@app/api/company.api';
import { UserModel } from '@app/domain/UserModel';
import { themeObject } from '@app/styles/themes/themeVariables';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { AccessRight, IAccessRights } from '@app/domain/AccessRightsModel';
import { AccessRightsHeader } from './Dataroom.styles';
import { Button as CustomButton } from '@app/components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import SearchUsersAccessRightsModal from './SearchUsersAccessRightsModal';
import { ExternalAccess } from '@app/types/externalAccessTypes';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { ExternalAccessTable } from './ExternalAccessTable';
import { ExternalAccessModal } from './ExternalAccessModal';
import { clientBaseURL } from '@app/api/http.api';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { setTriggerEventId } from '@app/store/slices/socketAppEventSlice';

type OverviewAccessRightsProps = {
  selectedKnowledgeArea: KnowledgeAreaModel | null;
};

export type AccessRightTeamMember = {
  imageUrl?: string;
  userId: string;
  teamMemberName: string;
  accessRight: AccessRight;
};

const TABLE_PAGINATION_PAGE_SIZE = 25;

const OverviewAccessRights: React.FC<OverviewAccessRightsProps> = ({ selectedKnowledgeArea }) => {
  const { t } = useTranslation();
  const [accessRightTeamMembers, setAccessRightTeamMembers] = useState<AccessRightTeamMember[]>([]);
  const [accessRight, setAccessRight] = useState<IAccessRights | null>(null);
  const [externalAccess, setExternalAccess] = useState<ExternalAccess[]>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isExternalAccessModalVisible, setIsExternalAccessModalVisible] = useState(false);

  const { handleApiError } = useErrorHandling();

  const dispatch = useAppDispatch();

  const theme = useAppSelector((state) => state.theme.theme);
  const companyState = useAppSelector((state) => state.company);

  useEffect(() => {
    readAccessRights();
    readExternalAccessInvitations();
  }, []);

  const readAccessRights = useCallback(async () => {
    if (selectedKnowledgeArea?._id && companyState._id) {
      try {
        const accessRights = await readAccessRightByEntity(
          companyState._id,
          'KNOWLEDGE_AREA',
          selectedKnowledgeArea?._id,
        );
        if (accessRights && companyState._id) {
          setAccessRight(accessRights);
          const userIds = accessRights.specificUserRights.map((user) => user.userId);
          if (userIds.length) {
            const teamMembers: UserModel[] = await getUsersByIds(userIds);
            setAccessRightTeamMembers(
              teamMembers.map((member) => ({
                userId: member._id,
                imageUrl: member.profileImage?.smallUrl,
                teamMemberName: `${member.firstName} ${member.lastName}`,
                accessRight:
                  accessRights.specificUserRights.find((user) => user.userId === member._id)?.accessRight ??
                  'READ_ONLY',
              })),
            );
          } else {
            setAccessRightTeamMembers([]);
          }
        }
      } catch (error) {
        console.log('****** readAccessRights error: ', error);
        handleApiError(error);
      }
    }
  }, [selectedKnowledgeArea, companyState]);

  const readExternalAccessInvitations = useCallback(async () => {
    if (companyState._id && selectedKnowledgeArea?._id) {
      await getExternalAccesses(companyState._id, selectedKnowledgeArea._id);
    }
  }, [companyState, selectedKnowledgeArea]);

  const getExternalAccesses = async (companyId: string, knowledgeAreaId: string) => {
    if (companyId && knowledgeAreaId) {
      try {
        const externalAccesses = await readAllExternalAccessesForCompanyByKnowledgeArea(companyId, knowledgeAreaId);
        setExternalAccess(externalAccesses);
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const handleDeleteAccess = async (accessId: string) => {
    try {
      await deleteExternalAccess(accessId);
      const externalAccessesToSave = externalAccess.filter((access) => access._id !== accessId);
      setExternalAccess(externalAccessesToSave);
    } catch (error) {
      console.log('Delete external access error', error);
      handleApiError(error);
    }
  };

  const showDeleteConfirm = (accessId: string) => {
    Modal.confirm({
      title: t('externalAccess.deleteAccessLink'),
      icon: <ExclamationCircleOutlined />,
      content: t('externalAccess.areYouSureWantDeleteAccessLink'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        handleDeleteAccess(accessId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const copyLink = (access: ExternalAccess, linkTo: 'avatar' | 'knowledge') => {
    if (!navigator.clipboard) return;

    const linkToAvatar = `${clientBaseURL}/public/demo-streaming-avatar/${selectedKnowledgeArea?._id}?token=${access.accessToken}`;
    const linkToKnowledge = `${clientBaseURL}/public/knowledge/${selectedKnowledgeArea?._id}?token=${access.accessToken}`;
    const link = linkTo === 'avatar' ? linkToAvatar : linkToKnowledge;

    navigator.clipboard.writeText(link).then(() => {
      message.success(t('externalAccess.accessLinkCopied'));
    });
  };

  const convertAccessRight = (ar: AccessRight) => {
    if (ar === 'WRITE_AND_READ') {
      return t('fullAccessDataroom');
    } else if (ar === 'READ_ONLY') {
      return t('readOnly');
    }
    return '-';
  };

  const handleOnChangeRights = () => {
    readAccessRights();
    dispatch(setTriggerEventId({ value: 'KNOWLEDGE_AREA_RIGHTS_CHANGED' }));
  };

  const columns = [
    {
      title: t('employee'),
      key: 'teamMemberName',
      defaultSortOrder: null,
      sorter: (a: AccessRightTeamMember, b: AccessRightTeamMember) => a.teamMemberName.localeCompare(b.teamMemberName),
      render: (item: AccessRightTeamMember) => (
        <Space>
          <Avatar
            alt="User"
            shape="circle"
            size={'default'}
            src={item.imageUrl}
            style={{ backgroundColor: themeObject[theme].avatarBg }}
          >
            {item.teamMemberName ? item.teamMemberName.slice(0, 2).toUpperCase() : ''}
          </Avatar>
          <NormalText size="s">{item.teamMemberName ?? '-'}</NormalText>
        </Space>
      ),
    },
    {
      title: t('rights'),
      key: 'accessRight',
      render: (item: AccessRightTeamMember) => <NormalText size="s">{convertAccessRight(item.accessRight)}</NormalText>,
    },
  ];

  const renderRightsInfo = () => {
    return (
      <AccessRightsHeader>
        <CustomButton type="primary" onClick={() => setIsModalVisible(true)}>
          {t('editTeamMembersAccess')}
        </CustomButton>
      </AccessRightsHeader>
    );
  };

  return (
    <div>
      {accessRight && renderRightsInfo()}
      <Table
        dataSource={accessRightTeamMembers.map((item) => ({ ...item }))}
        columns={columns}
        rowKey={(item) => `${item.userId}`}
        pagination={
          accessRightTeamMembers.length > TABLE_PAGINATION_PAGE_SIZE ? { pageSize: TABLE_PAGINATION_PAGE_SIZE } : false
        }
        style={{ marginBottom: '3rem' }}
      />
      <NormalText size="xl" verticalPadding>
        {t('externalAccess.title')}
      </NormalText>
      <NormalText size="s" verticalPadding>
        {t('externalAccess.description')}
      </NormalText>
      <Row justify="end" style={{ marginBottom: '1.5rem' }}>
        <CustomButton type="primary" onClick={() => setIsExternalAccessModalVisible(true)}>
          {t('externalAccess.createNewAccessLink')}
        </CustomButton>
      </Row>
      <ExternalAccessTable
        deleteAccess={(accessId: string) => showDeleteConfirm(accessId)}
        copyLink={copyLink}
        externalAccess={externalAccess}
      />
      <SearchUsersAccessRightsModal
        activeAccessRight={accessRight}
        activeAccessRightTeamMembers={accessRightTeamMembers}
        handleCloseModal={() => setIsModalVisible(false)}
        handleOnChangeRights={() => handleOnChangeRights()}
        isModalVisible={isModalVisible}
      />
      <ExternalAccessModal
        isModalVisible={isExternalAccessModalVisible}
        knowledgeAreaId={selectedKnowledgeArea?._id ?? ''}
        handleCloseModal={() => setIsExternalAccessModalVisible(false)}
        companyId={companyState._id ?? ''}
        getExternalAccess={() => getExternalAccesses(companyState._id ?? '', selectedKnowledgeArea?._id ?? '')}
      />
    </div>
  );
};

export default OverviewAccessRights;
