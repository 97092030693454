import { media } from '@app/styles/themes/constants';
import { Button, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const HeaderText = styled(Typography.Text)`
  font-size: 1.125rem;
  color: var(--text-main-color);
  padding: 0.75rem;
`;

export const ResetButton = styled(Button)`
  padding-right: 2rem;
`;

export const SettingsButtonWrapper = styled(Button)`
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 8px;
  height: 38px;
  cursor: pointer;

  @media only screen and ${media.md} {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 0.75rem;
  }
`;

export const ThinSettingsButtonWrapper = styled(SettingsButtonWrapper)`
  height: 32px;
`;

export const SettingsButtonWrapperWithIcon = styled(SettingsButtonWrapper)`
  display: flex;
  align-items: center;
`;

export const ThinSettingsButtonWrapperWithIcon = styled(ThinSettingsButtonWrapper)`
  display: flex;
  align-items: center;
`;

export const ModelName = styled(Typography.Text)`
  color: var(--primary-color);
  font-size: 0.75rem;
`;

export const SettingsButton = styled(Button)`
  height: 34px;
`;

export const MainChatContentStyle: React.CSSProperties = {
  backgroundColor: 'var(--secondary-background-color)',
  overflowY: 'auto',
};

export const HeaderFilesContentStyle: React.CSSProperties = {
  backgroundColor: 'var(--secondary-background-color)',
  padding: '4px',
};
