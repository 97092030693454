import styled from 'styled-components';
import { Col } from 'antd';

export const RootContainer = styled.div`
  height: calc(100vh - 70px);
  background-color: #fff;
`;

export const IntialMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  min-width: 500px;
  max-width: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  border: 1px solid var(--lightgrey);
  background-color: var(--secondary-background-color);
`;

export const TitleContainer = styled.div`
  background-color: #fff;
  margin: 2rem 0;
  max-width: 1000px;
  text-align: center;
`;

export const StepsContainer = styled.div`
  margin: 2rem 0;
  max-width: 1000px;
`;

export const EditorMainColumn = styled(Col)`
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  border-right: 2px solid var(--primary-color);
  background-color: #fff;
`;

export const EditorContainer = styled.div`
  flex-grow: 2;
  padding: 1rem 1rem 2rem 1rem;
  overflow-y: auto;
`;

export const EditorHeaderContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  border: 1px solid var(--lightgrey);
`;

export const FileUploaderContainer = styled.div`
  padding: 2rem 1rem;
`;

export const TextWrapper = styled.div`
  margin: 0 auto;
  padding: 2rem 0;
  max-width: 600px;
  /* overflow-y: scroll;
  height: calc(100vh - 10rem); */
`;

export const ContentWrapper = styled.div`
  height: calc(100vh - 70px);
  overflow: scroll;
`;

export const ResultItemContainer = styled.div`
  &:hover .ActionsContainer {
    display: block;
    height: 32px;
  }
  &:hover .ActionsContainerPlaceholder {
    display: none;
  }
`;

export const ActionsContainer = styled.div`
  display: none;
`;

export const ActionsContainerPlaceholder = styled.div`
  display: block;
  height: 27px;
`;

export const StatisticContainer = styled.div`
  padding: 1rem;
  margin: 1rem 0 1rem 0;
  width: 100%;
  height: 7rem;
  border-radius: 8px;
  border: 1px solid var(--lightgrey);
`;

export const BottomStatisticContainer = styled.div`
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 0rem 0 1rem 0;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--lightgrey);
`;

type ResultWrapperProps = { aiChatMode: boolean };

export const ResultWrapper = styled.div<ResultWrapperProps>`
  padding: ${(props) => (props.aiChatMode ? 0 : `1rem 2rem`)};
  height: calc(100vh - 70px);
  overflow: scroll;
  background-color: #fff;
`;

export const ResultContainer = styled.div`
  padding: 0 1rem 0 1rem;
  margin-bottom: 1rem;
  width: 100%;
  border-bottom: 1px solid var(--lightgrey);
`;

type ResultItemProps = { isSelected: boolean; isNested: boolean };

export const ResultItem = styled.div<ResultItemProps>`
  margin-bottom: 0.75rem;
  margin-left: ${(props) => (props.isNested ? '0.75rem' : '0px')};
  padding-left: ${(props) => (props.isSelected ? '0.5rem' : '0px')};
  border-left: ${(props) => (props.isSelected ? '2px solid #e5e559' : '0px')};
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const FooterContainer = styled.div`
  padding: 20px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  //border-top: 2px solid var(--border-color);
  margin-top: 1rem;
`;
