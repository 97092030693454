import React, { useEffect } from 'react';
import * as S from './DocumentContainer.styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor } from 'ckeditor5';
import { Col } from 'antd';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setAiGeneratedContent } from '@app/store/slices/legalDraftSlice';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import 'ckeditor5/ckeditor5.css';

interface DocumentContainerProps {
  index: number | null;
}

export const DocumentContainer: React.FC<DocumentContainerProps> = ({ index }) => {
  const aiGeneratedContent = useAppSelector((state) => state.legalDraft.aiGeneratedContent);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAiGeneratedContent({ value: '<p>Was soll Co-Brain für Sie erstellen?</p><p/><p/>' }));
  }, []);

  return (
    <S.ContainerWrapper>
      <Col>
        <NormalText size="xxl" verticalPadding>
          Beispiel Dokument
        </NormalText>
        <CKEditor
          editor={ClassicEditor}
          data={aiGeneratedContent ?? '<p>Was soll Co-Brain für Sie erstellen?</p><p/><p/>'}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            console.log({ data });
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
          config={{ language: 'de' }}
        />
      </Col>
    </S.ContainerWrapper>
  );
};
