import React, { useState, createContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useMenuTree } from '@app/hooks/useMenuTree';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { Col, Divider, Row, Dropdown, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { IconFolderPlus, IconPlus } from '@tabler/icons-react';
import { Tree } from 'react-arborist';
import { ModalCreateNewDataroom } from '@app/components/dataroom/ModalCreateNewDataroom';
import { ModalCreateMenuFolder } from './ModalCreateMenuFolder';
import { MenuNode } from '@app/types/menuFolderTypes';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { ModalEditKnowledgeArea } from '@app/components/dataroom/ModalEditKnowledgeArea';
import { useElementSize } from '@app/hooks/useElementSize';
import { MenuNodeItem } from './MenuNodeItem';

export type MenuActions = {
  openEditFolderModal: (updatedNode: MenuNode) => void;
  openModalCreateNewDataRoom: (folderId: string) => void;
  openModalEditKnowledgeAreaModal: (knowledgeArea: KnowledgeAreaModel | null) => void;
  onDeleteNestedMenuItem: (folderId: string) => void;
  defaultSelectedKey: string;
  isInitialLoad: boolean;
};

export const TreeActionsContext = createContext<MenuActions>({} as MenuActions);

const SiderKnowledgeMenu: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigation = useNavigate();

  const [isModalCreateDataRoomVisible, setIsModalCreateDataRoomVisible] = useState(false);
  const [isModalCreateFolderVisible, setIsModalCreateFolderVisible] = useState(false);
  const [isModalEditKnowledgeAreaVisible, setIsModalEditKnowledgeAreaVisible] = useState(false);
  const [isEditFolderMode, setIsEditFolderMode] = useState(false);
  const [folderToEdit, setFolderToEdit] = useState<MenuNode | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<string>('');
  const [selectedKnowledgeArea, setSelectedKnowledgeArea] = useState<KnowledgeAreaModel | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const targetElement = React.useRef<HTMLDivElement>(null);
  const size = useElementSize(targetElement);

  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);

  const {
    menuTree,
    isMenuTreeEmpy,
    initialHeight,
    menuItemHeight,
    height,
    handleMove,
    setHeight,
    handleFolderCreate,
    handleFolderUpdate,
    onDeleteNestedMenuItem,
    handleAddNewKnowledgeAreaToTheFolder,
    disableDrag,
    disableDrop,
    onActivateFolder,
  } = useMenuTree(loadedKnowledgeAreas);

  const currentMenuItem = loadedKnowledgeAreas.find(({ _id }) => location.pathname.includes(_id ?? ''));
  const defaultSelectedKey = currentMenuItem && currentMenuItem._id ? currentMenuItem._id : '';

  useEffect(() => {
    setTimeout(() => setIsInitialLoad(false), 800);
  }, []);

  // Handle first toggle when selected nested element
  const onToggle = (id: string) => {
    const initialOpenedNode = menuTree.find((menuNode) => menuNode.id === id);
    if (isInitialLoad && initialOpenedNode) {
      const nodeHeight = initialOpenedNode.children.length * menuItemHeight;

      setHeight(initialHeight + nodeHeight);
    }
  };

  const openEditFolderModal = (updatedNode: MenuNode) => {
    setIsEditFolderMode(true);
    setFolderToEdit(updatedNode);
    setIsModalCreateFolderVisible(true);
  };

  const openModalCreateNewDataRoom = (folderId: string) => {
    setSelectedFolder(folderId);
    setIsModalCreateDataRoomVisible(true);
  };

  const openModalEditKnowledgeAreaModal = (knowledgeArea: KnowledgeAreaModel | null) => {
    setSelectedKnowledgeArea(knowledgeArea);
    setIsModalEditKnowledgeAreaVisible(true);
  };

  const treeHight = useMemo(() => (height > 0 ? height : initialHeight), [height, initialHeight]);

  return (
    <>
      <Divider />
      <Row justify={'space-between'} align={'middle'} style={{ display: 'flex' }}>
        <Col>
          <NormalText size="m" semiBold style={{ paddingLeft: '1rem', color: 'var(--text-sider-secondary-color)' }}>
            {t('knowledgeAreas')}
          </NormalText>
        </Col>
        <Col style={{ position: 'relative', zIndex: 2 }}>
          <Dropdown
            menu={{
              items: [
                { label: t('newFolder'), key: 'folder', icon: <IconFolderPlus size={14} /> },
                { label: t('newKnowledgeArea'), key: 'area', icon: <IconPlus size={14} /> },
              ],
              onClick: ({ key }) => {
                if (key === 'folder') setIsModalCreateFolderVisible(true);
                if (key === 'area') setIsModalCreateDataRoomVisible(true);
              },
            }}
            trigger={['click']}
          >
            <Button type="text">
              <PlusCircleOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <TreeActionsContext.Provider
        value={{
          openEditFolderModal,
          openModalCreateNewDataRoom,
          openModalEditKnowledgeAreaModal,
          onDeleteNestedMenuItem,
          defaultSelectedKey,
          isInitialLoad,
        }}
      >
        <S.MenuTreeWrapper ref={targetElement}>
          <Tree<MenuNode>
            data={menuTree}
            openByDefault={false}
            width={size ? size.width : 280}
            height={treeHight}
            indent={24}
            rowHeight={menuItemHeight}
            overscanCount={1}
            paddingTop={30}
            paddingBottom={10}
            padding={25}
            disableDrag={disableDrag()}
            disableDrop={disableDrop}
            onMove={handleMove}
            onActivate={onActivateFolder}
            onToggle={onToggle}
          >
            {MenuNodeItem}
          </Tree>
        </S.MenuTreeWrapper>
        {!isInitialLoad && isMenuTreeEmpy && (
          <NormalText size="s" colorType="light" centered topMargin="l" horizontalPadding>
            {t('noKnowledgeAreasCreated')}
          </NormalText>
        )}
      </TreeActionsContext.Provider>
      <ModalCreateNewDataroom
        showCreateNewDataRoomModal={isModalCreateDataRoomVisible}
        onCloseModal={(createdKnowledeItemId?: string) => {
          setIsModalCreateDataRoomVisible(false);
          setSelectedFolder('');
          if (createdKnowledeItemId) {
            navigation(`/knowledge/${createdKnowledeItemId}`);
          }
        }}
        addCreatedKnowledgeAreaToTheFolder={(knowledgeAreaName: string, createdKnowledgeItemId?: string) => {
          handleAddNewKnowledgeAreaToTheFolder(selectedFolder, knowledgeAreaName, createdKnowledgeItemId);
        }}
        callbackOnCreate={() => setHeight((prevHeight) => prevHeight + menuItemHeight)}
      />
      <ModalCreateMenuFolder
        showCreateMenuFolderModal={isModalCreateFolderVisible}
        onCloseModal={() => {
          setIsModalCreateFolderVisible(false);
          setFolderToEdit(null);
          setIsEditFolderMode(false);
        }}
        onCreate={handleFolderCreate}
        onUpdate={handleFolderUpdate}
        isEditMode={isEditFolderMode}
        folderToEdit={folderToEdit}
      />
      <ModalEditKnowledgeArea
        showModalEditKnowledgeArea={isModalEditKnowledgeAreaVisible}
        onCloseModal={() => setIsModalEditKnowledgeAreaVisible(false)}
        selectedKnowledgeArea={selectedKnowledgeArea}
      />
    </>
  );
};

export default SiderKnowledgeMenu;
