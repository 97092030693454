import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import React, { useEffect, useState } from 'react';
import { GPT_MODEL_SELECTION } from '@app/utils/constants';
import {
  setAdvancedKnowledgeRAGActive,
  setConversationMemoryKnowledgeActive,
  setGptChatBoxLLM,
} from '@app/store/slices/settingsSlice';
import { useTranslation } from 'react-i18next';
import { setTriggerActionId } from '@app/store/slices/chatBoxSlice';
import { BaseSelect } from '../common/selects/BaseSelect/BaseSelect';
import { Switch } from '../common/Switch/Switch';
import { Form, Image, Modal, Select, Space, Tooltip } from 'antd';
import { ChatMode } from '@app/domain/ChatHistoryModel';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

export interface PropTypes {
  chatMode?: ChatMode;
}

const LlmSettingsModal: React.FC<PropTypes> = ({ chatMode }) => {
  const [settingsModalIsVisible, setSettingsModalIsVisible] = useState(false);

  const { gptChatBoxLLM, conversationMemoryKnowledgeActive, advancedKnowledgeRAGActive } = useAppSelector(
    (state) => state.settings,
  );
  const { triggerActionId } = useAppSelector((state) => state.chatBox);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (triggerActionId === 'SHOW_LLM_SETTINGS_MODAL') {
      setSettingsModalIsVisible(true);
      dispatch(setTriggerActionId({ value: null }));
    }
  }, [dispatch, triggerActionId]);

  useEffect(() => {
    form.setFieldValue('gptChatBoxLLM', gptChatBoxLLM);
    form.setFieldValue('conversationMemoryKnowledgeActive', conversationMemoryKnowledgeActive);
    form.setFieldValue('advancedKnowledgeRAGActive', advancedKnowledgeRAGActive);
  }, [gptChatBoxLLM, conversationMemoryKnowledgeActive, advancedKnowledgeRAGActive]);

  const handleOkSettings = () => {
    form
      .validateFields()
      .then(async (values) => {
        dispatch(setGptChatBoxLLM({ value: values.gptChatBoxLLM }));
        if (chatMode === 'KNOWLEDGE') {
          dispatch(setConversationMemoryKnowledgeActive({ value: values.conversationMemoryKnowledgeActive || false }));
          dispatch(setAdvancedKnowledgeRAGActive({ value: values.advancedKnowledgeRAGActive || false }));
        }
        setSettingsModalIsVisible(false);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancelSettings = () => {
    setSettingsModalIsVisible(false);
  };

  return (
    <Modal
      title={t('llmSettings')}
      open={settingsModalIsVisible}
      onOk={handleOkSettings}
      onCancel={handleCancelSettings}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="gptChatBoxLLM" rules={[{ required: true, message: t('pleaseSelectLanguageModel') }]}>
          <BaseSelect defaultValue={gptChatBoxLLM} placeholder={t('selectLanguageModel')}>
            {GPT_MODEL_SELECTION.map((item, index) => (
              <Option key={item.value + index} value={item.value} disabled={item.disabled || false}>
                <Space align="baseline">
                  <Image
                    src={item.image}
                    alt={item.label}
                    preview={false}
                    height={16}
                    width={16}
                    style={{ marginTop: 3, objectFit: 'contain' }}
                  />
                  {item.label}
                </Space>
              </Option>
            ))}
          </BaseSelect>
        </Form.Item>

        {chatMode === 'KNOWLEDGE' && (
          <>
            <Form.Item
              name={'conversationMemoryKnowledgeActive'}
              style={{ backgroundColor: 'var(--secondary-background-color)', borderRadius: 8, padding: '2px 8px' }}
            >
              <Switch
                defaultChecked={conversationMemoryKnowledgeActive}
                title={t('conversationMemoryKnowledgeActive')}
                spaceBetween
              />
            </Form.Item>
            <Form.Item
              name={'advancedKnowledgeRAGActive'}
              style={{ backgroundColor: 'var(--secondary-background-color)', borderRadius: 8, padding: '2px 8px' }}
            >
              <Switch
                defaultChecked={advancedKnowledgeRAGActive}
                title={t('advancedKnowledgeRAGActive')}
                spaceBetween
                toolTip={
                  <Tooltip title={t('advancedKnowledgeRAGActiveToolTip')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default LlmSettingsModal;
