import styled from 'styled-components';
import { Typography } from 'antd';

export const EmptyInfoText = styled(Typography.Text)`
  font-size: 1rem;
  text-align: center;
  align-items: center;
  padding: 60px;
  padding-top: 100px;
  color: var(--text-light-color);
`;
