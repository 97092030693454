import { getErrorText } from '@app/utils/apiHelpers';
import { setGlobalSessionExpired } from '@app/services/reduxStorage.service';
import { ExternalAccess, ExternalAccessCreateModel } from '@app/types/externalAccessTypes';
import { httpApi } from './http.api';

export const readAllExternalAccessesForCompanyByKnowledgeArea = (
  companyId: string,
  knowledgeAreaId: string,
): Promise<ExternalAccess[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/externalAccess/${companyId}/${knowledgeAreaId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readAllExternalAccessesForCompanyByKnowledgeArea failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const readInitialByAccessToken = (accessToken: string): Promise<ExternalAccess | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/externalAccess/initialByToken/${accessToken}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readInitialByAccessToken failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const createExternalAccess = (externalAccessData: ExternalAccessCreateModel): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(`/api/v1/externalAccess`, externalAccessData);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('createExternalAccess failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const deleteExternalAccess = (externalAccessId: string): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.delete(`/api/v1/externalAccess/${externalAccessId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('delete external access failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};
