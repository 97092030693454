import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export default styled(Content)`
  padding: 0;
  height: 100%;
  margin: 0em;
`;
