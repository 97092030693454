import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, Modal, Row } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { InviteExternalUserButton } from './AppRightsOverview.styles';
import { AppEntityIds, IAccessRights } from '@app/domain/AccessRightsModel';
import { AppRightsRowItem } from './AppRightsRowItem';
import { FilePdfOutlined, RocketOutlined, ScanOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { AccessRightTeamMember } from '../dataroom/OverviewAccessRights';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { readAccessRightByEntity } from '@app/api/accessRights.api';
import { UserModel } from '@app/domain/UserModel';
import { getUsersByIds } from '@app/api/company.api';
import SearchUsersAccessRightsModal from '../dataroom/SearchUsersAccessRightsModal';
import { useErrorHandling } from '@app/hooks/useErrorHandling';

export const AppRightsOverview: React.FC = () => {
  const { t } = useTranslation();

  const [accessRightTeamMembers, setAccessRightTeamMembers] = useState<AccessRightTeamMember[]>([]);
  const [accessRight, setAccessRight] = useState<IAccessRights | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const companyState = useAppSelector((state) => state.company);

  const { handleApiError } = useErrorHandling();

  const readAccessRights = useCallback(
    async (appId: string) => {
      if (companyState._id) {
        try {
          const accessRights = await readAccessRightByEntity(companyState._id, 'APP', appId);
          if (accessRights) {
            setAccessRight(accessRights);
            const userIds = accessRights.specificUserRights.map((user) => user.userId);
            if (userIds.length) {
              const teamMembers: UserModel[] = await getUsersByIds(userIds);
              setAccessRightTeamMembers(
                teamMembers.map((member) => ({
                  userId: member._id,
                  imageUrl: member.profileImage?.smallUrl,
                  teamMemberName: `${member.firstName} ${member.lastName}`,
                  accessRight:
                    accessRights.specificUserRights.find((user) => user.userId === member._id)?.accessRight ??
                    'READ_ONLY',
                })),
              );
            } else {
              setAccessRightTeamMembers([]);
            }
            setIsModalVisible(true);
          }
        } catch (error) {
          console.log('****** readAccessRights error: ', error);
          handleApiError(error);
        }
      }
    },
    [companyState],
  );

  const handleOnChangeRights = () => {
    // NOP
  };

  const appsData = [
    {
      appId: AppEntityIds.SecureChatGpt,
      label: t('dashboard.shortTitle'),
      description: 'AI App to use secure ChatGPT for common tasks',
      icon: <ThunderboltOutlined />,
    },
    {
      appId: AppEntityIds.SuperDos,
      label: t('gptTemplates.shortTitle'),
      description: "AI SuperDo's to boost general tasks",
      icon: <RocketOutlined />,
    },
    {
      appId: AppEntityIds.BasicDocumentAnalysis,
      label: t('contractAnalyze.shortTitle'),
      description: 'AI App for advanced document analysis',
      icon: <FilePdfOutlined />,
    },
    {
      appId: AppEntityIds.WebsiteMonitoring,
      label: t('sourceUpdateMonitoring.shortTitle'),
      description: 'AI App to create AI based reports for changes on external websites',
      icon: <ScanOutlined />,
    },
  ];

  const handleInviteClick = () => {
    Modal.confirm({
      title: t('addAiApp'),
      content: t('addAiAppMessage'),
      okText: t('ok'),
    });
  };

  return (
    <>
      <Row justify={'space-between'}>
        <NormalText semiBold size="m" style={{ marginTop: '1rem' }}>
          {t('overviewApps')}
        </NormalText>
        <InviteExternalUserButton type="primary" onClick={handleInviteClick}>
          {t('addAiApp')}
        </InviteExternalUserButton>
      </Row>

      <List
        dataSource={appsData}
        renderItem={(item, index) => (
          <AppRightsRowItem
            key={index + '_app_item'}
            appId={item.appId}
            label={item.label}
            description={item.description}
            icon={item.icon}
            onEdit={(appId) => readAccessRights(appId)}
          />
        )}
      />

      <SearchUsersAccessRightsModal
        entityLabel={appsData.find((item) => item.appId === accessRight?.entityId)?.label}
        activeAccessRight={accessRight}
        activeAccessRightTeamMembers={accessRightTeamMembers}
        handleCloseModal={() => setIsModalVisible(false)}
        handleOnChangeRights={() => handleOnChangeRights()}
        isModalVisible={isModalVisible}
      />
    </>
  );
};
