import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { Button, Row, Tabs, Dropdown, Menu, Modal, Form, Col, message } from 'antd';
import type { TabsProps } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { lightColorsTheme } from '@app/styles/themes/light/lightTheme';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { notificationController } from '@app/controllers/notificationController';
import OverviewWebsites from './OverviewWebsites';
import { ISourceUpdateMonitoring } from '@app/domain/SourceUpdateMonitoringModel';
import {
  deleteSourceUpdateMonitoring,
  executeSourceUpdateMonitoring,
  readSourceUpdateMonitoring,
} from '@app/api/sourceUpdateMonitoring.api';
import { GeneralSettings } from './GeneralSettings';

export const SourceUpdateMonitoring: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedSourceMonitoring, setSelectedSourceMonitoring] = useState<ISourceUpdateMonitoring | null>(null);

  const { id: selectedMonitoringItemId } = useParams();

  const [form] = Form.useForm();

  const navigation = useNavigate();

  const getTabIndexFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('tab-index') || '1';
  };

  const [activeTabKey, setActiveTabKey] = useState<string>(getTabIndexFromUrl());

  const onChange = (key: string) => {
    navigation(`${location.pathname}?tab-index=${key}`, { state: selectedSourceMonitoring?._id });
    setActiveTabKey(key);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        if (selectedMonitoringItemId) {
          const loadedItem = await readSourceUpdateMonitoring(selectedMonitoringItemId);
          if (loadedItem) {
            setSelectedSourceMonitoring(loadedItem);
            form.setFieldsValue({ ...loadedItem });
          }
        } else {
          notificationController.error({ message: 'No monitoring item id provided' });
        }
      } catch (error) {
        console.log('****** loadData error: ', error);
        if (error instanceof Error) {
          notificationController.error({ message: error.message });
        } else if (typeof error === 'string') {
          notificationController.error({ message: error });
        } else {
          notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
        }
      }
    };
    loadData();
    setActiveTabKey(getTabIndexFromUrl());
  }, [selectedMonitoringItemId, form]);

  const onDeleteConfirmed = async () => {
    if (selectedSourceMonitoring?._id) {
      try {
        await deleteSourceUpdateMonitoring(selectedSourceMonitoring?._id);
        navigation('/source-monitoring');
      } catch (error) {
        console.log('****** onDeleteConfirmed error: ', error);
        if (error instanceof Error) {
          notificationController.error({ message: error.message });
        } else if (typeof error === 'string') {
          notificationController.error({ message: error });
        } else {
          notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
        }
      }
    }
  };

  const onReloadSourceUpdateMonitoring = async () => {
    if (selectedSourceMonitoring?._id) {
      try {
        const reloadedItem = await readSourceUpdateMonitoring(selectedSourceMonitoring?._id);
        reloadedItem && setSelectedSourceMonitoring(reloadedItem);
      } catch (error) {
        console.log('****** onReloadSourceUpdateMonitoring error: ', error);
      }
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: t('sourceUpdateMonitoring.deleteMonitoringItem'),
      icon: <ExclamationCircleOutlined />,
      content: t('sourceUpdateMonitoring.areYouSureToDelete'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        onDeleteConfirmed();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onExecuteManualy = async () => {
    try {
      message.success(t('sourceUpdateMonitoring.monitoringStarted'));
      const changesFound = await executeSourceUpdateMonitoring(selectedSourceMonitoring?._id ?? '');
      message.success(
        t(
          changesFound
            ? 'sourceUpdateMonitoring.monitoringFinishedFoundChanges'
            : 'sourceUpdateMonitoring.monitoringFinished',
        ),
      );
      onReloadSourceUpdateMonitoring();
    } catch (error) {
      console.log('****** onExecuteManualy error: ', error);
      if (error instanceof Error) {
        notificationController.error({ message: error.message });
      } else if (typeof error === 'string') {
        notificationController.error({ message: error });
      } else {
        notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
      }
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('sourceUpdateMonitoring.generalSettings'),
      children: (
        <GeneralSettings
          selectedSourceMonitoring={selectedSourceMonitoring}
          onUpdateItem={(monitoringItem) => setSelectedSourceMonitoring(monitoringItem)}
        />
      ),
    },
    {
      key: '2',
      label: t('sourceUpdateMonitoring.websitesToMonitor'),
      children: (
        <OverviewWebsites
          selectedSourceMonitoring={selectedSourceMonitoring}
          onUpdateItem={(monitoringItem) => setSelectedSourceMonitoring(monitoringItem)}
        />
      ),
    },
  ];
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={onExecuteManualy}>
        {t('sourceUpdateMonitoring.executeManualy')}
      </Menu.Item>
      <Menu.Item key="2" danger onClick={showDeleteConfirm}>
        {t('delete')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Card>
      <Row justify="space-between" align="middle">
        <Col>
          <Row justify="start" align="middle">
            <Button
              type={'link'}
              onClick={() => navigation('/source-monitoring')}
              style={{ backgroundColor: lightColorsTheme.primaryLight, marginRight: '1rem' }}
              icon={<LeftOutlined />}
            />
            <Col>
              <NormalText bold size="l" style={{ marginRight: '2rem' }}>
                {t('sourceUpdateMonitoring.headerTitleSelectedItem', { name: selectedSourceMonitoring?.title ?? '-' })}
              </NormalText>
              <NormalText size="s">{selectedSourceMonitoring?.description}</NormalText>
            </Col>
          </Row>
        </Col>
        <Col>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>
              {t('actions')} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>

      <Tabs
        style={{ marginTop: '2rem' }}
        defaultActiveKey="1"
        items={items}
        activeKey={activeTabKey}
        onChange={onChange}
      />
    </Card>
  );
};
