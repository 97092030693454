import styled from 'styled-components';
import { Upload } from 'antd';

const { Dragger } = Upload;

export const StyledDragger = styled(Dragger)`
  margin-top: 30px;
  margin-bottom: 30px;
  max-height: 210px;
  padding: 20px;
  background-color: var(--secondary-background-color) !important;
`;
