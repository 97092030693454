import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import PageContainer from '@app/components/layouts/main/PageContainer/PageContainer';
import { UsageAnalytics } from '@app/components/usageAnalytics/UsageAnalytics';

const UsageAnalyticsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle>{t('usageAnalytics.headerTitle')}</PageTitle>
      <UsageAnalytics />
    </PageContainer>
  );
};

export default UsageAnalyticsPage;
