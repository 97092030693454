import { sendGetRequest, sendPostRequest, sendDeleteRequest } from '@app/api/http.api';
import { SystemPrompt } from '@app/types/systemPromptsTypes';

// Function to read all system prompts for a company
export const readCompanySystemPrompts = (companyId: string): Promise<SystemPrompt[]> => {
  return sendGetRequest<SystemPrompt[]>(`/api/v1/systemPrompts/${companyId}`);
};

// Function to create a new system prompt
export const createSystemPrompt = (systemPrompt: SystemPrompt): Promise<string> => {
  return sendPostRequest<SystemPrompt, string>('/api/v1/systemPrompts/create', systemPrompt);
};

// Function to update an existing system prompt
export const updateSystemPrompt = (promptId: string, updateData: Partial<SystemPrompt>): Promise<boolean> => {
  return sendPostRequest<Partial<SystemPrompt>, boolean>(`/api/v1/systemPrompts/${promptId}`, updateData);
};

// Function to delete a system prompt
export const deleteSystemPrompt = (promptId: string): Promise<void> => {
  return sendDeleteRequest<void>(`/api/v1/systemPrompts/${promptId}`);
};
