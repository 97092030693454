import { BREAKPOINTS, media } from '@app/styles/themes/constants';
import styled from 'styled-components';

type SendMessageWrapperProps = { insideSidebar: boolean };

export const SendMessageWrapper = styled('div')<SendMessageWrapperProps>`
  width: 100%;
  @media only screen and ${media.xl} {
    width: ${(props) => (props.insideSidebar ? '100%' : `${BREAKPOINTS.lg}px`)};
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SendMessageSuperDoWrapper = styled('div')`
  @media only screen and ${media.xl} {
    margin-left: auto;
    margin-right: auto;
  }
`;
