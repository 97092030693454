import { baseURL } from '@app/api/http.api';
import { Socket, io } from 'socket.io-client';

const socketUrl = `${baseURL}/api/stream`;
let socketIO: Socket | null = null;

const createSocketInstance = (socketInitCallback: (socketIO: Socket | null) => void) => {
  if (!socketIO || !socketIO.connected) {
    socketIO = io(socketUrl, { autoConnect: true, reconnectionAttempts: 10 });

    socketIO.on('connect', () => {
      console.log('Websocket is connected');
      socketInitCallback(socketIO);
    });

    socketIO.on('error', (error) => {
      console.error('Error occurred with the socket connection:', error);
    });
  } else {
    console.log('Websocket WAS already connected');
    socketInitCallback(socketIO);
  }
};

export { createSocketInstance, socketUrl };
