import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Avatar, Badge, Button, Col, Modal, Row, Space, Tag, Tooltip } from 'antd';
import React from 'react';
import { themeObject } from '@app/styles/themes/themeVariables';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { SourceDocContainer, SourceDocContainerHistory, SourceDocText } from './SourceDocument';
import { MemoizedReactMarkdown } from '../common/Markdown/MemoizedReactMarkdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeMathJaxSvg from 'rehype-mathjax';
import { CodeBlock } from '../common/Markdown/CodeBlock';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import {
  ActionsContainer,
  CancelledInfo,
  ChatBubble,
  ChatBubbleContainer,
  ChatBubbleLeft,
  ChatBubbleLeftImage,
  ChatBubbleRight,
  CopyButton,
  EditInput,
  GreenPointWrapper,
  MessageContent,
  StyledIconCheck,
  StyledLi,
  StyledOl,
  StyledP,
  StyledSourcesGrid,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledUl,
  UserName,
} from './Message.styles';
import { IconCopy, IconEdit, IconListDetails, IconRefresh } from '@tabler/icons-react';
import { MessageType, SourceDocumentType } from '@app/types/chatBoxTypes';
import { CheckCircleFilled, InfoCircleOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useMessageActions from '@app/hooks/useMessageActions';
import AgentToolMessageComponent from './AgentToolMessageComponent';
import { EXTERNAL_USER_ID } from '@app/utils/constants';
import GeneratedImageWithFallback from '../common/GeneratedImageWithFallback/GeneratedImageWithFallback';
import { SourceTooltipContainer } from './SourceTooltip';

interface KnowledgeMessageProps {
  message: MessageType;
  isGenerating?: boolean;
  isSidebarMode?: boolean;
  errorOnMessageProcessing?: boolean;
  showAllSourcesButton?: boolean;
  allowEdit?: boolean;
  onShowSources?: (allSources: SourceDocumentType[]) => void;
  onSourceClick?: (docItem: SourceDocumentType) => void;
  onInlineSourceClick?: (docItem?: SourceDocumentType) => void;
  onUpdateMessageText?: (messageId: string, newText: string) => void;
  onRefreshMessageText?: (messageId: string) => void;
  getSourceDocumentEntry?: (messageId: string, shortChunkId: string) => SourceDocumentType | undefined;
}

const KnowledgeMessage: React.FC<KnowledgeMessageProps> = ({
  message,
  isGenerating,
  isSidebarMode,
  errorOnMessageProcessing,
  showAllSourcesButton,
  onShowSources,
  onInlineSourceClick,
  allowEdit,
  onUpdateMessageText,
  onRefreshMessageText,
  getSourceDocumentEntry,
}) => {
  const user = useAppSelector((state) => state.user.user);
  const theme = useAppSelector((state) => state.theme.theme);

  const {
    messagedCopied,
    messagedEditMode,
    messagedEditText,
    setMessagedEditText,
    setMessagedEditMode,
    copyOnClick,
    onEditClicked,
    saveNewMessageText,
  } = useMessageActions(message, onUpdateMessageText);

  const { t } = useTranslation();
  const isMyMessage = message.uid === user?._id || message.uid === EXTERNAL_USER_ID;

  const renderAvatart = (message: MessageType) => {
    if (isMyMessage) {
      return message.avatar ? (
        <ChatBubbleLeftImage src={message.avatar} alt="user avatar" />
      ) : (
        <ChatBubbleLeft>
          <Avatar alt="User" shape="circle" size={30} style={{ backgroundColor: themeObject[theme].avatarBg }}>
            {user?.firstName ? user.firstName.slice(0, 2).toUpperCase() : ''}
          </Avatar>
        </ChatBubbleLeft>
      );
    }
    return null;
  };

  const renderToolAgentTool = (message: MessageType) => {
    if (message.agentToolMessages?.length) {
      return message.agentToolMessages.map((item, index) => (
        <AgentToolMessageComponent
          key={'agent_tool_' + index}
          actionType={item.actionType}
          contextType={item.contextType}
          message={item.message}
          errorMessage={item.errorMessage}
          messageType={item.messageType}
        />
      ));
    }
    return null;
  };

  const numberSources = message.sourceDocumentsUnique?.length || 0;
  const sourcesAvailable = numberSources > 0;

  const numberHistorySources = message.numberSourceDocumentsFromHistory || 0;
  const historySourcesAvailable = !sourcesAvailable && numberHistorySources > 0;

  const SkeletonAnswerText = () => <Skeleton count={5} style={{ height: 24, width: '100%', maxWidth: '600px' }} />;

  const SourcesContainer = (numberSources: number, onClick: () => void, history: boolean) => (
    <Col>
      <StyledSourcesGrid>
        {history ? (
          <SourceDocContainerHistory onClick={() => onClick()}>
            <SourceDocText>
              {numberSources}
              {numberSources === 1 ? t('shortSourceHistory') : t('shortSourcesHistory')}
            </SourceDocText>
          </SourceDocContainerHistory>
        ) : (
          <SourceDocContainer onClick={() => onClick()}>
            <SourceDocText>
              {numberSources}
              {numberSources === 1 ? t('shortSource') : t('shortSources')}
            </SourceDocText>

            <IconListDetails style={{ width: '1rem', height: '1rem', color: 'rgba(98, 98, 98, 1)' }} />
          </SourceDocContainer>
        )}
      </StyledSourcesGrid>
    </Col>
  );

  const AnswerIsGrounded = () => {
    if (message.knowledgeAnswerAnalysis) {
      if (message.knowledgeAnswerAnalysis.loading) {
        return <LoadingOutlined />;
      } else if (message.knowledgeAnswerAnalysis.answerIsGrounded) {
        return (
          <Tooltip title={t('responseBasedOnResources')}>
            <CheckCircleFilled style={{ fontSize: '1rem', color: '#9ad63a' }} />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={message.knowledgeAnswerAnalysis.reason || t('responseGroundedCheckFailed')}>
            <WarningOutlined style={{ fontSize: '1rem', color: '#dc9e20' }} />
          </Tooltip>
        );
      }
    } else {
      return null;
    }
  };

  const onHistorySourcesClicked = () => {
    Modal.confirm({
      title: t('common.info'),
      icon: <InfoCircleOutlined />,
      content: t('historySourcesNotAvailable'),
      okText: t('ok'),
    });
  };

  return (
    <ChatBubbleContainer isMyMessage={isMyMessage}>
      <ChatBubble isMyMessage={isMyMessage} isGenerating={false} isSidebarMode={isSidebarMode}>
        {renderAvatart(message)}
        <ChatBubbleRight>
          <Row justify={'space-between'} style={{ width: '100%' }}>
            <Col>
              {isMyMessage ? (
                <UserName>{message.name}</UserName>
              ) : (
                <NormalText verticalPadding size="l" bold>
                  {t('response')}
                </NormalText>
              )}
            </Col>
            {isMyMessage && !isGenerating && allowEdit && (
              <Col>
                <ActionsContainer className="ActionsContainer">
                  <CopyButton onClick={onEditClicked}>
                    <IconEdit size={16} />
                  </CopyButton>
                </ActionsContainer>
              </Col>
            )}
            {!isMyMessage && !isGenerating && (
              <Col>
                <ActionsContainer className="ActionsContainer">
                  <Row gutter={6}>
                    {onRefreshMessageText && (
                      <Col>
                        <CopyButton onClick={() => onRefreshMessageText(message.id)}>
                          <IconRefresh size={16} />
                        </CopyButton>
                      </Col>
                    )}
                    {!errorOnMessageProcessing && (
                      <Col>
                        {messagedCopied ? (
                          <StyledIconCheck size={16} />
                        ) : (
                          <CopyButton onClick={copyOnClick}>
                            <IconCopy size={16} />
                          </CopyButton>
                        )}
                      </Col>
                    )}
                  </Row>
                </ActionsContainer>
              </Col>
            )}
          </Row>

          {messagedEditMode ? (
            <Space direction="vertical">
              <EditInput
                isSidebarMode={isSidebarMode || false}
                value={messagedEditText}
                onChange={(e) => setMessagedEditText(e.target.value)}
                rows={4}
              ></EditInput>
              <Row justify={'center'} gutter={16}>
                <Col>
                  <Button size="small" type="primary" onClick={() => saveNewMessageText()}>
                    Save & submit
                  </Button>
                </Col>
                <Col>
                  <Button size="small" onClick={() => setMessagedEditMode(false)}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Space>
          ) : (
            <MessageContent>
              {message?.imageUrls &&
                message.imageUrls.map((imageUrl) => {
                  return <GeneratedImageWithFallback key={imageUrl} src={imageUrl} />;
                })}
              {message.messageIsLoading ? (
                <SkeletonAnswerText />
              ) : (
                <MemoizedReactMarkdown
                  className="prose dark:prose-invert flex-1"
                  remarkPlugins={[remarkGfm, remarkMath]}
                  rehypePlugins={[rehypeMathJaxSvg]}
                  components={{
                    a({ children, href }) {
                      //const uuidv4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
                      const cNumberRegex = /^C\d+$/;
                      if (href && children && (cNumberRegex.test(href) || href.length < 12)) {
                        const source = getSourceDocumentEntry?.(message.id, href);
                        const page = source?.page ? source.page.toString() : '';
                        const sourceTitle = source?.docTitle ? source.docTitle : '-';
                        const bodyText = source?.pageContent
                          ? source?.pageContent.length > 400
                            ? source?.pageContent.slice(0, 400) + '...'
                            : source?.pageContent
                          : '-';
                        return (
                          <Tooltip
                            title={
                              <SourceTooltipContainer
                                page={page}
                                bodyText={bodyText}
                                sourceTitle={sourceTitle}
                                type={source?.type}
                              />
                            }
                          >
                            <div
                              style={{ display: 'inline', cursor: 'pointer' }}
                              onClick={() => onInlineSourceClick?.(source)}
                            >
                              <Badge
                                count={children.toString()}
                                color={'rgba(245, 245, 245, 1)'}
                                size="small"
                                style={{
                                  fontSize: '0.75rem',
                                  color: 'rgba(98, 98, 98, 1)',
                                  borderColor: 'rgba(219, 219, 219, 1)',
                                }}
                              />
                            </div>
                          </Tooltip>
                        );
                      } else {
                        return <a href={href}>{children}</a>;
                      }
                    },
                    code({ className, children, ...props }) {
                      const childrenAny = children as any;

                      if (childrenAny && childrenAny.length) {
                        if (childrenAny[0] == '▍') {
                          return <span className="animate-pulse-cursor">▍</span>;
                        }
                        //children[0] = (children[0] as string).replace('`▍`', '▍');
                      }
                      const match = /language-(\w+)/.exec(className || '');
                      if (!match) return <code>{children}</code>;
                      return (
                        <CodeBlock
                          key={Math.random()}
                          language={(match && match[1]) || ''}
                          value={String(children).replace(/\n$/, '')}
                          {...props}
                        />
                      );
                    },
                    table({ children }) {
                      return <StyledTable>{children}</StyledTable>;
                    },
                    th({ children }) {
                      return <StyledTh>{children}</StyledTh>;
                    },
                    td({ children }) {
                      return <StyledTd>{children}</StyledTd>;
                    },
                    ul({ children }) {
                      return <StyledUl>{children}</StyledUl>;
                    },
                    ol({ children }) {
                      return <StyledOl>{children}</StyledOl>;
                    },
                    li({ children }) {
                      return <StyledLi>{children}</StyledLi>;
                    },
                    p({ children }) {
                      return <StyledP>{children}</StyledP>;
                    },
                  }}
                >
                  {`${message.text} ${message.isGenerating && !message.agentToolMessages?.length ? '`▍`' : ''}`}
                </MemoizedReactMarkdown>
              )}
              {!message.messageIsLoading &&
                message.agentToolMessages &&
                message.agentToolMessages.length > 0 &&
                renderToolAgentTool(message)}
              {!isMyMessage && !isGenerating && errorOnMessageProcessing && !message.wasCancelled && (
                <Row gutter={10} justify={'center'}>
                  <Col>
                    <InfoCircleOutlined />
                  </Col>
                  <Col>
                    <NormalText>{t('processingError')}</NormalText>
                  </Col>
                </Row>
              )}
              {!isMyMessage && !isGenerating && !message.wasCancelled && (
                <GreenPointWrapper>
                  <Row align={'middle'} gutter={20}>
                    {showAllSourcesButton &&
                      sourcesAvailable &&
                      SourcesContainer(numberSources, () => onShowSources?.(message.sourceDocumentsAll || []), false)}
                    {showAllSourcesButton &&
                      historySourcesAvailable &&
                      SourcesContainer(numberHistorySources, () => onHistorySourcesClicked(), true)}

                    <Col>{AnswerIsGrounded()}</Col>
                  </Row>
                </GreenPointWrapper>
              )}
            </MessageContent>
          )}
          {message.wasCancelled && (
            <CancelledInfo>
              <NormalText italic light size="s" colorType="light">
                {t('answerWasCancelled')}
              </NormalText>
            </CancelledInfo>
          )}
        </ChatBubbleRight>
      </ChatBubble>
    </ChatBubbleContainer>
  );
};

export default KnowledgeMessage;
