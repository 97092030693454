import React from 'react';
import { themeObject } from '@app/styles/themes/themeVariables';
import styled from 'styled-components';
import { IconBrandGoogleDrive, IconBrandNotion, IconSql, IconBug, IconCircleCheck } from '@tabler/icons-react';
import { AgentTaskType, ApiIntegrationType } from '@app/utils/constants';
import { AgentActionMessage } from '@app/types/socketMessageTypes';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import PuffLoader from 'react-spinners/PuffLoader';
import { useAppSelector } from '@app/hooks/reduxHooks';

const SearchContainer = styled('div')({
  'background-color': 'rgba(245, 245, 245, 1)',
  width: 'auto !important',
  'max-width': '400px',
  height: 'auto',
  'align-items': 'center',
  padding: '8px 8px',
  margin: '6px 2px 12px 2px',
  'border-radius': '6px',
});

const HeaderContainer = styled('div')({
  display: 'flex',
  'align-items': 'center',
  gap: '10px',
  overflow: 'hidden',
});

const StatusIcon = styled.div`
  color: ${(props) => props.color};
  margin-left: 8px;
  height: 20;
  width: 20;
`;

const AgentToolMessageComponent = ({ actionType, contextType, message, errorMessage }: AgentActionMessage) => {
  let statusIndicator;
  let messageContent;

  const theme = useAppSelector((state) => state.theme.theme);

  const getIconByToolType = (toolType: ApiIntegrationType | AgentTaskType) => {
    switch (toolType) {
      case 'ms-sql-server':
        return <IconSql />;
      case 'google-drive':
        return <IconBrandGoogleDrive />;
      case 'notion':
        return <IconBrandNotion />;
      default:
        return null;
    }
  };

  const getNameByToolType = (toolType: ApiIntegrationType | AgentTaskType) => {
    switch (toolType) {
      case 'ms-sql-server':
        return 'AI agent searches in the MS SQL database';
      case 'google-drive':
        return 'AI agent searches in Google Drive';
      case 'notion':
        return 'AI agent searches in Notion';
      case 'knowledge-search':
        return 'AI knowledge agent';
      case 'general':
        return 'AI agent';
      default:
        return null;
    }
  };

  switch (actionType) {
    case 'TOOL_STARTED':
      statusIndicator = <PuffLoader color={themeObject[theme].primary} loading={true} size={16} />;
      messageContent = (
        <NormalText size="s" light>
          {message}
        </NormalText>
      );
      break;
    case 'TOOL_FINISHED_SUCCESS':
      statusIndicator = (
        <StatusIcon color="green">
          <IconCircleCheck style={{ height: 20, width: 20 }} />
        </StatusIcon>
      );
      messageContent = (
        <NormalText size="s" light>
          {message}
        </NormalText>
      );
      break;
    case 'TOOL_FINISHED_WITH_ERROR':
      statusIndicator = (
        <StatusIcon color="red">
          <IconBug style={{ height: 20, width: 20 }} />
        </StatusIcon>
      );
      messageContent = (
        <NormalText size="s" light>
          {errorMessage}
        </NormalText>
      );
      break;
    default:
      statusIndicator = null;
  }

  return (
    <SearchContainer>
      <HeaderContainer>
        {getIconByToolType(contextType)}
        <NormalText semiBold size="s">
          {getNameByToolType(contextType)}
        </NormalText>
        {statusIndicator}
      </HeaderContainer>
      {messageContent}
    </SearchContainer>
  );
};

export default AgentToolMessageComponent;
