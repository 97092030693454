import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Row, Col, Input, Divider } from 'antd';
import { LoadingSpin } from '../common/LoadingSpin';
import { useTranslation } from 'react-i18next';
import { SuperDoTemplateStore } from '@app/types/superDoTemplateTypes';
import { readAllSuperDoTeamplates } from '@app/api/superDoArea.api';
import SuperDoItem from './SuperDoItem/SuperDoItem';
import { SuperDoAreaTemplates } from '@app/types/superDoAreaTypes';
import debounce from 'lodash/debounce';
import { useLanguage } from '@app/hooks/useLanguage';

interface SuperDoStoreModalProps {
  isVisible: boolean;
  onAddSuperDo: (superDo: SuperDoTemplateStore, areaTitle: string) => void;
  onCreateNewSuperDo: () => void;
  onCancel: () => void;
}

const SuperDoStoreModal: React.FC<SuperDoStoreModalProps> = ({
  isVisible,
  onAddSuperDo,
  onCreateNewSuperDo,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [allTemplates, setAllTemplates] = useState<SuperDoAreaTemplates[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();

  const { language } = useLanguage();

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      try {
        const templatesData = await readAllSuperDoTeamplates();
        setAllTemplates(language === 'de' ? templatesData.de : templatesData.en);
      } catch (error) {
        console.error('Failed to fetch templates:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isVisible) {
      fetchTemplates();
    } else {
      setSearchTerm('');
    }
  }, [isVisible]);

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
    }, 300),
    [],
  );

  const filteredTemplates = allTemplates.map((area) => ({
    ...area,
    templates: area.templates.filter((template) => template.title.toLowerCase().includes(searchTerm.toLowerCase())),
  }));

  return (
    <Modal
      title={t('createSuperDo.storeHeader')}
      open={isVisible}
      onCancel={onCancel}
      width={'70%'}
      bodyStyle={{
        maxHeight: '60vh',
        overflowY: 'scroll',
        backgroundColor: 'var(--secondary-background-color)',
      }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.cancel')}
        </Button>,
      ]}
    >
      {loading ? (
        <LoadingSpin marginTop={'2rem'} />
      ) : (
        <>
          <Row justify={'space-between'} align={'bottom'} style={{ marginBottom: '1rem' }}>
            <Col>
              <Input.Search
                placeholder={t('createSuperDo.searchPlaceholder')}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ width: 400 }}
              />
            </Col>
            <Col>
              <Button key="create" type="primary" onClick={onCreateNewSuperDo}>
                {t('createSuperDo.createCustomSuperDoButton')}
              </Button>
            </Col>
          </Row>
          <Divider />
          {filteredTemplates.map(
            (area) =>
              area.templates.length > 0 && (
                <div key={area.templateAreaId} style={{ margin: '1rem 0' }}>
                  <h3>{area.title}</h3>
                  <Row gutter={20}>
                    {area.templates.map((template, index) => (
                      <Col key={`${template.title}_${index}`} style={{ marginBottom: '1.5rem' }}>
                        <SuperDoItem
                          superDoId={template.title}
                          linkRoute={''}
                          title={template.title}
                          description={template.description ?? ''}
                          imagePath="/icons/template-default.png"
                          isShowActionButtons={false}
                          onDeleteSuperDoTemplate={() => ({})}
                          onEditSuperDoTemplate={() => ({})}
                          onClick={() => onAddSuperDo(template, area.title)}
                          toolNames={template.aiToolsToUse?.map((item) => item.name)}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              ),
          )}
        </>
      )}
    </Modal>
  );
};

export default SuperDoStoreModal;
