import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import PageContainer from '@app/components/layouts/main/PageContainer/PageContainer';
import { ProfileLayout } from '@app/components/profile/ProfileLayout';

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle>{t('profilePage')}</PageTitle>
      <ProfileLayout />
    </PageContainer>
  );
};

export default ProfilePage;
