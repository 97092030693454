import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { KnowledgeAreaFilter } from '@app/store/slices/knowledgeAreaFilterSlice';
import { SearchList } from './SearchList';
import { DocumentFilterType } from '@app/types/documentFilterTypes';

type WebsiteFilterProps = {
  updateFilter: (valuesToSave: Partial<KnowledgeAreaFilter>) => void;
  websites: DocumentFilterType[];
  isLoadingWebsites: boolean;
  selectedWebsites: DocumentFilterType[];
};

export const WebsiteFilter: React.FC<WebsiteFilterProps> = ({
  updateFilter,
  websites,
  isLoadingWebsites,
  selectedWebsites,
}) => {
  const { t } = useTranslation();
  const theme = useAppSelector((state) => state.theme.theme);

  const handleWebsiteSelect = (websiteId: string, checked: boolean) => {
    const foudnWebsite = websites.find((website) => website.id === websiteId);
    if (foudnWebsite) {
      const selectedWebsitesToSave = checked
        ? [...selectedWebsites, foudnWebsite]
        : selectedWebsites.filter((selectedWebsite) => selectedWebsite.id !== websiteId);
      updateFilter({ selectedWebsites: selectedWebsitesToSave });
    }
  };

  return (
    <>
      <NormalText verticalPadding color={themeObject[theme].primary} size="m">
        {t('individualWebsites')}
      </NormalText>
      <SearchList
        listData={websites}
        isLoadingData={isLoadingWebsites}
        selectedItems={selectedWebsites}
        handleItemSelect={handleWebsiteSelect}
      />
    </>
  );
};
