import { BORDER_RADIUS, media } from '@app/styles/themes/constants';
import { Card } from 'antd';
import styled from 'styled-components';

type Props = { isActive: boolean };

export const StyledCard = styled(Card)<Props>`
  width: '100%' !important;
  min-width: 250px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 0.75rem;
  border-radius: ${BORDER_RADIUS};
  border-width: 1px;
  border-color: rgba(226, 232, 240, 1);
  @media only screen and ${media.xs} {
    min-width: 90vw;
  }
  @media only screen and ${media.md} {
    min-width: 85vw;
  }
  @media only screen and ${media.lg} {
    min-width: 50vw;
  }
`;

export const CardTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 2px;
`;

export const CardDescription = styled.p`
  font-size: 0.875rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2em; /* Optional: Höhe basierend auf der Zeilenhöhe einstellen */
`;

export const CardSmallDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-main-color);
`;

export const StatusBadge = styled.div`
  width: 10px;
  height: 10px;
  background-color: #4fc854;
  border-radius: 10px;
`;

export const StatusBadgeInactive = styled.div`
  width: 10px;
  height: 10px;
  background-color: #b4b4b4;
  border-radius: 10px;
`;
