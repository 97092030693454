import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Dropdown,
  Menu,
  Modal,
  Form,
  Select,
  message,
  Space,
  Row,
  Col,
  Input,
  Switch,
  Divider,
  Button as AntButton,
} from 'antd';
import {
  DownOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  ReloadOutlined,
  ScanOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import {
  createApiIntegration,
  deleteApiIntegration,
  readApiIntegrationByKnowledgeArea,
  readWebsiteContent,
  startIngestingApiIntegration,
  updateApiIntegration,
} from '@app/api/apiIntegration.api';
import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import moment from 'moment';
import { ApiIntegrationModel, IngestionProgressStates } from '@app/domain/ApiIntegrationModel';
import { debounce } from 'lodash';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '../common/selects/BaseSelect/BaseSelect';
import { isValidUrl } from '@app/utils/utils';
import { createDisplayNameFromUrl } from '@app/api/gpt.api';
import { useTranslation } from 'react-i18next';
import WebsiteScannedContentViewer from './WebsiteScannedContentViewer';
import { API_INTEGRATIONS } from '@app/utils/constants';
import { AllowApiIntegration } from '@app/constants/config/appLayout';
import { resetKnowledgeAreaFilter } from '@app/store/slices/knowledgeAreaFilterSlice';
import { useErrorHandling } from '@app/hooks/useErrorHandling';

type OverviewApiIntegrationsProps = {
  selectedKnowledgeArea: KnowledgeAreaModel | null;
  mode: 'websites' | 'api';
};

type WebsiteContent = {
  content: string;
  url: string;
};

type TableDescription = {
  tableName: string;
  description: string;
};

const MaxKnowledgeWebsitesPerAreas =
  Number.parseInt(process.env.REACT_APP_MAX_WEBSITES_PER_KNOWLEDGE_AREAS ?? '') || 100;

const OverviewApiIntegrations: React.FC<OverviewApiIntegrationsProps> = ({ selectedKnowledgeArea, mode }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [urlDisplayNameLoading, setUrlDisplayNameLoading] = useState(false);
  const [sqlShowAdvancedSettings, setSqlShowAdvancedSettings] = useState(false);
  const [toShowScannedWebsiteContent, setToShowScannedWebsiteContent] = useState<WebsiteContent | null>(null);
  const [dataSource, setDataSource] = useState<ApiIntegrationModel[]>([]);
  const [editDataSource, setEditDataSource] = useState<ApiIntegrationModel | null>(null);
  const [tableDescriptions, setTableDescriptions] = useState<TableDescription[]>([{ tableName: '', description: '' }]);

  const [selectedApiType, setSelectedApiType] = useState('');

  const companyState = useAppSelector((state) => state.company);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { handleApiError } = useErrorHandling();

  const [form] = Form.useForm();
  const { Option } = Select;

  const maxLimitExceed = mode === 'websites' ? dataSource.length > MaxKnowledgeWebsitesPerAreas : false;

  useEffect(() => {
    readIntegrations();
  }, []);

  const readIntegrations = useCallback(async () => {
    if (selectedKnowledgeArea?._id) {
      try {
        const integrations = await readApiIntegrationByKnowledgeArea(selectedKnowledgeArea?._id);
        if (integrations.length) {
          setDataSource(
            integrations.filter((item) => (mode === 'websites' ? item.type === 'website' : item.type !== 'website')),
          );
        }
      } catch (error) {
        console.log('****** onDeleteConfirmed error: ', error);
        if (error instanceof Error) {
          notificationController.error({ message: error.message });
        } else if (typeof error === 'string') {
          notificationController.error({ message: error });
        } else {
          notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
        }
      }
    }
  }, [selectedKnowledgeArea]);

  const mapIngestionStates = (state?: IngestionProgressStates) => {
    if (state === 'INGESTION_FINISHED') {
      return t('ingestionFinished');
    } else if (state === 'INGESTION_IN_PROGRESS') {
      return t('ingestionInProgress');
    } else if (state === 'NOT_INGESTED_YET') {
      return t('notIngestedYet');
    } else if (state === 'INGESTION_FAILED') {
      return t('ingestionFailed');
    } else {
      return state;
    }
  };

  const onEditSource = (editItem: ApiIntegrationModel) => {
    setSelectedApiType(editItem.type);
    setEditDataSource(editItem);
    form.setFieldsValue({ ...editItem.parameters });
    if (editItem.type === API_INTEGRATIONS.msSqlServer && editItem.parameters) {
      form.setFieldValue('databaseEncryptConnection', editItem.parameters.databaseEncryptConnection === 'true');
      form.setFieldValue(
        'databaseTrustServerCertificate',
        editItem.parameters.databaseTrustServerCertificate === 'true',
      );

      if (editItem.parameters.additionalTableDescriptions) {
        const additionalTableDescriptionsArray: TableDescription[] = Object.entries(
          editItem.parameters.additionalTableDescriptions,
        ).map(([tableName, description]) => ({ tableName, description: description as string }));
        setTableDescriptions(additionalTableDescriptionsArray);
      }
    }
    setIsModalVisible(true);
  };

  const columns = [
    { title: t('name'), dataIndex: 'title', key: 'title' },
    {
      title: t('source'),
      key: 'sourceUrl',
      render: (item: ApiIntegrationModel) => (
        <NormalText size="s" style={{ fontWeight: 500 }}>
          {item.sourceUrl}
        </NormalText>
      ),
    },
    { title: t('lastProcessing'), dataIndex: 'dateLastIngestion', key: 'dateLastIngestion' },
    {
      title: t('status'),
      key: 'ingestionProgressState',
      render: (item: ApiIntegrationModel) => (
        <NormalText size="s" style={{ fontWeight: 500 }}>
          <Space>
            {item.ingestionProgressState === 'INGESTION_IN_PROGRESS' && <LoadingOutlined />}
            {mapIngestionStates(item.ingestionProgressState)}
          </Space>
        </NormalText>
      ),
    },
    {
      ...(mode === 'api' && {
        title: 'Integration-Typ',
        dataIndex: 'type',
        key: 'type',
      }),
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (item: ApiIntegrationModel) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => onIngestionStarted(item)}>
                {item.timestampLastIngestion ? t('readSourceAgain') : t('activateSource')}
              </Menu.Item>
              {item.type === API_INTEGRATIONS.msSqlServer && (
                <Menu.Item onClick={() => onEditSource(item)}>{t('editSource')}</Menu.Item>
              )}
              {item.type === 'website' && !!item.timestampLastIngestion && (
                <Menu.Item onClick={() => onShowScannedContent(item)}>{t('previewRawWebsiteContent')}</Menu.Item>
              )}
              <Menu.Item danger onClick={() => onDelete(item)}>
                {t('delete')}
              </Menu.Item>
            </Menu>
          }
        >
          <Button>
            {t('actions')} <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const onDelete = async (item: ApiIntegrationModel) => {
    Modal.confirm({
      title: 'Integration löschen',
      icon: <ExclamationCircleOutlined />,
      content: `Sind Sie sich wirklich sicher, dass sie die Integration "${item.title}" löschen wollen?`,
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nein',
      onOk: async () => {
        if (item?._id) {
          try {
            await deleteApiIntegration(item?._id);
            setDataSource(dataSource.filter((ds) => ds._id !== item._id));
            if (selectedKnowledgeArea?._id) {
              dispatch(resetKnowledgeAreaFilter({ value: selectedKnowledgeArea?._id }));
            }
          } catch (error) {
            console.log('****** onDeleteConfirmed error: ', error);
            handleApiError(error);
          }
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onShowScannedContent = async (item: ApiIntegrationModel) => {
    if (item?._id && item.sourceUrl) {
      try {
        setToShowScannedWebsiteContent({ content: t('common.loading'), url: item.sourceUrl });
        const rawContent = await readWebsiteContent(item?._id);
        setToShowScannedWebsiteContent({ content: rawContent, url: item.sourceUrl });
      } catch (error) {
        handleApiError(error);
        setToShowScannedWebsiteContent(null);
      }
    }
  };

  const onIngestionStarted = async (item: ApiIntegrationModel) => {
    if (item?._id) {
      try {
        setDataSource(
          dataSource.map((ds) =>
            ds._id === item._id ? { ...ds, ingestionProgressState: 'INGESTION_IN_PROGRESS' } : ds,
          ),
        );
        await startIngestingApiIntegration({ id: item?._id });
        setDataSource(
          dataSource.map((ds) => (ds._id === item._id ? { ...ds, ingestionProgressState: 'INGESTION_FINISHED' } : ds)),
        );
        message.success('Die Quelle wurde erfolgreich eingelesen');
      } catch (error) {
        console.log('****** onDeleteConfirmed error: ', error);
        setDataSource(
          dataSource.map((ds) => (ds._id === item._id ? { ...ds, ingestionProgressState: 'INGESTION_FAILED' } : ds)),
        );
        handleApiError(error);
      }
    }
  };

  const debouncedReadIntegrations = debounce(readIntegrations, 1000);

  const onRefreshClick = async () => {
    debouncedReadIntegrations();
  };

  const showModalCreateNew = () => {
    if (mode === 'api' && !AllowApiIntegration) {
      Modal.confirm({
        title: t('apiIntegrationsNotAvailable'),
        icon: <InfoCircleOutlined />,
        content: t('pleaseContactSupport'),
        okText: t('ok'),
      });
    } else {
      setEditDataSource(null);
      setIsModalVisible(true);
    }
  };

  const prepareSqlParameters = (addedIntegration: ApiIntegrationModel, rest: any) => {
    if (addedIntegration.parameters) {
      addedIntegration.parameters.databaseEncryptConnection = rest.databaseEncryptConnection ? 'true' : 'false';
      addedIntegration.parameters.databaseTrustServerCertificate = rest.databaseTrustServerCertificate
        ? 'true'
        : 'false';

      if (addedIntegration.parameters.databaseIncludedTables) {
        const databaseIncludedTables: string[] = addedIntegration.parameters.databaseIncludedTables
          .split(',')
          .map((item: string) => item.trim());
        addedIntegration.parameters.databaseIncludedTables = databaseIncludedTables;
      }
      if (tableDescriptions.length) {
        const additionalTableDescriptions: Record<string, string> = tableDescriptions
          .filter((item) => item.tableName.length > 0)
          .reduce<Record<string, string>>((acc, item) => {
            acc[item.tableName.trim()] = item.description.trim();
            return acc;
          }, {});

        addedIntegration.parameters.additionalTableDescriptions = additionalTableDescriptions;
      }
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (mode === 'websites' && !isValidUrl(values.sourceUrl)) {
          message.error('Bitte geben Sie eine gültige URL ein');
          return;
        } else if (editDataSource?._id) {
          if (editDataSource.type !== API_INTEGRATIONS.msSqlServer) {
            message.warning('Sorry, only sql connectors can be edited');
            return;
          }
          const { type, ...rest } = values;

          const foundSource = dataSource.find((item) => item._id === editDataSource?._id);
          if (foundSource) {
            foundSource.parameters = rest || {};

            if (rest.databaseName && rest.databaseHost) {
              foundSource.title = `MS-SQL-Database ${rest.databaseName}`;
              foundSource.sourceUrl = `${rest.databaseName}@${rest.databaseHost}`;
            }

            if (foundSource.parameters) {
              prepareSqlParameters(foundSource, rest);

              try {
                await updateApiIntegration(editDataSource?._id, {
                  title: foundSource.title,
                  sourceUrl: foundSource.sourceUrl,
                  parameters: foundSource.parameters,
                });
                setDataSource([...dataSource.map((item) => (item._id === editDataSource?._id ? foundSource : item))]);
                form.resetFields();
                setIsModalVisible(false);
                setSelectedApiType('');
              } catch (error) {
                handleApiError(error);
              }
            }
          }
        } else {
          form.resetFields();
          setIsModalVisible(false);
          setSelectedApiType('');

          const { type, ...rest } = values;

          let title = values.title ?? '-';
          let sourceUrl = values.sourceUrl ?? '-';
          if (type === API_INTEGRATIONS.msSqlServer && rest.databaseName && rest.databaseHost) {
            title = `MS-SQL-Database ${rest.databaseName}`;
            sourceUrl = `${rest.databaseName}@${rest.databaseHost}`;
          }

          try {
            const addedIntegration: ApiIntegrationModel = {
              title,
              type: mode === 'websites' ? 'website' : type ?? '-',
              companyId: companyState._id ?? '-',
              createdAt: moment().unix(),
              state: 'Active',
              knowledgeAreaId: selectedKnowledgeArea?._id,
              thematicAreaId: selectedKnowledgeArea?.thematicAreaId,
              sourceUrl,
              ingestionProgressState: 'NOT_INGESTED_YET',
              timestampLastIngestion: 0,
            };

            if (mode !== 'websites' && rest) {
              addedIntegration.parameters = rest || {};
              if (type === API_INTEGRATIONS.msSqlServer && addedIntegration.parameters) {
                prepareSqlParameters(addedIntegration, rest);
              }
            }

            const id = await createApiIntegration(addedIntegration);
            addedIntegration._id = id;
            setDataSource([...dataSource, addedIntegration]);
          } catch (error) {
            console.log('****** onDeleteConfirmed error: ', error);
            handleApiError(error);
          }
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setSelectedApiType('');
  };

  const onUrlInputChanged = async (url: string) => {
    try {
      setUrlDisplayNameLoading(true);
      const displayName = await createDisplayNameFromUrl({ url });
      form.setFieldValue('title', displayName);
    } catch (error) {
      console.error('**** error in onUrlInputChanged', error);
    } finally {
      setUrlDisplayNameLoading(false);
    }
  };

  const onSelectedApiChanged = (value: unknown) => {
    setSelectedApiType(value as string);
    if (value === API_INTEGRATIONS.msSqlServer) {
      form.setFieldValue('databaseEncryptConnection', true);
      form.setFieldValue('databaseTrustServerCertificate', false);
    }
  };

  const renderExecutionInfo = () => {
    const now = moment();

    const date = now
      .set('day', now.day() + 1)
      .set('hour', 7)
      .set('minutes', 0)
      .format('D MMMM, YYYY HH:mm');
    return (
      <NormalText size="s" semiBold>
        <ScanOutlined /> {t('nextWebsiteScan', { date })}
      </NormalText>
    );
  };

  const addTableDescriptionField = () => {
    setTableDescriptions([...tableDescriptions, { tableName: '', description: '' }]);
  };

  const removeTableDescriptionField = (index: number) => {
    const updatedDescriptions = [...tableDescriptions];
    updatedDescriptions.splice(index, 1);
    setTableDescriptions(updatedDescriptions);
  };

  const handleTableNameChange = (index: number, value: string) => {
    const updatedDescriptions = [...tableDescriptions];
    updatedDescriptions[index].tableName = value;
    setTableDescriptions(updatedDescriptions);
  };

  const handleTableDescriptionChange = (index: number, value: string) => {
    const updatedDescriptions = [...tableDescriptions];
    updatedDescriptions[index].description = value;
    setTableDescriptions(updatedDescriptions);
  };

  return (
    <div>
      <Row justify={'space-between'}>
        <Col>{mode === 'websites' && renderExecutionInfo()}</Col>
        <Col>
          <Space>
            <Button
              type="primary"
              style={{ float: 'right', marginBottom: 16 }}
              disabled={maxLimitExceed}
              onClick={showModalCreateNew}
            >
              {mode === 'websites' ? t('createNewWebsite') : t('createNewApiIntegration')}
            </Button>
            <Button
              style={{ float: 'right', marginBottom: 16, marginRight: 16 }}
              icon={<ReloadOutlined />}
              onClick={onRefreshClick}
            ></Button>
          </Space>
        </Col>
      </Row>
      {mode === 'websites' && maxLimitExceed && (
        <Space>
          <InfoCircleOutlined />
          <NormalText verticalPadding size="m" colorType="light">
            {t('maxWebsitesPerAreaReached', { MaxKnowledgeWebsitesPerAreas })}
          </NormalText>
        </Space>
      )}
      <Table
        dataSource={dataSource.map((item) => ({
          dateLastIngestion: item.timestampLastIngestion
            ? moment.unix(item.timestampLastIngestion).format('DD/MM/YY HH:mm')
            : '-',
          ...item,
        }))}
        rowKey={(item) => `${item.title}_${item.type}`}
        columns={columns}
      />
      <Modal
        title={t(editDataSource?._id ? 'editIntegration' : 'createNewIntegration')}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {mode === 'api' ? (
          <Form form={form} layout="vertical">
            {!editDataSource && (
              <Form.Item name="type" rules={[{ required: true, message: t('pleaseChooseAnIntegration') }]}>
                <BaseSelect placeholder={t('chooseAnIntegration')} onChange={(value) => onSelectedApiChanged(value)}>
                  <Option value={API_INTEGRATIONS.msSqlServer}>MS-SQL Server</Option>
                  <Option disabled={true} value={API_INTEGRATIONS.googleDrive}>
                    Google-Drive
                  </Option>
                  <Option disabled={true} value={API_INTEGRATIONS.notion}>
                    Notion
                  </Option>
                </BaseSelect>
              </Form.Item>
            )}
            {selectedApiType === API_INTEGRATIONS.msSqlServer && (
              <>
                <NormalText bottomMargin="s">{t('apiIntegration.msSqlDatabaseHeader')}</NormalText>
                <Form.Item name="databaseName" rules={[{ required: true, message: t('requiredField') }]}>
                  <BaseInput placeholder={t('apiIntegration.databaseName')} />
                </Form.Item>
                <Form.Item name="databaseHost" rules={[{ required: true, message: t('requiredField') }]}>
                  <BaseInput placeholder={t('apiIntegration.databaseHost')} />
                </Form.Item>
                <Form.Item name="databasePort" rules={[{ required: true, message: t('requiredField') }]}>
                  <BaseInput placeholder={t('apiIntegration.databasePort')} />
                </Form.Item>
                <Form.Item name="databaseUsername" rules={[{ required: true, message: t('requiredField') }]}>
                  <BaseInput placeholder={t('apiIntegration.databaseUsername')} />
                </Form.Item>
                <Form.Item name="databasePassword" rules={[{ required: true, message: t('requiredField') }]}>
                  <BaseInput placeholder={t('apiIntegration.databasePassword')} />
                </Form.Item>
                <Form.Item name="databaseDescription">
                  <Input.TextArea rows={4} placeholder={t('apiIntegration.databaseDescription')} />
                </Form.Item>
                <Space>
                  <NormalText>{t('apiIntegration.showAdvancedSettings')} </NormalText>
                  <Switch
                    checked={sqlShowAdvancedSettings}
                    onChange={() => setSqlShowAdvancedSettings(!sqlShowAdvancedSettings)}
                  />
                </Space>
                {sqlShowAdvancedSettings && (
                  <>
                    <Divider />
                    <Form.Item
                      name="databaseEncryptConnection"
                      label={t('apiIntegration.databaseEncryptConnection')}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    <Form.Item
                      name="databaseTrustServerCertificate"
                      label={t('apiIntegration.databaseTrustServerCertificate')}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    <Divider />
                    <NormalText bottomMargin="s">{t('apiIntegration.databaseIncludedTables')}</NormalText>
                    <Form.Item name="databaseIncludedTables">
                      <Input.TextArea rows={2} placeholder={t('apiIntegration.databaseIncludedTablesPlaceholder')} />
                    </Form.Item>
                    <Divider />
                    <NormalText bottomMargin="s">{t('apiIntegration.additionalTablePrompt')}</NormalText>
                    {tableDescriptions.map((item, index) => (
                      <Space
                        key={`table_prompt_row_${index}`}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline"
                      >
                        <Input
                          placeholder={t('apiIntegration.tableName')}
                          value={item.tableName}
                          onChange={(e) => handleTableNameChange(index, e.target.value)}
                        />
                        <Input
                          placeholder={t('apiIntegration.tableShortDescription')}
                          value={item.description}
                          onChange={(e) => handleTableDescriptionChange(index, e.target.value)}
                        />
                        <AntButton
                          type="text"
                          icon={<DeleteOutlined />}
                          onClick={() => removeTableDescriptionField(index)}
                        />
                      </Space>
                    ))}
                    <AntButton type="text" onClick={addTableDescriptionField} block icon={<PlusOutlined />}>
                      {t('apiIntegration.addTableShortDescription')}
                    </AntButton>
                  </>
                )}
              </>
            )}
          </Form>
        ) : (
          <Form form={form} layout="vertical">
            <Form.Item name="sourceUrl" rules={[{ required: true, message: t('pleaseEnterAWebsiteURL') }]}>
              <BaseInput placeholder={t('websiteURL')} onChange={(event) => onUrlInputChanged(event.target.value)} />
            </Form.Item>
            <Form.Item name="title" rules={[{ required: true, message: t('pleaseEnterANameForTheWebsite') }]}>
              <BaseInput prefix={urlDisplayNameLoading && <LoadingOutlined />} placeholder={t('nameOfTheWebsite')} />
            </Form.Item>
          </Form>
        )}
      </Modal>
      <WebsiteScannedContentViewer
        open={!!toShowScannedWebsiteContent}
        url={toShowScannedWebsiteContent?.url ?? ''}
        content={toShowScannedWebsiteContent?.content ?? ''}
        onClose={() => setToShowScannedWebsiteContent(null)}
      />
    </div>
  );
};

export default OverviewApiIntegrations;
