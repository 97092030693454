import { httpApi } from './http.api';
import { SuperDoArea, SuperDoAreaAllTemplates, SuperDoAreaTemplates } from '@app/types/superDoAreaTypes';

export const readAllSuperDoAreas = (companyId: string): Promise<SuperDoArea[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/superDoArea/${companyId}`);
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('read superDo templates failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const readAllSuperDoTeamplates = (): Promise<SuperDoAreaAllTemplates> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/superDoArea/store/all`);
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('read superDo templates failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const createSuperDoArea = (title: string, companyId: string, createdBy: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(`/api/v1/superDoArea/create`, { title, companyId, createdBy });
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('create superDo area failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const updateSuperDoArea = (areaId: string, title: string): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(`/api/v1/superDoArea/${areaId}`, { title });
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('update superDo area failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const deleteSuperDoArea = (areaId: string): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.delete(`/api/v1/superDoArea/${areaId}`);
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('create superDo area failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};
