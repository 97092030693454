import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 100%;
  margin-left: 1rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  border-left: 1px solid var(--lightgrey);
`;
