import React, { useEffect, useState } from 'react';
import { IconCheck, IconEdit, IconX } from '@tabler/icons-react';
import type { DatePickerProps } from 'antd';
import moment, { Moment } from 'moment';
import { ActionButtons, DateItemWrapper, DatePickerStyled, ButtonsWrapper } from './EditableDate.styles';
import { NormalText } from '../BaseTexts/BaseTexts';

type EditableDateProps = {
  date: number | undefined;
  onDateSave: (date: number) => void;
  disableEdit?: boolean;
};

const EditableDate: React.FC<EditableDateProps> = ({ date, onDateSave, disableEdit }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [dateWasPresent, setDateWasPresent] = useState(false);
  const [editedDate, setEditedDate] = useState<Moment | null>();

  useEffect(() => {
    if (date) {
      setEditedDate(moment.unix(date));
    }
  }, [date]);

  const onChange: DatePickerProps['onChange'] = (date: Moment | null) => {
    setEditedDate(date);
  };

  const onFinishEdit = () => {
    if (editedDate) {
      onDateSave(editedDate.unix());
    } else if (dateWasPresent) {
      onDateSave(0);
    }
    setIsEditMode(false);
  };

  const onEdit = () => {
    if (disableEdit) return;
    setDateWasPresent(!!editedDate);
    setIsEditMode(true);
  };

  return (
    <DateItemWrapper>
      {isEditMode ? (
        <DatePickerStyled value={editedDate} onChange={onChange} size={'small'} />
      ) : (
        <NormalText size="s" style={{ minWidth: '100px' }}>
          {editedDate ? editedDate.format('DD/MM/YY') : ' - '}
        </NormalText>
      )}
      <ButtonsWrapper>
        <ActionButtons isSelected={isEditMode} className="ActionButtons">
          {isEditMode ? (
            <>
              {editedDate || dateWasPresent ? (
                <IconCheck size={16} onClick={() => onFinishEdit()} />
              ) : (
                <IconX size={16} onClick={() => setIsEditMode(false)} />
              )}
            </>
          ) : (
            <IconEdit size={16} onClick={() => onEdit()} />
          )}
        </ActionButtons>
      </ButtonsWrapper>
    </DateItemWrapper>
  );
};

export default EditableDate;
