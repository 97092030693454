import React, { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { createSession, startSession, handleICE, talkAvatar, stopSession, SessionInfo } from '@app/api/gptAiAvatar.api';
import { CanvasElement } from './AvatarVideoElement.styles';
import { message } from 'antd';
import { getErrorText } from '@app/utils/apiHelpers';
import { setIframeMessageError, setIframeMessageStatus } from '@app/store/slices/iframeMessagesSlice';

// To setup video assistant visit https://docs.heygen.com/reference/new-session-copy
export type VideoQuality = 'low' | 'medium' | 'high';

interface RTCRtpReceiverCustom extends RTCRtpReceiver {
  jitterBufferTarget?: number;
}

const AVATAR_ID = 'Kayla-incasualsuit-20220818';
const VOICE_ID = '';
//const germanFemaleVoiceId = '4e5f51f94b7642ed9b065e506dc1c296';
//const germanFemaleVoiceId = 'd47ae8f1b293429f9e1513ce85d482e4';
const germanFemaleVoiceId = 'fb879a3cd2c040c8a730775b935263a1';

type AvatarVideoElementProps = {
  text: string;
  setIsLoadingAvatar: Dispatch<SetStateAction<boolean>>;
  isRefreshAvatar: boolean;
  refreshAvatarCallback: () => void;
  showAvatar: boolean;
  avatarHeight?: number;
  avatarVolume?: number;
  handleIframeDebugMessages: (message: string) => void;
  onAvatarIsReadyCallback: () => void;
};

export const AvatarVideoElement: React.FC<AvatarVideoElementProps> = ({
  text,
  setIsLoadingAvatar,
  isRefreshAvatar,
  refreshAvatarCallback,
  showAvatar,
  avatarHeight,
  avatarVolume,
  handleIframeDebugMessages,
  onAvatarIsReadyCallback,
}) => {
  const { videoAssistantVideoQuality, videoAssistantLanguage } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const voiceId = videoAssistantLanguage === 'de' ? germanFemaleVoiceId : VOICE_ID;

  const [avatarIsReady, setAvatarIsReady] = useState<boolean>(false);

  const mediaRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const renderId = useRef<number>(0);

  const [sessionInfo, setSessionInfo] = useState<SessionInfo | null>(null);
  const [peerConnection, setPeerConnection] = useState<RTCPeerConnection | null>(null);

  async function createNewSession(
    mediaElement: HTMLVideoElement,
    avatarId: string,
    voiceId: string,
    videoQuality: VideoQuality,
  ) {
    // call the new interface to get the server's offer SDP and ICE server to create a new RTCPeerConnection
    try {
      const session = await createSession(videoQuality, avatarId, voiceId);

      if (!session) {
        throw new Error('Session load fail');
      }

      setSessionInfo(session);
      const { sdp: serverSdp, ice_servers2: iceServers } = session;

      // Create a new RTCPeerConnection
      const peerConnection = new RTCPeerConnection({ iceServers: iceServers });
      setPeerConnection(peerConnection);

      // When audio and video streams are received, display them in the video element
      peerConnection.ontrack = (event) => {
        if (event.track.kind === 'audio' || event.track.kind === 'video') {
          mediaElement.srcObject = event.streams[0];
        }
      };

      // When receiving a message, display it in the status element
      peerConnection.ondatachannel = (event) => {
        const dataChannel = event.channel;
        dataChannel.onmessage = (event) => {
          console.log(event.data);
        };
      };

      // Set server's SDP as remote description
      const remoteDescription = new RTCSessionDescription(serverSdp);
      await peerConnection.setRemoteDescription(remoteDescription);

      await startAndDisplaySession(session, peerConnection);
    } catch (e) {
      const errorText = `AI avatar session create failed: ${getErrorText(e)}`;
      console.log('AI avatar session create failed', e);
      handleIframeDebugMessages(errorText);
      message.error(errorText);
    }
  }

  const refreshSession = async () => {
    if (mediaRef.current && canvasRef.current) {
      try {
        await createNewSession(mediaRef.current, AVATAR_ID, voiceId, videoAssistantVideoQuality);
        await renderCanvas(canvasRef.current, mediaRef.current);
      } catch (e) {
        handleIframeDebugMessages(`Error refresh video assistant session: ${getErrorText(e)}`);
        console.log('Error refresh video assistant session', e);
      } finally {
        refreshAvatarCallback();
      }
    }
  };

  async function startAndDisplaySession(sessionInfo: SessionInfo, peerConnection: RTCPeerConnection) {
    if (!sessionInfo) {
      console.log('Please create a connection first');
      return;
    }

    console.log('Starting session... please wait');

    // Create and set local SDP description
    if (peerConnection && sessionInfo) {
      const localDescription = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(localDescription);

      // When ICE candidate is available, send to the server
      peerConnection.onicecandidate = ({ candidate }) => {
        if (candidate) {
          handleICE(sessionInfo?.session_id ?? '', candidate.toJSON());
        }
      };

      // When ICE connection state changes, display the new state
      peerConnection.oniceconnectionstatechange = () => {
        if (peerConnection?.iceConnectionState === 'disconnected') {
          refreshSession();
        }
        console.log(`ICE connection state changed to: ${peerConnection?.iceConnectionState}`);
      };

      // Start session
      try {
        await startSession(sessionInfo.session_id, localDescription);
      } catch (e) {
        handleIframeDebugMessages(`AI avatar session start failed: ${getErrorText(e)}`);
        console.log('AI avatar session start failed', e);
        return;
      }

      const receivers: RTCRtpReceiverCustom[] = peerConnection.getReceivers();

      receivers.forEach((receiver) => {
        receiver.jitterBufferTarget = 500;
      });

      console.log('Session started successfully');

      setAvatarIsReady(true);
    }
  }

  async function talkAvatarHandler(text: string) {
    if (!sessionInfo) {
      console.log('TalkAvatarHandler: please create a connection first');
      message.info('TalkAvatarHandler: please create a connection first');
      return;
    }

    if (text) {
      try {
        await talkAvatar(sessionInfo.session_id, text);
        dispatch(setIframeMessageStatus({ value: false }));
      } catch (error) {
        handleIframeDebugMessages(`AI avatar talkAvatar method failed: ${getErrorText(error)}`);
        message.error('There was a problem processing the streaming. Please try again.');
        dispatch(setIframeMessageError({ errorText: getErrorText(error) }));
      }
    }
  }

  function isCloseToGreen(color: number[]) {
    const [red, green, blue] = color;
    return green > 90 && red < 90 && blue < 90;
  }

  function renderCanvas(canvasElement: HTMLCanvasElement, mediaElement: HTMLVideoElement) {
    const curRenderID = Math.trunc(Math.random() * 1000000000);
    renderId.current = curRenderID;

    const ctx = canvasElement.getContext('2d', { willReadFrequently: true });

    function processFrame() {
      if (curRenderID !== renderId.current || !canvasElement.parentElement) return;
      const { width, height } = canvasElement.parentElement.getBoundingClientRect();

      canvasElement.width = width;
      canvasElement.height = height;

      if (ctx && width > 0) {
        ctx.drawImage(mediaElement, 0, 0, width, height);
        ctx.getContextAttributes().willReadFrequently = true;
        const imageData = ctx.getImageData(0, 0, width, height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          const red = data[i];
          const green = data[i + 1];
          const blue = data[i + 2];

          if (isCloseToGreen([red, green, blue])) {
            data[i + 3] = 0;
          }
        }

        ctx.putImageData(imageData, 0, 0);
      }

      requestAnimationFrame(processFrame);
    }

    processFrame();
  }

  async function closeConnectionHandler() {
    if (!sessionInfo) {
      return;
    }

    console.log('Closing connection... please wait');

    try {
      if (peerConnection) {
        peerConnection.close();
        const resp = await stopSession(sessionInfo?.session_id);

        console.log(resp);
      }
    } catch (err) {
      handleIframeDebugMessages(`Failed to close the connection: ${getErrorText(err)}`);
      console.error('Failed to close the connection:', err);
    }
    console.log('Connection closed successfully');
  }

  useEffect(() => {
    const startAvatar = async () => {
      if (mediaRef.current && canvasRef.current) {
        try {
          setIsLoadingAvatar(true);
          await createNewSession(mediaRef.current, AVATAR_ID, voiceId, videoAssistantVideoQuality);
          await renderCanvas(canvasRef.current, mediaRef.current);
        } catch (e) {
          handleIframeDebugMessages(`Error starting video assistant session: ${getErrorText(e)}`);
          console.log('Error starting video assistant session', e);
        } finally {
          setIsLoadingAvatar(false);
        }
      }
    };

    startAvatar();

    return () => {
      closeConnectionHandler();
    };
  }, []);

  useEffect(() => {
    if (avatarIsReady) {
      onAvatarIsReadyCallback();
    }
  }, [avatarIsReady]);

  useEffect(() => {
    if (avatarIsReady && text) {
      talkAvatarHandler(text);
    }
  }, [avatarIsReady, text]);

  useEffect(() => {
    if (avatarIsReady && avatarVolume && mediaRef.current) {
      mediaRef.current.volume = avatarVolume;
      dispatch(setIframeMessageStatus({ value: false }));
    }
  }, [avatarIsReady, avatarVolume]);

  useEffect(() => {
    if (isRefreshAvatar) {
      refreshSession();
    }
  }, [isRefreshAvatar]);

  return (
    <>
      <video ref={mediaRef} autoPlay style={{ display: 'none' }}></video>
      <CanvasElement ref={canvasRef} showAvatar={showAvatar} avatarHeight={avatarHeight}></CanvasElement>
    </>
  );
};
