import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IframeCommandResultStatus = {
  isWaitStatus: boolean;
  messageId?: string;
  errorText?: string;
};

export interface SettingsState {
  iframeMessageStatus: IframeCommandResultStatus | null;
}

const initialState: SettingsState = {
  iframeMessageStatus: null,
};

export const iframeMessagesSlice = createSlice({
  name: 'iframeMessagesSlice',
  initialState,
  reducers: {
    setIframeMessage: (state, { payload }: PayloadAction<{ value: IframeCommandResultStatus }>) => {
      state.iframeMessageStatus = payload.value;
    },
    setIframeMessageStatus: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      if (state.iframeMessageStatus) {
        state.iframeMessageStatus.isWaitStatus = payload.value;
      }
    },
    setIframeMessageError: (state, { payload }: PayloadAction<{ errorText: string }>) => {
      if (state.iframeMessageStatus) {
        state.iframeMessageStatus.errorText = payload.errorText;
        state.iframeMessageStatus.isWaitStatus = false;
      }
    },
  },
});

export const { setIframeMessage, setIframeMessageStatus, setIframeMessageError } = iframeMessagesSlice.actions;

export default iframeMessagesSlice.reducer;
