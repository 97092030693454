import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, List, Form, Row } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { SuperDoArea } from '@app/types/superDoAreaTypes';
import { createSuperDoArea, deleteSuperDoArea, updateSuperDoArea } from '@app/api/superDoArea.api';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { LoadingSpin } from '../common/LoadingSpin';
import { useTranslation } from 'react-i18next';

interface SuperDoAreaModalProps {
  isVisible: boolean;
  areasToEdit: SuperDoArea[];
  onSave: (editedAreas: SuperDoArea[]) => void;
  onCancel: () => void;
}

type SuperDoAreaEdit = SuperDoArea & {
  justCreated?: boolean;
};

const SuperDoAreaModal: React.FC<SuperDoAreaModalProps> = ({ isVisible, areasToEdit, onSave, onCancel }) => {
  const [areas, setAreas] = useState<SuperDoAreaEdit[]>([]);
  const [loading, setLoading] = useState(false);
  const [deletedAreas, setDeletedAreas] = useState<SuperDoAreaEdit[]>([]);
  const [form] = Form.useForm();
  const companyState = useAppSelector((state) => state.company);
  const user = useAppSelector((state) => state.user.user);

  const { t } = useTranslation();

  useEffect(() => {
    setAreas(areasToEdit);
    setDeletedAreas([]);
    form.resetFields();
  }, [areasToEdit, form]);

  const handleTitleChange = (areaId: string, newTitle: string) => {
    setAreas((prevAreas) => prevAreas.map((area) => (area.areaId === areaId ? { ...area, title: newTitle } : area)));
  };

  const handleDeleteArea = (areaId: string) => {
    const deletedArea = areas.find((item) => item.areaId === areaId);
    if (deletedArea) {
      setAreas((prevAreas) => prevAreas.filter((area) => area.areaId !== areaId));
      setDeletedAreas([...deletedAreas, deletedArea]);
    }
  };

  const handleAddArea = () => {
    const newArea: SuperDoAreaEdit = {
      areaId: Date.now().toString(), // Temporäre ID
      title: '',
      templates: [],
      justCreated: true,
    };
    setAreas((prevAreas) => [...prevAreas, newArea]);
  };

  const handleSave = async () => {
    try {
      await form.validateFields();
      setLoading(true);
      for (const area of areas) {
        const foundExistent = areasToEdit.find((areaToEdit) => areaToEdit.areaId === area.areaId);
        if (!foundExistent) {
          await createSuperDoArea(area.title, companyState._id ?? '', user?._id ?? '');
        } else if (foundExistent.title !== area.title) {
          await updateSuperDoArea(area.areaId, area.title);
        }
      }
      for (const area of deletedAreas) {
        if (!area.justCreated) {
          await deleteSuperDoArea(area.areaId);
        }
      }
      onSave(areas);
    } catch (error) {
      console.error('Validation failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t('superDoArea.modalHeader')}
      open={isVisible}
      onOk={handleSave}
      onCancel={onCancel}
      bodyStyle={{ maxHeight: '60vh', overflowY: 'scroll' }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={handleSave}>
          {t('common.save')}
        </Button>,
      ]}
    >
      {loading ? (
        <LoadingSpin marginTop={'2rem'} />
      ) : (
        <Form form={form}>
          <List
            dataSource={areas}
            renderItem={(area) => (
              <Row align={'middle'} justify={'center'}>
                <List.Item
                  key={area.areaId}
                  style={{ padding: 6 }}
                  actions={[
                    <DeleteOutlined
                      key={area.areaId}
                      onClick={() => handleDeleteArea(area.areaId)}
                      style={{ color: 'red', marginBottom: 16 }}
                    />,
                  ]}
                >
                  <Form.Item
                    name={['areas', area.areaId, 'title']}
                    initialValue={area.title}
                    labelCol={{ xs: 24 }}
                    rules={[
                      { required: true, message: t('superDoArea.titleValidationEmpty') },
                      { max: 30, message: t('superDoArea.titleValidationLong') },
                      {
                        validator: (_, value) =>
                          value && ['alle', 'all'].includes(value.toLowerCase())
                            ? Promise.reject(t('superDoArea.titleValidationPreset'))
                            : Promise.resolve(),
                      },
                    ]}
                  >
                    <Input style={{ width: 350 }} onChange={(e) => handleTitleChange(area.areaId, e.target.value)} />
                  </Form.Item>
                </List.Item>
              </Row>
            )}
          />
          <Row align={'middle'} justify={'center'}>
            <Button
              type="dashed"
              onClick={handleAddArea}
              style={{ width: '100%', marginTop: 16, marginLeft: 20, marginRight: 20 }}
              icon={<PlusOutlined />}
            >
              {t('superDoArea.addNewCategory')}
            </Button>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default SuperDoAreaModal;
