import styled from 'styled-components';
import { Button, Drawer } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

export const DrawerStyled = styled(Drawer)`
  & .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }

  & .ant-drawer-title {
    color: var(--primary-color);
  }

  & .ant-drawer-header {
    border-bottom: none;
  }

  & .ant-tabs,
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }
`;

export const Description = styled.p`
  position: absolute;
  top: 60px;
  margin: 0;
  font-size: ${FONT_SIZE.md};
  color: var(--text-light-color);
`;

export const ResetButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  color: var(--primary-color);
`;
