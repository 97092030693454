import { notificationController } from '@app/controllers/notificationController';
import React, { useEffect, useState } from 'react';
import * as S from './AnalyzeCaveatDocumentContainer.styles';
import { List, Space, Spin, Tag } from 'antd';
import { CaveatAnalysisResult } from '@app/types/caveatTypes';

type Props = {
  analysisResult: CaveatAnalysisResult | null;
  loading: boolean;
};

const SidebarCaveatAnalyzeResults: React.FC<Props> = ({ analysisResult, loading }) => {
  const [mainCaveats, setMainCaveats] = useState<string[]>([]);
  const [matchedThemes, setMatchedThemes] = useState<string[]>([]);

  useEffect(() => {
    if (analysisResult) {
      try {
        setMainCaveats(analysisResult.mainCaveats);
        setMatchedThemes(analysisResult.matchedThemes);
      } catch (error) {
        notificationController.error({ message: 'Leider ist beim Laden ein unerwarteter Fehler aufgetreten' });
      }
    }
  }, [analysisResult]);

  return (
    <S.SidebarContainer>
      <S.TopHeaderText size="l">Auswertung des Dokumentes</S.TopHeaderText>
      {analysisResult ? (
        <>
          <S.AnalyzeHeader>
            <p>Zugeordnete Themen</p>
          </S.AnalyzeHeader>
          <Space size={[0, 8]} wrap>
            {matchedThemes.map((item, index) => (
              <S.StyledTag key={index}>{item}</S.StyledTag>
            ))}
          </Space>
          <S.AnalyzeHeader>
            <p>Hauptargumente</p>
          </S.AnalyzeHeader>

          <List size="small" bordered dataSource={mainCaveats} renderItem={(item) => <List.Item>{item}</List.Item>} />
        </>
      ) : (
        <>
          {loading ? (
            <S.LoadingContainer>
              <Spin size="large" />
              <S.LoadingText>Dokument wird verarbeitet, bitte warten ...</S.LoadingText>
            </S.LoadingContainer>
          ) : (
            <S.EmptyText size="m">Noch kein Dokument hochgeladen</S.EmptyText>
          )}
        </>
      )}
    </S.SidebarContainer>
  );
};

export default SidebarCaveatAnalyzeResults;
