import React from 'react';
import { Dropdown, List, Menu } from 'antd';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useTranslation } from 'react-i18next';
import { IconDotsVertical } from '@tabler/icons-react';

type DepartmentRowItemProps = {
  name: string;
  id?: string;
  description?: string | null;
  onEdit: (id?: string) => void;
  onDelete: (id?: string) => void;
};

export const DepartmentRowItem: React.FC<DepartmentRowItemProps> = ({ description, id, name, onEdit, onDelete }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item onClick={() => onEdit(id)} key="edit">
        {t('common.edit')}
      </Menu.Item>
      <Menu.Item danger onClick={() => onDelete(id)} key="delete">
        {t('common.delete')}
      </Menu.Item>
    </Menu>
  );
  return (
    <List.Item
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '0.5rem',
        backgroundColor: themeObject[theme].backgroundColorBase,
        padding: '1rem',
      }}
    >
      <List.Item.Meta
        title={
          <NormalText size="m" semiBold>
            {name}
          </NormalText>
        }
        description={
          <NormalText size="m" colorType="light">
            {description ?? t('noDescription')}
          </NormalText>
        }
      />
      <Dropdown overlay={menu} trigger={['click']}>
        <div style={{ padding: '0.5rem', cursor: 'pointer' }}>
          <IconDotsVertical color={themeObject[theme].textLight} />
        </div>
      </Dropdown>
    </List.Item>
  );
};
