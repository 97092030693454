import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';
import AuthConfirmEmailPage from '@app/pages/AuthConfirmEmailPage';
import TeamMemberInvitationValidationPage from '@app/pages/TeamMemberInvitationValidationPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import AiSurvey from '@app/pages/Survey/AiSurvey';
import AiSurveyAutoGenerated from '@app/pages/Survey/AiSurveyAutoGenerated';
import AiSurveyHR from '@app/pages/SurveyHRDigitalization/AiSurveyHR';
import AiSurveyHRAutoGenerated from '@app/pages/SurveyHRDigitalization/AiSurveyHRAutoGenerated';
import RequireAccessToken from './RequireAccessToken';
import MainPublicLayout from '../layouts/main/MainLayout/MainPublicLayout';
import MainLayoutWithoutSider from '../layouts/main/MainLayout/MainLayoutWithoutSider';

import AuthLayout from '@app/components/layouts/AuthLayout/AuthLayout';
import ServerErrorPage from '@app/pages/ServerErrorPage';
import Error404Page from '@app/pages/Error404Page';
import ProfilePage from '@app/pages/Profile/ProfilePage';
import RightsManagementPage from '@app/pages/RightsManagement/RightsManagementPage';
import NotificationsPage from '@app/pages/Notifications/NotificationsPage';
import TermsOfUsePage from '@app/pages/TermsOfUseAndPrivacy/TermsOfUsePage';
import DataPrivacyPage from '@app/pages/TermsOfUseAndPrivacy/DataPrivacyPage';
import DataroomOverviewPage from '@app/pages/Dataroom/DataroomOverviewPage';
import SourceUpdateMonitoringOverviewPage from '@app/pages/SourceUpdateMonitoring/SourceUpdateMonitoringOverviewPage';
import DataroomAreaPage from '@app/pages/Dataroom/DataroomAreaPage';
import SourceUpdateMonitoringPage from '@app/pages/SourceUpdateMonitoring/SourceUpdateMonitoringPage';
import CompanyOverviewPage from '@app/pages/CompanyOverview/CompanyOverviewPage';
import UsageAnalyticsPage from '@app/pages/UsageAnalytics/UsageAnalyticsPage';
import PlatformSettingsPage from '@app/pages/PlatformSettings/PlatformSettingsPage';
import GPTAgentsPage from '@app/pages/GPTAgents/GPTAgents';
import GPTSearchPage from '@app/pages/GPTSearch/GPTSearch';
import GPTTemplatesPage from '@app/pages/GPTTemplates/GPTTemplates';
import GPTCreateTemplatePage from '@app/pages/GPTTemplates/GPTTemplateCreatePage';
import GPTTemplateDetailsPage from '@app/pages/GPTTemplates/GPTTemplateDetails';
import GPTTemplateCustomPage from '@app/pages/GPTTemplates/GPTTemplateCustom';
import GPTKnowledgebaseSearchPage from '@app/pages/GPTKnowledgebaseSearch/GPTKnowledgebaseSearch';
import AiAvatarIframePage from '@app/pages/AiAvatarIframe/AiAvatarIframePage';
import DraftDocumentPage from '@app/pages/Legal/DraftDocument';
import AnalyzeDocumentPage from '@app/pages/Legal/AnalyzeDocument';
import AnalyzeCaveatDocumentPage from '@app/pages/Engineering/AnalyzeCaveatDocument';
import AnalyzeTechOfferDocumentPage from '@app/pages/Engineering/AnalyzeTechOfferDocument';
import GPTAgentSearchPage from '@app/pages/GPTAgentSearch/GPTAgentSearch';
import StreamingAvatarPage from '@app/pages/StreamingAvatar/StreamingAvatarPage';
import Logout from './Logout';
import { EditChecklistsContainer } from '../analyzeDocumentContainer/EditChecklistsContainer';
import DocumenReviewResults from '@app/pages/Legal/DocumenReviewResults';

export const ROOT_PATH = '/';
export const ROOT_PATH_WITHOUT_SIDER = '/zen/';
export const PUBLIC_ROOT_PATH = '/public/';
export const NOTIFICATION_PATH = '/notifications';

const ServerError = ServerErrorPage;
const Error404 = Error404Page;

// Profile
const Profile = ProfilePage;
const RightsManagement = RightsManagementPage;
const Notifications = NotificationsPage;
const TermsOfUse = TermsOfUsePage;
const DataPrivacy = DataPrivacyPage;
const DataroomOverview = DataroomOverviewPage;
const SourceUpdateMonitoringOverview = SourceUpdateMonitoringOverviewPage;
const DataroomArea = DataroomAreaPage;
const SourceUpdateMonitoring = SourceUpdateMonitoringPage;
const CompanyOverview = CompanyOverviewPage;
const UsageAnalytics = UsageAnalyticsPage;
const PlatformSettings = PlatformSettingsPage;
const GPTAgents = GPTAgentsPage;
const GPTSearch = GPTSearchPage;
const GPTTemplates = GPTTemplatesPage;
const GPTCreateTemplate = GPTCreateTemplatePage;
const GPTTemplateDetails = GPTTemplateDetailsPage;
const GPTTemplateCustom = GPTTemplateCustomPage;
const GPTKnowledgebaseSearch = GPTKnowledgebaseSearchPage;
const AiAvatarIframe = AiAvatarIframePage;
const DraftDocument = DraftDocumentPage;
const AnalyzeDocument = AnalyzeDocumentPage;
const AnalyzeCaveatDocument = AnalyzeCaveatDocumentPage;
const AnalyzeTechOfferDocument = AnalyzeTechOfferDocumentPage;
const GPTAgentSearch = GPTAgentSearchPage;
const StreamingAvatar = StreamingAvatarPage;

const AuthLayoutFallback = AuthLayout;
const LogoutFallback = Logout;

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  const protectedLayoutWithoutSider = (
    <RequireAuth>
      <MainLayoutWithoutSider />
    </RequireAuth>
  );

  const publicProtectedLayout = (
    <RequireAccessToken>
      <MainPublicLayout />
    </RequireAccessToken>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROOT_PATH} element={protectedLayout}>
          <Route index element={<GPTSearch />} />
          <Route path="knowledge/:id" element={<GPTKnowledgebaseSearch />} />
          <Route path="survey" element={<AiSurvey />} />
          <Route path="survey-ai-generated" element={<AiSurveyAutoGenerated />} />
          <Route path="survey-hr" element={<AiSurveyHR />} />
          <Route path="survey-ai-hr-generated" element={<AiSurveyHRAutoGenerated />} />
          <Route path={NOTIFICATION_PATH} element={<Notifications />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="profile" element={<Profile />} />
          <Route path="gptsearch" element={<GPTSearch />} />
          <Route path="gptagents" element={<GPTAgents />} />
          <Route path="superdos" element={<GPTTemplates />} />
          <Route path="legaldraft" element={<DraftDocument />} />
          <Route path="contractanalyze" element={<AnalyzeDocument />} />
          <Route path="caveatanalyze" element={<AnalyzeCaveatDocument />} />
          <Route path="techofferdocumentanalyze" element={<AnalyzeTechOfferDocument />} />
          <Route path="quick-document-review" element={<GPTTemplateCustom />} />
          <Route path="gptagentsearch" element={<GPTAgentSearch />} />
          <Route path="dataroom" element={<DataroomOverview />} />
          <Route path="source-monitoring" element={<SourceUpdateMonitoringOverview />} />
          <Route path="source-monitoring-selected/:id" element={<SourceUpdateMonitoring />} />
          <Route path="company-overview" element={<CompanyOverview />} />
          <Route path="usage-analytics" element={<UsageAnalytics />} />
          <Route path="platform-settings" element={<PlatformSettings />} />
          <Route path="rights-management" element={<RightsManagement />} />
        </Route>
        <Route path={ROOT_PATH_WITHOUT_SIDER} element={protectedLayoutWithoutSider}>
          <Route path="demo-streaming-avatar/:knowledgeAreaId" element={<StreamingAvatar />} />
          <Route path="test-iframe-interaction" element={<AiAvatarIframe />} />
          <Route path="dataroom-selected" element={<DataroomArea />} />
          <Route path="superdo-details/:templateId" element={<GPTTemplateDetails />} />
          <Route path="edit-checklist/:id" element={<EditChecklistsContainer />} />
          <Route path="superdo-create" element={<GPTCreateTemplate />} />
          <Route path="document-review-results" element={<DocumenReviewResults />} />
        </Route>
        <Route path={PUBLIC_ROOT_PATH} element={publicProtectedLayout}>
          <Route path="demo-streaming-avatar/:knowledgeAreaId" element={<StreamingAvatar />} />
          <Route path="knowledge/:id" element={<GPTKnowledgebaseSearch />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="secure-sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
          <Route path="team-invitation" element={<TeamMemberInvitationValidationPage />} />
          <Route path="email-verify" element={<AuthConfirmEmailPage />} />
        </Route>
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/data-privacy" element={<DataPrivacy />} />
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="404" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};
