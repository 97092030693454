import { createSocketInstance } from '@app/services/socketIO.service';
import { GeneralSocketAppEvent } from '@app/types/socketMessageTypes';
import { useEffect, useRef } from 'react';
import { Socket } from 'socket.io-client';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { setTriggerEventId } from '@app/store/slices/socketAppEventSlice';
import { debounce } from 'lodash';

export const useAppEventSocketListener = ({
  onSockeAppEvent,
}: {
  onSockeAppEvent: (value: GeneralSocketAppEvent) => void;
}) => {
  const socketIO = useRef<Socket | null>(null);
  const user = useAppSelector((state) => state.user.user);

  const dispatch = useAppDispatch();

  const { triggerEventId } = useAppSelector((state) => state.socketAppEvent);
  const companyId = useAppSelector((state) => state.company._id);

  useEffect(() => {
    if (companyId) {
      debouncedConnectWebSocket();
    }
  }, [companyId]);

  useEffect(() => {
    if (
      triggerEventId &&
      ['KNOWLEDGE_AREA_CHANGES', 'KNOWLEDGE_AREA_FOLDER_CHANGED', 'KNOWLEDGE_AREA_RIGHTS_CHANGED'].includes(
        triggerEventId,
      )
    ) {
      socketIO.current?.emit('new-message-general', {
        appEventId: triggerEventId,
        companyId,
      });
      dispatch(setTriggerEventId({ value: null }));
    }
    if (triggerEventId === 'APPLICATION_CLOSED_EVENT_ID') {
      cleanupWebSocket();
      dispatch(setTriggerEventId({ value: null }));
    }
  }, [triggerEventId]);

  const cleanupWebSocket = () => {
    socketIO.current?.emit('user-leaved-general', { userId: user?._id, companyId });
    socketIO.current?.off('new-message-general', onNewMessageFromSocket);
    socketIO.current?.close();
  };

  const connectWebSocket = (connectedCallback?: () => void) => {
    createSocketInstance((socket) => {
      socketIO.current?.off('new-message-general', onNewMessageFromSocket);
      socketIO.current = socket;
      socketIO.current?.on('new-message-general', onNewMessageFromSocket);
      socketIO.current?.emit('user-joined-general', {
        userId: user?._id,
        companyId,
        listenToEvents: ['KNOWLEDGE_AREA_CHANGES', 'KNOWLEDGE_AREA_FOLDER_CHANGED', 'KNOWLEDGE_AREA_RIGHTS_CHANGED'],
      });
      connectedCallback?.();
    });
  };

  const debouncedConnectWebSocket = debounce(connectWebSocket, 500);

  const onNewMessageFromSocket = (value: GeneralSocketAppEvent) => {
    const { messageType } = value;
    if (value.companyId === companyId && messageType === 'GENERAL_APP_EVENT') {
      onSockeAppEvent(value);
    }
  };

  return { cleanupWebSocket };
};
