import React from 'react';
import GptChatBox from '@app/components/gptchatbox/GptChatBox';
import PageContainer from '@app/components/layouts/main/PageContainer/PageContainer';

function GPTSearch() {
  return (
    <PageContainer>
      <GptChatBox chatMode={'AGENT'} />
    </PageContainer>
  );
}

export default GPTSearch;
