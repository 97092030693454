import { IChecklistReviewResults } from '@app/domain/ChecklistReviewResultsModel';
import { sendPostRequest, sendGetRequest, sendDeleteRequest } from '../api/http.api';

export const createChecklistReviewResult = (data: IChecklistReviewResults): Promise<string> => {
  return sendPostRequest<IChecklistReviewResults, string>('/api/v1/checklistReviewResults/create', data);
};

export const getChecklistReviewResultById = (id: string): Promise<IChecklistReviewResults | null> => {
  return sendGetRequest<IChecklistReviewResults | null>(`/api/v1/checklistReviewResults/${id}`);
};

export const getChecklistReviewResultsByCompanyId = (
  companyId: string,
  page = 1,
  limit = 100,
): Promise<IChecklistReviewResults[] | null> => {
  return sendGetRequest<IChecklistReviewResults[] | null>(
    `/api/v1/checklistReviewResults/byCompany/${companyId}?page=${page}&limit=${limit}`,
  );
};

export const getChecklistReviewResultsByUserId = (
  userId: string,
  page = 1,
  limit = 100,
): Promise<IChecklistReviewResults[] | null> => {
  return sendGetRequest<IChecklistReviewResults[] | null>(
    `/api/v1/checklistReviewResults/byUser/${userId}?page=${page}&limit=${limit}`,
  );
};

export const updateChecklistReviewResult = (id: string, data: Partial<IChecklistReviewResults>): Promise<boolean> => {
  return sendPostRequest<Partial<{ data: Partial<IChecklistReviewResults> }>, boolean>(
    `/api/v1/checklistReviewResults/update/${id}`,
    {
      data,
    },
  );
};

export const deleteChecklistReviewResult = (id: string): Promise<boolean> => {
  return sendDeleteRequest<boolean>(`/api/v1/checklistReviewResults/${id}`);
};
