import React from 'react';

type PdfIconProps = {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
};

export const PdfIcon: React.FC<PdfIconProps> = ({ width, height, style }) => (
  <svg
    width={width ? width : '18'}
    height={height ? height : '18'}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M4.5 0C3.88125 0 3.375 0.50625 3.375 1.125V16.875C3.375 17.4937 3.88125 18 4.5 18H15.75C16.3687 18 16.875 17.4937 16.875 16.875V4.5L12.375 0H4.5Z"
      fill="#E2E5E7"
    />
    <path d="M13.5 4.5H16.875L12.375 0V3.375C12.375 3.99375 12.8812 4.5 13.5 4.5Z" fill="#B0B7BD" />
    <path d="M16.875 7.875L13.5 4.5H16.875V7.875Z" fill="#CAD1D8" />
    <path
      d="M14.625 14.625C14.625 14.9344 14.3719 15.1875 14.0625 15.1875H1.6875C1.37812 15.1875 1.125 14.9344 1.125 14.625V9C1.125 8.69062 1.37812 8.4375 1.6875 8.4375H14.0625C14.3719 8.4375 14.625 8.69062 14.625 9V14.625Z"
      fill="#F15642"
    />
    <path
      d="M3.57812 10.6577C3.57812 10.5092 3.69512 10.3472 3.88356 10.3472H4.9225C5.5075 10.3472 6.034 10.7387 6.034 11.489C6.034 12.2 5.5075 12.596 4.9225 12.596H4.17156V13.19C4.17156 13.388 4.04556 13.5 3.88356 13.5C3.73506 13.5 3.57812 13.388 3.57812 13.19V10.6577ZM4.17156 10.9136V12.0341H4.9225C5.224 12.0341 5.4625 11.768 5.4625 11.489C5.4625 11.1746 5.224 10.9136 4.9225 10.9136H4.17156Z"
      fill="white"
    />
    <path
      d="M6.91597 13.5C6.76747 13.5 6.60547 13.419 6.60547 13.2216V10.6667C6.60547 10.5053 6.76747 10.3877 6.91597 10.3877H7.94591C10.0013 10.3877 9.95628 13.5 7.98641 13.5H6.91597ZM7.19947 10.9367V12.9516H7.94591C9.16034 12.9516 9.21434 10.9367 7.94591 10.9367H7.19947Z"
      fill="white"
    />
    <path
      d="M10.686 10.9726V11.6876H11.833C11.995 11.6876 12.157 11.8496 12.157 12.0065C12.157 12.155 11.995 12.2765 11.833 12.2765H10.686V13.221C10.686 13.3785 10.5741 13.4994 10.4166 13.4994C10.2186 13.4994 10.0977 13.3785 10.0977 13.221V10.6661C10.0977 10.5046 10.2192 10.3871 10.4166 10.3871H11.9955C12.1935 10.3871 12.3105 10.5046 12.3105 10.6661C12.3105 10.8101 12.1935 10.9721 11.9955 10.9721H10.686V10.9726Z"
      fill="white"
    />
    <path
      d="M14.0625 15.1875H3.375V15.75H14.0625C14.3719 15.75 14.625 15.4969 14.625 15.1875V14.625C14.625 14.9344 14.3719 15.1875 14.0625 15.1875Z"
      fill="#CAD1D8"
    />
  </svg>
);
