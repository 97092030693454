import { media } from '@app/styles/themes/constants';
import { Input, Typography } from 'antd';
import styled from 'styled-components';

export const Description = styled(Typography.Text)`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  max-width: 600px;

  @media only screen and ${media.md} {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

export const GoalDescription = styled(Typography.Text)`
  display: block;
  font-size: 1rem;
  margin-bottom: 1rem;
  max-width: 600px;
  font-weight: 600;

  @media only screen and ${media.md} {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

interface MessageContainerProps {
  isActive?: boolean;
}

export const MessageContainer = styled.div<MessageContainerProps>`
  max-width: 900px;
  min-height: 300px;
  max-height: 48vh;
  overflow-y: auto;
  font-size: 1rem;
  padding: 1rem;
  border: 2px solid var(--primary-color);
  animation: ${(props) => (props.isActive ? 'pulse 2s infinite' : 'none')};
  background-color: var(--secondary-background-color);

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(3, 149, 188, 0.4);
      box-shadow: 0 0 0 0 rgba(3, 149, 188, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(3, 149, 188, 0);
      box-shadow: 0 0 0 10px rgba(3, 149, 188, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(3, 149, 188, 0);
      box-shadow: 0 0 0 0 rgba(3, 149, 188, 0);
    }
  }
`;

export const StyledInput = styled(Input)`
  max-width: 900px;
  margin-top: 1rem;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
`;

export const StyledInitialInput = styled(Input.TextArea)`
  max-width: 600px;
  margin-top: 1rem;
  border: 1px solid var(--primary-color);
  background-color: var(--secondary-background-color);
  border-radius: 8px;
`;

export const EmptyText = styled(Typography.Text)`
  font-size: 1.25rem;
  color: var(--text-light-color);
`;

export const NotificationContainer = styled.div`
  margin-left: 1rem;
  padding: 1rem;
  border-left: 1px solid var(--border-color);
`;
