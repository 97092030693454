import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { NormalText } from '../BaseTexts/BaseTexts';

const { TextArea } = Input;

export const TextItemWrapper = styled.div<{ isEditMode: boolean; parentHeight: number | null }>`
  display: flex;
  min-height: 28px;
  justify-content: start;
  overflow-x: hidden;

  &:hover .ActionButtons {
    display: block;
  }

  ${({ isEditMode, parentHeight }) =>
    isEditMode &&
    css`
      position: absolute;
      left: 0;
      top: 0;
      height: ${parentHeight ? `${parentHeight}px` : '100%'};
      width: 100%;
    `}
`;

export const InputStyled = styled(TextArea)`
  height: auto;
  font-size: ${FONT_SIZE.xs};
  border-radius: 0;
`;

export const ButtonsWrapper = styled.div`
  position: relative;
  width: 20px;
  min-width: 20px;
`;

export const ActionButtons = styled.div<{ isSelected: boolean }>`
  position: absolute;
  top: 4px;
  right: 0;
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  & > * {
    cursor: pointer;
  }
`;

export const Text = styled(NormalText)<{ maxRowNumber?: number }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ maxRowNumber }) => maxRowNumber || 3};
  overflow: hidden;
`;
