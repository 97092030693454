import React from 'react';
import { useTranslation } from 'react-i18next';
import GptDocumentChecker from '@app/components/gpttemplates/DocumentCheck/GptDocumentChecker';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

function DocumenReviewResults() {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>{t('contractAnalyze.shortTitle')}</PageTitle>
      <GptDocumentChecker />
    </>
  );
}

export default DocumenReviewResults;
