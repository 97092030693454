import { BORDER_RADIUS, media } from '@app/styles/themes/constants';
import { Button, Card, Typography } from 'antd';
import styled from 'styled-components';

export const Description = styled(Typography.Text)`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 1rem;

  @media only screen and ${media.md} {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

export const FooterContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: '100%';
  background-color: #fff;
`;

export const StyledCard = styled(Card)`
  width: '100%';
  min-width: 210px;
  height: 200px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
  border-radius: ${BORDER_RADIUS};
  border-width: 1px;
  border-color: rgba(226, 232, 240, 1);
`;

export const CardTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
`;

export const CardDescription = styled.p`
  font-size: 1rem;
`;

export const AccessRightsHeader = styled.div`
  padding-bottom: 20px;
  float: right;
`;

export const EditButton = styled(Button)`
  margin-left: 8px;
`;
