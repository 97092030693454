import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const CenteredLogo: React.FC = () => {
  const { appLogo, appName } = useAppSelector((state) => state.appLayout);

  return (
    <S.CenteredLogoDiv>
      <S.SiderLogoLink to="/">
        <img src={appLogo} alt={appName} width={process.env.REACT_APP_CUSTOM_APP_LOGO_WIDTH || 140} />
      </S.SiderLogoLink>
    </S.CenteredLogoDiv>
  );
};
