import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { CenteredLogo } from '../layouts/main/sider/CenteredLogo';

export const TermsOfUse: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <CenteredLogo />
      <Card>
        <h3 id="-allgemeine-gesch-ftsbedingungen-f-r-softwaretransaktionen-und-it-dienste-von-co-brain-">
          <strong>Allgemeine Geschäftsbedingungen für Softwaretransaktionen und IT-Dienste von Co-Brain</strong>
        </h3>
        <p>Zuletzt aktualisiert und gültig ab 01.12.2023</p>
        <p>
          <strong>1. HAUPTKLAUSELN FÜR SÄMTLICHE LEISTUNGEN</strong>
        </p>
        <p>
          Diese Klauseln sind für sämtliche Softwaretransaktionen und IT-Dienste bindend, die von der Co-Brain -
          Balashov und Engelmann GbR für Kunden erbracht werden. Die Basis für die Dienste von Co-Brain – Balashov und
          Engelmann GbR bildet das vorliegende Angebot, es sei denn, es wird von Co-Brain – Balashov und Engelmann GbR
          als unverbindlich gekennzeichnet und vom Kunden durch eine passende Bestellung innerhalb der Gültigkeitsdauer
          akzeptiert.
        </p>
        <h3 id="1-1-kundenmitwirkung">1.1. Kundenmitwirkung</h3>
        <p>
          1.1.1. Änderungen des Wohn- oder Geschäftssitzes sowie Modifikationen der Rechtsform oder der
          Haftungsverhältnisse des Unternehmens des Kunden sind Co-Brain umgehend mitzuteilen.
        </p>
        <p>
          1.1.2. Zur Leistungserbringung benötigte Informationen und Dokumente, insbesondere bezüglich Geräten, Daten,
          Programmen und Programmteilen, die mit der Software von Co- Brain interagieren, sind vonseiten des Kunden
          unverzüglich bereitzustellen. Der Kunde muss Co-Brain auch über das geplante Anwendungsfeld,
          geschäftspolitische und prozesstechnische Zielsetzungen und Prioritäten sowie alle weiteren relevanten
          Vorgaben informieren. Gegebenenfalls sind Zustimmungen Dritter einzuholen. Co-Brain kann für Leistungen in den
          Räumlichkeiten des Kunden das notwendige Personal für den Unfallschutz kostenfrei verlangen.
        </p>
        <p>
          1.1.3. Für Leistungen, die Lieferung und/oder Installation von Software umfassen, ist der Kunde verpflichtet,
          a) Co-Brain sofortigen und unbehinderten Zugang zur Datenverarbeitungseinheit zu gewähren, auf der die
          Software verwendet wird. Der Kunde stellt die erforderlichen technischen Einrichtungen, wie Stromversorgung,
          Telekommunikationsverbindungen und Datenübertragungsleitungen, kostenfrei zur Verfügung.
          Telekommunikationskosten trägt der Kunde. b) Die Software sofort nach Erhalt auf Funktionsfähigkeit zu prüfen
          und Mängel umgehend zu melden. c) Vorkehrungen für den Fall zu treffen, dass die Software nicht wie vereinbart
          funktioniert, insbesondere durch Notfallpläne, Datensicherungen, ständige Ergebniskontrollen und bei Störungen
          durch detaillierte Fehlerbeschreibungen. Daten sollten aus maschinenlesbarem Material rekonstruierbar sein. d)
          Für die Datensicherung der installierten Software eigenständig zu sorgen. e) Über erstellte Softwarekopien
          bzw. Benutzerdokumentationen Buch zu führen und auf Anfrage von Co-Brain Auskunft zu erteilen.
        </p>
        <p>
          1.1.4. Der Kunde versichert, berechtigt zu sein, die an Co-Brain bzw. deren Subunternehmer gelieferten
          personenbezogenen Daten Dritter speichern und verarbeiten zu lassen.
        </p>
        <p>
          1.1.5. Der Kunde trägt die Kosten, die durch Arbeiten entstehen, die aufgrund seiner ungenauen, nachträglich
          korrigierten oder unvollständigen Angaben von Co-Brain wiederholt oder verzögert werden müssen.
        </p>
        <h3 id="1-2-nderungen-und-erweiterungen-der-leistungen-change-requests-">
          1.2. ÄNDERUNGEN UND ERWEITERUNGEN DER LEISTUNGEN (CHANGE REQUESTS)
        </h3>
        <p>
          1.2.1. Der Kunde kann Änderungen oder Erweiterungen des Leistungsumfangs bei Arbeiten an Konzepten und
          Software bis zur Übergabe schriftlich anfordern („Change Request“). Co-Brain prüft die Durchführbarkeit der
          Änderung, sofern sie im Rahmen ihrer betrieblichen Kapazitäten zumutbar ist. Eine vereinbarte
          Fertigstellungsfrist verlängert sich um die Prüfungsfrist. Co-Brain gibt an, welche Änderungen sich ergeben,
          welche Auswirkungen dies auf Termine hat und welchen Zeitaufwand sie benötigt, um ein detailliertes Angebot
          für den Change Request zu unterbreiten.
        </p>
        <p>
          1.2.2. Unterbreitet Co-Brain ein Angebot, kann der Kunde innerhalb von zehn Kalendertagen nach Erhalt
          schriftlich entscheiden, ob er das Angebot annimmt oder nicht. Ohne Antwort innerhalb dieser Frist gilt das
          Angebot als abgelehnt.
        </p>
        <p>
          1.2.3. Co-Brain kann für die Prüfung des Änderungswunsches und für die Erstellung entsprechender Unterlagen
          eine Vergütung fordern, grundsätzlich nach Zeitaufwand, sofern nicht anders vereinbart. Die Berechnung basiert
          auf der aktuellen Preisliste von Co-Brain.
        </p>
        <h3 id="1-3-nutzungsrechte-an-software">1.3. NUTZUNGSRECHTE AN SOFTWARE</h3>
        <p>
          1.3.1. Nach vollständiger Bezahlung räumt Co-Brain dem Kunden das nicht ausschließliche und zeitlich begrenzte
          Recht ein, die Software innerhalb Deutschlands zu nutzen. Dies umfasst Laden, Anzeigen, Ablaufen, Übertragen
          oder Speichern für eigene Zwecke im Unternehmen. Das Nutzungsrecht beschränkt sich auf die im Angebot
          festgelegten Datenverarbeitungseinheiten, Benutzer und deren Anzahl sowie den vorgesehenen
          Vervielfältigungsumfang. Der Kunde darf eine Sicherungs- oder Archivierungskopie der Software erstellen und
          aufbewahren. Falls der Kunde die Co-Brain für weitere individuelle Softwareentwicklungsprojekte beauftragt,
          dann können projektbasiert gesonderte Bedingungen abgeschlossen werden, die jedoch immer auf das zu
          entwickelnde Projekt individuell beschränkt sind.
        </p>
        <p>
          1.3.2. Die in 1.2.1. definierten Rechte gelten auch für maschinenlesbar überlassene Benutzerdokumentation.
          Druckfassungen dürfen nur zum Zweck der Softwarenutzung vervielfältigt werden.
        </p>
        <p>
          1.3.3. Der Kunde darf die Software mit anderen Programmen kombinieren. Die Rückübersetzung in andere
          Codeformen ist nur gesetzlich erlaubt. Die Übertragung dieser Handlungen an Dritte ist nur zulässig, wenn
          Co-Brain nicht bereit ist, die Interoperabilität gegen Entgelt herzustellen.
        </p>
        <p>
          1.3.4. Der Kunde darf die Software zur Fehlerkorrektur bearbeiten, wenn dies für die bestimmungsgemäße Nutzung
          notwendig ist und Co-Brain diese Handlungen nicht selbst durchführt. Der eigene Gebrauch schließt die
          Verbreitung der Software aus. Der Kunde muss die Notwendigkeit der Fehlerkorrektur nachweisen.
        </p>
        <p>
          1.3.5. Kennzeichnungen, Marken und Schutzrechtsvermerke dürfen nicht entfernt werden und müssen auf Kopien
          unverändert übernommen werden.
        </p>
        <p>
          1.3.6. Der Kunde darf das Nutzungsrecht einschließlich der Benutzerdokumentation dauerhaft auf einen Dritten
          übertragen, sofern der Dritte den Verpflichtungen aus diesem Vertrag schriftlich zustimmt. Co-Brain ist über
          die Übertragung zu informieren. Bei der Übertragung erlischt das Nutzungsrecht des Kunden.
        </p>
        <p>
          1.3.7. Die Rechte zur Vervielfältigung, Verbreitung, Überlassung, Unterlizenzierung, Bearbeitung und Änderung
          sowie die Nutzung und Vervielfältigung der Ergebnisse und deren Verbreitung sind ansonsten ausgeschlossen.
        </p>
        <p>
          1.3.8. Soweit Open-Source-Software erforderlich ist, beschafft der Kunde diese und die notwendigen Lizenzen
          selbst und stellt sie Co-Brain zur Verfügung.
        </p>
        <p>1.3.9. Gilt auch für alle Weiterentwicklungen, die Co-Brain vertraglich für den Kunden erstellt.</p>
        <h3 id="1-4-verg-tung-und-zahlungsbedingungen">1.4 VERGÜTUNG UND ZAHLUNGSBEDINGUNGEN</h3>
        <p>
          1.4.1. Die vereinbarte Vergütung ist vom Kunden zu zahlen. Bei nicht festgelegter Vergütung gelten die zum
          Vertragsabschluss gültigen Preise von Co-Brain. Die Vergütung erfolgt ab dem Sitz von Co-Brain, sofern nicht
          anders vereinbart.
        </p>
        <p>
          1.4.2. Die Vergütung erfolgt ohne Umsatzsteuer und länderspezifische Abgaben bei Auslandslieferungen.
          Reisekosten werden zusätzlich berechnet. Reisezeiten gelten als Arbeitszeiten.
        </p>
        <p>
          1.4.3. Bei aufwandsbezogener Vergütung sind Abschlagszahlungen nach Aufforderung von Co-Brain zu leisten. Die
          endgültige Vergütung berücksichtigt etwaige Abschlagszahlungen und ist sofort nach Rechnungsstellung zu
          zahlen, sofern nicht anders vereinbart.
        </p>
        <p>
          1.4.4. Bei Pauschalvergütung: 40 % bei Vertragsabschluss; 30 % acht Wochen nach Vertragsabschluss bzw. zum
          vereinbarten Termin und 30 % nach Abnahme.
        </p>
        <p>
          1.4.5. Bei Projekten, die länger als 12 Monate dauern, kann Co-Brain die Vergütung mit dreimonatiger
          Ankündigungsfrist erhöhen, sofern kein Festpreis vereinbart ist. Die Erhöhung darf 5 % pro Jahr nicht
          überschreiten.
        </p>
        <p>
          1.4.6. Alle Forderungen werden sofort fällig bei Nichteinhaltung von Zahlungsterminen ohne Grund oder bei
          wesentlicher Verschlechterung der finanziellen Verhältnisse des Kunden.
        </p>
        <p>
          1.4.7. Zahlungen werden zunächst auf nicht titulierte und nicht rechtshängige Verbindlichkeiten, zuletzt auf
          titulierte angerechnet, jeweils zuerst auf ältere, dann auf jüngere Forderungen.
        </p>
        <p>
          1.4.8. Der Kunde darf nur mit unbestrittenen oder rechtskräftigen Forderungen aufrechnen. Ein
          Zurückbehaltungsrecht besteht nur bei unbestrittenen oder rechtskräftig festgestellten Ansprüchen.
        </p>
        <h3 id="1-5-gew-hrleistung">1.5 GEWÄHRLEISTUNG</h3>
        <p>
          1.5.1. Die Verjährungsfrist für Mängelansprüche beträgt ein Jahr nach Ablieferung (Standardsoftware) oder
          Abnahme (Konzepte oder angepasste, erstellte und installierte Software).
        </p>
        <p>
          1.5.2. Ein Mangel liegt vor, wenn die Vertragssoftware nicht die vereinbarte Beschaffenheit aufweist oder sich
          nicht zur vereinbarten Verwendung eignet. Mängel sind sofort nach Entdeckung mit Beschreibung zu melden.
        </p>
        <p>
          1.5.3. Co-Brain hat eine angemessene Frist zur Nacherfüllung. Co-Brain wählt zwischen Mängelbeseitigung oder
          Neuerbringung. Eine zumutbare Umgehung des Softwaremangels gilt als ausreichende Mangelbeseitigung. Mehr als
          zwei Nachbesserungsversuche sind in der Regel notwendig. Bei unerheblicher Minderung des Werts oder der
          Tauglichkeit ist der Rücktritt ausgeschlossen, ebenso bei Annahmeverzug des Kunden oder überwiegender
          Verantwortung des Kunden für den Mangel. Bei Benutzung der Software ohne Einhaltung der
          Einsatzumgebungsbedingungen entfällt die Nacherfüllungspflicht, es sei denn, der Kunde weist nach, dass der
          Mangel nicht darauf zurückzuführen ist.
        </p>
        <p>
          1.5.4. Bei unberechtigten Mängelrügen trägt der Kunde die Kosten, wenn er das Fehlen des Mangels schuldhaft
          verkannt hat.
        </p>
        <h3 id="1-6-vertragslaufzeit-und-k-ndigung">1.6 VERTRAGSLAUFZEIT UND KÜNDIGUNG</h3>
        <p>
          1.6.1 Eine Auflösung von Vereinbarungen über Anpassung, Erstellung, Installation und Überlassung von Software
          und Konzepten sowie Beratungs- und Schulungsleistungen ist nur nach gesetzlichen Bestimmungen möglich.
        </p>
        <p>
          1.6.2 Ein Softwarewartungsvertrag beginnt zum vereinbarten Datum und hat eine Mindestlaufzeit von 12 Monaten.
          Er verlängert sich um ein Jahr, wenn nicht mit dreimonatiger Frist gekündigt wird. Eine Kündigung einzelner
          Vertragsteile ist möglich, wenn dies für den anderen Vertragspartner zumutbar ist und die Teile funktionell
          trennbar sind. Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt unberührt. Wichtige
          Kündigungsgründe sind unter anderem, a) Unmöglichkeit der Leistungserbringung durch Co- Brain aufgrund eines
          Lieferausfalls; b) Eingriffe des Kunden an der zu wartenden Software; c) durch den Kunden veranlasste
          Änderungen und Erweiterungen, die zu Mehraufwand führen; d) Ablehnung eines neuen Release durch den Kunden;
          oder e) Zahlungsverzug des Kunden über zwei Monate oder Nichtbezahlung zweier Rechnungen innerhalb der Frist.
          Die Kündigung erfordert die Schriftform.
        </p>
        <h3 id="1-7-haftung">1.7 HAFTUNG</h3>
        <p>
          1.7.1. Ein Vertragspartner haftet nur bei schuldhafter Verletzung einer wesentlichen Vertragspflicht oder bei
          Vorsatz oder grober Fahrlässigkeit. Bei nicht vorsätzlicher oder grob fahrlässiger Verletzung einer
          wesentlichen Vertragspflicht ist die Haftung auf vorhersehbare, vertragstypische Schäden begrenzt.
        </p>
        <p>
          1.7.2. Für die Wiederherstellung von Daten haftet Co-Brain nur, wenn der Kunde durch angemessene Maßnahmen,
          insbesondere tägliche Sicherungskopien, die Rekonstruktion mit vertretbarem Aufwand sicherstellt.
        </p>
        <p>
          1.7.3. Haftungsausschluss oder -begrenzung gilt auch für persönliche Haftung von Organen, Angestellten,
          Arbeitnehmern, Mitarbeitern, Vertretern und Unterauftragnehmern.
        </p>
        <p>1.7.4. Die Haftungsbegrenzung gilt nicht für Ansprüche nach dem Produkthaftungsgesetz.</p>
        <p>
          1.7.5. Keine Haftung für Pflichtverletzungen bei höherer Gewalt, z.B. Krieg, Naturkatastrophen, Terrorakte,
          Streik (nicht Aussperrung), Embargo. Streik gilt nicht als höhere Gewalt, wenn er durch rechtswidrige
          Handlungen des Vertragspartners verschuldet wurde. Bei höherer Gewalt verlängern sich die Fristen entsprechend
          der Dauer des Hindernisses. Die betroffene Partei informiert die andere Partei unverzüglich über das Ereignis.
        </p>
        <h3 id="1-8-vertraulichkeit">1.8. VERTRAULICHKEIT</h3>
        <p>
          1.8.1. Jeder Vertragspartner behandelt die vom anderen übermittelten Informationen vertraulich und nutzt sie
          nur zum Vertragszweck. Ausnahmen gelten, wenn die Informationen a) bereits öffentlich waren, b) ohne
          Verschulden des Informationsempfängers öffentlich wurden, c) von einem berechtigten Dritten zugänglich gemacht
          wurden, d) dem Informationsempfänger bereits bekannt waren oder unabhängig entwickelt wurden, e) einem Dritten
          von Co-Brain unter Vertraulichkeitsverpflichtung zur Verfügung gestellt werden, f) einem berufsverschwiegenen
          Berater überlassen werden, g) aufgrund einer behördlichen oder gerichtlichen Entscheidung oder eines Gesetzes
          offenbart werden müssen. Bei Bekanntwerden eines solchen Grundes ist der Informationsgeber unverzüglich zu
          informieren.
        </p>
        <p>
          1.8.2. Die Vertragspartner wenden bei der Geheimhaltung die gleiche Sorgfalt wie bei ihren eigenen
          Betriebsgeheimnissen an und sorgen dafür, dass ihre Mitarbeiter entsprechend verpflichtet sind.
        </p>
        <p>
          1.8.3 Alle Rechte an den Informationen bleiben beim informierenden Vertragspartner. Unabhängig von der
          Laufzeit des Vertrages wird jeder Vertragspartner die ihm übermittelten Informationen für weitere drei Jahre
          nach Erhalt nur gemäß dieser Vereinbarung verwenden.
        </p>
        <h3 id="2-besondere-bestimmungen-f-r-spezifische-leistungen">
          <strong>2. BESONDERE BESTIMMUNGEN FÜR SPEZIFISCHE LEISTUNGEN</strong>
        </h3>
        <p>Diese Bestimmungen gelten für die jeweiligen Leistungen, die Co-Brain im vereinbarten Umfang erbringt:</p>
        <h3 id="2-1-konzepterstellung">2.1. Konzepterstellung</h3>
        <p>
          2.1.1 Co-Brain erstellt ein Konzept in schriftlicher Form basierend auf dem Angebot. Ziel ist es, eine
          datenverarbeitungstechnische Grundlage für individuelle Software oder anzupassende Software zu schaffen.
        </p>
        <p>
          2.1.2. Co-Brain analysiert, bewertet und dokumentiert die Kundenanforderungen. Es werden Funktionen, Aufgaben,
          Arbeitsabläufe, Schnittstellen und das Zusammenwirken der Funktionen sowie die benötigten und zu erzeugenden
          Informationen beschrieben. Das Konzept wird in enger Zusammenarbeit mit dem Kunden erstellt.
        </p>
        <p>
          2.1.3. Der Inhalt des Konzepts ist nur dann eine Beschaffenheitsgarantie, wenn dies ausdrücklich erklärt ist.
          Leistungstermine oder -fristen sind unverbindlich, sofern nicht ausdrücklich als verbindlich zugesagt.
        </p>
        <p>
          2.1.4. Entspricht das Arbeitsergebnis den Vereinbarungen, hat der Kunde die Abnahme unverzüglich, spätestens
          innerhalb von vier Wochen nach Erhalt, schriftlich zu erklären. Bei wesentlichen Abweichungen hat Co-Brain
          diese in angemessener Frist zu beheben. Danach wird das Arbeitsergebnis zur erneuten Abnahme bereitgestellt.
          Die Abnahme kann unter Vorbehalt von Mängelrechten erfolgen.
        </p>
        <p>
          2.1.5. Erklärt der Kunde ohne Gründe die Abnahme nicht, kann Co-Brain eine Frist zur Erklärung setzen. Das
          Arbeitsergebnis gilt mit Fristablauf als abgenommen, wenn der Kunde die Gründe für die Verweigerung nicht
          schriftlich spezifiziert.
        </p>
        <p>
          2.1.6. Co-Brain räumt dem Kunden das nicht ausschließliche und nicht übertragbare Recht ein, das Konzept als
          Grundlage zur Erstellung oder Anpassung seiner Software zu nutzen. Das Recht von Co-Brain, Konzepte für Dritte
          zu erstellen, bleibt unberührt.
        </p>
        <h3 id="2-2-beratungs-und-schulungsleistungen">2.2. Beratungs- und Schulungsleistungen</h3>
        <p>2.2.1. Co-Brain bietet Beratungs- und/oder Schulungsleistungen gemäß dem Angebot.</p>
        <p>
          2.2.2. Sollten die Dienstleistungen nicht vertragsgemäß erbracht worden sein, muss Co- Brain diese innerhalb
          angemessener Frist vertragsgemäß erbringen, sofern der Kunde dies schriftlich gerügt hat.
        </p>
        <h3 id="2-3-berlassung-von-standardsoftware">2.3 Überlassung von Standardsoftware</h3>
        <p>
          2.3.1. Co-Brain überlässt dem Kunden Standardsoftware im Objektprogramm mit Benutzerdokumentation. Der
          Leistungs- und Funktionsumfang bestimmt sich nach der zum Vertragsabschluss gültigen Produktbeschreibung.
        </p>
        <p>
          2.3.2. Die Software ist ablauffähig auf der in der Produktbeschreibung oder im Vertrag genannten Hard- und
          Softwareumgebung. Einsatzumgebungsbedingungen sind in der Produktbeschreibung oder im Angebot angegeben.
          Darüber hinausgehende Vereinbarungen sind abhängig von der kundenspezifischen Situation und gesondert zu
          vereinbaren.
        </p>
        <p>
          2.3.3. Technische Daten und Beschreibungen in der Produktbeschreibung sind keine Beschaffenheitsgarantie, es
          sei denn, sie werden ausdrücklich als solche bezeichnet.
        </p>
        <p>
          2.3.4. Der Kunde erhält eine Kopie der Software auf dem vereinbarten maschinenlesbaren Datenträger und ein
          Exemplar der Benutzerdokumentation. Die Benutzerdokumentation wird druckschriftlich oder maschinenlesbar
          überlassen, gegebenenfalls auf dem gleichen Träger wie die Softwarekopie. Auf Kundenwunsch erfolgt die
          Lieferung auf einem vom Kunden bereitgestellten maschinenlesbaren Datenträger.
        </p>
        <p>
          2.3.5. Zusätzliche Leistungen, wie Installation, Einweisung und Schulung, sind gesondert zu vereinbaren und zu
          vergüten. Die Pflege der Software und kundenspezifische Anpassungen sind nur Gegenstand der Leistungen, wenn
          sie schriftlich vereinbart wurden.
        </p>
        <h3 id="2-4-wartung-von-standardsoftware">2.4. Wartung von Standardsoftware</h3>
        <p>
          2.4.1. Co-Brain führt Wartungsleistungen an der im Angebot bezeichneten Standardsoftware durch. Die
          Wartungsleistungen dienen der Aufrechterhaltung der Funktionsfähigkeit, garantieren jedoch keine störungs- und
          unterbrechungsfreie Arbeitsweise. Verschiedene Service Levels werden angeboten. Art, Umfang und Inhalt der
          Wartungsleistungen bestimmen sich nach dem vereinbarten Service Level. Falls kein Service Level benannt wird,
          erfolgt die Wartung entsprechend des „Silver“ Service Level Agreements in der jeweils gültigen Fassung. Das
          jeweils gültige Service Level Agreement wird auf Nachfrage überlassen.
        </p>
        <p>
          2.4.2. Der Kunde kann zu einem höheren Service Level wechseln, indem er dies Co-Brain spätestens 60 Tage vor
          Vertragsende mitteilt. Der neue Service Level gilt ab Beginn des neuen Vertragsjahres. Die Vergütung richtet
          sich nach der entsprechenden Preisliste.
        </p>
        <p>
          2.4.3. Die Überlassung neuer Programmversionen mit Leistungs- und Funktionserweiterungen kann gegen Vergütung
          Gegenstand eines separaten Software- Überlassungsvertrags sein.
        </p>
        <h3 id="2-5-erstellung-anpassung-und-installation-von-software">
          2.5 Erstellung, Anpassung und Installation von Software
        </h3>
        <p>
          2.5.1. Art und Umfang der Leistungen ergeben sich aus dem Angebot oder dem Konzept, das Co-Brain für den
          Kunden erstellt.
        </p>
        <p>
          2.5.2. Sind die Leistungen funktionsfähig, stellt Co-Brain das Arbeitsergebnis zur Verfügung und teilt die
          Funktionsfähigkeit schriftlich mit. Die Abnahme setzt eine erfolgreiche Funktionsprüfung voraus. Ist das
          Arbeitsergebnis im Wesentlichen vertragsgemäß, hat der Kunde die Abnahme schriftlich zu erklären. Ohne Angabe
          von Gründen gilt das Arbeitsergebnis mit Ablauf einer von Co-Brain gesetzten Frist als abgenommen, wenn der
          Kunde die Gründe für die Verweigerung nicht schriftlich spezifiziert. Bei hinreichend begründeter Nichtabnahme
          ist Co-Brain verpflichtet, nachzubessern.
        </p>
        <p>
          2.5.3. Die Vertragspartner können schriftlich Abnahmekriterien festlegen, die während der Funktionsprüfung
          eingehalten werden müssen.
        </p>
        <p>
          2.5.4. Wesentliche Abweichungen von den vertraglichen Anforderungen hat Co-Brain in angemessener Frist zu
          beheben. Eine wesentliche Abweichung liegt vor, wenn Störungen in Teilen der Programmabläufe oder eine
          nachhaltige Störung des Softwareablaufs mit resultierender Funktionsuntüchtigkeit des Systems auftritt. Danach
          wird das Arbeitsergebnis zur erneuten Abnahme bereitgestellt.
        </p>
        <p>
          2.5.5. Die Abnahme kann auch unter Vorbehalt von Mängelrechten erfolgen. In diesem Fall gilt die Regelung für
          wesentliche Abweichungen, hinsichtlich derer sich der Kunde seine Rechte vorbehalten hat, gleichermaßen.
        </p>
        <p>
          2.5.6. Bei Leistungen mit unterschiedlichen Zeitpunkten beschränkt sich die Funktionsprüfung auf die jeweilige
          Teilleistung. Bei Abnahme der letzten Teilleistung wird das vertragsgemäße Zusammenwirken aller Leistungen
          festgestellt.
        </p>
        <p>
          2.5.7. Jeder Vertragspartner benennt einen verantwortlichen Ansprechpartner für Besprechungen und Auskünfte,
          die das Projekt betreffen. Der Kunde benennt unverzüglich nach Vertragsabschluss die für ihn bindenden
          Personen. Beide Vertragspartner bestimmen jeweils einen Projektleiter, der für alle technischen Fragen
          verantwortlich ist. Bei einem Wechsel des Projektleiters ist der andere Vertragspartner zu informieren.
        </p>
        <h3 id="3-schlussbestimmungen">
          <strong>3. Schlussbestimmungen</strong>
        </h3>
        <p>
          3.1. Das zugrundeliegende Angebot einschließlich etwaiger Ergänzungen ist wesentlicher Bestandteil des
          Vertrages.
        </p>
        <p>
          3.2. Erfüllungsort für die Leistungen von Co-Brain ist der Sitz von Co-Brain. Gerichtsstand für alle
          Streitigkeiten aus dem Vertrag ist das für den Sitz von Co-Brain zuständige Gericht.
        </p>
        <p>
          3.3. Nebenabreden, Änderungen und Ergänzungen des Vertrages bedürfen der Schriftform. Diese
          Schriftformbestimmung kann nur schriftlich aufgehoben werden.
        </p>
        <p>
          3.4. Die Vertragsbeziehungen unterliegen dem Recht der Bundesrepublik Deutschland. Die Anwendung des Wiener
          UNCITRAL-Übereinkommens über internationale Warenkaufverträge und des deutschen Kollisionsrechts ist
          ausgeschlossen.
        </p>
        <p>
          3.5. Sollte der Vertrag eine Lücke enthalten oder eine Bestimmung ganz oder teilweise unwirksam sein, bleibt
          der Vertrag im Übrigen wirksam. Anstelle der fehlenden oder unwirksamen Bestimmung gilt eine dem
          ursprünglichen wirtschaftlichen Zweck am nächsten kommende Regelung. Der Vertrag ist jedoch vollständig
          unwirksam, wenn das Festhalten an ihm auch unter Berücksichtigung der vorgesehenen Änderung eine unzumutbare
          Härte für einen Vertragspartner darstellen würde.
        </p>
        <p>
          3.6. Co-Brain darf ihre Rechte und Pflichten aus dem Vertrag an ihre Konzernobergesellschaft oder verbundene
          Konzerngesellschaften übertragen oder abtreten. Der Kunde darf seine Rechte und Pflichten nur mit
          schriftlicher Zustimmung von Co-Brain übertragen.
        </p>
        <p>
          3.7. Der Kunde wird darauf hingewiesen, dass personenbezogene Daten im Rahmen der Geschäftsbeziehung
          gespeichert und verarbeitet werden, soweit dies zur Vertragserfüllung erforderlich ist.
        </p>
        <p>
          3.8. Der Kunde stimmt zu, dass Co-Brain ihn als Referenzkunden für die im Rahmen dieses Projektes erstellte
          Lösung benennen darf. Dies schließt die Verwendung des Kundennamens und der erstellten Anwendung zu
          Marketingzwecken ein. Lösungen, die von Co-Brain erstellt oder unterstützt wurden, enthalten einen Hinweis auf
          die Nutzung von Produkten und Dienstleistungen der Co-Brain.
        </p>
      </Card>
    </div>
  );
};
