import { sendPostRequest } from '@app/api/http.api';
import { IChecklistReviewResults } from '@app/domain/ChecklistReviewResultsModel';
import { RelevantSentecesResult } from '@app/types/analysisResultTypes';
import { FilterSourceDocument, FilterValdityPeriod, SupportedDocumentType } from '@app/types/chatBoxTypes';
import { ChecklistImportResult } from '@app/types/documentCheckTypes';
import { SuperDoTemplate } from '@app/types/superDoTemplateTypes';
import { BEDROCK_GPT_MODEL, GPT_MODEL, LLM_ANSWER_SIZE_TYPE, LLM_OUTPUT_LANUGAGE_TYPE } from '@app/utils/constants';

export type KnowledgeBaseQueryResponse = { text: string; sourceDocuments: any };

export type DocumentCheckResponse = { text: string; sourceDocuments: any };

type QueryData = { queryText: string; aiModelName?: GPT_MODEL };
type QueryGptData = {
  queryText: string;
  enableHistory?: boolean;
  chatId?: string;
  parameters?: any;
  aiModelName?: GPT_MODEL | BEDROCK_GPT_MODEL;
  outputLanguage?: LLM_OUTPUT_LANUGAGE_TYPE;
  modelResponseLength?: LLM_ANSWER_SIZE_TYPE;
  customSystemPrompt?: string;
} & AnalyticsData;

type BuildSuperDoTemplateData = {
  userTask: string;
  aiModelName?: GPT_MODEL | BEDROCK_GPT_MODEL;
} & AnalyticsData;

type HighlihtSentecesData = {
  queryText: string;
  answer: string;
  sources: string[];
  aiModelName: GPT_MODEL | BEDROCK_GPT_MODEL;
  keywords?: string[];
};
type SourceAuditForApiData = {
  currentSourceInfo: string;
  apiType: SupportedDocumentType;
  userId: string;
  companyId: string;
};
type KeywordsData = {
  text: string;
  userId: string;
  companyId: string;
};
type ExecuteCaveatDocumentAnalysisData = { docUrl: string; docTitle: string; themeCategories: string };
type ExecuteSwatData = { queryText: string; parameters: { name: string; value: string }[] };
type StartAgentData = { goal: string; modelSettings: any };
type ExecuteAgentData = { goal: string; task: string; modelSettings: any };
type CreateAgentData = {
  goal: string;
  tasks: string[];
  lastTask: string;
  result: string;
  completedTasks: string[];
  modelSettings: any;
};
type ExecuteRemotePDFQueryData = { fileName: string; url: string; queryText: string } & AnalyticsData;

type UrlData = { url: string };

type ExecuteKnowledgeBaseQueryData = {
  queryText: string;
  chatId: string;
  namespace: string;
  aiModelName?: GPT_MODEL | BEDROCK_GPT_MODEL;
  maxRetrievalDocs?: number;
  sourceFilters?: FilterSourceDocument[];
  documentValdityPeriodFilter?: FilterValdityPeriod;
  enableConversationMemory?: boolean;
  enableAdvancedKnowledgeRAG?: boolean;
  customSystemPrompt?: string;
  outputLanguage?: LLM_OUTPUT_LANUGAGE_TYPE;
  modelResponseLength?: LLM_ANSWER_SIZE_TYPE;
} & AnalyticsData;

type ExecuteMultiDocumentQueryData = {
  queryText: string;
  chatId: string;
  fileIds: string[];
  aiModelName: GPT_MODEL | BEDROCK_GPT_MODEL;
} & AnalyticsData;

type AnalyticsData = {
  userId?: string;
  companyId?: string;
};

export const executeGptQuery = (data: QueryGptData): Promise<string> => {
  return sendPostRequest<QueryGptData, string>('/api/v1/gpt/execute', data);
};

export const buildSuperDoTemplate = (data: BuildSuperDoTemplateData): Promise<SuperDoTemplate> => {
  return sendPostRequest<BuildSuperDoTemplateData, SuperDoTemplate>('/api/v1/gpt/buildSuperDoTemplate', data);
};

export const executeGptQueryStream = (data: QueryGptData, signal?: AbortSignal): Promise<string> => {
  return sendPostRequest<QueryGptData, string>('/api/v1/gpt/executeAsStream', data, signal);
};

export const markRelevantSentences = (data: HighlihtSentecesData): Promise<RelevantSentecesResult[]> => {
  return sendPostRequest<HighlihtSentecesData, RelevantSentecesResult[]>('/api/v1/gpt/markRelevantSentences', data);
};

export const prepareSourceAuditInfoForApi = (data: SourceAuditForApiData): Promise<string> => {
  return sendPostRequest<SourceAuditForApiData, string>('/api/v1/gpt/prepareSourceAuditInfoForApi', data);
};

export const getRelevantKeywords = (data: KeywordsData): Promise<string[]> => {
  return sendPostRequest<KeywordsData, string[]>('/api/v1/gpt/relevantKeywords', data);
};

export const executeGptQueryStreamWithFormData = (data: FormData, signal?: AbortSignal): Promise<string> => {
  return sendPostRequest<FormData, string>('/api/v1/gpt/executeWithFileAsStream', data, signal);
};

export const executeSuperDoAsStream = (data: FormData, signal?: AbortSignal): Promise<string> => {
  return sendPostRequest<FormData, string>('/api/v1/gpt/executeSuperDoAsStream', data, signal);
};

export const executeGptQuerySpecificEndpoint = (data: QueryGptData, endpoint: string): Promise<string> => {
  return sendPostRequest<QueryGptData, string>(endpoint, data);
};

export const executeCaveatDocumentAnalysis = (data: ExecuteCaveatDocumentAnalysisData): Promise<string> => {
  return sendPostRequest<ExecuteCaveatDocumentAnalysisData, string>('/api/v1/gpt/caveatDocumentAnalysis', data);
};

export const executeTechOfferComponentsAnalysis = (data: FormData): Promise<string> => {
  return sendPostRequest<FormData, string>('/api/v1/gpt/techOfferComponentsAnalysis', data);
};

export const executeSwat = (data: ExecuteSwatData): Promise<string> => {
  return sendPostRequest<ExecuteSwatData, string>('/api/v1/gpt/executeSwat', data);
};

export const startAgent = (data: StartAgentData): Promise<string[]> => {
  return sendPostRequest<StartAgentData, string[]>('/api/v1/gpt/startAgent', data);
};

export const executeAgent = (data: ExecuteAgentData): Promise<string> => {
  return sendPostRequest<ExecuteAgentData, string>('/api/v1/gpt/executeAgent', data);
};

export const createAgent = (data: CreateAgentData): Promise<string[]> => {
  return sendPostRequest<CreateAgentData, string[]>('/api/v1/gpt/createAgent', data);
};

export const executeToolAgent = (data: QueryGptData): Promise<string> => {
  return sendPostRequest<QueryGptData, string>('/api/v1/gpt/executeToolAgent', data);
};

export const executeToolAgentStream = (data: QueryGptData, signal?: AbortSignal): Promise<string> => {
  return sendPostRequest<QueryGptData, string>('/api/v1/gpt/executeToolAgentAsStream', data, signal);
};

export const executeDocsQuery = (data: QueryData): Promise<string> => {
  return sendPostRequest<QueryData, string>('/api/v1/gpt/executeDocsQuery', data);
};

export const executeLegalDraftAgent = (data: QueryData): Promise<string> => {
  return sendPostRequest<QueryData, string>('/api/v1/gpt/executeLegalDraftAgent', data);
};

export const executeSingleDocumentQuery = (data: ExecuteRemotePDFQueryData): Promise<string> => {
  return sendPostRequest<ExecuteRemotePDFQueryData, string>('/api/v1/gpt/executeSingleDocumentQuery', data);
};

export const createDisplayNameFromUrl = (data: UrlData): Promise<string> => {
  return sendPostRequest<UrlData, string>('/api/v1/gpt/createDisplayNameFromUrl', data);
};

export const executeKnowledgeBaseQuery = (data: ExecuteKnowledgeBaseQueryData): Promise<KnowledgeBaseQueryResponse> => {
  return sendPostRequest<ExecuteKnowledgeBaseQueryData, KnowledgeBaseQueryResponse>(
    '/api/v1/gpt/executeKnowledgeBaseQuery',
    data,
  );
};

export const executeKnowledgeBaseQueryStream = (
  data: ExecuteKnowledgeBaseQueryData,
  signal?: AbortSignal,
): Promise<KnowledgeBaseQueryResponse> => {
  return sendPostRequest<ExecuteKnowledgeBaseQueryData, KnowledgeBaseQueryResponse>(
    '/api/v1/gpt/executeKnowledgeBaseQueryAsStream',
    data,
    signal,
  );
};

export const executeKnowledgeBaseQueryStreamPublic = (
  data: ExecuteKnowledgeBaseQueryData,
  signal?: AbortSignal,
): Promise<KnowledgeBaseQueryResponse> => {
  return sendPostRequest<ExecuteKnowledgeBaseQueryData, KnowledgeBaseQueryResponse>(
    '/api/v1/gpt/public/executeKnowledgeBaseQueryAsStream',
    data,
    signal,
  );
};

export const executeMultiDocumentQueryStream = (
  data: ExecuteMultiDocumentQueryData,
  signal?: AbortSignal,
): Promise<KnowledgeBaseQueryResponse> => {
  return sendPostRequest<ExecuteMultiDocumentQueryData, KnowledgeBaseQueryResponse>(
    '/api/v1/gpt/executeMultiDocumentQueryAsStream',
    data,
    signal,
  );
};

export const executeDocumentCheck = (data: FormData): Promise<IChecklistReviewResults> => {
  return sendPostRequest<FormData, IChecklistReviewResults>('/api/v1/gpt/executeDocumentCheck', data);
};

export const extractChecklistCriterias = (data: FormData): Promise<ChecklistImportResult[]> => {
  return sendPostRequest<FormData, ChecklistImportResult[]>('/api/v1/gpt/extractChecklistCriterias', data);
};
