import React from 'react';
import { lightColorsTheme } from '@app/styles/themes/light/lightTheme';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const BackButton = ({
  marginLeft,
  onGoBack,
  style,
}: {
  marginLeft?: string;
  onGoBack?: () => void;
  style?: React.CSSProperties;
}) => {
  const navigation = useNavigate();
  return (
    <Button
      type={'link'}
      onClick={() => (onGoBack ? onGoBack() : navigation(-1))}
      style={{
        ...style,
        backgroundColor: lightColorsTheme.primaryLight,
        marginRight: '1rem',
        marginLeft: marginLeft || 0,
      }}
      icon={<LeftOutlined />}
    />
  );
};

export default BackButton;
