import React, { useMemo } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Select, Button, Row, Modal } from 'antd';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { DocumentChecklist } from '@app/types/documentCheckTypes';
import { BaseSelect } from '../../common/selects/BaseSelect/BaseSelect';
import { themeObject } from '@app/styles/themes/themeVariables';

type ChecklistSelectProps = {
  documentChecklists: DocumentChecklist[];
  value: string;
  setSelectedChecklist: (id: string) => void;
  openEditChecklistModal: (checklist: DocumentChecklist) => void;
  onDeleteDocumentChecklist: (documentChecklistId: string) => void;
};

const { Option } = Select;

export const ChecklistSelect: React.FC<ChecklistSelectProps> = ({
  documentChecklists,
  value,
  setSelectedChecklist,
  openEditChecklistModal,
  onDeleteDocumentChecklist,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { t } = useTranslation();

  const showDeleteConfirm = (checklistId: string) => {
    Modal.confirm({
      title: t('documentChecker.deleteChecklist'),
      icon: <ExclamationCircleOutlined />,
      content: t('documentChecker.areYouSureToDeleteChecklist'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        onDeleteDocumentChecklist(checklistId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const groupedChecklists = useMemo(
    () =>
      documentChecklists.reduce(
        (acc, checklist) => {
          if (!acc[checklist.category]) {
            acc[checklist.category] = [];
          }
          acc[checklist.category].push(checklist);
          return acc;
        },
        {} as Record<string, DocumentChecklist[]>,
      ),
    [documentChecklists],
  );

  const getCategoryText = (category: string) => {
    if (category === 'LEGAL_DOCUMENT') {
      return 'Legal';
    } else if (category === 'TECHNICAL_DOCUMENT') {
      return 'Technical';
    } else {
      return 'Other';
    }
  };

  return (
    <Form.Item labelCol={{ span: 24 }}>
      <BaseSelect
        labelInValue
        value={value}
        onChange={(optionValue: any) => setSelectedChecklist(optionValue?.value ?? '')}
      >
        {Object.entries(groupedChecklists).map(([category, checklists]) => (
          <Select.OptGroup key={category} label={getCategoryText(category)}>
            {checklists.map((checklist) => (
              <Option value={checklist._id} key={checklist._id} style={{ position: 'relative' }} title={checklist.name}>
                <Row justify={'space-between'} align="middle">
                  <span>{checklist.name}</span>
                  <div>
                    <Button
                      key="edit"
                      type="text"
                      icon={<IconPencil size={18} color={themeObject[theme].textLight} />}
                      onClick={(e) => {
                        e.preventDefault();
                        openEditChecklistModal(checklist);
                      }}
                      size="small"
                    />
                    <Button
                      key="delete"
                      type="text"
                      icon={<IconTrash size={18} color={themeObject[theme].textLight} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        showDeleteConfirm(checklist._id ?? '');
                      }}
                      size="small"
                    />
                  </div>
                </Row>
              </Option>
            ))}
          </Select.OptGroup>
        ))}
      </BaseSelect>
    </Form.Item>
  );
};
