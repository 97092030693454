import styled from 'styled-components';
import { DatePicker } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

export const DateItemWrapper = styled.div`
  display: flex;
  max-width: 150px;
  min-height: 28px;
  justify-content: start;
  overflow-x: hidden;

  &:hover .ActionButtons {
    display: block;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  height: auto;
  max-width: 150px;

  & .ant-picker-input > input {
    font-size: ${FONT_SIZE.xs};
  }
`;

export const ButtonsWrapper = styled.div`
  position: relative;
  width: 30px;
`;

export const ActionButtons = styled.div<{ isSelected: boolean }>`
  position: absolute;
  top: 4px;
  right: 0;
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  & > * {
    cursor: pointer;
  }
`;
