import styled from 'styled-components';

export const TabBarWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  background-color: var(--background-color);
`;

export const AddTabButton = styled.div`
  margin-left: 6px;
  margin-right: 6px;
`;
