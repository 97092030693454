import { Input } from 'antd';
import styled from 'styled-components';

type Props = { isSelected: boolean };

export const ConversationItemWrapper = styled.div<Props>`
  background-color: ${(props) => (props.isSelected ? '#f0f0f0' : 'transparent')};
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
  position: relative;
  cursor: pointer;
  overflow-x: hidden;
`;

export const EditInput = styled(Input)`
  background-color: '#f0f0f0';
  padding: 2px;
  max-width: 205px;
  font-size: 1rem;
`;

export const TextContent = styled.p<Props>`
  margin: 0;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
  max-width: ${(props) => (props.isSelected ? '205px' : '230px')};
`;

export const ActionButtons = styled.div<Props>`
  display: ${(props) => (props.isSelected ? 'block' : 'none')};
  position: absolute;
  top: 10px;
  right: 10px;
  margin-left: 10px;

  & > * {
    margin-left: 8px;
    cursor: pointer;
  }
`;
