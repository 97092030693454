import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import * as S from './PasswordForm.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doResetPassword } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';

export const PasswordForm: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onSendPwResetMail = () => {
    setLoading(true);
    dispatch(doResetPassword(user?.email ?? ''))
      .unwrap()
      .then(() => notificationController.success({ message: t('common.pwResetEmailSent') }))
      .finally(() => setLoading(false));
  };

  return user?.email ? (
    <>
      <BaseButtonsForm.Title>{t('profile.nav.securitySettings.changePassword')}</BaseButtonsForm.Title>
      <S.Btn loading={isLoading} type="primary" htmlType="submit" onClick={onSendPwResetMail}>
        {t('profile.nav.securitySettings.sendPasswortResetEmail')}
      </S.Btn>
    </>
  ) : (
    <p>Loading Error</p>
  );
};
