import React from 'react';
import { Checkbox, Row, Col } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { AccessRight } from '@app/domain/AccessRightsModel';
import { SettingsRow } from './KnowledgeAreaSettingsRowItem.styles';

type KnowledgeAreaSettingsRowItemProps = {
  canRead: boolean;
  areaId: string;
  canWrite: boolean;
  title: string;
  description?: string;
  onAreaSelect: (areaId: string, accessRight: AccessRight, status: boolean) => void;
};

export const KnowledgeAreaSettingsRowItem: React.FC<KnowledgeAreaSettingsRowItemProps> = ({
  canRead,
  canWrite,
  title,
  areaId,
  description,
  onAreaSelect,
}) => {
  return (
    <SettingsRow justify={'center'} align={'middle'}>
      <Col span={4} flex={'column'}>
        <Row justify={'center'}>
          <Checkbox
            value="canRead"
            checked={canRead}
            onChange={(e) => onAreaSelect(areaId, 'READ_ONLY', e.target.checked)}
          />
        </Row>
      </Col>
      <Col span={4}>
        <Row justify={'center'}>
          <Checkbox
            value="canWrite"
            checked={canWrite}
            onChange={(e) => onAreaSelect(areaId, 'WRITE_AND_READ', e.target.checked)}
          />
        </Row>
      </Col>
      <Col span={16}>
        <NormalText size="m">{title}</NormalText>
        {description && (
          <NormalText colorType="light" size="xs">
            {description}
          </NormalText>
        )}
      </Col>
    </SettingsRow>
  );
};
