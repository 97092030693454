import { BREAKPOINTS, media } from '@app/styles/themes/constants';
import { Tag } from 'antd';
import styled from 'styled-components';

export const MessageWrapper = styled('div')`
  padding-bottom: 60px;
  width: 100%;
`;

export const UploadedDocumentsWrapper = styled('div')`
  width: 100%;
  @media only screen and ${media.xl} {
    width: ${BREAKPOINTS.lg}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const DocumentTagStyled = styled(Tag)`
  color: var(--text-light-color);
  background-color: var(--white);
  border: 0.5px solid var(--border-color);
  margin: 0px 8px 4px 0px;
  font-size: 0.875rem;
`;

export type MessageDateWrapperProps = { showDate: boolean };

export const MessageDateWrapper = styled('div')<MessageDateWrapperProps>`
  margin-top: ${(props) => (props.showDate ? '1.25rem' : '2rem')};
  margin-bottom: 1rem;
  width: 100%;
`;

export const ChooseKnowledgeAreaWrapper = styled('div')`
  display: grid;
  justify-items: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

export const ExampleHeaderWrapper = styled('div')`
  display: grid;
  justify-items: center;
  align-items: center;
  margin-top: 2rem;
`;

export const ExampleQuestionsWrapper = styled('div')`
  max-width: '800px' !important;
  padding: 16;
  margin-top: 2rem;
`;

export const ErrorWrapper = styled('div')`
  padding: 16px;
  background-color: var(--error-background-color);
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  @media only screen and ${media.xl} {
    width: ${BREAKPOINTS.lg}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ErrorMessageWrapper = styled('div')`
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  @media only screen and ${media.xl} {
    width: ${BREAKPOINTS.lg}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ExternalLinkWrapper = styled('a')``;

export const ChatIdeaBlock = styled.div`
  background-color: var(--primary-light-color);
  padding: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0 auto;
  height: auto;
  border-radius: 8px;
  margin: 6px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`;

export const ModeTag = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid var(--border-color);
`;
