import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import PageContainer from '@app/components/layouts/main/PageContainer/PageContainer';
import { GptAgentsOverview } from '@app/components/gptagents/GptAgentsOverview';

const GPTAgents: React.FC = () => {
  return (
    <PageContainer>
      <GptAgentsOverview />
    </PageContainer>
  );
};

export default GPTAgents;
