import antrophicIcon from '@app/assets/images/antrophic-claude.png';
import openaiIcon from '@app/assets/images/openai.png';
import { UserModel } from '@app/domain/UserModel';
import { LanguageType } from '@app/interfaces/interfaces';

export const GPT_35_TURBO = 'gpt-3.5-turbo';
export const GPT_4 = 'gpt-4';
export const GPT_4o = 'gpt-4o';
export const GPT_4o_mini = 'gpt-4o-mini';
export const GPT_o1_preview = 'o1-preview';
export const GPT_4_32k = 'gpt-4-32k';

export const CLAUDE_V3_HAIKU = 'anthropic.claude-3-haiku-20240307-v1:0';
export const CLAUDE_V3_SONNET = 'anthropic.claude-3-sonnet-20240229-v1:0';
export const CLAUDE_V3_5_SONNET = 'anthropic.claude-3-5-sonnet-20240620-v1:0';
export const LLAMA_3_1_8B_INSTRUCT = 'meta.llama3-1-8b-instruct-v1:0';
export const LLAMA_3_1_70B_INSTRUCT = 'meta.llama3-1-70b-instruct-v1:0';
export const LLAMA_3_1_405B_INSTRUCT = 'meta.llama3-1-405b-instruct-v1:0';

export type BEDROCK_GPT_MODEL =
  | 'anthropic.claude-v2:1'
  | 'anthropic.claude-3-haiku-20240307-v1:0'
  | 'anthropic.claude-3-sonnet-20240229-v1:0'
  | 'anthropic.claude-3-5-sonnet-20240620-v1:0';

export type GPT_MODEL = 'gpt-3.5-turbo' | 'gpt-4' | 'gpt-4-32k' | 'gpt-4o' | 'gpt-4o-mini' | 'o1-preview';
export const GPT_MODEL_NAMES = [GPT_35_TURBO, GPT_4, GPT_4o];

export const enableBedrockModels = process.env.REACT_APP_ENABLE_BEDROCK_MODELS === 'true';
export const enableO1Models = process.env.REACT_APP_ENABLE_O1_MODELS === 'true';

export type LLM_MODEL_SELECTION = {
  value: GPT_MODEL | BEDROCK_GPT_MODEL;
  label: string;
  image: string;
  disabled?: boolean;
};

export const GPT_MODEL_SELECTION: LLM_MODEL_SELECTION[] = [
  { value: GPT_35_TURBO, label: 'GPT-3.5 Turbo', image: openaiIcon },
  { value: GPT_4o_mini, label: 'GPT-4o-mini', image: openaiIcon },
  { value: GPT_4o, label: 'GPT-4o', image: openaiIcon },
  { value: GPT_o1_preview, label: 'o1-preview', image: openaiIcon, disabled: !enableO1Models },
  {
    value: CLAUDE_V3_HAIKU,
    label: 'Claude 3 Haiku',
    image: antrophicIcon,
    disabled: !enableBedrockModels,
  },
  {
    value: CLAUDE_V3_SONNET,
    label: 'Claude 3 Sonnet',
    image: antrophicIcon,
    disabled: !enableBedrockModels,
  },
  {
    value: CLAUDE_V3_5_SONNET,
    label: 'Claude 3.5 Sonnet',
    image: antrophicIcon,
    disabled: !enableBedrockModels,
  },
];

export type LLM_MODEL_SETTINGS_TYPE = {
  label: string;
  image: string;
  models: { value: GPT_MODEL | BEDROCK_GPT_MODEL; label: string; image: string; disabled: boolean }[];
};

export const GPT_MODEL_SETTINGS: LLM_MODEL_SETTINGS_TYPE = {
  label: 'OpenAI',
  image: openaiIcon,
  models: [
    { value: GPT_35_TURBO, label: 'GPT-3.5 Turbo', image: openaiIcon, disabled: false },
    { value: GPT_4o_mini, label: 'GPT-4o-mini', image: openaiIcon, disabled: false },
    { value: GPT_4o, label: 'GPT-4o', image: openaiIcon, disabled: false },
    { value: GPT_o1_preview, label: 'o1-preview', image: openaiIcon, disabled: !enableO1Models },
  ],
};

export const ANTHROPIC_MODEL_SETTINGS: LLM_MODEL_SETTINGS_TYPE = {
  label: 'Anthropic',
  image: antrophicIcon,
  models: [
    { value: CLAUDE_V3_HAIKU, label: 'Claude 3 Haiku', image: antrophicIcon, disabled: !enableBedrockModels },
    { value: CLAUDE_V3_SONNET, label: 'Claude 3 Sonnet', image: antrophicIcon, disabled: !enableBedrockModels },
    { value: CLAUDE_V3_5_SONNET, label: 'Claude 3.5 Sonnet', image: antrophicIcon, disabled: !enableBedrockModels },
  ],
};

export const LLM_MODEL_SELECTION: LLM_MODEL_SETTINGS_TYPE[] = [GPT_MODEL_SETTINGS, ANTHROPIC_MODEL_SETTINGS];

export type LLM_ANSWER_SIZE_TYPE = 'auto' | 'minimal' | 'detailed';
export type LLM_OUTPUT_LANUGAGE_TYPE = 'auto' | LanguageType;
export type LLM_SYSTEM_PROMPT = 'default' | string;

export const AUTO_ANSWER_SIZE = 'auto';
export const MINIMAL_ANSWER_SIZE = 'minimal';
export const DETAILED_ANSWER_SIZE = 'detailed';

export const ANSWER_SIZE_SELECTION: LLM_ANSWER_SIZE_TYPE[] = [MINIMAL_ANSWER_SIZE, DETAILED_ANSWER_SIZE];

export type DEFAULT_APP_LANGUAGES = 'en' | 'de';

export const DEFAULT_MAX_LOOPS_FREE = 10;
export const DEFAULT_MAX_LOOPS_PAID = 10;
export const DEFAULT_MAX_LOOPS_CUSTOM_API_KEY = 10;

export type ApiIntegrationType = 'ms-sql-server' | 'google-drive' | 'notion';
export type AgentTaskType = 'knowledge-search' | 'general';

export const API_INTEGRATIONS = { msSqlServer: 'ms-sql-server', googleDrive: 'google-drive', notion: 'notion' };

export type INTERACTION_MODE = 'AI_ONLY' | 'WEB' | 'IMAGE_GENERATION' | 'DOCUMENT_ANALYSIS';

export type SOURCE_AUDIT_INTERACTION_ID = 'SHOW_PDF_VIEWER';

export const EXTERNAL_USER_ID = 'EXTERNAL_USER';

export const DeletedUser: UserModel = {
  email: '-',
  emailVerified: false,
  firstName: 'Unknown',
  lastName: 'User',
  role: 'EMPLOYEE',
  title: '',
  belongsToDepartmentIds: [],
  belongsToCompanyId: null,
  phoneNumber: null,
  _id: '',
};
