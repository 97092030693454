import styled from 'styled-components';
import { Button, Tag } from 'antd';

export const ActionsContainer = styled.div`
  position: sticky;
  z-index: 5;
  top: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  color: var(--text-light-color);
  background-color: var(--secondary-background-color);
`;

export const TagsWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0.2rem 2rem;
`;

export const ActionButtonWrapper = styled(Button)`
  height: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--text-light-color);
  padding: 0;
`;

export const TagStyled = styled(Tag)`
  color: var(--text-light-color);
  background-color: var(--white);
  border: 0.5px solid var(--border-color);
  margin: 4px 0px 4px 16px;
  font-size: 0.875rem;
`;
