import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import * as S from './TermsOfUse.styles';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { CenteredLogo } from '../layouts/main/sider/CenteredLogo';

export const DataPrivacy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <CenteredLogo />
      <Card>
        <NormalText bold size="l">
          {t('signup.privacyOPolicy')} - Co-Brain.ai
        </NormalText>
        <S.Description>As of July 1, 2024</S.Description>
      </Card>
    </div>
  );
};
