import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '@app/store/slices/userSlice';
import legalDraftReducer from '@app/store/slices/legalDraftSlice';
import companyReducer from '@app/store/slices/companySlice';
import uploadedDocumentsReducer from '@app/store/slices/uploadedDocumentsSlice';
import authReducer from '@app/store/slices/authSlice';
import authExternalAccessTokenReducer from '@app/store/slices/authExternalAccessTokenSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import caveatReducer from '@app/store/slices/caveatSlice';
import techOfferReducer from '@app/store/slices/techOfferSlice';
import departmentReducer from '@app/store/slices/departmentSlice';
import settingsReducer from '@app/store/slices/settingsSlice';
import iframeMessagesReducer from '@app/store/slices/iframeMessagesSlice';
import chatBoxReducer from '@app/store/slices/chatBoxSlice';
import knowledgeAreaReducer from '@app/store/slices/knowledgeAreaSlice';
import superDoReducer from '@app/store/slices/superDoSlice';
import appLayoutReducer from '@app/store/slices/appLayoutSlice';
import superDoAreasReducer from '@app/store/slices/superDoAreaSlice';
import documentAnalysisReducer from '@app/store/slices/documentAnalysisSlice';
import knowledgeAreaFilterReducer from '@app/store/slices/knowledgeAreaFilterSlice';
import menuFolderReducer from '@app/store/slices/menuFolderSlice';
import socketAppEventReducer from '@app/store/slices/socketAppEventSlice';

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  authExternalAccessToken: authExternalAccessTokenReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  legalDraft: legalDraftReducer,
  company: companyReducer,
  uploadedDocuments: uploadedDocumentsReducer,
  caveat: caveatReducer,
  techOffer: techOfferReducer,
  department: departmentReducer,
  settings: settingsReducer,
  chatBox: chatBoxReducer,
  knowledgeArea: knowledgeAreaReducer,
  superDos: superDoReducer,
  appLayout: appLayoutReducer,
  superDoAreas: superDoAreasReducer,
  iframeMessages: iframeMessagesReducer,
  documentAnalysis: documentAnalysisReducer,
  knowledgeAreaFilter: knowledgeAreaFilterReducer,
  menuFolder: menuFolderReducer,
  socketAppEvent: socketAppEventReducer,
});

export default rootReducer;
