import { ChatMode, IChatHistory } from '@app/domain/ChatHistoryModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { createChatHistory, readChatHistoryOfUser } from '@app/api/chatHistory.api';
import { setSelectedChatId } from './settingsSlice';
import { INTERACTION_MODE } from '@app/utils/constants';
import moment from 'moment';
import { ChatHistoryItemsPerPage } from '@app/hooks/useHandleChatHistory';

export type TriggerActionId = 'RESET_MESSAGES' | 'SHOW_LLM_SETTINGS_MODAL' | 'SHOW_LLM_SETTINGS_DRAWER' | null;

export interface ChatBoxState {
  triggerActionId: TriggerActionId;
  editMessageId: string | null;
  loadedChatHistory: IChatHistory[];
  intialAppDataLoaded: boolean;
  moreHistoryResultsAvailable: boolean;
  loadedChatHistoryPage: number;
}

const initialState: ChatBoxState = {
  triggerActionId: null,
  editMessageId: null,
  loadedChatHistory: [],
  intialAppDataLoaded: false,
  moreHistoryResultsAvailable: false,
  loadedChatHistoryPage: 1,
};

export const chatBoxSlice = createSlice({
  name: 'chatBoxSlice',
  initialState,
  reducers: {
    setTriggerActionId: (state, { payload }: PayloadAction<{ value: TriggerActionId }>) => {
      state.triggerActionId = payload.value;
    },
    setLoadedChatHistory: (state, { payload }: PayloadAction<{ value: IChatHistory[] }>) => {
      state.loadedChatHistory = payload.value;
    },
    setLoadedChatHistoryPage: (state, { payload }: PayloadAction<{ value: number }>) => {
      state.loadedChatHistoryPage = payload.value;
    },
    setEditMessageId: (state, { payload }: PayloadAction<{ value: string | null }>) => {
      state.editMessageId = payload.value;
    },
    setIntialAppDataLoaded: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.intialAppDataLoaded = payload.value;
    },
    setMoreHistoryResultsAvailable: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.moreHistoryResultsAvailable = payload.value;
    },
    resetHistorySettingsState: (state) => {
      state.loadedChatHistory = initialState.loadedChatHistory;
      state.loadedChatHistoryPage = initialState.loadedChatHistoryPage;
      state.moreHistoryResultsAvailable = initialState.moreHistoryResultsAvailable;
    },
  },
});

export const onCreateNewChatHistory =
  (title: string, chatMode: ChatMode, aiInteractionMode: INTERACTION_MODE) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const userId = getState().user.user?._id;
      if (userId) {
        const loadedChatHistory = getState().chatBox.loadedChatHistory;
        const numberNewConversationTitles = loadedChatHistory.filter(
          (item) => item.chatMode == chatMode && item.title?.includes(title),
        ).length;
        const newChatHistory: IChatHistory = {
          chatMode,
          aiInteractionMode,
          userId: userId,
          title: numberNewConversationTitles ? `${title} (${numberNewConversationTitles + 1})` : title,
          messages: [],
          uploadedFiles: 0,
          createdAt: moment().unix(),
        };
        const id = await createChatHistory(newChatHistory);
        newChatHistory._id = id;
        dispatch(setSelectedChatId({ value: id }));
        dispatch(setLoadedChatHistory({ value: [...loadedChatHistory, newChatHistory] }));
      }
    } catch (error) {
      console.log('****** onCreateNewChatHistory error: ', error);
    }
  };

export const loadChatHistory =
  (page = 1, limit = 100) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const loadedChatHistory = getState().chatBox.loadedChatHistory || [];
      const result = await readChatHistoryOfUser(getState().user.user?._id ?? '', 'CHAT_GPT', page, limit);

      const sortedResult = (result || []).sort((a, b) => {
        const aTimestamp = a.lastInteractionTimestamp ?? a.createdAt ?? 0;
        const bTimestamp = b.lastInteractionTimestamp ?? b.createdAt ?? 0;
        return aTimestamp - bTimestamp;
      });
      dispatch(setMoreHistoryResultsAvailable({ value: sortedResult.length === ChatHistoryItemsPerPage }));
      dispatch(setLoadedChatHistory({ value: [...loadedChatHistory, ...sortedResult] }));
      return sortedResult;
    } catch (error) {
      console.log('****** loadChatHistory error: ', error);
      throw error;
    }
  };

export const {
  setTriggerActionId,
  setLoadedChatHistory,
  setEditMessageId,
  setIntialAppDataLoaded,
  setLoadedChatHistoryPage,
  setMoreHistoryResultsAvailable,
  resetHistorySettingsState,
} = chatBoxSlice.actions;

export default chatBoxSlice.reducer;
