import React from 'react';
import { Button, Card, Image, Modal, Row } from 'antd';
import { ExampleQuestionsWrapper } from './GptChatBox.styles';
import StartAutomation from 'assets/images/undraw_User_flow_re_bvfx.png';
import { ButtonWrapper, StyledCentredTitle } from '@app/pages/Dashboard/Dashboard.styles';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getWorkflowEngineBaseURL } from '@app/api/http.api';

const AutomationsOverview: React.FC = () => {
  const { t } = useTranslation();
  const onCreateClick = () => {
    if (process.env.REACT_APP_N8N_ENABLE === 'true') {
      window.open(getWorkflowEngineBaseURL(), '_blank');
    } else {
      Modal.confirm({
        title: t('workflowEngineNotActivated'),
        icon: <InfoCircleOutlined />,
        content: t('pleaseContactSupport'),
        okText: t('ok'),
      });
    }
  };

  return (
    <Card style={{ width: '100%', height: `calc(100vh - 6rem)`, padding: 32 }}>
      <ExampleQuestionsWrapper>
        <StyledCentredTitle level={3}>Workflow Automations</StyledCentredTitle>
        <Row justify={'center'}>
          <Image
            src={StartAutomation}
            alt="Start search"
            preview={false}
            height={200}
            style={{ objectFit: 'contain' }}
          />
        </Row>
        <ButtonWrapper>
          <Button type="primary" onClick={onCreateClick}>
            {t('editWorkflows')}
          </Button>
        </ButtonWrapper>
      </ExampleQuestionsWrapper>
    </Card>
  );
};

export default AutomationsOverview;
