import { sendPostRequest } from '@app/api/http.api';
import { DEFAULT_APP_LANGUAGES, GPT_MODEL } from '@app/utils/constants';

export type KnowledgeBaseQueryResponse = { text: string; sourceDocuments: any };

type ExecuteKnowledgeBaseQueryData = {
  queryText: string;
  chatId: string;
  knowledgeAreaId: string;
  selectedLanguage?: DEFAULT_APP_LANGUAGES;
  aiModelName?: GPT_MODEL;
  maxRetrievalDocs?: number;
  enableHistory?: boolean;
  toUseFunctionsDescription?: string;
} & AnalyticsData;

type ExecutePublicKnowledgeBaseQueryData = {
  queryText: string;
  chatId: string;
  knowledgeAreaId: string;
  accessToken: string;
  selectedLanguage?: DEFAULT_APP_LANGUAGES;
  aiModelName?: GPT_MODEL;
  maxRetrievalDocs?: number;
  enableHistory?: boolean;
  toUseFunctionsDescription?: string;
} & AnalyticsData;

type AnalyticsData = {
  userId?: string;
  companyId?: string;
};

export const knowledgeVoiceAssistantQueryAsStream = (
  data: ExecuteKnowledgeBaseQueryData,
  signal?: AbortSignal,
): Promise<KnowledgeBaseQueryResponse> => {
  return sendPostRequest<ExecuteKnowledgeBaseQueryData, KnowledgeBaseQueryResponse>(
    '/api/v1/gptVoiceAssistant/knowledgeVoiceAssistantQueryAsStream',
    data,
    signal,
  );
};

export const knowledgeVoiceAssistantQueryAsStreamPublic = (
  data: ExecutePublicKnowledgeBaseQueryData,
  signal?: AbortSignal,
): Promise<KnowledgeBaseQueryResponse> => {
  return sendPostRequest<ExecutePublicKnowledgeBaseQueryData, KnowledgeBaseQueryResponse>(
    '/api/v1/gptVoiceAssistant/public/knowledgeVoiceAssistantQueryAsStream',
    data,
    signal,
  );
};
