import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileType } from '@app/domain/UploadedDocumentModel';
import { DocumentFilterType } from '@app/types/documentFilterTypes';

export interface KnowledgeAreaFilter {
  validFrom?: number;
  validUntil?: number;
  selectedDocuments: DocumentFilterType[];
  filterDocumentTypes: FileType[];
  selectedWebsites: DocumentFilterType[];
}

export interface KnowledgeAreaFilterState {
  [key: string]: KnowledgeAreaFilter;
}

const initialState: KnowledgeAreaFilterState = {};

export const knowledgeAreaFilterSlice = createSlice({
  name: 'knowledgeAreaFilterSlice',
  initialState,
  reducers: {
    setKnowledgeAreaFilter: (
      state,
      { payload }: PayloadAction<{ knowledgeAreaId: string; value: KnowledgeAreaFilter }>,
    ) => {
      state[payload.knowledgeAreaId] = payload.value;
    },
    resetKnowledgeAreaFilter: (state, { payload }: PayloadAction<{ value: string }>) => {
      const updatedState = { ...state };
      delete updatedState[payload.value];
      return updatedState;
    },
  },
});

export const { setKnowledgeAreaFilter, resetKnowledgeAreaFilter } = knowledgeAreaFilterSlice.actions;

export default knowledgeAreaFilterSlice.reducer;
