import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Card } from '@app/components/common/Card/Card';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { WhiteLabelSettings } from './WhiteLabelSettings';

export const PlatformSettingsOverview: React.FC = () => {
  const { t } = useTranslation();

  const items: TabsProps['items'] = [
    { key: '1', label: t('generalSettings.whiteLabelHeader'), children: <WhiteLabelSettings /> },
  ];

  return (
    <Card>
      <BaseButtonsForm.Item>
        <NormalText bold size="l">
          {t('generalSettings.headerTitle')}
        </NormalText>
      </BaseButtonsForm.Item>
      <Tabs defaultActiveKey="1" items={items} />
    </Card>
  );
};
