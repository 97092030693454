import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Card } from '@app/components/common/Card/Card';
import { FirstNameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/FirstNameItem/FirstNameItem';
import { LanguageItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/LanguageItem/LanguageItem';
import { EmailItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/EmailItem/EmailItem';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { LastNameItem } from './LastNameItem/LastNameItem';
import { JobTitleItem } from './JobTitleItem/JobTitleItem';
import { updateUserData } from '@app/store/slices/userSlice';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { useLanguage } from '@app/hooks/useLanguage';

interface PersonalInfoFormValues {
  lastName: string;
  firstName: string;
  title?: string;
  language?: string;
  email: string;
}

const initialPersonalInfoValues: PersonalInfoFormValues = {
  firstName: '',
  lastName: '',
  title: '',
  language: undefined,
  email: '',
};

export const PersonalInfo: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const departments = useAppSelector((state) => state.department.departments);

  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { setLanguage } = useLanguage();

  const dispatch = useAppDispatch();

  const userFormValues = useMemo(
    () =>
      user
        ? {
            firstName: user.firstName,
            lastName: user.lastName,
            title: user.title,
            email: user.email,
            departmentIds: user.belongsToDepartmentIds,
          }
        : initialPersonalInfoValues,
    [user],
  );

  const [form] = BaseButtonsForm.useForm();

  const { t } = useTranslation();

  const onFinish = useCallback(() => {
    // todo dispatch an action here
    setLoading(true);
    setLoading(false);
    setFieldsChanged(false);

    const firstName = form.getFieldValue('firstName') ?? '';
    const lastName = form.getFieldValue('lastName') ?? '';
    const title = form.getFieldValue('title') ?? '';
    const belongsToDepartmentIds = form.getFieldValue('departmentIds') ?? [];

    dispatch(updateUserData({ userData: { firstName, lastName, title, belongsToDepartmentIds } }))
      .unwrap()
      .then(() => {
        notificationController.success({ message: t('common.success') });
      })
      .catch((e) => {
        notificationController.error({ message: e.message });
      });
    const langCode = form.getFieldValue('language') ?? 'en';
    setLanguage(langCode);
  }, [form, dispatch, t]);

  return (
    <>
      <BaseButtonsForm
        form={form}
        name="info"
        loading={isLoading}
        initialValues={userFormValues}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          <Col span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>{t('profile.nav.personalInfo.title')}</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </Col>

          <Col xs={24} md={12}>
            <FirstNameItem />
          </Col>

          <Col xs={24} md={12}>
            <LastNameItem />
          </Col>

          <Col xs={24} md={12}>
            <JobTitleItem />
          </Col>

          <Col xs={24} md={12}>
            <BaseButtonsForm.Item name="departmentIds" label={t('profile.nav.personalInfo.departments')}>
              <BaseSelect
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Bitte wählen Sie Ihre Abteilung"
                options={departments.map((item) => ({ value: item._id, label: item.name }))}
              />
            </BaseButtonsForm.Item>
          </Col>

          <Col xs={24} md={12}>
            <EmailItem verified={user?.emailVerified} />
          </Col>

          <Col xs={24} md={12}>
            <LanguageItem />
          </Col>
        </Row>
      </BaseButtonsForm>
    </>
  );
};
