import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Input, Dropdown, Menu, Row, InputProps } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { MenuNode } from '@app/types/menuFolderTypes';
import { ColorsContainer, ColorItem, ColorPickerWrapper } from './ModalCreateMenuFolder.styles';

type ModalCreateMenuFolderProps = {
  showCreateMenuFolderModal: boolean;
  onCloseModal: () => void;
  onCreate: (menuNode: MenuNode) => void;
  onUpdate: (menuNode: MenuNode) => void;
  isEditMode: boolean;
  folderToEdit: MenuNode | null;
};

const DEFAULT_COLOR = '#B3DCF5';
const PREDEFINED_COLORS = [
  '#B3DCF5',
  '#CDC8F8',
  '#EBB6FF',
  '#C8F1C1',
  '#C0E9DB',
  '#FDE6B1',
  '#F6D0B6',
  '#F1E0C1',
  '#E4C1B5',
  '#FDB3B8',
  '#CECECE',
  '#9AA1B4',
];

export const ModalCreateMenuFolder: React.FC<ModalCreateMenuFolderProps> = ({
  showCreateMenuFolderModal,
  onCloseModal,
  onCreate,
  onUpdate,
  isEditMode,
  folderToEdit,
}) => {
  const { t } = useTranslation();

  const [color, setColor] = useState<string>(DEFAULT_COLOR);

  const [form] = Form.useForm();

  const handleCancel = () => {
    onCloseModal();
    form.resetFields();
  };
  useEffect(() => {
    if (isEditMode && folderToEdit) {
      form.setFieldValue('name', folderToEdit.name);
      setColor(folderToEdit.hexColor);
    }
  }, [isEditMode, folderToEdit]);

  const handleCreateFolder = () => {
    form
      .validateFields()
      .then(async (values) => {
        const folderToSave: MenuNode = {
          name: values.name,
          id: uuidv4(),
          isFolder: true,
          children: [],
          hexColor: color,
        };
        if (isEditMode && folderToEdit) {
          onUpdate({ ...folderToEdit, name: values.name, hexColor: color });
        } else {
          onCreate(folderToSave);
        }
        handleCancel();
      })
      .catch(() => {
        console.log('Validate Failed');
      });
  };

  // Need this component to solve issue with ant design nested form item
  const CombinedInput = (props: InputProps) => (
    <Row align={'middle'}>
      <ColorPickerWrapper>
        <Dropdown
          overlay={
            <Menu>
              <Menu.ItemGroup style={{ padding: '0rem 0.5rem 0.5rem 0.5rem' }}>
                <ColorsContainer>
                  {PREDEFINED_COLORS.map((color) => (
                    <ColorItem key={color} color={color} onClick={() => setColor(color)} />
                  ))}
                </ColorsContainer>
              </Menu.ItemGroup>
            </Menu>
          }
          trigger={['click']}
        >
          <ColorItem color={color} />
        </Dropdown>
      </ColorPickerWrapper>
      <Input
        maxLength={50}
        showCount
        placeholder={t('folderTitle')}
        style={{ width: 'auto', flexGrow: 2 }}
        {...props}
      />
    </Row>
  );

  return (
    <Modal
      title={t(isEditMode ? 'editFolder' : 'createNewFolder')}
      open={showCreateMenuFolderModal}
      onCancel={handleCancel}
      onOk={handleCreateFolder}
      okText={isEditMode ? t('edit') : t('create')}
      cancelText={t('common.cancel')}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label={t('selectColorAndFolderTitle')}
          labelCol={{ span: 18 }}
          rules={[{ required: true, message: t('pleaseEnterTitle') }]}
        >
          <CombinedInput />
        </Form.Item>
      </Form>
    </Modal>
  );
};
