import { InlineSourceDocument } from '@app/types/chatBoxTypes';
import {
  INTERACTION_MODE,
  BEDROCK_GPT_MODEL,
  DEFAULT_APP_LANGUAGES,
  GPT_4o,
  GPT_MODEL,
  LLM_ANSWER_SIZE_TYPE,
  LLM_OUTPUT_LANUGAGE_TYPE,
  SOURCE_AUDIT_INTERACTION_ID,
  LLM_SYSTEM_PROMPT,
} from '@app/utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SettingsState {
  gptChatBoxLLM: GPT_MODEL | BEDROCK_GPT_MODEL;
  gptSuperDoLLM: GPT_MODEL | BEDROCK_GPT_MODEL;
  modelLanguage: LLM_OUTPUT_LANUGAGE_TYPE;
  modelResponseLength: LLM_ANSWER_SIZE_TYPE;
  customSuperDoSystemPrompt: LLM_SYSTEM_PROMPT;
  customSystemPrompt: LLM_SYSTEM_PROMPT;
  aiInteractionMode: INTERACTION_MODE;
  sourceAuditInteractionData: { id: SOURCE_AUDIT_INTERACTION_ID; data?: InlineSourceDocument } | null;
  conversationMemoryKnowledgeActive: boolean;
  advancedKnowledgeRAGActive: boolean;
  turboSpeechModelActive: boolean;
  waitSpeechTextActive: boolean;
  contextSizeVoiceAssistant: 'small' | 'normal' | 'large';
  waitSpeechVideoAssistantTextActive: boolean;
  conversationMemoryVideoAssistantActive: boolean;
  webBrowserSpeechRecognitionActive: boolean;
  contextSizeVideoAssistant: 'small' | 'normal' | 'large';
  videoAssistantVideoQuality: 'low' | 'medium' | 'high';
  videoAssistantLanguage: DEFAULT_APP_LANGUAGES;
  selectedChatId: string | null;
  lastSelectedKnowledgeAreaId: string | null;
}

const initialState: SettingsState = {
  gptChatBoxLLM: GPT_4o,
  gptSuperDoLLM: GPT_4o,
  modelLanguage: 'auto',
  modelResponseLength: 'auto',
  aiInteractionMode: 'AI_ONLY',
  customSuperDoSystemPrompt: 'default',
  customSystemPrompt: 'default',
  sourceAuditInteractionData: null,
  conversationMemoryKnowledgeActive: false,
  advancedKnowledgeRAGActive: false,
  turboSpeechModelActive: false,
  waitSpeechTextActive: false,
  contextSizeVoiceAssistant: 'small',
  waitSpeechVideoAssistantTextActive: false,
  conversationMemoryVideoAssistantActive: false,
  webBrowserSpeechRecognitionActive: false,
  contextSizeVideoAssistant: 'small',
  videoAssistantVideoQuality: 'low',
  videoAssistantLanguage: 'en',
  selectedChatId: null,
  lastSelectedKnowledgeAreaId: null,
};

export const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    setGptChatBoxLLM: (state, { payload }: PayloadAction<{ value: GPT_MODEL | BEDROCK_GPT_MODEL }>) => {
      state.gptChatBoxLLM = payload.value;
    },
    setGptSuperDoLLM: (state, { payload }: PayloadAction<{ value: GPT_MODEL | BEDROCK_GPT_MODEL }>) => {
      state.gptSuperDoLLM = payload.value;
    },
    setLLMLanguage: (state, { payload }: PayloadAction<{ value: LLM_OUTPUT_LANUGAGE_TYPE }>) => {
      state.modelLanguage = payload.value;
    },
    setLLMModelAnswerSize: (state, { payload }: PayloadAction<{ value: LLM_ANSWER_SIZE_TYPE }>) => {
      state.modelResponseLength = payload.value;
    },
    setaiInteractionMode: (state, { payload }: PayloadAction<{ value: INTERACTION_MODE }>) => {
      state.aiInteractionMode = payload.value;
    },
    setCustomSystemPrompt: (state, { payload }: PayloadAction<{ value: LLM_SYSTEM_PROMPT }>) => {
      state.customSystemPrompt = payload.value;
    },
    setCustomSuperDoSystemPrompt: (state, { payload }: PayloadAction<{ value: LLM_SYSTEM_PROMPT }>) => {
      state.customSuperDoSystemPrompt = payload.value;
    },
    setSourceAuditInteractionData: (
      state,
      { payload }: PayloadAction<{ value: { id: SOURCE_AUDIT_INTERACTION_ID; data?: InlineSourceDocument } | null }>,
    ) => {
      state.sourceAuditInteractionData = payload.value;
    },
    setConversationMemoryKnowledgeActive: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.conversationMemoryKnowledgeActive = payload.value;
    },
    setAdvancedKnowledgeRAGActive: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.advancedKnowledgeRAGActive = payload.value;
    },
    setTurboSpeechModelActive: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.turboSpeechModelActive = payload.value;
    },
    setWaitSpeechTextActive: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.waitSpeechTextActive = payload.value;
    },
    setContextSizeVoiceAssistant: (state, { payload }: PayloadAction<{ value: 'small' | 'normal' | 'large' }>) => {
      state.contextSizeVoiceAssistant = payload.value;
    },
    setWaitSpeechVideoAssistantTextActive: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.waitSpeechVideoAssistantTextActive = payload.value;
    },
    setConversationMemoryVideoAssistantActive: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.conversationMemoryVideoAssistantActive = payload.value;
    },
    setWebBrowserSpeechRecognitionActive: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.webBrowserSpeechRecognitionActive = payload.value;
    },
    setContextSizeVideoAssistant: (state, { payload }: PayloadAction<{ value: 'small' | 'normal' | 'large' }>) => {
      state.contextSizeVideoAssistant = payload.value;
    },
    setVideoAssistantVideoQuality: (state, { payload }: PayloadAction<{ value: 'low' | 'medium' | 'high' }>) => {
      state.videoAssistantVideoQuality = payload.value;
    },
    setVideoAssistantLanguage: (state, { payload }: PayloadAction<{ value: 'en' | 'de' }>) => {
      state.videoAssistantLanguage = payload.value;
    },
    setLastSelectedKnowledgeAreaId: (state, { payload }: PayloadAction<{ value: string | null }>) => {
      state.lastSelectedKnowledgeAreaId = payload.value;
    },
    setSelectedChatId: (state, { payload }: PayloadAction<{ value: string | null }>) => {
      state.selectedChatId = payload.value;
    },
    resetSettingsState: (state) => {
      state.aiInteractionMode = initialState.aiInteractionMode;
      state.selectedChatId = initialState.selectedChatId;
      state.lastSelectedKnowledgeAreaId = initialState.lastSelectedKnowledgeAreaId;
    },
  },
});

export const {
  setGptChatBoxLLM,
  setGptSuperDoLLM,
  setLLMLanguage,
  setLLMModelAnswerSize,
  setLastSelectedKnowledgeAreaId,
  setConversationMemoryKnowledgeActive,
  setAdvancedKnowledgeRAGActive,
  setTurboSpeechModelActive,
  setWaitSpeechTextActive,
  setContextSizeVoiceAssistant,
  setWaitSpeechVideoAssistantTextActive,
  setConversationMemoryVideoAssistantActive,
  setWebBrowserSpeechRecognitionActive,
  setContextSizeVideoAssistant,
  setVideoAssistantVideoQuality,
  setVideoAssistantLanguage,
  setSelectedChatId,
  setaiInteractionMode,
  setSourceAuditInteractionData,
  resetSettingsState,
  setCustomSystemPrompt,
  setCustomSuperDoSystemPrompt,
} = settingsSlice.actions;

export default settingsSlice.reducer;
