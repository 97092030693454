export const darkenHexColor = (hex: string, amount: number) => {
  let usePound = false;

  if (hex[0] === '#') {
    hex = hex.slice(1);
    usePound = true;
  }

  const num = parseInt(hex, 16);

  let r = (num >> 16) - amount;
  if (r < 0) r = 0;
  else if (r > 255) r = 255;

  let g = ((num >> 8) & 0x00ff) - amount;
  if (g < 0) g = 0;
  else if (g > 255) g = 255;

  let b = (num & 0x0000ff) - amount;
  if (b < 0) b = 0;
  else if (b > 255) b = 255;

  return (
    (usePound ? '#' : '') +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0')
  );
};
