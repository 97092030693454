import { useState, useRef, useEffect } from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { getErrorText } from '@app/utils/apiHelpers';

const SPEECH_KEY = process.env.REACT_APP_MICROSOFT_COGNITIVESERVICES_SPEECH_KEY || '';
const SPEECH_REGION = process.env.REACT_APP_MICROSOFT_COGNITIVESERVICES_SPEECH_REGION || '';

export const useSpeechRecognition = () => {
  const [text, setText] = useState<string>('');
  const lastStreamTextRef = useRef<string>('');
  const [isSpeechRecognition, setIsSpeechRecognition] = useState<boolean>(false);

  const speechConfig = useRef<null | sdk.SpeechConfig>(null);
  const audioConfig = useRef<null | any>(null);
  const recognizerRef = useRef<null | any>(null);

  const isSpeechRecognitionAvailable = Boolean(SPEECH_KEY);

  const startContinuousSpeechRecognition = () => {
    try {
      recognizerRef.current?.startContinuousRecognitionAsync();
      setIsSpeechRecognition(true);
    } catch (error) {
      console.log(`Error on start CSR: ${getErrorText(error)}`);
    }
  };

  const stopContinuousSpeechRecognition = () => {
    try {
      recognizerRef.current?.stopContinuousRecognitionAsync();
      setIsSpeechRecognition(false);
      setText('');
      lastStreamTextRef.current = '';
    } catch (error) {
      console.log(`Error on stop CSR: ${getErrorText(error)}`);
    }
  };

  const setSpeechRecognitionText = (text: string) => {
    setText(text);
    lastStreamTextRef.current === text;
  };

  useEffect(() => {
    if (isSpeechRecognitionAvailable) {
      speechConfig.current = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);

      audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();
      const autoDetectLanguage = sdk.AutoDetectSourceLanguageConfig.fromLanguages(['de-DE', 'en-US']);
      recognizerRef.current = sdk.SpeechRecognizer.FromConfig(
        speechConfig.current,
        autoDetectLanguage,
        audioConfig.current,
      );

      recognizerRef.current.recognizing = (s: any, event: any) => {
        const textToAdd = event.result.text.replace(lastStreamTextRef.current, '');
        lastStreamTextRef.current = event.result.text;

        setText(textToAdd);
      };

      stopContinuousSpeechRecognition();
    }
  }, [isSpeechRecognitionAvailable]);

  return {
    isSpeechRecognitionAvailable,
    isSpeechRecognition,
    text,
    setSpeechRecognitionText,
    startContinuousSpeechRecognition,
    stopContinuousSpeechRecognition,
  };
};
