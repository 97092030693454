import { useEffect, useState, useCallback } from 'react';
import { getUsersWithAccessToKnowledgeSpace } from '@app/api/company.api';
import { AccessRightTeamMember } from '@app/types/accessRightTypes';

export const useUserAccessRightToKnowledgeArea = (knowledgeAreaId: string, companyId: string) => {
  const [accessRightTeamMembers, setAccessRightTeamMembers] = useState<AccessRightTeamMember[]>([]);

  useEffect(() => {
    readAccessRights();
  }, [knowledgeAreaId]);

  const readAccessRights = useCallback(async () => {
    if (knowledgeAreaId && companyId) {
      try {
        const teamMembers = await getUsersWithAccessToKnowledgeSpace(companyId, knowledgeAreaId);
        setAccessRightTeamMembers(teamMembers);
      } catch (error) {
        console.log('****** readAccessRights error: ', error);
      }
    }
  }, [knowledgeAreaId, companyId]);

  return { accessRightTeamMembers };
};
