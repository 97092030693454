import { IChatHistory } from '@app/domain/ChatHistoryModel';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setSelectedChatId } from '@app/store/slices/settingsSlice';
import ConversationItem from './ConversationItem';
import moment from 'moment';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { Button, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

interface Props {
  conversations: IChatHistory[];
  onDeleteClick: (chatId: string) => void;
  onTitleEdited: (chatId: string, title: string) => void;
  loadMoreResults: () => void;
  conversationsLoading: boolean;
  moreConversationsAvailable: boolean;
}

export const Conversations = ({
  conversations,
  onDeleteClick,
  onTitleEdited,
  loadMoreResults,
  conversationsLoading,
  moreConversationsAvailable,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { selectedChatId } = useAppSelector((state) => state.settings);

  const getCluster = (timestamp: number) => {
    const startOfToday = moment().startOf('day');
    const startOfYesterday = moment().subtract(1, 'day').startOf('day');
    const oneWeekAgo = moment().subtract(1, 'week');
    const oneMonthAgo = moment().subtract(1, 'month');

    if (moment.unix(timestamp).isAfter(startOfToday)) {
      return t('common.today');
    } else if (moment.unix(timestamp).isAfter(startOfYesterday)) {
      return t('common.yesterday');
    } else if (moment.unix(timestamp).isAfter(oneWeekAgo)) {
      return t('common.last7days');
    } else if (moment.unix(timestamp).isAfter(oneMonthAgo)) {
      return t('common.last30days');
    } else {
      return t('common.older');
    }
  };

  const clusteredConversations = useMemo(
    () =>
      conversations.reduce(
        (acc, conversation) => {
          const timestamp = conversation.lastInteractionTimestamp ?? conversation.createdAt ?? 0;
          const cluster = getCluster(timestamp);
          if (!acc[cluster]) {
            acc[cluster] = [];
          }
          acc[cluster].push(conversation);
          return acc;
        },
        {} as Record<string, IChatHistory[]>,
      ),
    [conversations],
  );

  const clusterOrder = [
    t('common.today'),
    t('common.yesterday'),
    t('common.last7days'),
    t('common.last30days'),
    t('common.older'),
  ];

  return (
    <div className="flex w-full flex-col gap-1">
      {clusterOrder.map((cluster) => {
        const conversations = (clusteredConversations[cluster] || []).sort((a, b) => {
          const aTimestamp = a.lastInteractionTimestamp ?? a.createdAt ?? 0;
          const bTimestamp = b.lastInteractionTimestamp ?? b.createdAt ?? 0;
          return bTimestamp - aTimestamp;
        });

        return conversations.length > 0 ? (
          <div key={cluster}>
            <Divider />
            <NormalText size="s" colorType="light" bottomMargin="s" semiBold>
              {cluster}
            </NormalText>

            {conversations.map((conversation, index) => (
              <ConversationItem
                key={index + '_history_btn'}
                text={conversation.title?.length ? conversation.title : conversation._id ?? 'Unknown conversation'}
                uploadedFiles={conversation.uploadedFiles || 0}
                isSelected={selectedChatId === conversation._id}
                onClick={() => dispatch(setSelectedChatId({ value: conversation._id ?? '' }))}
                onDeleteClick={() => onDeleteClick(conversation._id ?? '')}
                onTitleEditFinished={(title) => onTitleEdited(conversation._id ?? '', title)}
              />
            ))}
          </div>
        ) : null;
      })}

      {moreConversationsAvailable && (
        <Row justify="center" style={{ margin: '20px 0' }}>
          <Button size="small" type="ghost" onClick={loadMoreResults} disabled={conversationsLoading}>
            {t('documentChecker.loadMore')}
          </Button>
        </Row>
      )}
    </div>
  );
};
