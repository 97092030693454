import React, { useState } from 'react';
import { GeneratedImage, LoadingFailedImage } from './GeneratedImageWithFallback.styles';
import { NormalText } from '../BaseTexts/BaseTexts';
import { Row, Space } from 'antd';
import { IconDownload, IconPhoto } from '@tabler/icons-react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export type ImageWithFallbackProps = { src: string; alt?: string; small?: boolean };

export const GeneratedImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, alt, small }) => {
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const handleError = () => {
    setError(true);
  };

  const downloadImage = () => {
    if (src) {
      axios
        .get(src, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'generated-image.jpeg');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log(error));
    }
  };

  return error ? (
    <LoadingFailedImage small={small}>
      <Space direction="vertical" align="center">
        <IconPhoto />
        <NormalText centered light>
          {t('imageNotAvailbleMore')}
        </NormalText>
      </Space>
    </LoadingFailedImage>
  ) : (
    <Row>
      <a target="_blank" href={src} rel="noreferrer">
        <GeneratedImage src={src} alt={alt} onError={handleError} small={small} />
      </a>
      <IconDownload size={16} style={{ margin: '0 10px', cursor: 'pointer' }} onClick={() => downloadImage()} />
    </Row>
  );
};

export default GeneratedImageWithFallback;
