import { sendPostRequest, sendGetRequest, sendDeleteRequest } from '@app/api/http.api';
import { ApiIntegrationModel } from '@app/domain/ApiIntegrationModel';

export const createApiIntegration = (data: ApiIntegrationModel): Promise<string> => {
  return sendPostRequest<ApiIntegrationModel, string>('/api/v1/integration/create', data);
};

export const startIngestingApiIntegration = ({ id }: { id: string }): Promise<string> => {
  return sendPostRequest<{ id: string }, string>('/api/v1/integration/startIngestion', { id });
};

export const readWebsiteContent = (id: string): Promise<string> => {
  return sendGetRequest<string>(`/api/v1/integration/readWebsiteContent/${id}`);
};

export const readApiIntegration = (id: string): Promise<ApiIntegrationModel> => {
  return sendGetRequest<ApiIntegrationModel>(`/api/v1/integration/read/${id}`);
};

export const updateApiIntegration = (
  id: string,
  updatedApiIntegration: Partial<ApiIntegrationModel>,
): Promise<ApiIntegrationModel> => {
  return sendPostRequest<{ updatedApiIntegration: Partial<ApiIntegrationModel> }, ApiIntegrationModel>(
    `/api/v1/integration/update/${id}`,
    {
      updatedApiIntegration,
    },
  );
};

export const deleteApiIntegration = (id: string): Promise<ApiIntegrationModel> => {
  return sendDeleteRequest<ApiIntegrationModel>(`/api/v1/integration/delete/${id}`);
};

export const readApiIntegrationByCompanyId = (companyId: string): Promise<ApiIntegrationModel[]> => {
  return sendGetRequest<ApiIntegrationModel[]>(`/api/v1/integration/byCompany/${companyId}`);
};

export const readApiIntegrationByKnowledgeArea = (knowledgeAreaId: string): Promise<ApiIntegrationModel[]> => {
  return sendGetRequest<ApiIntegrationModel[]>(`/api/v1/integration/byKnowledgeArea/${knowledgeAreaId}`);
};
