import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createSocketInstance } from '@app/services/socketIO.service';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Socket } from 'socket.io-client';
import { ProgressMessage } from '@app/types/socketMessageTypes';

const useSocketMessageHandling = (handleNewMessageFromSocket?: (value: ProgressMessage) => void) => {
  const [aiProgress, setAiProgress] = useState<number>(0);
  const [aiProgressDoneStepInfo, setAiProgressDoneStepInfo] = useState<string>('');
  const socketIO = useRef<Socket | null>(null);
  const socketChanelId = useRef<string>(uuidv4());
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    connectWebSocket(socketChanelId.current);

    return () => {
      console.log('### cleanup user-leaved', socketChanelId.current);
      socketIO.current?.emit('user-leaved', { userId: user?._id, chatId: socketChanelId.current });
      socketIO.current?.off('new-message', onNewMessageFromSocket);
      socketIO.current?.close();
    };
  }, []);

  const connectWebSocket = (_chatId: string, connectedCallback?: () => void) => {
    createSocketInstance((socket) => {
      socketIO.current?.off('new-message', onNewMessageFromSocket);
      socketIO.current = socket;
      socketIO.current?.on('new-message', onNewMessageFromSocket);
      socketIO.current?.emit('user-joined', { userId: user?._id, chatId: _chatId });
      connectedCallback?.();
    });
  };

  const onNewMessageFromSocket = (value: ProgressMessage) => {
    const { messageType, progress, doneStepInfo } = value;
    if (messageType === 'PROGRESS') {
      setAiProgress(progress);
      setAiProgressDoneStepInfo(doneStepInfo ?? '');
    }
    if (handleNewMessageFromSocket) {
      handleNewMessageFromSocket(value);
    }
  };

  const resetState = () => {
    setAiProgressDoneStepInfo('');
    setAiProgress(0);
  };

  return { aiProgress, aiProgressDoneStepInfo, socketChanelId, socketIO, resetState };
};

export default useSocketMessageHandling;
