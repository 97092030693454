import React, { useMemo } from 'react';
import { Drawer, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { deleteChatHistory, updateChatHistory } from '@app/api/chatHistory.api';
import { ChatMode } from '@app/domain/ChatHistoryModel';
import { Conversations } from './Conversations';
import { NewButton } from './ChatHistoryDrawer.styles';
import { setSelectedChatId } from '@app/store/slices/settingsSlice';
import { notificationController } from '@app/controllers/notificationController';
import { onCreateNewChatHistory, setLoadedChatHistory } from '@app/store/slices/chatBoxSlice';
import { useHandleChatHistory } from '@app/hooks/useHandleChatHistory';

export interface PropTypes {
  showHistoryDrawer: boolean;
  chatMode: ChatMode;
  onCloseHistoryDrawer: () => void;
}

export const NewConversationTitle = 'New Conversation';

const ChatHistoryDrawer: React.FC<PropTypes> = ({ showHistoryDrawer, onCloseHistoryDrawer, chatMode }) => {
  const { loadedChatHistory, historyResultLoading, loadMoreResults, moreHistoryResultsAvailable } =
    useHandleChatHistory();

  const { aiInteractionMode } = useAppSelector((state) => state.settings);

  const { mobileOnly } = useResponsive();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const onClose = () => {
    onCloseHistoryDrawer();
  };

  const onCreateNew = async () => {
    dispatch(onCreateNewChatHistory(NewConversationTitle, chatMode, aiInteractionMode));
  };

  const loadedChatHistoryForChatMode = useMemo(
    () => loadedChatHistory.filter((item) => item.chatMode === chatMode),
    [loadedChatHistory, chatMode],
  );

  const onTitleEdited = async (chatId: string, title: string) => {
    const foundHistory = loadedChatHistory.find((item) => item._id === chatId);
    if (foundHistory) {
      await updateChatHistory(chatId, { title: title });
      const updatedChatHistory = loadedChatHistory.map((history) => {
        if (history._id === chatId) {
          return { ...history, title: title };
        } else return history;
      });

      dispatch(setLoadedChatHistory({ value: updatedChatHistory }));
    }
  };

  const onDeleteClick = async (chatId: string) => {
    Modal.confirm({
      title: t('deleteConverationHistory'),
      icon: <ExclamationCircleOutlined />,
      content: t('deleteConverationHistoryConfirm'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        try {
          await deleteChatHistory(chatId);
          const indexOfDeletedItem = loadedChatHistory.findIndex((item) => item._id === chatId);
          const updatedHistory = loadedChatHistory.filter((item) => item._id !== chatId);
          dispatch(setLoadedChatHistory({ value: updatedHistory }));
          let newSelectedChatId: string | null = null;
          if (updatedHistory.length > 0) {
            const nextChatId = updatedHistory[Math.min(indexOfDeletedItem, updatedHistory.length - 1)]._id ?? null;
            newSelectedChatId = nextChatId;
          }
          dispatch(setSelectedChatId({ value: newSelectedChatId }));
        } catch (error) {
          console.log('****** deleteChatHistory error: ', error);
          if (error instanceof Error) {
            notificationController.error({ message: error.message });
          } else if (typeof error === 'string') {
            notificationController.error({ message: error });
          } else {
            notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
          }
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <Drawer
      width={mobileOnly ? '80%' : 330}
      title={t('converationHistory')}
      placement="right"
      onClose={onClose}
      open={showHistoryDrawer}
    >
      <NewButton
        type="dashed"
        icon={<PlusOutlined />}
        onClick={() => {
          onCreateNew();
        }}
      >
        {t('newConveration')}
      </NewButton>

      <Conversations
        conversations={loadedChatHistoryForChatMode}
        conversationsLoading={historyResultLoading}
        moreConversationsAvailable={moreHistoryResultsAvailable}
        loadMoreResults={loadMoreResults}
        onDeleteClick={onDeleteClick}
        onTitleEdited={onTitleEdited}
      />
    </Drawer>
  );
};

export default ChatHistoryDrawer;
