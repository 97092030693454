import { useEffect, useState, useMemo } from 'react';
import { useAppSelector } from './reduxHooks';

export const useUserAccessRight = (knowledgeAreaId: string): boolean => {
  const [isAvailableForWriting, setIsAvailableForWriting] = useState(false);
  const userAccessRights = useAppSelector((state) => state.user.userRights);

  const { isAdmin } = useAppSelector((state) => state.user);

  const userAccessRightsForWriting = useMemo(() => {
    return userAccessRights?.accessRights
      .filter(
        (userAccessRights) =>
          userAccessRights.entityType === 'KNOWLEDGE_AREA' && userAccessRights.accessRight === 'WRITE_AND_READ',
      )
      .map((userAccessRights) => userAccessRights.entityId);
  }, [userAccessRights]);

  useEffect(() => {
    const isAvailableArea = Boolean(userAccessRightsForWriting?.includes(knowledgeAreaId));
    setIsAvailableForWriting(isAdmin || isAvailableArea);
  }, [knowledgeAreaId]);

  return isAvailableForWriting;
};
