import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { InviteExternalUserButton, NoDataImageContainer } from './GuestOverview.styles';

export const GuestOverview: React.FC = () => {
  const { t } = useTranslation();

  const handleInviteClick = () => {
    Modal.confirm({
      title: t('addNewExternalUser'),
      content: t('addNewExternalUserMessage'),
      okText: t('ok'),
    });
  };

  return (
    <>
      <Row justify={'space-between'}>
        <NormalText semiBold size="m" style={{ marginTop: '1rem' }}>
          {t('overviewMembers')}
        </NormalText>
        <InviteExternalUserButton type="primary" onClick={handleInviteClick}>
          {t('addNewExternalUser')}
        </InviteExternalUserButton>
      </Row>
      <NoDataImageContainer />
    </>
  );
};
