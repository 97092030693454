import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { LoadingSpin } from '../common/LoadingSpin';
import { loadPublicKnowledgeAreas } from '@app/store/slices/knowledgeAreaSlice';
import { useTranslation } from 'react-i18next';
import { checkVersionIsOk } from '@app/api/general.api';
import { Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { doVerifyAccessToken } from '@app/store/slices/authExternalAccessTokenSlice';

const RequireAccessToken: React.FC<WithChildrenProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const accessToken = searchParams.get('token');

  const [tokenExists, setTokenExists] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (accessToken) {
      dispatch(doVerifyAccessToken({ accessToken }))
        .unwrap()
        .then(async (res) => {
          setTokenExists(!!res);
          if (res) {
            await dispatch(loadPublicKnowledgeAreas(res.companyId, res.accessToken));
          }
        })
        .finally(() => setLoading(false));
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    const checkClientVersion = async () => {
      const result = await checkVersionIsOk();
      if (!result.versionOk) {
        Modal.confirm({
          title: t('yourAppVersionIsOld'),
          icon: <InfoCircleOutlined />,
          content: t('yourAppVersionIsOldDescription'),
          okText: t('ok'),
        });
      }
    };
    if (!loading) {
      checkClientVersion();
    }
  }, [loading]);

  if (loading) return <LoadingSpin marginTop={'5rem'} text={t('establishingSecureConnection')} />;

  // TODO: if token is not valid, redirect to custom "invalid token page"
  return tokenExists ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAccessToken;
