import styled from 'styled-components';
import voiceAssistantImage from '@app/assets/images/voice_assistant.png';
import { media } from '@app/styles/themes/constants';

export const FlexContainerWithImage = styled.div`
  width: 100%;
  height: 60vh;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: end;
  background-image: url(${voiceAssistantImage});
  background-position: bottom center;
  background-size: cover;
`;

export const UserChat = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;

  @media only screen and ${media.sm} {
    max-width: 50%;
  }
`;

const TextBox = styled.p`
  display: flex;
`;

export const UserQuestion = styled(TextBox)`
  color: var(--error-color);
`;

export const Answer = styled(TextBox)`
  color: var(--success-color);
`;

export const SpeakerDescription = styled.span`
  display: inline-block;
  margin-right: 1.5rem;
  font-weight: 700;
  min-width: 40px;
`;

export const AudioHiddenWrapper = styled.div`
  position: fixed;
  z-index: -10;
  bottom: 0;
  left: -1000px;
`;
