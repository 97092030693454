import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { AccessRightEntityType } from '@app/domain/AccessRightsModel';

type Props = {
  entityType: AccessRightEntityType;
  toggleAreasCheckboxes: (entityType: string, selectorType: boolean) => void;
  hasSelectedArea: boolean;
};

export const KnowledgeAreaRowHeader: React.FC<Props> = ({ entityType, toggleAreasCheckboxes, hasSelectedArea }) => {
  const { t } = useTranslation();

  return (
    <Row justify={'center'} align={'top'}>
      <Col span={4}>
        <NormalText semiBold size="s" centered>
          {entityType === 'APP' ? t('settings.readAccessLabelApp') : t('settings.readAccessLabel')}
        </NormalText>
      </Col>
      <Col span={4}>
        <NormalText semiBold size="s" centered>
          {entityType === 'APP' ? t('settings.writeAccessLabelApp') : t('settings.writeAccessLabel')}
        </NormalText>
      </Col>
      <Col span={16}>
        <Row justify={'end'}>
          <Button
            onClick={() => toggleAreasCheckboxes(entityType, hasSelectedArea)}
            type="link"
            style={{ fontSize: '0.875rem', textDecoration: 'underlined' }}
          >
            {hasSelectedArea ? t('settings.unselectAll') : t('settings.selectAll')}
          </Button>
        </Row>
      </Col>
    </Row>
  );
};
