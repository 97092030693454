import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 95vh;
  overflow-y: auto; */
`;

export const KnowledgeAreWrapper = styled.div`
  padding: 1rem;
  overflow-y: auto;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const TableWrapper = styled.div`
  padding-top: 20px;

  .ant-table-container {
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }

  .ant-table-container {
    border-radius: 0 !important;
    border-color: var(--border-color) !important;

    & table {
      border-color: var(--border-color) !important;
    }
    & tr > td {
      border-color: var(--border-color) !important;
    }
    & tr > th {
      border-color: var(--border-color) !important;
    }
  }

  .ant-table-thead .ant-table-cell:first-child {
    border-top-left-radius: 0 !important;
  }

  .ant-table-thead .ant-table-cell:last-child {
    border-top-right-radius: 0 !important;
  }
`;
