import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Card } from '@app/components/common/Card/Card';
import * as S from './Notifications.styles';
import { NoDataImageContainer } from '@app/components/guest/GuestOverview.styles';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';

export const Notifications: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <BaseButtonsForm.Item>
        <NormalText bold size="l">
          {t('profile.nav.notifications.settings')}
        </NormalText>
      </BaseButtonsForm.Item>
      <S.Description>{t('noNotificationsAvailable')}</S.Description>
      <NoDataImageContainer />
    </>
  );
};
