import { ISourceUpdateMonitoring } from '@app/domain/SourceUpdateMonitoringModel';
import { sendPostRequest, sendGetRequest, sendDeleteRequest } from '../api/http.api';

export const createSourceUpdateMonitoring = (data: ISourceUpdateMonitoring): Promise<string> => {
  return sendPostRequest<ISourceUpdateMonitoring, string>('/api/v1/sourceUpdateMonitoring/create', data);
};

export const readSourceUpdateMonitoring = (id: string): Promise<ISourceUpdateMonitoring | null> => {
  return sendGetRequest<ISourceUpdateMonitoring | null>(`/api/v1/sourceUpdateMonitoring/${id}`);
};

export const readSourceUpdateMonitoringByUser = (
  createdByUserId: string,
): Promise<ISourceUpdateMonitoring[] | null> => {
  return sendGetRequest<ISourceUpdateMonitoring[] | null>(`/api/v1/sourceUpdateMonitoring/byUser/${createdByUserId}`);
};

export const readSourceUpdateMonitoringByCompany = (companyId: string): Promise<ISourceUpdateMonitoring[] | null> => {
  return sendGetRequest<ISourceUpdateMonitoring[] | null>(`/api/v1/sourceUpdateMonitoring/byCompany/${companyId}`);
};

export const updateSourceUpdateMonitoring = (
  id: string,
  updatedSourceUpdateMonitoring: Partial<ISourceUpdateMonitoring>,
): Promise<string> => {
  return sendPostRequest<{ updatedSourceUpdateMonitoring: Partial<ISourceUpdateMonitoring> }, string>(
    `/api/v1/sourceUpdateMonitoring/${id}`,
    {
      updatedSourceUpdateMonitoring,
    },
  );
};

export const executeSourceUpdateMonitoring = (id: string): Promise<boolean> => {
  return sendPostRequest<null, boolean>(`/api/v1/sourceUpdateMonitoring/execute/${id}`, null);
};

export const deleteSourceUpdateMonitoring = (id: string): Promise<string> => {
  return sendDeleteRequest<string>(`/api/v1/sourceUpdateMonitoring/${id}`);
};
