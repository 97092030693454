import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { Button } from 'antd';
import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  height: 90vh;
  padding: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  overflow-y: auto;
`;

export const ContainerEditWrapper = styled.div`
  display: flex;
  height: 90vh;
  padding: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  overflow-y: auto;
  background-color: 'white';
  border-left: 2px solid #7cc5d9;
`;

export const SaveButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const DonwloadButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
  margin-bottom: 2rem;
`;

export const TextInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--text-superLight-color);
`;

export const HeaderSpacing = styled.div`
  width: 100%;
  height: 2rem;
  margin-bottom: 1rem;
`;

export const TextDescription = styled.div`
  display: flex;
  margin-left: 1.25rem;
  width: 100%;
`;
