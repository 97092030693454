import { AgentTaskType, ApiIntegrationType } from '@app/utils/constants';
import { DocumentInterface } from './generalTypes';

export type SockeAppEventIds =
  | 'KNOWLEDGE_AREA_CHANGES'
  | 'KNOWLEDGE_AREA_FOLDER_CHANGED'
  | 'KNOWLEDGE_AREA_RIGHTS_CHANGED';

export type BaseWebsocketMessage = {
  messageType:
    | 'NEW_TOKEN'
    | 'SOURCE_DOCUMENTS'
    | 'STREAM_DONE'
    | 'AGENT_ACTION'
    | 'PROGRESS'
    | 'SUPER_DO_TOOL_RESULT'
    | 'KNOWLEDGE_QUERY_ANALYSIS_RESULT'
    | 'GENERAL_APP_EVENT';
  timestampt?: number;
};

export type ChatResponseMessage = {
  token?: string;
  isFirstMessage?: boolean;
  sourceDocuments?: DocumentInterface<Record<string, any>>[];
} & BaseWebsocketMessage;

export type AgentActionMessage = {
  actionType: 'TOOL_STARTED' | 'TOOL_FINISHED_SUCCESS' | 'TOOL_FINISHED_WITH_ERROR';
  contextType: ApiIntegrationType | AgentTaskType;
  message?: string;
  errorMessage?: string;
} & BaseWebsocketMessage;

export type ProgressMessage = {
  actionId: 'AI_DOCUMENT_CHECK';
  progress: number;
  doneStepInfo?: string;
} & BaseWebsocketMessage;

export type SuperDoToolResult = {
  results: string[];
  errorMessage?: string;
} & BaseWebsocketMessage;

export type KnowledgeAnswerAnalysisResult = {
  answerIsGrounded?: boolean;
  reason?: string;
  score?: number;
};

export type KnowledgeAnswerAnalysisMessage = {
  actionType: 'ANALYSIS_STARTED' | 'ANALYSIS_FINISHED_SUCCESS' | 'ANALYSIS_FINISHED_WITH_ERROR';
  result?: KnowledgeAnswerAnalysisResult;
} & BaseWebsocketMessage;

export type GeneralSocketAppEvent = {
  appEventId: SockeAppEventIds;
  companyId: string;
  payload?: any;
} & BaseWebsocketMessage;

export const isAgentActionMessage = (message: BaseWebsocketMessage): message is AgentActionMessage => {
  return message.messageType === 'AGENT_ACTION';
};

export const isKnowledgeAnswerAnalysisMessage = (
  message: BaseWebsocketMessage,
): message is KnowledgeAnswerAnalysisMessage => {
  return message.messageType === 'KNOWLEDGE_QUERY_ANALYSIS_RESULT';
};
