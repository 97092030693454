import axios from 'axios';
import { DEFAULT_MAX_LOOPS_CUSTOM_API_KEY } from '@app/utils/constants';
import { ModelSettings } from '@app/utils/types';
import { createAgent, executeAgent, startAgent } from '@app/api/gpt.api';

export type Message = {
  type: 'goal' | 'thinking' | 'task' | 'action' | 'system';
  value: string;
  info?: string | undefined;
};

const TIMEOUT_LONG = 1000;
const TIMOUT_SHORT = 800;

class AutonomousAgent {
  name: string;
  goal: string;
  tasks: string[] = [];
  completedTasks: string[] = [];
  modelSettings: ModelSettings;
  isRunning = true;
  renderMessage: (message: Message) => void;
  shutdown: () => void;
  numLoops = 0;
  session?: any;

  constructor(
    name: string,
    goal: string,
    renderMessage: (message: Message) => void,
    shutdown: () => void,
    modelSettings: ModelSettings,
    session?: any,
  ) {
    this.name = name;
    this.goal = goal;
    this.renderMessage = renderMessage;
    this.shutdown = shutdown;
    this.modelSettings = modelSettings;
    this.session = session;
  }

  async run() {
    this.sendGoalMessage();
    this.sendThinkingMessage();

    // Initialize by getting tasks
    try {
      this.tasks = await this.getInitialTasks();
      for (const task of this.tasks) {
        await new Promise((r) => setTimeout(r, TIMOUT_SHORT));
        this.sendTaskMessage(task);
      }
    } catch (e) {
      console.log(e);
      this.sendErrorMessage(getMessageFromError(e));
      this.shutdown();
      return;
    }

    await this.loop();
  }

  async loop() {
    console.log(`Loop ${this.numLoops}`);
    console.log(this.tasks);

    if (!this.isRunning) {
      return;
    }

    if (this.tasks.length === 0) {
      this.sendCompletedMessage();
      this.shutdown();
      return;
    }

    this.numLoops += 1;
    const maxLoops = this.maxLoops();
    if (this.numLoops > maxLoops) {
      this.sendLoopMessage();
      this.shutdown();
      return;
    }

    // Wait before starting
    await new Promise((r) => setTimeout(r, TIMEOUT_LONG));

    // Execute first task
    // Get and remove first task
    this.completedTasks.push(this.tasks[0] || '');
    const currentTask = this.tasks.shift();
    this.sendThinkingMessage();

    const result = await this.executeTask(currentTask as string);
    this.sendExecutionMessage(currentTask as string, result);

    // Wait before adding tasks
    await new Promise((r) => setTimeout(r, TIMEOUT_LONG));
    this.sendThinkingMessage();

    // Add new tasks
    try {
      const newTasks = await this.getAdditionalTasks(currentTask as string, result);
      this.tasks = this.tasks.concat(newTasks);
      for (const task of newTasks) {
        await new Promise((r) => setTimeout(r, TIMOUT_SHORT));
        this.sendTaskMessage(task);
      }

      if (newTasks.length == 0) {
        this.sendActionMessage('Task marked as complete!');
      }
    } catch (e) {
      console.log(e);
      this.sendErrorMessage(
        `ERROR adding additional task(s). It might have been against our model's policies to run them. Continuing.`,
      );
      this.sendActionMessage('Task marked as complete.');
    }

    await this.loop();
  }

  private maxLoops() {
    return DEFAULT_MAX_LOOPS_CUSTOM_API_KEY;
  }

  async getInitialTasks(): Promise<string[]> {
    const data = await startAgent({ modelSettings: this.modelSettings, goal: this.goal });
    return data;
  }

  async getAdditionalTasks(currentTask: string, result: string): Promise<string[]> {
    const data = await createAgent({
      modelSettings: this.modelSettings,
      goal: this.goal,
      tasks: this.tasks,
      lastTask: currentTask,
      result: result,
      completedTasks: this.completedTasks,
    });
    return data;
  }

  async executeTask(task: string): Promise<string> {
    const data = await executeAgent({
      modelSettings: this.modelSettings,
      goal: this.goal,
      task: task,
    });
    return data;
  }

  stopAgent() {
    this.sendManualShutdownMessage();
    this.isRunning = false;
    this.shutdown();
    return;
  }

  sendMessage(message: Message) {
    if (this.isRunning) {
      this.renderMessage(message);
    }
  }

  sendGoalMessage() {
    this.sendMessage({ type: 'goal', value: this.goal });
  }

  sendLoopMessage() {
    this.sendMessage({
      type: 'system',
      value: `This agent has maxed out on loops. You can configure the number of loops in the advanced settings.`,
    });
  }

  sendManualShutdownMessage() {
    this.sendMessage({
      type: 'system',
      value: `The agent has been manually shutdown.`,
    });
  }

  sendCompletedMessage() {
    this.sendMessage({
      type: 'system',
      value: 'All tasks completed. Shutting down.',
    });
  }

  sendThinkingMessage() {
    this.sendMessage({ type: 'thinking', value: '' });
  }

  sendTaskMessage(task: string) {
    this.sendMessage({ type: 'task', value: task });
  }

  sendErrorMessage(error: string) {
    this.sendMessage({ type: 'system', value: error });
  }

  sendExecutionMessage(task: string, execution: string) {
    this.sendMessage({
      type: 'action',
      info: `Executing "${task}"`,
      value: execution,
    });
  }

  sendActionMessage(message: string) {
    this.sendMessage({
      type: 'action',
      info: message,
      value: '',
    });
  }
}

const getMessageFromError = (e: unknown) => {
  let message = 'ERROR accessing OpenAI APIs. Please check your API key or try again later';
  if (axios.isAxiosError(e)) {
    const axiosError = e;
    if (axiosError.response?.status === 429) {
      message = `ERROR using your OpenAI API key. You've exceeded your current quota, please check your plan and billing details.`;
    }
    if (axiosError.response?.status === 404) {
      message = `ERROR your API key does not have GPT-4 access. You must first join OpenAI's wait-list. (This is different from ChatGPT Plus)`;
    }
  } else {
    message = `ERROR retrieving initial tasks array. Retry, make your goal more clear, or revise your goal such that it is within our model's policies to run. Shutting Down.`;
  }
  return message;
};

export default AutonomousAgent;
