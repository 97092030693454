import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { Button, Row, Tabs, Dropdown, Menu, Modal, Form, Col, Space, Tooltip } from 'antd';
import type { TabsProps } from 'antd';
import { KnowledgeFiles } from './KnowledgeFiles';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdditionalDocumentFields, KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { deleteKnowledgeArea, updateKnowledgeArea } from '@app/api/knowledgeArea.api';
import OverviewApiIntegrations from './OverviewApiIntegrations';
import OverviewAccessRights from './OverviewAccessRights';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setLoadedKnowledgeAreas } from '@app/store/slices/knowledgeAreaSlice';
import { getUsersByIds } from '@app/api/company.api';
import { UserModel } from '@app/domain/UserModel';
import { IconInfoCircle, IconEdit, IconSettings, IconTrash } from '@tabler/icons-react';
import moment from 'moment';
import { DeletedUser } from '@app/utils/constants';
import { useMenuTree } from '@app/hooks/useMenuTree';
import { ModalEditKnowledgeArea } from './ModalEditKnowledgeArea';
import { useErrorHandling } from '@app/hooks/useErrorHandling';
import { resetKnowledgeAreaFilter } from '@app/store/slices/knowledgeAreaFilterSlice';
import BackButton from '../common/buttons/Button/BackButton';
import { ModalSettingsKnowledgeArea } from './ModalSettingsKnowledgeArea';

export const Dataroom: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedKnowledgeArea, setSelectedKnowledgeArea] = useState<KnowledgeAreaModel | null>(null);
  const [creatorUser, setCreatorUser] = useState<UserModel | null>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);

  const [showEditRightsTab, setShowEditRightsTab] = useState(false);

  const { isAdmin, isManager } = useAppSelector((state) => state.user);

  const [form] = Form.useForm();

  const { handleApiError } = useErrorHandling();

  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);
  const currentUser = useAppSelector((state) => state.user.user);

  const { onDeleteNestedMenuItem } = useMenuTree(loadedKnowledgeAreas);

  const dispatch = useAppDispatch();

  const navigation = useNavigate();

  const onChange = (key: string) => {
    console.log(key);
  };

  useEffect(() => {
    const knowledgeArea = location.state as KnowledgeAreaModel;
    setSelectedKnowledgeArea(knowledgeArea);
    setShowEditRightsTab(isAdmin || isManager || knowledgeArea.createdByUserId === currentUser?._id);
  }, [location, form]);

  useEffect(() => {
    const loadCreatorInfo = async () => {
      if (selectedKnowledgeArea?.createdByUserId) {
        const users = await getUsersByIds([selectedKnowledgeArea?.createdByUserId]);
        if (users.length) {
          setCreatorUser(users[0]);
        } else {
          setCreatorUser(DeletedUser);
        }
      }
    };
    loadCreatorInfo();
  }, [selectedKnowledgeArea]);

  const onDeleteConfirmed = async () => {
    if (selectedKnowledgeArea?._id) {
      try {
        await deleteKnowledgeArea(selectedKnowledgeArea?._id);
        dispatch(
          setLoadedKnowledgeAreas({
            value: loadedKnowledgeAreas.filter((item) => item._id !== selectedKnowledgeArea?._id),
          }),
        );
        onDeleteNestedMenuItem(selectedKnowledgeArea?._id);
        navigation('/dataroom');
      } catch (error) {
        console.log('****** onDeleteConfirmed error: ', error);
        handleApiError(error);
      }
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: t('deleteKnowledgeArea'),
      icon: <ExclamationCircleOutlined />,
      content: t('areYouSureToDelete'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        onDeleteConfirmed();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onTriggerShowAdditionalDocumentFields = async (
    selectedKnowledgeAreaId: string,
    fields: AdditionalDocumentFields,
  ) => {
    try {
      if (selectedKnowledgeArea) {
        const additionalDocumentFields = selectedKnowledgeArea?.additionalDocumentFields?.some(
          (item) => item === fields,
        )
          ? []
          : [fields];

        const updatedItem: KnowledgeAreaModel = {
          ...selectedKnowledgeArea,
          additionalDocumentFields,
        };

        await updateKnowledgeArea(selectedKnowledgeAreaId, { additionalDocumentFields });

        setSelectedKnowledgeArea(updatedItem);

        dispatch(
          setLoadedKnowledgeAreas({
            value: loadedKnowledgeAreas.map((item) => (item._id === selectedKnowledgeAreaId ? updatedItem : item)),
          }),
        );
        if (additionalDocumentFields.length === 0) {
          // reset filters if additional fields where removed
          dispatch(resetKnowledgeAreaFilter({ value: selectedKnowledgeAreaId }));
        }
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const tabItems: TabsProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: t('documents'),
        children: (
          <KnowledgeFiles
            onTriggerShowAdditionalDocumentFields={onTriggerShowAdditionalDocumentFields}
            selectedKnowledgeArea={selectedKnowledgeArea || undefined}
          />
        ),
      },
      {
        key: '3',
        label: t('websites'),
        children: <OverviewApiIntegrations mode="websites" selectedKnowledgeArea={selectedKnowledgeArea} />,
      },
      {
        key: '4',
        label: t('apiIntegrations'),
        children: <OverviewApiIntegrations mode="api" selectedKnowledgeArea={selectedKnowledgeArea} />,
      },
      {
        key: '5',
        label: t('rightsManagement'),
        children: <OverviewAccessRights selectedKnowledgeArea={selectedKnowledgeArea} />,
        disabled: !showEditRightsTab,
      },
    ],
    [selectedKnowledgeArea, showEditRightsTab],
  );

  const menu = (
    <Menu>
      <Menu.Item key="0" icon={<IconEdit size={16} />} onClick={() => setIsEditModalVisible(true)}>
        {t('edit')}
      </Menu.Item>
      <Menu.Item key="1" icon={<IconSettings size={16} />} onClick={() => setIsSettingsModalVisible(true)}>
        {t('knowledgeAreaSettings')}
      </Menu.Item>
      <Menu.Item key="2" icon={<IconTrash size={16} />} danger onClick={showDeleteConfirm}>
        {t('delete')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Card>
      <Row justify="space-between" align="middle">
        <Col>
          <Row justify="start" align="middle">
            <BackButton />
            <Col>
              <Space>
                <NormalText bold size="l">
                  {t('dataroom.headerTitleSelectedArea', { area: selectedKnowledgeArea?.title ?? '-' })}
                </NormalText>
                {creatorUser && selectedKnowledgeArea?.timestampLastUpdatedAt && (
                  <Tooltip
                    title={t('dataroom.createdBy', {
                      name: `${creatorUser.firstName} ${creatorUser.lastName}`,
                      date: moment.unix(selectedKnowledgeArea?.timestampLastUpdatedAt).format('DD.MM.YY HH:mm'),
                    })}
                  >
                    <IconInfoCircle size={'1rem'} color="gray" />
                  </Tooltip>
                )}
              </Space>
              <NormalText size="s">{selectedKnowledgeArea?.description}</NormalText>
            </Col>
          </Row>
        </Col>
        <Col>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>
              {t('actions')} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>

      <Tabs style={{ marginTop: '2rem' }} defaultActiveKey="1" items={tabItems} onChange={onChange} />
      <ModalEditKnowledgeArea
        showModalEditKnowledgeArea={isEditModalVisible}
        onCloseModal={() => setIsEditModalVisible(false)}
        selectedKnowledgeArea={selectedKnowledgeArea}
        setSelectedArea={(knowledgeArea: KnowledgeAreaModel) => setSelectedKnowledgeArea(knowledgeArea)}
      />
      <ModalSettingsKnowledgeArea
        showModalSettingsKnowledgeArea={isSettingsModalVisible}
        onCloseModal={() => setIsSettingsModalVisible(false)}
        selectedKnowledgeArea={selectedKnowledgeArea}
        setSelectedArea={(knowledgeArea: KnowledgeAreaModel) => setSelectedKnowledgeArea(knowledgeArea)}
      />
    </Card>
  );
};
