import React, { useEffect, useState } from 'react';
import { Table, Modal, Form, message, Space, Row, Col, Tooltip, Select, List } from 'antd';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  ScanOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { isValidUrl } from '@app/utils/utils';
import { createDisplayNameFromUrl } from '@app/api/gpt.api';
import { useTranslation } from 'react-i18next';
import { ISourceToMonitor, ISourceUpdateMonitoring } from '@app/domain/SourceUpdateMonitoringModel';
import { readSourceUpdateMonitoringByCompany, updateSourceUpdateMonitoring } from '@app/api/sourceUpdateMonitoring.api';
import moment from 'moment';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { IconDatabaseImport } from '@tabler/icons-react';
import { BaseSelect } from '../common/selects/BaseSelect/BaseSelect';

type OverviewWebsitesProps = {
  selectedSourceMonitoring: ISourceUpdateMonitoring | null;
  onUpdateItem: (selectedSourceMonitoring: ISourceUpdateMonitoring | null) => void;
};

const MaxWebsites = Number.parseInt(process.env.REACT_APP_MAX_WEBSITES_PER_UPDATE_MONITORING ?? '') || 50;

const OverviewWebsites: React.FC<OverviewWebsitesProps> = ({ selectedSourceMonitoring, onUpdateItem }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [showWebsitesPreviewImportModal, setShowWebsitesPreviewImportModal] = useState(false);
  const [selectedAssistantToImport, setSelectedAssistantToImport] = useState<string | null>(null);

  const [urlDisplayNameLoading, setUrlDisplayNameLoading] = useState(false);
  const [dataSource, setDataSource] = useState<ISourceToMonitor[]>([]);

  const [otherMonitorings, setOtherMonitoring] = useState<ISourceUpdateMonitoring[]>([]);

  const companyState = useAppSelector((state) => state.company);

  const { Option } = Select;

  useEffect(() => {
    if (companyState._id && selectedSourceMonitoring?._id) {
      const readData = async () => {
        const data = await readSourceUpdateMonitoringByCompany(companyState._id || '');
        setOtherMonitoring(data?.filter((item) => item._id !== selectedSourceMonitoring?._id) || []);
      };
      readData();
    }
  }, [companyState, selectedSourceMonitoring]);

  useEffect(() => {
    if (isImportModalVisible) {
      setShowWebsitesPreviewImportModal(false);
      setSelectedAssistantToImport(null);
    }
  }, [isImportModalVisible]);

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const theme = useAppSelector((state) => state.theme.theme);

  const maxLimitExceed = dataSource.length > MaxWebsites;

  useEffect(() => {
    setDataSource(selectedSourceMonitoring?.sources || []);
  }, [selectedSourceMonitoring]);

  const columns = [
    { title: t('name'), dataIndex: 'title', key: 'title' },
    {
      title: t('source'),
      key: 'source',
      render: (item: ISourceToMonitor) => (
        <NormalText size="s" style={{ fontWeight: 500 }}>
          {item.source}
        </NormalText>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (item: ISourceToMonitor) => (
        <Space size="large">
          <a onClick={() => onDelete(item)}>
            <DeleteOutlined />
          </a>
        </Space>
      ),
    },
  ];

  const onDelete = async (item: ISourceToMonitor) => {
    Modal.confirm({
      title: t('sourceUpdateMonitoring.deleteWebsite'),
      icon: <ExclamationCircleOutlined />,
      content: t('sourceUpdateMonitoring.areYouSureToDeleteWebsite'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        if (item?.source) {
          try {
            const updatedSources = dataSource.filter((ds) => ds.source !== item.source);
            await updateSourceUpdateMonitoring(selectedSourceMonitoring?._id ?? '', { sources: updatedSources });
            setDataSource(updatedSources);
          } catch (error) {
            console.log('****** onDeleteConfirmed error: ', error);
            if (error instanceof Error) {
              notificationController.error({ message: error.message });
            } else if (typeof error === 'string') {
              notificationController.error({ message: error });
            } else {
              notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
            }
          }
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (!isValidUrl(values.source)) {
          message.error(t('pleasAddValidUrl'));
          return;
        }

        if (dataSource.some((item) => item.source === values.source)) {
          message.error(t('sourceUpdateMonitoring.sourcesMustBeUnique'));
          return;
        }

        form.resetFields();
        setIsModalVisible(false);

        try {
          const updatedSources = [
            ...dataSource,
            { source: values.source ?? '-', type: 'WEBSITE', title: values.title ?? '-' } as ISourceToMonitor,
          ];
          await updateSourceUpdateMonitoring(selectedSourceMonitoring?._id ?? '', { sources: updatedSources });
          setDataSource(updatedSources);
          selectedSourceMonitoring && onUpdateItem({ ...selectedSourceMonitoring, sources: updatedSources });
        } catch (error) {
          console.log('****** handleOk error: ', error);
          if (error instanceof Error) {
            notificationController.error({ message: error.message });
          } else if (typeof error === 'string') {
            notificationController.error({ message: error });
          } else {
            notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
          }
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleOkImport = () => {
    form
      .validateFields()
      .then(async () => {
        if (!selectedAssistantToImport) {
          message.error(t('sourceUpdateMonitoring.selectSource'));
          return;
        }

        const selectedSource = otherMonitorings.find((item) => item._id === selectedAssistantToImport);
        const sourcesToImport = selectedSource?.sources || [];
        const numberWebsites = sourcesToImport.length;

        if (numberWebsites === 0) {
          message.error(t('sourceUpdateMonitoring.selectedSourceHaveNoWebsites'));
          return;
        }

        try {
          let importCounter = 0;
          const updatedSources = [...dataSource];
          sourcesToImport.forEach((source) => {
            if (!updatedSources.some((item) => item.source === source.source)) {
              updatedSources.push(source);
              importCounter += 1;
            }
          });

          await updateSourceUpdateMonitoring(selectedSourceMonitoring?._id ?? '', { sources: updatedSources });
          setDataSource(updatedSources);
          selectedSourceMonitoring && onUpdateItem({ ...selectedSourceMonitoring, sources: updatedSources });
          form.resetFields();
          setIsImportModalVisible(false);
          message.success(t('sourceUpdateMonitoring.sourceImported', { counter: importCounter }));
        } catch (error) {
          console.log('****** handleOkImport error: ', error);
          if (error instanceof Error) {
            notificationController.error({ message: error.message });
          } else if (typeof error === 'string') {
            notificationController.error({ message: error });
          } else {
            notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
          }
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onUrlInputChanged = async (url: string) => {
    try {
      setUrlDisplayNameLoading(true);
      const displayName = await createDisplayNameFromUrl({ url });
      form.setFieldValue('title', displayName);
    } catch (error) {
      console.error('**** error in onUrlInputChanged', error);
    } finally {
      setUrlDisplayNameLoading(false);
    }
  };

  const renderExecutionInfo = () => {
    const intervalCheckForUpdatesInHours = selectedSourceMonitoring?.intervalCheckForUpdatesInHours || 24;
    const now = moment();
    if (!selectedSourceMonitoring?.timestampLastScanedAt) {
      const date = now
        .set('day', now.day() + 1)
        .set('hour', 7)
        .set('minutes', 0)
        .format('D MMMM, YYYY HH:mm');
      return (
        <NormalText size="s" semiBold>
          <ScanOutlined /> {t('sourceUpdateMonitoring.notExecutedYet', { date })}
        </NormalText>
      );
    }

    const hasSomeErrors = selectedSourceMonitoring.lastScanErrors && selectedSourceMonitoring.lastScanErrors.length > 0;

    const lastScanedDate = moment.unix(selectedSourceMonitoring.timestampLastScanedAt);
    const date = now
      .set('day', intervalCheckForUpdatesInHours === 24 ? now.day() + 1 : lastScanedDate.day() + 7)
      .set('hour', 7)
      .set('minutes', 0)
      .format('D MMMM, YYYY HH:mm');
    return (
      <Space direction="vertical" style={{ marginBottom: hasSomeErrors ? '1rem' : 0 }}>
        <NormalText size="s" semiBold>
          <ScanOutlined />{' '}
          {t('sourceUpdateMonitoring.nextMonitoringWillBe', {
            dateLastScan: lastScanedDate.format('D MMMM, YYYY HH:mm'),
            date,
          })}
        </NormalText>
        {hasSomeErrors && (
          <NormalText size="s" style={{ color: themeObject[theme].warning }}>
            <Tooltip
              placement="right"
              style={{ minWidth: '200px' }}
              title={
                <div style={{ backgroundColor: 'white' }}>
                  {selectedSourceMonitoring.lastScanErrors?.map((item, index) => (
                    <Space direction="vertical" key={'_error_info' + index}>
                      <NormalText size="s" semiBold>
                        {item.source}
                      </NormalText>
                      <NormalText size="s">{item.errorText}</NormalText>
                    </Space>
                  ))}
                </div>
              }
            >
              <WarningOutlined /> {t('sourceUpdateMonitoring.someErrorsOnLastScan')}
            </Tooltip>
          </NormalText>
        )}
      </Space>
    );
  };

  return (
    <div>
      <Row justify={'space-between'}>
        <Col>{renderExecutionInfo()}</Col>
        <Col>
          <Space>
            <Button style={{ marginBottom: 16 }} onClick={() => setIsImportModalVisible(true)}>
              <IconDatabaseImport size={'1.25rem'} />
            </Button>
            <Button type="primary" style={{ marginBottom: 16 }} disabled={maxLimitExceed} onClick={showModal}>
              {t('createNewWebsite')}
            </Button>
          </Space>
        </Col>
      </Row>
      {maxLimitExceed && (
        <Space>
          <InfoCircleOutlined />
          <NormalText verticalPadding size="m" colorType="light">
            {t('maxWebsitesPerAreaReached', { MaxKnowledgeWebsitesPerAreas: MaxWebsites })}
          </NormalText>
        </Space>
      )}
      <Table dataSource={dataSource} columns={columns} rowKey={(item) => `${item.title}_${item.type}`} />
      <Modal title={t('createNewIntegration')} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Form.Item name="source" rules={[{ required: true, message: t('pleaseEnterAWebsiteURL') }]}>
            <BaseInput placeholder={t('websiteURL')} onChange={(event) => onUrlInputChanged(event.target.value)} />
          </Form.Item>
          <Form.Item name="title" rules={[{ required: true, message: t('pleaseEnterANameForTheWebsite') }]}>
            <BaseInput prefix={urlDisplayNameLoading && <LoadingOutlined />} placeholder={t('nameOfTheWebsite')} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t('sourceUpdateMonitoring.importFromOtherSource')}
        open={isImportModalVisible}
        onOk={handleOkImport}
        onCancel={() => setIsImportModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="sourceAssistant">
            <BaseSelect
              placeholder={t('sourceUpdateMonitoring.selectSource')}
              onChange={(val) => setSelectedAssistantToImport(val as string)}
            >
              {otherMonitorings.map((monitoringItem, index) => (
                <Option key={index + '_mon_item'} value={monitoringItem._id}>
                  {monitoringItem.title}
                </Option>
              ))}
            </BaseSelect>
            {selectedAssistantToImport && (
              <Space style={{ margin: '0.5rem' }}>
                <NormalText verticalPadding>
                  {t('sourceUpdateMonitoring.countWebsitesOtherSource', {
                    number: otherMonitorings.find((item) => item._id === selectedAssistantToImport)?.sources.length,
                  })}
                </NormalText>
                <Button type="text" onClick={() => setShowWebsitesPreviewImportModal(!showWebsitesPreviewImportModal)}>
                  {showWebsitesPreviewImportModal
                    ? t('sourceUpdateMonitoring.hideWebsitesPreview')
                    : t('sourceUpdateMonitoring.showWebsitesPreview')}
                </Button>
              </Space>
            )}
            {selectedAssistantToImport && showWebsitesPreviewImportModal && (
              <div style={{ marginTop: '0.5rem', maxHeight: 300, overflowY: 'auto' }}>
                <List
                  bordered
                  dataSource={otherMonitorings
                    .find((item) => item._id === selectedAssistantToImport)
                    ?.sources?.map((item) => item.source)}
                  renderItem={(item) => (
                    <List.Item>
                      <NormalText light size="s">
                        {item}
                      </NormalText>
                    </List.Item>
                  )}
                />
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OverviewWebsites;
