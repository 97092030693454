import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const ScrollDownButton = ({ onScrollDownClick }: { onScrollDownClick: () => void }) => (
  <div>
    <ScrollButton type="dashed" onClick={onScrollDownClick}>
      <DownOutlined />
    </ScrollButton>
  </div>
);

const ScrollButton = styled(Button)`
  position: absolute;
  bottom: 190px;
  right: 50px;
  z-index: 100;

  @media (min-width: 1024px) {
    bottom: 160px;
    right: 80px;
  }

  display: flex;
  height: 36px;
  width: 36px;

  align-items: center;
  justify-content: center;

  border-radius: 50%;
`;

export default ScrollDownButton;
