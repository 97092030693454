import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';

export const SelectSourceRow = styled(Row)`
  padding: 0.5rem 0rem;
`;

export const FilterActionButtonStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  backgroundColor: '#e8e8e8',
  width: '100%',
  padding: '1rem',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};
