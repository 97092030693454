import React, { useEffect, useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import NoPaddingPageContainer from '@app/components/layouts/main/NoPaddingPageContainer/NoPaddingPageContainer';
import { TabBarRow } from './AnalyzeDocument.styles';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

import LlmSettingsModal from '@app/components/gptchatbox/LlmSettingsModal';
import { ChecklistsOverviewContainer } from '@app/components/analyzeDocumentContainer/ChecklistsOverviewContainer';
import { ChecklistReviewResultsOverview } from '@app/components/analyzeDocumentContainer/ChecklistReviewResultsOverview';
import GptDocumentReviewUploader from '@app/components/gpttemplates/DocumentCheck/GptDocumentReviewUploader';

function AnalyzeDocument() {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [initialized, setInitiliazed] = useState<boolean>(false);

  const [tabIndex, setTabIndex] = useState<string>(searchParams.get('tab') || '1');

  useEffect(() => {
    setInitiliazed(true);
  }, []);

  useEffect(() => {
    const searchParamsTab = searchParams.get('tab');
    if (initialized && searchParamsTab && tabIndex !== searchParamsTab) {
      setTabIndex(searchParamsTab);
    }
  }, [searchParams]);

  useEffect(() => {
    setSearchParams({ tab: tabIndex });
  }, [tabIndex]);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('documentAudit'),
      children: (
        <NoPaddingPageContainer>
          <GptDocumentReviewUploader />
        </NoPaddingPageContainer>
      ),
    },
    {
      key: '2',
      label: t('checklistReviewResults'),
      children: (
        <NoPaddingPageContainer>
          <ChecklistReviewResultsOverview />
        </NoPaddingPageContainer>
      ),
    },
    {
      key: '3',
      label: t('internChecklists'),
      children: (
        <NoPaddingPageContainer>
          <ChecklistsOverviewContainer />
        </NoPaddingPageContainer>
      ),
    },
  ];

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <TabBarRow justify={'space-between'} align={'middle'}>
      <DefaultTabBar {...props} />
    </TabBarRow>
  );

  return (
    <>
      <PageTitle>{t('contractAnalyze.shortTitle')}</PageTitle>

      <Tabs
        tabBarStyle={{
          backgroundColor: 'white',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
          marginBottom: 0,
        }}
        renderTabBar={renderTabBar}
        defaultActiveKey={'1'}
        activeKey={tabIndex}
        items={items}
        onChange={(key) => setTabIndex(key)}
      />

      <LlmSettingsModal />
    </>
  );
}

export default AnalyzeDocument;
